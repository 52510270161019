import * as colors from './colors'
import { CURRENT_BRAND } from './config-env'
import * as c from './img-constants'
import { type MiniBrandStruct, SPECTATEST_CONFIG, SWEEPLY_CONFIG } from './mini-brand'

export interface Brand {
    key: string
    name: string
    appLogo: any
    webLogo: any
    webNavBarLogo: any
    openWebNavBarLogo: any
    navBarColor: string
    colorClass: string
    navbarIcons: {
        myTasks: string
        cleaning: string
        camera: string
        issues: string
    }
    notificationColor: string
    urls: {
        marketingSite: string
        webappSite: string
        appStore: string
        playStore: string
        mobileHowTo: string
        helpEmail: string
        guide: string
    }
    intercomAppId?: string
    contactUsLink: string
    firebase?: MiniBrandStruct
}

type LogoInfo = { img: any; style: { width: number; height: number }; url: string }

export const SWEEPLY_BRAND: Brand = {
    key: 'sweeply',
    name: 'Sweeply',
    appLogo: c.IMG_BRAND_SWEEPLY_OPEN_NAVBAR_LOGO,
    webLogo: c.IMG_BRAND_SWEEPLY_OPEN_NAVBAR_LOGO,
    webNavBarLogo: c.IMG_BRAND_SWEEPLY_NAVBAR_LOGO,
    openWebNavBarLogo: c.IMG_BRAND_SWEEPLY_OPEN_NAVBAR_LOGO,
    navBarColor: colors.red_sweeply,
    colorClass: 'sweeply-red',
    navbarIcons: {
        myTasks: require('./img/brand/sweeply/my-tasks-selected.png'),
        cleaning: require('./img/brand/sweeply/cleaning-selected.png'),
        camera: require('./img/brand/sweeply/camera-selected.png'),
        issues: require('./img/brand/sweeply/issues-selected.png')
    },
    notificationColor: colors.white,
    urls: SWEEPLY_CONFIG.urls,
    intercomAppId: 'trrzt06t',
    contactUsLink: 'mailto:support@getsweeply.com'
}

export const SPECTATEST_BRAND: Brand = {
    key: 'spectatest',
    name: 'Spectatest',
    appLogo: c.IMG_BRAND_SPECTATEST_LOGO,
    webLogo: c.IMG_BRAND_SPECTATEST_LOGO,
    webNavBarLogo: c.IMG_BRAND_SPECTATEST_NAVBAR_ICON,
    openWebNavBarLogo: c.IMG_BRAND_SPECTATEST_NAVBAR_ICON,
    navBarColor: colors.yello_spectaflow,
    colorClass: 'specta-yellow',
    navbarIcons: {
        myTasks: require('./img/brand/spectatest/my-tasks-selected.png'),
        cleaning: require('./img/brand/spectatest/cleaning-selected.png'),
        camera: require('./img/brand/spectatest/camera-selected.png'),
        issues: require('./img/brand/spectatest/issues-selected.png')
    },
    notificationColor: colors.red_spectaflow,
    urls: SPECTATEST_CONFIG.urls,
    intercomAppId: 'qge8o33c',
    contactUsLink: 'mailto:support@spectaflow.com'
}

export function getBrand(): Brand {
    let brand: Brand

    if (CURRENT_BRAND === SWEEPLY_BRAND.key) {
        brand = SWEEPLY_BRAND
        brand.firebase = SWEEPLY_CONFIG
    } else {
        brand = SPECTATEST_BRAND
        brand.firebase = SPECTATEST_CONFIG
    }
    return brand
}

export function getNavBarNOAuthLogo(): LogoInfo {
    const logo: LogoInfo = {
        img: SWEEPLY_BRAND.openWebNavBarLogo,
        style: { width: 151, height: 29 },
        url: SWEEPLY_BRAND.urls.webappSite
    }

    if (CURRENT_BRAND === SPECTATEST_BRAND.key) {
        logo.img = SPECTATEST_BRAND.openWebNavBarLogo
        logo.style = {
            width: 151,
            height: 29
        }
        logo.url = SPECTATEST_BRAND.urls.webappSite
    } else if (CURRENT_BRAND === SWEEPLY_BRAND.key) {
        logo.img = SWEEPLY_BRAND.openWebNavBarLogo
        logo.style = {
            width: 220,
            height: 42
        }
        logo.url = SWEEPLY_BRAND.urls.webappSite
    }
    return logo
}

export function getNavBarAuthLogo(): LogoInfo {
    const logo: LogoInfo = {
        img: SWEEPLY_BRAND.webNavBarLogo,
        style: {
            width: 31,
            height: 32
        },
        url: SWEEPLY_BRAND.urls.webappSite
    }
    if (CURRENT_BRAND === SPECTATEST_BRAND.key) {
        logo.img = SPECTATEST_BRAND.webNavBarLogo
        logo.style = {
            width: 35,
            height: 35
        }
        logo.url = SPECTATEST_BRAND.urls.webappSite
    } else if (CURRENT_BRAND === SWEEPLY_BRAND.key) {
        logo.img = SWEEPLY_BRAND.webNavBarLogo
        logo.style = { width: 50, height: 26 }
        logo.url = SWEEPLY_BRAND.urls.webappSite
    }
    return logo
}
