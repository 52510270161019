import AsyncStorage from '@react-native-community/async-storage'
import { isEqual, isFunction } from 'lodash'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { initialFilter } from './GuestsUIHelpers'

export const GuestsContext = createContext()

const initGuest = {
    id: '',
    name: '',
    subscription: '',
    status: 'active',
    synced: '',
    issueHashtags: [],
    assignMaintenance: false
}

export const GuestsProvider = props => {
    const [guests, setGuests] = useState([])
    const [allGuests, setAllGuests] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [listLoading, setListLoading] = useState(true)
    const [filterLoading, setFilterLoading] = useState(true)
    const [queryParams, setQueryParamsBase] = useState(initialFilter)
    const [ids, setIds] = useState([])
    const [guestForEdit, setGuestForEdit] = useState(initGuest)
    const [actionsLoading, setActionsLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    const guestsUIEvents = props.guestsUIEvents

    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }
            return nextQueryParams
        })
    }, [])

    const value = {
        guests,
        setGuests,
        allGuests,
        setAllGuests,
        totalCount,
        setTotalCount,
        listLoading,
        setListLoading,
        ids,
        setIds,
        queryParams,
        setQueryParams,
        setQueryParamsBase,
        initGuest,
        guestForEdit,
        setGuestForEdit,
        actionsLoading,
        setActionsLoading,
        showAlert,
        setShowAlert,
        filterLoading,
        setFilterLoading,
        newGuestButtonClick: guestsUIEvents.newGuestButtonClick,
        openEditGuestDialog: guestsUIEvents.openEditGuestDialog,
        openDeleteGuestDialog: guestsUIEvents.openDeleteGuestDialog,
        openDeleteGuestsDialog: guestsUIEvents.openDeleteGuestsDialog
    }

    return <GuestsContext.Provider value={value} {...props} />
}
