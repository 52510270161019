import { getBrand } from '@shared/brand'
import { green_spectaflow, light_grey_spectaflow, yellow_sweeply } from '@shared/colors'
import styled from 'styled-components'

export type WorkloadColumnProps = { calculated?: boolean; isToday?: boolean }
export const SummaryRow = styled.tr`
    border-top: 2px solid #cccccc;
    font-weight: bold;
`

export const WorkloadColumn = styled.td`
    padding: 1.5rem 0 !important;
    text-align: center;
    color: ${({ calculated }: WorkloadColumnProps) => (calculated ? getBrand().navBarColor : '')};
    border-left: ${({ isToday }: WorkloadColumnProps) => (isToday ? `2px solid ${yellow_sweeply}` : '')};
    border-right: ${({ isToday }: WorkloadColumnProps) => (isToday ? `2px solid ${yellow_sweeply}` : '')};
`

export const SummaryColumn = styled.td`
    text-align: center;
    border-left: ${({ isToday }: WorkloadColumnProps) => (isToday ? `2px solid ${yellow_sweeply}` : '')};
    border-right: ${({ isToday }: WorkloadColumnProps) => (isToday ? `2px solid ${yellow_sweeply}` : '')};
    border-bottom: ${({ isToday }: WorkloadColumnProps) => (isToday ? `2px solid ${yellow_sweeply}` : '')};
`

export const HeaderRow = styled.tr`
    text-align: center;
    text-wrap: none;
`

export const HeaderColumn = styled.td`
    border-left: ${({ isToday }: WorkloadColumnProps) => (isToday ? `2px solid ${yellow_sweeply}` : '')};
    border-right: ${({ isToday }: WorkloadColumnProps) => (isToday ? `2px solid ${yellow_sweeply}` : '')};
    border-top: ${({ isToday }: WorkloadColumnProps) => (isToday ? `2px solid ${yellow_sweeply} !important` : '')};
    border-radius: 6px;
`

export const WorkloadTypeColumn = styled.td`
    font-weight: bold;
`
