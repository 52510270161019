import { atom } from 'recoil'
import type { IntegrationParams, TaskboardContext } from '../../../../../app/src/shared/traces-types'

export const integrationParamsAtom = atom<IntegrationParams<TaskboardContext.RESERVATIONS | TaskboardContext.PROPERTY> | null>({
    key: 'traces-integrationParamsAtom',
    default: null
})

export const lastKnownSearchParamsAtom = atom<string>({
    key: 'traces-searchParamsAtom',
    default: window.location.search
})

export const loadingIntegrationParamsAtom = atom<boolean>({
    key: 'traces-loadingIntegrationParamsAtom',
    default: false
})

export const showEmailPasswordLoginLinkAtom = atom<boolean>({
    key: 'traces-showEmailPasswordLoginLinkAtom',
    default: false
})
