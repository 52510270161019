import * as ahelpers from '@shared/area-groups-helpers'
import type { AreaStruct, UserStruct } from '@shared/firestore-structs'
import * as helpers from '@shared/helpers'
import type React from 'react'
import { useEffect, useState } from 'react'
// TODO: investigate why ts errors on interfaces
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Select, { createFilter, type ActionMeta, type OptionProps, type FilterOptionConfig, type ValueType } from 'react-select'
import type { CSSProperties } from 'styled-components'
import firebase from '../utils/firebase'
import { selectStyles } from './helpers'

export interface AreaOption {
    value: string
    name: string
    description: string
    label: string
    status: string
    address: string
    group: string
    data: AreaStruct
}

const Option: React.FC<OptionProps<AreaOption>> = props => {
    return (
        <div
            key={props.data.value}
            className="border-bottom border-dark-25 px-5"
            onClick={() => props.selectOption(props.data)}
            {...props.innerProps}
            style={props.getStyles('option', props) as CSSProperties}>
            <div>
                <span className="font-size">
                    {helpers.shortenLongName(props.data.name, 19) + ' ' + helpers.shortenLongName(props.data.description, 30)}
                </span>
            </div>
            <div>
                <span className="font-size-sm text-muted">{helpers.shortenLongName(props.data.group, 30)}</span>
            </div>
        </div>
    )
}

interface AreaSelectorProps {
    currentUser: UserStruct
    width?: number
    inputHidden?: boolean
    onChange: (newValue: ValueType<AreaOption, false>, actionMeta: ActionMeta<AreaOption>) => void
    setAreasGroups: (areas: AreaOption[]) => void
    onBlur?: () => void
    setRef?: (ref: any) => void
}

const AreaSelector: React.FC<AreaSelectorProps> = props => {
    const [allAreas, setAllAreas] = useState<AreaOption[]>([])

    const filterConfig: FilterOptionConfig<AreaOption> = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        stringify: (option: AreaOption) => `${option.label} ${option.value}`,
        matchFrom: 'start'
    }

    const updateAreas = () => {
        firebase
            .firestore()
            .collection('areas')
            .where('organizationKey', '==', props.currentUser.organizationKey)
            .where('visible', '==', true)
            .get()
            .then((snapAreas: any) => {
                const areas = snapAreas.docs.map((a: any) => ({
                    value: a.data().key,
                    name: a.data().name,
                    description: a.data().description,
                    label:
                        helpers.shortenLongName(a.data().name, 19) +
                        ' ' +
                        helpers.shortenLongName(a.data().description, 30) +
                        ' ' +
                        helpers.shortenLongName(a.data().address, 30),
                    status: a.data().cleaningStatus,
                    address: a.data().address,
                    group: a.data().group,
                    data: a.data()
                }))

                let sortedAreas = helpers.sortAreas(areas)
                sortedAreas = ahelpers.filterByAreaGroupAccess(props.currentUser, sortedAreas)

                setAllAreas(sortedAreas)
                props.setAreasGroups(sortedAreas)
            })
    }

    const onChange = (value: ValueType<AreaOption, false>, actionMeta: ActionMeta<AreaOption>) => {
        if (value) {
            props.onChange(value, actionMeta)
        }
    }

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur()
        }
    }

    const setRef = (ref: any) => {
        if (props.setRef) {
            props.setRef(ref)
        }
    }

    useEffect(() => {
        updateAreas()
    }, [])

    return (
        <Select
            openMenuOnFocus={true}
            ref={setRef}
            placeholder="Select unit..."
            name="areas"
            isSearchable
            components={{ Option }}
            options={allAreas}
            styles={selectStyles(props)}
            onChange={onChange}
            filterOption={createFilter(filterConfig)}
            // currentUser={props.currentUser}
            noOptionsMessage={() => 'No area found, try another search'}
            onBlur={onBlur}
        />
    )
}

export default AreaSelector
