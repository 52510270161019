import { ALL_FEATURES } from '@shared/subscriptions/feature-items'
import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Image, View } from 'react-native-web'
import { NavLink } from 'react-router-dom'
import { CloseModalButton } from '../../components/buttons/close-modal-button'
import { HoverableTextButton } from '../../components/buttons/hoverable-text-button'
import { FeatureLockedSignal, UpgradeMessageContent, renderAvailbleUpgrades } from '../../components/upgrade-signals'
import * as uc from '../../utils/constants'
import { SubscriptionContext } from './SubscriptionContext'

export function UpgradeModal(props) {
    const [show, setShow] = useState(props.show)
    const { setShowUpgradeModal } = useContext(SubscriptionContext)

    useEffect(() => {
        setShow(props.show)
        setShowUpgradeModal(props.show)
    }, [props.show])

    useEffect(() => {
        setShow(show)
        setShowUpgradeModal(show)
    }, [show])

    const onCloseHandler = () => {
        if (props.onClose) {
            props.onClose()
        }
        setShow(false)
    }

    if (show && props.notAccessibleFeature) {
        return (
            <Modal
                show={show}
                // size="md"
                dialogClassName="modal-upgrade-size"
                contentClassName="px-4"
                onHide={onCloseHandler}
                centered={true}
                aria-labelledby="example-modal-sizes-title-lg">
                <div
                    style={{
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        height: uc.EXTRA_SMALL_BUTTON_DIM
                    }}>
                    {<CloseModalButton dimension={14} style={{ zIndex: 3000 }} close={onCloseHandler} />}
                </div>
                <div className="modal-header border-bottom-0">
                    <div className="col p-0 m-0">
                        <div className="mt-3 d-flex">
                            {/* <div style={{ position: 'absolute', top: 15 }}> */}
                            <FeatureLockedSignal justifyContent={'flex-start'} isActive={false} />
                            {/* </div> */}
                        </div>
                        <div className="d-flex">
                            <span
                                style={{
                                    fontSize: ALL_FEATURES[props.notAccessibleFeature].title.length > 20 ? 20 : 22,
                                    fontWeight: '500'
                                }}>
                                {ALL_FEATURES[props.notAccessibleFeature].title}
                            </span>
                        </div>
                    </div>
                </div>

                <Modal.Body className="overlay overlay-block cursor-default pt-2">
                    <div className="d-flex flex-center">
                        <Image style={{ marginTop: 0, width: 134, height: 111 }} source={require('../../img/feature-lock.svg')} />
                    </div>
                    <div className="d-flex flex-center">
                        <span
                            className="text-mute"
                            style={{
                                lineHeight: 1.25,
                                textAlign: 'center',
                                marginTop: 24,
                                fontSize: 16
                            }}>
                            <span style={{ lineHeight: 2 }}>
                                Try our {renderAvailbleUpgrades(props.availableUpgrades)} subscription for 30 days. No credit card needed!
                            </span>
                        </span>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0 flex-center">
                    <div className="d-flex flex-center">
                        <NavLink to={'/settings/account/subscription'}>
                            <HoverableTextButton text={'Learn more'} className="btn btn-warning" />
                        </NavLink>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }

    return null
}
