import { getBrand } from '@shared/brand'
import * as colors from '@shared/colors'
import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from '../../../utils/constants'

const black = colors.black_sweeply
const brandColor = getBrand().navBarColor

export const LoginLayoutWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 0.65fr 3fr 0.3fr;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        label {
            font-size: 12px;
        }
    }
`

export const FooterWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    span,
    a {
        font-weight: 450;
        font-size: 14px;
        line-height: 24px;
    }

    span {
        color: ${black};
    }

    a {
        color: ${brandColor};
        font-weight: 700;
    }

    > div {
        display: flex;
        gap: 15px;
    }

    .switcher {
        position: absolute;
        bottom: -20px;
        right: -70px;
    }
`
