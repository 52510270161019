import React, { useMemo, useContext } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import MainActionButton from '../../../components/buttons/MainActionButton'
import { UsersContext } from '../UsersContext'
import { UsersFilter } from './users-filter/UsersFilter'
import { UsersGrouping } from './users-grouping/UsersGrouping'
import { UsersTable } from './users-table/UsersTable'

export function UsersCard({ currentOrganization }) {
    const { ids, newUserButtonClick, allUsers } = useContext(UsersContext)
    const usersUIProps = useMemo(() => {
        return {
            ids: ids,
            newUserButtonClick: newUserButtonClick
        }
    }, [ids, newUserButtonClick])

    return (
        <Card className="card-no-shadow">
            <CardHeader count={allUsers.length} title="Users">
                <CardHeaderToolbar>
                    <MainActionButton type="primary" size="large" onClick={usersUIProps.newUserButtonClick}>
                        New User
                    </MainActionButton>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <UsersFilter />
                {usersUIProps.ids.length > 0 && <UsersGrouping />}
                <UsersTable currentOrganization={currentOrganization} />
            </CardBody>
        </Card>
    )
}
