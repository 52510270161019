import { pageIsNotExist, search, sortByName } from '@shared/helpers'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import moment from 'moment-timezone'
import React, { useState, useEffect, useMemo, useContext } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    getHandlerTableChange,
    headerSortingClasses,
    sortCaret
} from '../../../../../_metronic/_helpers'
import { Pagination } from '../../../../../_metronic/_partials/controls'
import { Tooltip } from '../../../../components/misc/Tooltip'
import { db } from '../../../../utils/firebase'
import LoadingView from '../../../../utils/loading-view'
import { AuthContext } from '../../../Auth/AuthContext'
import { UsersContext } from '../../UsersContext'
import * as uiHelpers from '../../UsersUIHelpers'
import { UserDeleteDialog } from '../user-delete-dialog/UserDeleteDialog'
import * as columnFormatters from './column-formatters'

import { httpsCallable } from 'app/api'
import { USERS } from 'app/navigation/url-constants'
import { useFeatureToggle } from '../../../../features'
import { UserQrDialog } from '../user-qr-dialog/UserQrDialog'

export function UsersTable(props) {
    const [deleteUserId, setDeleteUserId] = useState(null)
    const [appUrlToLogin, setAppUrlToLogin] = useState(null)
    const [stateLoading, setStateLoading] = useState(false)
    const [stateSelectedUser, setStateSelectedUser] = useState(null)
    const [openQrDialog, setOpenQrDialog] = useState(false)
    const { isFeatureOn } = useFeatureToggle()

    const {
        users,
        setUsers,
        setAllUsers,
        totalCount,
        setTotalCount,
        listLoading,
        setListLoading,
        ids,
        setIds,
        queryParams,
        setQueryParams,
        openUserDialog,
        openEditUserDialog,
        openDeleteUserDialog
    } = useContext(UsersContext)

    const { user } = useContext(AuthContext)

    const fields = props.fields

    const usersUIProps = useMemo(() => {
        return {
            ids: ids,
            setIds: setIds,
            queryParams: queryParams,
            setQueryParams: setQueryParams,
            openEditUserDialog: openEditUserDialog,
            openDeleteUserDialog: openDeleteUserDialog,
            openUserDialog: openUserDialog
        }
    }, [ids, setIds, queryParams, setQueryParams, openEditUserDialog, openDeleteUserDialog, openUserDialog])

    useEffect(() => {
        setListLoading(true)

        const sortUsers = users => {
            const sortProperty = queryParams.sortField
            let sortedUsers = []
            if (sortProperty === 'name') {
                sortedUsers = users.sort((a, b) => sortByName(a.name, b.name))
            } else {
                sortedUsers = users.sort((a, b) => (a[sortProperty] > b[sortProperty] ? 1 : -1))
            }

            if (queryParams.sortOrder === 'desc') {
                sortedUsers = sortedUsers.reverse()
            }

            sortedUsers = sortedUsers.slice(
                (queryParams.pageNumber - 1) * queryParams.pageSize,
                queryParams.pageNumber * queryParams.pageSize
            )
            return sortedUsers
        }

        const getUsers = async () => {
            let usersRef = await db
                .collection('users')
                .where('organizationKey', '==', user.organizationKey)
                .where('visible', '==', true)
                .get()

            let users = usersRef.docs.map(u => u.data())

            let fetchedUsers = users.map(user => {
                const { key, name, email, created, phoneNumber, areaGroups, initials, issueHashtags, lastLogin, appVersion } = user

                const areaGroupsCondition = areaGroups ? areaGroups.join(', ') : ''
                const deviceCondition = user.appOS === 'ios' ? 'iOS ' : 'Android '

                return {
                    id: key,
                    name,
                    email,
                    created,
                    phoneNumber,
                    areaGroups: areaGroupsCondition,
                    initials,
                    issueHashtags,
                    lastLogin,
                    device: deviceCondition,
                    version: appVersion
                }
            })

            fetchedUsers.forEach(u => {
                const authUser = users.find(au => u.id === au.key)
                let roles = []
                if (authUser) {
                    roles = authUser.authRole === 'admin' ? ['Admin'] : []
                    u.roles = roles
                }
            })

            setAllUsers(fetchedUsers)

            const filteredUsers = search(queryParams.filter.name, fetchedUsers, user => {
                return user.name + ' ' + user.email + ' ' + user.phoneNumber
            })

            const sortedUsers = sortUsers(filteredUsers)
            const totalCount = filteredUsers.length
            const { setQueryParams } = usersUIProps
            const { pageSize, pageNumber } = usersUIProps.queryParams

            if (pageIsNotExist({ totalCount, pageSize, pageNumber })) {
                setQueryParams({ ...usersUIProps.queryParams, pageNumber: 1 })
            }

            setUsers(sortedUsers)
            setTotalCount(totalCount)
            setListLoading(false)
        }

        getUsers()
    }, [
        queryParams.pageNumber,
        queryParams.pageSize,
        queryParams.sortField,
        queryParams.sortOrder,
        setUsers,
        setIds,
        setListLoading,
        setTotalCount,
        user.organizationKey,
        ids,
        queryParams.filter.status,
        queryParams,
        setAllUsers
    ])

    useEffect(() => {
        setQueryParams({ ...usersUIProps.queryParams, pageNumber: 1 })
    }, [usersUIProps.queryParams.filter.name])

    const openUserQrDialog = async userKey => {
        try {
            setStateLoading(true)
            setStateSelectedUser(userKey)
            const generateAuthUserToken = httpsCallable('getAuthUserToken')
            const { data: tokenHash } = await generateAuthUserToken({ userKey, organizationKey: user.organizationKey })

            const url = `${USERS.LOGIN}/token?token=${tokenHash}`
            setAppUrlToLogin(url)
            setOpenQrDialog(true)
            setStateLoading(false)
        } catch (error) {
            console.error({ error })
            setStateLoading(false)
        }
    }

    const allColumns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            classes: 'font-weight-bolder'
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            style: { maxWidth: '10%' },
            headerStyle: { maxWidth: '10%' }
        },
        {
            dataField: 'phoneNumber',
            text: 'Phone number',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            // eslint-disable-next-line react/display-name
            formatter: cellContent => {
                const phoneNumber = parsePhoneNumberFromString(cellContent).formatInternational()
                return <span>{phoneNumber}</span>
            }
        },
        {
            dataField: 'roles',
            text: 'Roles',
            sort: false,
            sortCaret: sortCaret,
            // eslint-disable-next-line react/display-name
            formatter: cellContent => {
                if (cellContent) {
                    const icons = []
                    cellContent.forEach(role => {
                        icons.push(role.toLowerCase())
                    })
                    return (
                        <>
                            {cellContent.map(role => {
                                return (
                                    <Tooltip key={role} text={role}>
                                        <span className="mr-2">
                                            <i key={role} className={'specta-' + role.toLowerCase() + ' icon-md'} />
                                        </span>
                                    </Tooltip>
                                )
                            })}
                        </>
                    )
                }
            },
            headerSortingClasses,
            style: {
                minWidth: '80px'
            }
        },
        {
            dataField: 'areaGroups',
            text: 'Unit groups',
            sort: true,
            sortCaret: sortCaret,
            style: { maxWidth: '20%' }
        },
        {
            dataField: 'lastLogin',
            text: 'Last login',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            // eslint-disable-next-line react/display-name
            formatter: cellContent => {
                const lastLogin = cellContent ? moment(cellContent).fromNow() : 'Never logged into app'
                return <span>{lastLogin}</span>
            }
        },
        {
            dataField: 'created',
            text: 'Created',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            // eslint-disable-next-line react/display-name
            formatter: cellContent => {
                const formattedCreated = new Date(cellContent).toLocaleDateString('en-GB')
                return <span>{formattedCreated}</span>
            }
        },
        {
            dataField: 'version',
            text: 'App version',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openEditUserDialog: usersUIProps.openEditUserDialog,
                setDeleteUserId,
                openUserQrDialog,
                currentUser: user,
                stateSelectedUser,
                stateLoading,
                isFeatureOn
            },
            classes: 'text-right pr-0 px-5',
            headerClasses: 'text-right pr-3 px-5',
            style: {
                minWidth: '230px'
            }
        }
    ]

    const columns = fields ? allColumns.filter(c => fields.includes(c.dataField)) : allColumns
    columns.forEach(col => {
        col.events = {
            onClick: (e, column, columnIndex, row) => {
                if (column.dataField !== 'action') {
                    usersUIProps.openEditUserDialog(row.id)
                }
            }
        }
    })

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber
    }

    return (
        <div>
            <UserDeleteDialog deleteUserId={deleteUserId} onHide={() => setDeleteUserId(null)} />
            {openQrDialog && <UserQrDialog appUrl={appUrlToLogin} onClose={() => setOpenQrDialog(false)} isOpen={openQrDialog} />}

            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination isLoading={listLoading} paginationProps={paginationProps}>
                            <BootstrapTable
                                rowStyle={{ cursor: 'pointer' }}
                                hover={true}
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                remote
                                keyField="id"
                                data={users === null ? [] : users}
                                columns={columns}
                                defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(usersUIProps.setQueryParams)}
                                {...paginationTableProps}>
                                <PleaseWaitMessage entities={users} />
                                <NoRecordsFoundMessage entities={users} />
                            </BootstrapTable>
                        </Pagination>
                    )
                }}
            </PaginationProvider>

            {listLoading && <LoadingView />}
        </div>
    )
}
