// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from 'react'
import { httpsCallable } from '../../../../../api'
import firebase from '../../../../../utils/firebase'

export function ActionsColumnFormatter(
    cellContent,
    row,
    rowIndex,
    { openEditAccountDialog, setListLoading, setLoadingAuthState, onAccountDeleteClick, allowedAccounts }
) {
    return (
        <>
            <a
                title="Edit account"
                className="btn btn-icon btn-circle btn-bg-specta-gray btn-hover-bg-specta-gray-light btn-hover-icon-primary btn-sm mx-3"
                onClick={() => openEditAccountDialog(row.id)}>
                <i className="specta-pencil2" />
            </a>
            <> </>
            {allowedAccounts.includes(row.id) && (
                <a
                    title="Switch org"
                    className="btn btn-icon btn-circle btn-bg-specta-gray btn-hover-bg-specta-gray-light btn-hover-icon-info btn-sm"
                    onClick={async () => {
                        try {
                            const changeOrgCloudFunc = httpsCallable('userChangeOrganization')

                            setListLoading(true)
                            setLoadingAuthState(true)

                            const result = await changeOrgCloudFunc({ organizationKey: row.id })

                            if (result.data !== null) {
                                // Update current user claims immediately
                                await firebase.auth().currentUser.getIdToken(true)
                                setListLoading(false)
                                document.location.reload()
                            }
                        } catch (error) {
                            console.error('error', error)
                        }
                    }}>
                    <i className="fa fa-arrow-right" />
                </a>
            )}
            {/* <> </>
            <a
                onClick={() => onAccountDeleteClick({ organizationKey: row.id, organizationName: row.name })}
                className="btn btn-icon btn-circle btn-bg-specta-gray btn-hover-bg-specta-gray-light btn-hover-icon-danger btn-sm">
                <i className="specta-trash" />
            </a> */}
        </>
    )
}
