import { isEqual, isFunction } from 'lodash'
import moment from 'moment-timezone'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { initialFilter } from './RulesUIHelpers'

export const RulesContext = createContext()

const initRule = {
    name: '',
    description: '',
    trigger: 'booking',
    repeatType: 'custom',
    repeatInterval: 1,
    repeatDays: [],
    repeatOffsetStart: 1,
    repeatOffsetEnd: 1,
    start: moment().startOf('day').valueOf(),
    end: moment().startOf('day').add(6, 'months').valueOf(),
    taskColor: '',
    taskName: '',
    estimatedTime: 60,
    checklistTasks: [''],
    areas: [],
    inspection: false,
    priority: 2,
    isDefaultRule: false
}

export const RulesProvider = props => {
    const [rules, setRules] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [listLoading, setListLoading] = useState(true)
    const [queryParams, setQueryParamsBase] = useState(initialFilter)
    const [ids, setIds] = useState([])
    const [ruleForEdit, setRuleForEdit] = useState(initRule)
    const [actionsLoading, setActionsLoading] = useState(false)

    const rulesUIEvents = props.rulesUIEvents

    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }
            return nextQueryParams
        })
    }, [])

    const value = {
        rules,
        setRules,
        totalCount,
        setTotalCount,
        listLoading,
        setListLoading,
        ids,
        setIds,
        queryParams,
        setQueryParams,
        setQueryParamsBase,
        initRule,
        ruleForEdit,
        setRuleForEdit,
        actionsLoading,
        setActionsLoading,
        newRuleButtonClick: rulesUIEvents.newRuleButtonClick,
        openEditRuleDialog: rulesUIEvents.openEditRuleDialog,
        openRuleAreasDialog: rulesUIEvents.openRuleAreasDialog
    }

    return <RulesContext.Provider value={value} {...props} />
}

// export const useAreasContext = () => {
// 	const context = useContext(AreasContext)

// 	if (!context) {
// 		throw new Error("useAreasContext must be used inside a AreasProvider")
// 	}

// 	const { state, dispatch } = context
// 	console.log(context)
// 	const { areas } = state

// 	return { areas }
// }
