import { useFeatureToggle } from 'app/features'
import React, { useEffect } from 'react'
import { compose } from 'redux'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { INTEGRATIONS } from '../../../../utils/constants'
import firebase, { asFirebase } from '../../../../utils/firebase'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import LoadingScreen from '../../LoadingScreen'
import SignUpLayout from '../../SignUpLayout'
import { noPMSSetup } from '../../cloud-functions'
import { getApi } from '../../frontend-api'
import { errorModalConfigs } from '../../helpers'

const NoPmsCallback = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}) => {
    const { isFeatureOn } = useFeatureToggle()
    const useNewInitialImport = isFeatureOn('new-initial-import-procedure')

    const setupNoPMSOld = async () => {
        console.info('Initial NoPMS Sync: Using old method')
        try {
            const setupNoPMSIntegration = noPMSSetup()

            const { data } = await setupNoPMSIntegration({
                orgId: '' + newOrgKey,
                integration: INTEGRATIONS.NO_PMS,
                signupPayload: { organizationID: newOrgKey }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.NO_PMS_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupNoPMSNew = async () => {
        console.info('Initial NoPMS Sync: Using new server-side method')
        try {
            const api = await getApi(asFirebase(firebase))

            await api['setup-org'].$post({
                json: {
                    newAdminUserKey: newUserKey,
                    orgId: newOrgKey,
                    version: 'stable',
                    integration: 'nopms',
                    isTest: 'test' in workflowInstance.values,
                    signupPayload: {
                        organizationID: newOrgKey
                    }
                }
            })

            workflowInstance.completeStep(SIGN_UP.NO_PMS_CALLBACK, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)
            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupNoPMS = async () => (useNewInitialImport ? await setupNoPMSNew() : await setupNoPMSOld())

    async function setup() {
        setStopAuthListener(true)
        await setupNoPMS()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(NoPmsCallback)
