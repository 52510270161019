import { getBrand } from '@shared/brand'
import { Field, Form, Formik } from 'formik'
import type React from 'react'
import { type PropsWithChildren, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as Yup from 'yup'
import RectangularButton from '../../../../components/buttons/RectangularButton'
import { type CountryInfo, getAllCountries, getCountryItem, getGeoLocation } from '../../../../components/country-selector'
import Input from '../../../../components/forms/Input'
import PhoneInput from '../../../../components/forms/PhoneInput'
import { getRecaptchaVerifier } from '../../../../utils/app-verifier'
import firebase from '../../../../utils/firebase'
import { formatPhoneInputValue, phoneNumberValidationTest } from '../../../../utils/formatters'
import { toastErrorStyle } from '../../../../utils/styles'
import { AuthContext } from '../../../Auth/AuthContext'
import { confirmationAtom, formIsOpenAtom, signUpDataAtom } from '../../state'
import { FormBox } from './style'
import { type SignUpChangeEvent, extractErrorMessage } from './types'

type SignUpSubmitEvent = React.FormEvent<HTMLFormElement> & React.MouseEvent<HTMLFormElement>

type SignUpFormProps = PropsWithChildren<{ setCodeVerificationModalIsOpen: (value: boolean) => void }>

const SignUpForm = ({ setCodeVerificationModalIsOpen }: SignUpFormProps) => {
    const initialValues = { orgName: '', ownerPhoneNumber: '', ownerName: '', ownerEmail: '' }

    const [selectedCountry, setSelectedCountry] = useState<CountryInfo | null>(null)
    const formIsOpen = useRecoilValue(formIsOpenAtom)
    const { setStopAuthListener } = useContext(AuthContext)
    const setSignUpData = useSetRecoilState(signUpDataAtom)
    const setConfirmation = useSetRecoilState(confirmationAtom)
    const brand = getBrand()
    const brandColor = brand.navBarColor
    const brandName = brand.name

    const accountSchema = Yup.object().shape({
        orgName: Yup.string().required('Account name is required'),
        ownerName: Yup.string().required('Full name is required'),
        ownerEmail: Yup.string().email('Email must be valid').required('Email is required'),
        ownerPhoneNumber: Yup.string()
            .test('ownerPhoneNumber', 'Phone number must be valid', function (value) {
                return phoneNumberValidationTest(value, selectedCountry)
            })
            .required('Phone number is required')
    })

    type Account = Yup.InferType<typeof accountSchema>

    useEffect(() => {
        const allCountries = getAllCountries().map(country => ({ ...country, label: country.dialCode }))

        getGeoLocation(allCountries, country => {
            if (country) {
                setSelectedCountry(country)
            } else {
                setSelectedCountry(getCountryItem('US', allCountries))
            }
        })
    }, [])

    const onSubmit = async (values: Account) => {
        setCodeVerificationModalIsOpen(true)
        setStopAuthListener(true)

        const { orgName, ownerName, ownerEmail, ownerPhoneNumber } = values
        if (!selectedCountry) throw new Error('No country selected to get dial code!')
        const phoneNumber = selectedCountry.dialCode + ownerPhoneNumber

        try {
            const appVerifier = getRecaptchaVerifier()
            const confirmation = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)

            setSignUpData({
                orgName,
                ownerName,
                ownerEmail,
                phoneNumber
            })
            setConfirmation(confirmation)
        } catch (error) {
            const message = extractErrorMessage(error)
            setCodeVerificationModalIsOpen(false)
            toast.error(message, toastErrorStyle)
            console.error(error)
        }
    }

    return (
        <FormBox formIsOpen={formIsOpen}>
            <h1>Your {brandName} Account</h1>

            <Formik enableReinitialize initialValues={initialValues} validationSchema={accountSchema} onSubmit={onSubmit}>
                {({ handleSubmit, setFieldValue, values }) => {
                    const { orgName, ownerName, ownerEmail, ownerPhoneNumber } = values

                    return (
                        <Form>
                            <Field
                                autoFocus
                                name={'orgName'}
                                value={orgName}
                                component={Input}
                                label={'Account name'}
                                placeholder={'Name of your hotel or organization'}
                                onChange={(e: SignUpChangeEvent) => setFieldValue('orgName', e.target.value)}
                            />

                            <Field
                                name={'ownerName'}
                                value={ownerName}
                                component={Input}
                                label={'Full name'}
                                placeholder={'Your name'}
                                onChange={(e: SignUpChangeEvent) => setFieldValue('ownerName', e.target.value)}
                            />

                            <Field
                                name={'ownerEmail'}
                                value={ownerEmail}
                                component={Input}
                                label={'Email'}
                                placeholder={'Your email address'}
                                onChange={(e: SignUpChangeEvent) => setFieldValue('ownerEmail', e.target.value)}
                            />

                            <Field
                                name={'ownerPhoneNumber'}
                                component={PhoneInput}
                                label={'Mobile phone number'}
                                placeholder={'Your mobile phone number'}
                                value={formatPhoneInputValue(ownerPhoneNumber, selectedCountry)}
                                selectedCountry={selectedCountry}
                                onChange={(e: SignUpChangeEvent) => setFieldValue('ownerPhoneNumber', e.target.value)}
                                onCountryChange={setSelectedCountry}
                            />

                            <RectangularButton
                                type="submit"
                                // @ts-ignore
                                onClick={handleSubmit}
                                width={'318px'}
                                height={'64px'}
                                backgroundColor={brandColor}
                                margin={'62px 0'}>
                                Create your account
                            </RectangularButton>

                            <div id="recaptcha-container" />
                        </Form>
                    )
                }}
            </Formik>
            <h3>
                By signing up for Sweeply, I state that I have read and understood the{' '}
                <a href={'https://getsweeply.com/terms'} target={'_blank'} rel="noreferrer">
                    terms and conditions
                </a>
                .
            </h3>
        </FormBox>
    )
}

export default SignUpForm
