import * as colors from '@shared/colors'
import styled from 'styled-components'
import { MEDIUM_DESKTOP_SCREEN_HEIGHT, MOBILE_BREAKPOINT } from '../../../../utils/constants'

const red = colors.red_sweeply
const black = colors.black_sweeply
const yellow = colors.yellow_sweeply

interface IntroductionWrapProps {
    formIsOpen: boolean
    isMobile: boolean
    brandColor: string
}

interface PmsIntegrationWrapProps {
    formIsOpen: boolean
    isMobile: boolean
    isImageWithMargins: boolean
}

const getBrandColor = ({ brandColor }: Pick<IntroductionWrapProps, 'brandColor'>) => (brandColor ? brandColor : red)

export const IntroductionWrap = styled.div<IntroductionWrapProps>`
    padding-left: 15px;

    .introduction {
        color: ${black};
        display: ${({ formIsOpen, isMobile }) => {
            if (isMobile) {
                return formIsOpen ? 'none' : 'block'
            }
            return 'block'
        }};

        > h1 {
            font-family: 'Circular Std';
            margin-top: 102px;
            margin-bottom: 24px;
            font-style: normal;
            font-weight: 700;
            font-size: 3.692rem;
            line-height: 64px;

            span {
                color: ${getBrandColor};
                position: relative;
                display: inline-block;

                > svg {
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                }
            }
        }

        > p {
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: normal;
            font-size: 1.846rem;
            line-height: 40px;
            width: 85%;
            margin-bottom: 131px;

            b {
                color: ${getBrandColor};
            }
        }
    }

    @media (max-height: ${MEDIUM_DESKTOP_SCREEN_HEIGHT}px) {
        .introduction {
            > h1 {
                margin-top: 60px;
            }
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        padding: 0;
        width: 100%;

        .img-container {
            width: 100%;

            > img {
                width: 128px !important;
                height: 23.46px !important;
                bottom: 0;
            }
        }

        .introduction {
            > h1 {
                font-size: 2.667rem;
                margin-top: 86.54px;
                margin-bottom: 16px;
                line-height: 48px;

                > span {
                    > svg {
                        width: 62.77px;
                        height: 10.49px;
                        bottom: -5.5px;
                    }
                }
            }

            > p {
                font-size: 1.333rem;
                line-height: 32px;
                width: 100%;
                margin-bottom: 41px;
            }
        }

        > button {
            font-size: 1.333rem !important;
            line-height: 24px !important;
        }
    }
`

export const PmsIntegrationWrap = styled.div<PmsIntegrationWrapProps>`
    display: ${({ formIsOpen, isMobile }) => {
        if (isMobile) {
            return formIsOpen ? 'none' : 'flex'
        }
        return 'flex'
    }};
    column-gap: 30px;
    height: 121px;

    .line {
        height: 100%;
        width: 8px;
        border-radius: 8px;
        background-color: ${yellow};
    }

    .content {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        height: 100%;
        justify-content: flex-end;
        position: relative;
        padding: 0;
        padding-bottom: 10px;

        img {
            position: absolute;
            top: ${({ isImageWithMargins }) => (isImageWithMargins ? '-40px' : '0')};
            left: 0;
            width: 122px;
            height: auto;
        }

        p {
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 450;
            font-size: 1.077rem;
            line-height: 24px;
            opacity: 0.5;
            color: ${black};
            width: 85%;
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        margin-bottom: 13px;

        .line {
            display: none;
        }

        .content {
            justify-content: start;
            row-gap: 10px;
            padding: 0;
            width: 100%;

            img {
                top: ${({ isImageWithMargins }) => (isImageWithMargins ? '-20px' : '0')};
                width: 75px !important;
                height: auto;
            }

            p {
                position: absolute;
                bottom: 33px;
                width: 85%;
                font-size: 1rem;
                line-height: 24px;
            }
        }
    }
`
