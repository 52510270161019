import { iOSColors } from '@shared/react-native-typography'
import React, { useState } from 'react'
import { Image, TouchableOpacity, View } from 'react-native-web'
import Tooltip from '../tooltips/BlackTooltip'

interface HoverableImageButtonProps {
    dimension: number
    hoverOpacity: number
    imageSource: string
    onPress: () => void

    hoverImageSource?: string
    tooltipDistance?: number
    tooltipOffset?: number
    tooltipText?: string
    useHover?: boolean
    disabledOpacity?: number
    height?: number
    width?: number
    disabled?: boolean
    tooltipDisabled?: boolean
    viewWidth?: number | string
    text?: string
    fontSize?: number
    fontWeight?: string
    fontColor?: string
    isActive?: boolean
    justifyContent?: string | undefined
    isView?: boolean
    imgStyle?: any
    rightIcon?: string
    debug?: boolean
}

export const HoverableImageButton = React.forwardRef<any, HoverableImageButtonProps>((props, ref) => {
    const [hover, setHover] = useState(false)

    const hoverOpacity = props.hoverOpacity || 0.5
    const useHover = props.useHover || false
    const dimension = props.dimension || 32
    const disabledOpacity = props.disabledOpacity || undefined

    let height
    let width
    if (props.height && props.width) {
        height = props.height
        width = props.width
    } else {
        height = dimension
        width = dimension
    }

    let opacity
    if (props.disabled) {
        opacity = 0.3
        if (useHover) {
            opacity = hover ? hoverOpacity : 1
        } else if (disabledOpacity) {
            opacity = disabledOpacity
        } else {
            opacity = hover ? hoverOpacity : 1
        }
    } else {
        opacity = hover ? hoverOpacity : 1
    }
    const tooltipText = props.tooltipText ? props.tooltipText : ''
    let tooltipDisabled = props.tooltipDisabled ? props.tooltipDisabled : false
    tooltipDisabled = tooltipText === '' ? true : false
    const viewWidth = props.viewWidth ? props.viewWidth : dimension

    const text = props.text ? props.text : null
    const font_size = props.fontSize ? props.fontSize : 15
    const font_weight = props.fontWeight ? props.fontWeight : '400'
    const font_color = props.fontColor ? props.fontColor : iOSColors.black
    const isActive = props.isActive ? true : false
    const imageSource = props.imageSource ? props.imageSource : null
    const hoverImageSource = props.hoverImageSource ? props.hoverImageSource : null

    const justify_content = props.justifyContent ? props.justifyContent : 'flex-start'

    let selectedSource = imageSource
    if (hoverImageSource && hover) {
        opacity = 1
        selectedSource = hoverImageSource
    }

    if (isActive && hover) {
        opacity = 0.3
        selectedSource = hoverImageSource
    } else if (isActive) {
        opacity = 1
        selectedSource = hoverImageSource
    }

    const isView = props.isView ? props.isView : false
    const Content = text && <span style={{ marginLeft: 9, fontWeight: font_weight, fontSize: font_size, color: font_color }}>{text}</span>

    let img_style
    if (props.imgStyle) {
        img_style = props.imgStyle
    } else {
        img_style = {
            height: height,
            width: width
        }
    }

    const show_right_icon = hover && props.rightIcon ? true : false

    const Pressable = (
        <TouchableOpacity
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={{
                height: dimension,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: justify_content,
                opacity,
                borderWidth: props.debug ? 1 : 0,
                outline: 'none'
            }}
            disabled={props.disabled}
            onPress={props.onPress}>
            {selectedSource && <Image style={img_style} source={{ uri: selectedSource }} />}
            {Content}
            {props.rightIcon && show_right_icon && (
                <Image source={{ uri: props.rightIcon }} style={{ marginTop: 1, marginLeft: 9, width: 14, height: 14 }} />
            )}
            {props.rightIcon && !show_right_icon && <View style={{ marginTop: 1, marginLeft: 9, width: 14, height: 14 }} />}
        </TouchableOpacity>
    )

    const NonPressable = (
        <View
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={{
                height: dimension,
                width: viewWidth,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: justify_content,
                opacity,
                borderWidth: props.debug ? 1 : 0,
                outline: 'none'
            }}
        >
            {selectedSource && <Image style={img_style} source={{ uri: selectedSource }} />}
            {Content}
        </View>
    )

    const displayComponent = isView ? NonPressable : Pressable

    return (
        <Tooltip disabled={props.disabled || tooltipDisabled} content={tooltipText} placement="top">
            {displayComponent}
        </Tooltip>
    )
})

HoverableImageButton.displayName = 'HoverableImageButton'
