import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import guestyLogo from '../../../../img/pms/guesty/guesty.png'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { PMS_NAMES } from '../../../../utils/constants'
import { startWorkflow } from '../../helpers'
import { GUESTY_WORKFLOW } from '../../signup-worflow-templates'

const GuestyStart = () => {
    const history = useHistory()

    useEffect(() => {
        startWorkflow({
            bus: PMS_NAMES.GUESTY,
            imageUrl: guestyLogo,
            workflowTemplate: GUESTY_WORKFLOW,
            currentStep: SIGN_UP.GUESTY_START,
            history
        })
    }, [])

    return <></>
}

export default GuestyStart
