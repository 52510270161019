import React, { useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { LayoutSplashScreen } from '../../../_metronic/layout'
import firebase from '../../utils/firebase'
import { AuthContext } from '../Auth/AuthContext'

export default function Logout() {
    const { isAuthorized } = useContext(AuthContext)
    const { shutdown } = useIntercom()
    useEffect(() => {
        if (isAuthorized) {
            shutdown()
            firebase.auth().signOut()
        }
    }, [isAuthorized])

    return isAuthorized ? <LayoutSplashScreen /> : <Redirect to="/login" />
}
