import React from 'react'
import { useHistory } from 'react-router-dom'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { getWorkflowInstance, storeWorkflowInstance } from '../../../../utils/signup-flow-template/signup-workflow-instance'
import ApiKeyForm from '../../ApiKeyForm'
import { currentIntegrationKey } from '../../signup-worflow-templates'

const BluelagoonAuthorize = () => {
    const history = useHistory()
    const workflowInstance = getWorkflowInstance(currentIntegrationKey)
    const bus = workflowInstance.getInput('bus')

    const onSubmit = values => {
        const { apiKey } = values
        workflowInstance.completeStep(SIGN_UP.BLUE_LAGOON_AUTHORIZE, { apiKey })
        storeWorkflowInstance(currentIntegrationKey, workflowInstance)
        setTimeout(() => history.push(workflowInstance.nextStep()), 10)
    }

    return <ApiKeyForm bus={bus} apiKeyUrl={''} onSubmit={onSubmit} />
}

export default BluelagoonAuthorize
