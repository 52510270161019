import React, { useContext } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { AccountDeleteDialog } from './account-delete-dialog/AccountDeleteDialog'
import { AccountEditDialog } from './account-edit-dialog/AccountEditDialog'
// import { CustomersFetchDialog } from "./customers-fetch-dialog/CustomersFetchDialog"
// import { CustomersUpdateStateDialog } from "./customers-update-status-dialog/CustomersUpdateStateDialog"

import { AccountsProvider } from '../AccountsContext'
// import { CustomersCard } from "./CustomersCard"
import { AccountsCard } from './AccountsCard'

export default function AccountsPage() {
    let history = useHistory()
    const accountsUIEvents = {
        newAccountButtonClick: () => {
            history.push('/partner/accounts/new')
        },
        openEditAccountDialog: id => {
            history.push(`/partner/accounts/${id}/edit`)
        },
        openDeleteAccountDialog: id => {
            history.push(`/partner/accounts/${id}/delete`)
        },
        openDeleteAccountsDialog: () => {
            history.push('/partner/accounts/deleteAccounts')
        }
    }

    return (
        <AccountsProvider accountsUIEvents={accountsUIEvents}>
            <Route path="/partner/accounts/new">
                {({ history, match }) => (
                    <AccountEditDialog
                        show={match != null}
                        onHide={() => {
                            history.push('/partner/accounts')
                        }}
                    />
                )}
            </Route>
            <Route path="/partner/accounts/:id/edit">
                {({ history, match }) => (
                    <AccountEditDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            // setAccountForEdit(initAccount)
                            history.push('/partner/accounts')
                        }}
                    />
                )}
            </Route>
            <Route path="/partner/accounts/:id/delete">
                {({ history, match }) => (
                    <AccountDeleteDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            // setAccountForEdit(initAccount)
                            history.push('/partner/accounts')
                        }}
                    />
                )}
            </Route>
            <AccountsCard />
        </AccountsProvider>
    )
}
