import * as colors from '@shared/colors'
import * as helpers from '@shared/helpers'
import { iOSColors, iOSUIKit, webWeights } from '@shared/react-native-typography'
import React from 'react'
import Select, { createFilter } from 'react-select'

export default class OrganizationStatusSelect extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            status: undefined,
            isDisabled: props.isDisabled
        }

        this.filterConfig = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'start'
        }

        this.allStatus = [
            { value: 'active', label: 'Active' },
            { value: 'suspended', label: 'Suspended' }
            // { value: 'test', label: 'Test' },
            // { value: 'trial', label: 'Trial' }
        ]

        this.selectStyles = {
            control: styles => ({
                ...styles,
                backgroundColor: colors.white,
                height: 40,
                width: 260,
                borderWidth: 1,
                borderColor: iOSColors.lightGray,
                borderRadius: 6,
                boxShadow: 'none',
                fontSize: 15,
                fontWeight: 500,
                ':hover': {
                    borderWidth: 1,
                    borderColor: colors.gentle_gray_spectaflow,
                    borderRadius: 6
                }
            }),
            option: (styles, { data, isDisabled, isFocused }) => {
                const style = {
                    ...styles,
                    borderBottom: '1px solid',
                    backgroundColor: isFocused ? colors.gentle_gray_spectaflow : colors.white,
                    color: colors.textPrimary,
                    fontSize: 14,
                    borderColor: colors.white,
                    cursor: isDisabled ? 'default' : 'default'
                }
                return style
            },
            placeholder: styles => {
                const style = {
                    ...styles,
                    color: iOSColors.lightGray,
                    fontSize: 15,
                    backgroundColor: colors.white
                }
                return style
            },
            singleValue: (styles, { data }) => {
                const style = [
                    {
                        ...styles,
                        color: data.value ? colors.textPrimary : iOSColors.lightGray
                    }
                ]
                return style
            },
            indicatorSeparator: (styles, { data }) => ({
                ...styles,
                backgroundColor: colors.gentle_gray_spectaflow,
                width: 0
            })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.status !== undefined && state.status === undefined) {
            return {
                status: [
                    {
                        value: props.status,
                        label: helpers.capitalizeFirstLetter(props.status)
                    }
                ]
            }
        }

        if (props.isDisabled !== state.isDisabled) {
            return { isDisabled: props.isDisabled }
        }

        return null
    }

    render() {
        return (
            <Select
                isDisabled={this.state.isDisabled}
                value={this.state.status}
                maxMenuHeight={150}
                placeholder="Select organization status..."
                isMulti={false}
                name="organizationStatus"
                options={this.allStatus}
                // styles={this.selectStyles}
                onChange={status => {
                    this.setState({ status: [status] })
                    this.props.onChange(status.value)
                }}
                filterOption={createFilter(this.filterConfig)}
            />
        )
    }
}
