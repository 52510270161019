import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import type React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import RectangularButton from '../buttons/RectangularButton'
import { AlertWrap } from './style'

interface Props {
    title: string
    text?: string
    children?: React.ReactNode
    cancelBtnText?: string
    confirmBtnText?: string
    type?: string
    onConfirm: () => void
    onCancel: () => void
}

const Alert = ({ title, type = 'default', text, onConfirm, onCancel, cancelBtnText, confirmBtnText, children }: Props) => {
    const {
        LL: { shared }
    } = useI18nContext()
    const cancelText = cancelBtnText ?? shared.cancel()
    const confirmText = confirmBtnText ?? shared.confirm()
    return (
        <AlertWrap>
            {/* FIXME */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <SweetAlert
                title={title}
                customClass="alert-wrap"
                onCancel={onCancel}
                customButtons={
                    <>
                        {type !== 'success' && (
                            <div className="buttons-wrap">
                                <button onClick={onCancel} className="cancel-button">
                                    {cancelText}
                                </button>
                                {/* TODO: add types to RectangularButton */}
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                <RectangularButton onClick={onConfirm} width={'196px'} height={'48px'}>
                                    {confirmText}
                                </RectangularButton>
                            </div>
                        )}
                        {type === 'success' && (
                            <div className="buttons-wrap justify-content-center">
                                {/* TODO: add types to RectangularButton */}
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                <RectangularButton onClick={onConfirm} width={'196px'} height={'48px'}>
                                    {confirmBtnText}
                                </RectangularButton>
                            </div>
                        )}
                    </>
                }>
                {text}
                {children}
            </SweetAlert>
        </AlertWrap>
    )
}

export default Alert
