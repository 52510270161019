import { getBrand, getNavBarNOAuthLogo } from '@shared/brand'
import React, { useState, useEffect, useContext, useRef, type PropsWithChildren } from 'react'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useFeatureToggle } from '../../../../app/features'
import RectangularButton from '../../../components/buttons/RectangularButton'
import { HOUSEKEEPING } from '../../../navigation/url-constants'
import type { CatchErrorComponentProps } from '../../../types'
import { type DocumentSnapshot, type QuerySnapshot, default as firebase } from '../../../utils/firebase'
import { formatErrorMessage } from '../../../utils/formatters'
import withErrorCatching from '../../../utils/hocs/withErrorCatching'
import { useIsMobile } from '../../../utils/hooks/useIsMobile'
import ActivityView from '../../../utils/loading-view'
import { removeWorkflowInstance } from '../../../utils/signup-flow-template/signup-workflow-instance'
import { renderRectangles, toastErrorStyle } from '../../../utils/styles'
import { AuthContext } from '../../Auth/AuthContext'
import { currentIntegrationKey } from '../signup-worflow-templates'
import Count from './Count'
import {
    ContentWrap,
    CountBox,
    CountWrap,
    LogoWrap,
    Rectangles,
    ServerSideImportErrorMessageWrap,
    ServerSideImportErrorWrap,
    SignUpWelcomeWrapper
} from './style'

const MAX_CLIENT_SIDE_IMPORT_TIME = 120 * 1000

const ServerSideImportErrorMessage = ({ message }: Pick<Error, 'message'>) => {
    return (
        <ServerSideImportErrorMessageWrap>
            <div>{message}</div>
        </ServerSideImportErrorMessageWrap>
    )
}

const ServerSideImportError = ({ message }: Pick<Error, 'message'>) => {
    return (
        <div>
            <h3>PMS Sync Failure</h3>
            <div>There was a problem with the initial sync of PMS data.</div>
            <div style={{ fontWeight: 'bold' }}>You can still sign in.</div>
            <div style={{ fontWeight: 'bold' }}>Please contact support with the information below.</div>
            <ServerSideImportErrorMessage message={message} />
        </div>
    )
}

export type SignUpWelcomeProps = PropsWithChildren<CatchErrorComponentProps>

const SignUpWelcome = ({ catchError }: SignUpWelcomeProps) => {
    const { isFeatureOn } = useFeatureToggle()
    const useNewInitialImport = isFeatureOn('new-initial-import-procedure')

    const history = useHistory()
    const isMobile = useIsMobile()
    const { currentUser, setStopAuthListener, isAuthorized } = useContext(AuthContext)
    const [areaCount, setAreaCount] = useState(0)
    const [bookingCount, setBookingCount] = useState(0)
    const [areaFetchDone, setAreaFetchDone] = useState(false)
    const [areaCountDone, setAreaCountDone] = useState(false)
    const [bookingFetchDone, setBookingFetchDone] = useState(false)
    const [bookingCountDone, setBookingCountDone] = useState(false)
    const [accountReady, setAccountReady] = useState(false)
    const [importStatus, setImportStatus] = useState('in_progress')
    const [importMessage, setImportMessage] = useState('')
    const [loading, setLoading] = useState(true)
    const lastAreaCount = useRef(0)
    const lastBookingCount = useRef(0)

    const brandLogo = getNavBarNOAuthLogo()
    const brand = getBrand()
    const brandColor = brand.navBarColor

    /**
     * Listen to the initial-imports Firestore collection for completion
     * of initial PMS sync. When we hit a completion state, cancel the
     * "taking too long" handler.
     */
    function createImportCompleteHandler(longImportHandlerID: number) {
        return (snap: DocumentSnapshot) => {
            if (!snap.exists) return
            const data = snap.data()

            if (!data) return

            if ('status' in data) {
                setImportStatus(data.status)
                setImportMessage('message' in data ? data.message : 'Unknown message')
                console.log(data.status in ['error', 'fatal_error'])
                if (data.status === 'complete') {
                    console.log('Imported rooms and bookings successfully')
                    clearTimeout(longImportHandlerID)
                    setAccountReady(true)
                } else if (data.status === 'error' || data.status === 'fatal_error') {
                    const message = 'message' in data ? data.message : 'Unknown error'
                    console.error(message)
                    toast.error(message, toastErrorStyle)
                    clearTimeout(longImportHandlerID)

                    // A regular error with PMS sync allows us to proceed.
                    // Fatal error means signup has failed completely.
                    if (data.status == 'error') {
                        console.info('Allowing sign-up to proceed despite PMS sync error')
                        setAccountReady(true)
                    } else {
                        console.error(`Fatal sign-up error: ${message}`)
                        catchError(new Error(message))
                    }
                }
            }
        }
    }

    const onAreaUpdate = (snap: QuerySnapshot) => {
        setAreaCount(snap.size)
        setAreaFetchDone(true)
    }

    const onBookingUpdate = (snap: QuerySnapshot) => {
        setBookingCount(snap.size)
        setBookingFetchDone(true)
    }

    const onAreaCountEnd = () => {
        lastAreaCount.current = areaCount
        if (!useNewInitialImport) setAreaCountDone(true)
    }

    const onBookingCountEnd = () => {
        lastBookingCount.current = bookingCount
        if (!useNewInitialImport) setBookingCountDone(true)
    }

    useEffect(() => {
        setStopAuthListener(true)
        removeWorkflowInstance(currentIntegrationKey)
    }, [])

    useEffect(() => {
        if (isAuthorized) {
            setLoading(false)
        } else {
            setStopAuthListener(false)
        }
        return () => {}
    }, [isAuthorized])

    useEffect(() => {
        let unsubscribeAreas: () => void
        let unsubscribeBookings: () => void
        let unsubscribeImportCompletion: () => void

        // Old way: listen to bookings and areas imports until
        // things have probably stop importing, then set account to
        // ready. Does not handle max client-side timeout well.
        function oldInitialImport() {
            if (!currentUser) {
                console.error('No user to do initial import (old way)!')
                return () => {}
            }

            try {
                const db = firebase.firestore()

                const areaRef = db
                    .collection('areas')
                    .where('organizationKey', '==', currentUser.organizationKey)
                    .where('visible', '==', true)
                unsubscribeAreas = areaRef.onSnapshot(onAreaUpdate)

                setTimeout(() => {
                    const bookingsRef = db.collection('bookings').where('organizationKey', '==', currentUser.organizationKey)
                    unsubscribeBookings = bookingsRef.onSnapshot(onBookingUpdate)
                }, 500)
            } catch (error) {
                const message = formatErrorMessage(error)
                console.error(message)
                toast.error(message, toastErrorStyle)
            }

            return () => {
                if (unsubscribeAreas) {
                    unsubscribeAreas()
                }
                if (unsubscribeBookings) {
                    unsubscribeBookings()
                }
            }
        }

        // New way: listen to an explicit completion signal from the
        // server. Set accountReady to true when the done signal is received
        // from the initial-imports collection, or a  maximum
        // client-side timeout is exceeded. If the import is taking too
        // long, tell user import is still going on, and set accountReady
        // anyway.
        function newInitialImport() {
            if (!currentUser) {
                console.error('No user to do initial import (new way)!')
                return () => {}
            }

            try {
                const db = firebase.firestore()

                // If we are having a long import, redirect to a pleasant
                // soft error state. (type annotation required!!)
                const importTimeoutHandler: TimerHandler = () => {
                    console.warn('Import is taking longer than expected.')
                    setAccountReady(true) // TODO Need to set some kind of intermediate state
                }

                const timeoutID = setTimeout(importTimeoutHandler, MAX_CLIENT_SIDE_IMPORT_TIME)

                const importCompletion = db.collection('initial-imports').doc(currentUser.organizationKey)
                const importCompletionHandler = createImportCompleteHandler(timeoutID)
                const importErrorHandler = (err: Error) => {
                    console.error('Fatal error with PMS import completion', err)
                    toast.error(err.message, toastErrorStyle)
                    catchError(err)
                }

                unsubscribeImportCompletion = importCompletion.onSnapshot(importCompletionHandler, importErrorHandler)

                const areaRef = db
                    .collection('areas')
                    .where('organizationKey', '==', currentUser.organizationKey)
                    .where('visible', '==', true)
                unsubscribeAreas = areaRef.onSnapshot(onAreaUpdate)

                setTimeout(() => {
                    const bookingsRef = db.collection('bookings').where('organizationKey', '==', currentUser.organizationKey)
                    unsubscribeBookings = bookingsRef.onSnapshot(onBookingUpdate)
                }, 500)
            } catch (error) {
                const message = formatErrorMessage(error)
                console.error(message)
                toast.error(message, toastErrorStyle)
            }

            return () => {
                if (unsubscribeAreas) {
                    unsubscribeAreas()
                }
                if (unsubscribeBookings) {
                    unsubscribeBookings()
                }

                if (unsubscribeImportCompletion) {
                    unsubscribeImportCompletion()
                }
            }
        }

        if (!loading) {
            return useNewInitialImport ? newInitialImport() : oldInitialImport()
        }
    }, [loading])

    useEffect(() => {
        if (!useNewInitialImport) {
            console.log('Setting up effect for old import method')
            if (areaCountDone && bookingCountDone && areaFetchDone && bookingFetchDone) {
                setAccountReady(true)
            }
        }
    }, [areaCountDone, areaFetchDone, bookingCountDone, bookingFetchDone])

    const onButtonClick = () => {
        if (isMobile) {
            const iosApp = brand.urls.appStore
            const androidApp = brand.urls.playStore
            const device = navigator.userAgent
            const url = device.includes('iPhone') || device.includes('iPad') ? iosApp : androidApp

            return (window.location.href = url)
        }

        return history.push(HOUSEKEEPING.ONBOARDING)
    }

    return (
        <SignUpWelcomeWrapper>
            {loading && <ActivityView />}
            {!loading && (
                <div className="content-container">
                    <ContentWrap>
                        <LogoWrap>
                            <img src={brandLogo.img} style={brandLogo.style} />
                            {!isMobile && <Rectangles>{renderRectangles(6)}</Rectangles>}
                        </LogoWrap>

                        <CountWrap>
                            <h1>Importing rooms and {!isMobile && <br />} bookings</h1>
                            <span>Usually takes between 30-60 seconds</span>

                            {importStatus !== 'error' && (
                                <div>
                                    <CountBox done={accountReady}>
                                        <label>Rooms</label>

                                        <Count start={lastAreaCount.current} end={areaCount} onEnd={onAreaCountEnd} />
                                    </CountBox>

                                    <CountBox done={accountReady}>
                                        <label>Bookings</label>

                                        <Count start={lastBookingCount.current} end={bookingCount} onEnd={onBookingCountEnd} />
                                    </CountBox>
                                </div>
                            )}

                            {importStatus === 'error' && (
                                <ServerSideImportErrorWrap>
                                    <ServerSideImportError message={importMessage} />
                                </ServerSideImportErrorWrap>
                            )}

                            <RectangularButton
                                onClick={onButtonClick}
                                disabled={!accountReady}
                                margin={'64px 0 0 0'}
                                width={'343px'}
                                height={'64px'}
                                backgroundColor={brandColor}>
                                {isMobile ? 'Download the App' : 'Take me to my account'}
                            </RectangularButton>
                        </CountWrap>
                    </ContentWrap>
                </div>
            )}
            {isMobile && <Rectangles>{renderRectangles(5)}</Rectangles>}
            <ToastContainer />
        </SignUpWelcomeWrapper>
    )
}

export default withErrorCatching(SignUpWelcome)
