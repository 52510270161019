import * as colors from '@shared/colors'
import styled from 'styled-components'
import { MOBILE_BREAKPOINT, SMALL_DESKTOP_SCREEN_HEIGHT } from '../../utils/constants'

const black = colors.black_sweeply
const yellow = colors.main_yellow
const red = colors.main_red

export const ModalTitle = styled.h1`
    font-weight: 700;
    font-size: 2.462rem;
    line-height: 48px;
    margin-top: 16px;
    margin-bottom: 16px;
`

export const ModalDescription = styled.p`
    font-weight: 450;
    font-size: 1.077rem;
    line-height: 24px;
    margin-bottom: 40px;
    flex-direction: column;
    row-gap: 8px;

    > a {
        color: ${red};
        font-weight: 700;
        font-size: 1.077rem;
        line-height: 27px;
        border-bottom: 2px solid ${yellow};
        width: fit-content;
    }
`

export const CodeVerificationWrapper = styled.div`
    width: 377px;
    font-family: 'Circular Std';
    font-style: normal;
    color: ${black};
    margin-top: 47.25px;

    > h1 {
        font-weight: 700;
        font-size: 2.462rem;
        line-height: 48px;
        margin-bottom: 16px;
    }

    > p {
        font-weight: 450;
        font-size: 1.077rem;
        line-height: 24px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        > a {
            color: ${red};
            font-weight: 700;
            font-size: 1.077rem;
            line-height: 27px;
            border-bottom: 2px solid ${yellow};
            width: fit-content;
        }
    }

    input {
        height: 74px !important;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        margin-top: 42px;

        > h1 {
            margin-bottom: 8px;
        }

        > p {
            font-size: 1rem;
            line-height: 24px;
        }

        label {
            display: none;
        }

        button {
            width: 125px !important;
            height: 48px !important;
            margin-top: 40px !important;
            font-size: 1.333rem !important;
        }
    }

    @media (max-height: ${SMALL_DESKTOP_SCREEN_HEIGHT}px) {
        margin-top: 30px;
    }
`

export const CenteredContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`
