import AsyncStorage from '@react-native-community/async-storage'
import { Formik } from 'formik'
import { isEqual } from 'lodash'
import { capitalize } from 'lodash'
import React, { useMemo, useContext, useEffect, useState } from 'react'
import { SearchInput } from '../../../../components/forms/SearchInput'
import { AccountsContext } from '../../AccountsContext'

const prepareFilter = (queryParams, values) => {
    const { status, subscription, pms, searchText } = values
    const newQueryParams = { ...queryParams }
    const filter = {}
    // Filter by status

    filter.status = status !== '' ? status : ''
    filter.subscription = subscription !== '' ? subscription : ''
    filter.pms = pms !== '' ? pms : ''
    // filter.synced = synced !== '' ? synced : ''

    if (status === 'All') {
        filter.status = ''
    }
    if (subscription === 'All') {
        filter.subscription = ''
    }
    if (pms === 'All' || pms === undefined) {
        filter.pms = ''
    }
    // Filter by type
    // filter.type = type !== "" ? +type : undefined;
    // Filter by all fields
    filter.name = searchText
    // filter.description = searchText
    if (searchText) {
        filter.name = searchText
    }
    newQueryParams.filter = filter
    return newQueryParams
}

export function AccountsFilter(props) {
    // Customers UI Context
    const { accounts, allAccounts, queryParams, setQueryParams, filterLoading, setFilterLoading } = useContext(AccountsContext)
    // const customersUIContext = useCustomersUIContext();
    const customersUIProps = useMemo(() => {
        return {
            queryParams: queryParams,
            setQueryParams: setQueryParams
        }
    }, [queryParams, setQueryParams])

    let statuses = allAccounts
        .map(a => a.status)
        .filter(x => x !== undefined)
        .filter((x, i, a) => a.indexOf(x) === i)
    let subscriptions = allAccounts
        .map(a => a.subscription)
        .filter(x => x !== undefined)
        .filter((x, i, a) => a.indexOf(x) === i)

    let pms = allAccounts
        .map(a => a.pms)
        .filter(x => x !== '')
        .filter(x => x !== undefined)
        .filter((x, i, a) => a.indexOf(x) === i)

    statuses.unshift('All')
    subscriptions.unshift('All')
    pms.unshift('All')

    useEffect(() => {
        AsyncStorage.getItem('accounts_table_filter', (err, result) => {
            if (result && !err) {
                customersUIProps.setQueryParams(JSON.parse(result))
                setFilterLoading(false)
            }
        })
    }, [])

    // queryParams, setQueryParams,
    const applyFilter = values => {
        const newQueryParams = prepareFilter(customersUIProps.queryParams, values)
        if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
            // update list by queryParams
            // AsyncStorage.setItem('accounts_table_filter', JSON.stringify(newQueryParams))
            customersUIProps.setQueryParams(newQueryParams)
        }
    }

    return (
        <>
            {!filterLoading && (
                <Formik
                    initialValues={{
                        status: queryParams.filter.status || 'active',
                        subscription: queryParams.filter.subscription || 'All',
                        pms: queryParams.filter.pms || 'All',
                        searchText: queryParams.filter.name || ''
                    }}
                    onSubmit={values => {
                        applyFilter(values)
                    }}>
                    {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className="form form-label-right">
                            <div className="form-group row">
                                <div className={'col-lg'}>
                                    <SearchInput
                                        handleBlur={handleBlur}
                                        handleSubmit={handleSubmit}
                                        setFieldValue={setFieldValue}
                                        fieldValueToSet="searchText"
                                        placeholderText="Search..."
                                        helpText="by name"
                                        initValue={values.searchText}
                                    />
                                </div>
                                <div className={'col-lg'}>
                                    <select
                                        className="form-control"
                                        placeholder="Filter by status"
                                        name="status"
                                        onBlur={handleBlur}
                                        onChange={e => {
                                            setFieldValue('status', e.target.value)
                                            handleSubmit()
                                        }}
                                        value={customersUIProps.queryParams.status || values.status}>
                                        {statuses.map(status => (
                                            <option key={status} value={status}>
                                                {capitalize(status)}
                                            </option>
                                        ))}
                                    </select>
                                    <small className="form-text text-muted">
                                        {/* <b>Filter</b> by  */}
                                        Status
                                    </small>
                                </div>
                                <div className={'col-lg'}>
                                    <select
                                        className="form-control"
                                        placeholder="Filter by subscription"
                                        name="subscription"
                                        onBlur={handleBlur}
                                        onChange={e => {
                                            setFieldValue('subscription', e.target.value)
                                            handleSubmit()
                                        }}
                                        value={values.subscription}>
                                        {subscriptions.map(subscription => (
                                            <option key={subscription} value={subscription}>
                                                {capitalize(subscription)}
                                            </option>
                                        ))}
                                    </select>
                                    <small className="form-text text-muted">
                                        {/* <b>Filter</b> by  */}
                                        Subscription
                                    </small>
                                </div>

                                <div className={'col-lg'}>
                                    <select
                                        className="form-control"
                                        placeholder="Filter by PMS"
                                        name="pms"
                                        onBlur={handleBlur}
                                        onChange={e => {
                                            setFieldValue('pms', e.target.value)
                                            handleSubmit()
                                        }}
                                        value={values.pms}>
                                        {pms.map(s => (
                                            <option key={s} value={s}>
                                                {capitalize(s)}
                                            </option>
                                        ))}
                                    </select>
                                    <small className="form-text text-muted">
                                        {/* <b>Filter</b> by  */}
                                        Pms
                                    </small>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            )}
        </>
    )
}
