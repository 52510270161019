import type { FeatureId } from '@shared/subscriptions.types'
import { Feature } from '@shared/subscriptions/feature'
import type { Subscription } from '@shared/subscriptions/subscription'
import type React from 'react'
import { useContext, useEffect, useState } from 'react'
import { SubscriptionContext } from '../../../modules/Subscriptions/SubscriptionContext'
import { UpgradeModal } from '../../../modules/Subscriptions/UpgradeModal'
import RectangularButton from '../RectangularButton'
import { type ButtonSize, type ButtonType, MainButtonWrap } from './style'

export default function MainActionButton({
    disabled = false,
    type,
    size = 'medium',
    onClick,
    featureToCheck,
    children
}: {
    disabled?: boolean
    type?: ButtonType
    size?: ButtonSize
    onClick?: () => void
    featureToCheck?: FeatureId
    children?: React.ReactNode
}) {
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)
    const [notAccessibleFeature, setNotAccessibleFeature] = useState<FeatureId | null>()
    const [hasAccess, setHasAccess] = useState(true)
    const [avalableUpgrades, setAvalableUpgrades] = useState<Subscription[] | null>(null)
    const { checkSubscription } = useContext(SubscriptionContext)

    const onClickHandler = () => {
        if (hasAccess) {
            onClick && onClick()
        } else {
            setShowUpgradeModal(true)
        }
    }

    const onCloseHandler = () => {
        setShowUpgradeModal(false)
    }

    useEffect(() => {
        if (featureToCheck) {
            checkSubscription(
                featureToCheck,
                () => {
                    setHasAccess(true)
                },
                (notAccessibleFeature, availableUpgrades) => {
                    setHasAccess(false)
                    setNotAccessibleFeature(notAccessibleFeature)
                    setAvalableUpgrades(availableUpgrades)
                }
            )
        }
    }, [featureToCheck])

    return (
        <>
            <UpgradeModal
                show={showUpgradeModal}
                notAccessibleFeature={notAccessibleFeature}
                availableUpgrades={avalableUpgrades}
                onClose={onCloseHandler}
            />
            <MainButtonWrap size={size} disabled={disabled} type={type}>
                <RectangularButton disabled={disabled} type="button" onClick={onClickHandler}>
                    {children}
                </RectangularButton>
            </MainButtonWrap>
        </>
    )
}
