import moment, { type Moment } from 'moment-timezone'
import { logActivity } from './area-data'
import { tasksQueryDirect } from './data-helpers'
import * as dataObjects from './dataObjects'
import { type AreaCleaningStatus, getMiniUserObject } from './dataObjects'
import type { DocumentData, Firebase, FirebaseFirestore, Query, WriteBatch } from './firebase'
import type {
    ActivityStruct,
    AreaStruct,
    ChecklistItemStruct,
    CleaningStruct,
    IssueStruct,
    RuleOverride,
    TaskStatus,
    TaskStruct,
    UserStruct
} from './firestore-structs'
import { removeUndefinedValues } from './helpers'
import { type IntegrationParams, TaskboardContext } from './traces-types'
import {
    NON_EXIST,
    TASK_ASSIGNED,
    TASK_CLEANING_DEFAULT_NAME,
    TASK_COMPLETE,
    TASK_OPEN,
    TASK_TYPE_GENERAL,
    TASK_TYPE_HOUSEKEEPING,
    TASK_TYPE_ISSUE
} from './txt-constants'
import type { Needed } from './type-utils'

export class TaskClientRepo {
    constructor(private firebase: Firebase) {}

    async getTask(taskKey: string) {
        const tasksSnap = await this.firebase.firestore().collection('tasks').doc(taskKey).get()
        let task = null
        if (tasksSnap.exists) {
            task = tasksSnap.data() as TaskStruct
        }
        return task
    }
}

interface TaskConfigsBase {
    priority?: boolean
    assignedTo?: Pick<UserStruct, 'key' | 'name' | 'initials'>[] | null
}

interface IssueTaskConfig extends TaskConfigsBase {
    issue: IssueStruct
    startDate?: Moment | null
}

interface CleaningTaskConfig extends TaskConfigsBase {
    status?: TaskStatus
    cleaning?: CleaningStruct
    assignedTo: Pick<UserStruct, 'key' | 'name' | 'initials'>[]
    startDate: Moment
    name: string
    cleaningStatus?: AreaCleaningStatus
}

interface GeneralTaskConfig extends TaskConfigsBase {
    name: string
    startDate: Moment
    reservationId?: string
    pmsLinkUrl?: string
    propName?: string
    propKey?: string
    areaKey?: string
}

type TaskConfigs<T> = T extends 'issue' ? IssueTaskConfig : T extends 'cleaning' ? CleaningTaskConfig : GeneralTaskConfig

export interface TaskUpdate {
    status?: TaskStatus
    name?: string
    cleaningStatus?: AreaCleaningStatus
    cleaning?: CleaningStruct
    assignedTo?: Needed<Partial<UserStruct>, 'key' | 'initials' | 'name'>[] | null
    area?: Partial<AreaStruct>
    issue?: IssueStruct
    startDate?: Moment
    priority?: boolean
    updated?: number
}

interface GetTasksQuery {
    organizationKey: string
    currentTasksTypes: TaskStruct['type'][]
    context: TaskboardContext
    integrationParams: IntegrationParams<TaskboardContext.RESERVATIONS | TaskboardContext.PROPERTY> | null
}

interface GetTasksByDateQuery extends GetTasksQuery {
    date: number
}

type GetTasksFilters = [string, '<=' | '>=' | '==' | 'in', string | number | boolean | null | string[]][]

export type ActivityLog = Partial<ActivityStruct> & {
    type: ActivityStruct['type']
    date: ActivityStruct['date']
    change: ActivityStruct['change']
    areaKey: string
}

function getTaskDocRefById(firebase: Firebase | FirebaseFirestore, taskKey: string) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return db.collection('tasks').doc(taskKey)
}

export async function createTask<T extends TaskStruct['type']>(
    firebase: Firebase | FirebaseFirestore,
    area: Needed<Partial<Omit<AreaStruct, 'activeRule'>>, 'key' | 'organizationKey' | 'name'> | null,
    currentUser: UserStruct,
    type: T,
    configs: TaskConfigs<T> | null,
    batch?: WriteBatch | null,
    activityLog?: ActivityLog,
    checklist?: ChecklistItemStruct[] | null,
    taskKey?: string,
    ruleOverride?: RuleOverride
) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    // const org = (await db.collection('organizations').doc(currentUser.organizationKey).get()).data() as OrgStruct
    const startDate = configs?.startDate
    const taskRef = taskKey ? db.collection('tasks').doc(taskKey) : db.collection('tasks').doc()
    const taskObject: Needed<Partial<TaskStruct>, 'key'> = {
        ...dataObjects.getTaskBaseObject(area, startDate, configs?.assignedTo ?? [], type, currentUser),
        key: taskRef.id
    }

    if (checklist) {
        taskObject.checklist = checklist
    }

    if (ruleOverride) {
        taskObject.ruleOverride = ruleOverride
    }

    taskObject.priority = configs?.priority ?? false
    taskObject.status = taskObject.assignedTo && taskObject.assignedTo.length > 0 ? TASK_ASSIGNED : TASK_OPEN

    switch (type) {
        case TASK_TYPE_HOUSEKEEPING:
            taskObject.cleaning = (configs as CleaningTaskConfig).cleaning
            taskObject.cleaningStatus = (configs as CleaningTaskConfig).cleaningStatus
            taskObject.status =
                (configs as CleaningTaskConfig).status ??
                (configs?.assignedTo && configs?.assignedTo.length > 0 ? TASK_ASSIGNED : TASK_OPEN)
            taskObject.name = (configs as CleaningTaskConfig).name || TASK_CLEANING_DEFAULT_NAME
            break

        case TASK_TYPE_ISSUE:
            // taskObject.issue = {
            //     ...dataObjects.getIssueObjectForTask((configs as IssueTaskConfig).issue),
            //     taskKey: taskRef.id
            // }
            break

        default: {
            const generalTaskConfig = configs as GeneralTaskConfig
            taskObject.name = generalTaskConfig.name

            if (generalTaskConfig.reservationId) {
                taskObject.areaKey = generalTaskConfig.areaKey

                Object.assign(taskObject, {
                    reservationId: generalTaskConfig.reservationId,
                    pmsLinkUrl: generalTaskConfig.pmsLinkUrl,
                    propName: generalTaskConfig.propName,
                    propKey: generalTaskConfig.propKey
                })
            }

            break
        }
    }

    const finalTaskObject = removeUndefinedValues(taskObject)

    if (batch) {
        batch.set(taskRef, finalTaskObject)
    } else {
        try {
            await taskRef.set(finalTaskObject)
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    if (activityLog) {
        const { areaKey, type, date, change, created, key } = activityLog
        await logActivity(firebase, currentUser, areaKey, type, date, change, batch ?? undefined, key, created)
    }

    return finalTaskObject
}

export const unassignTask = (
    firebase: Firebase | FirebaseFirestore,
    currentUser: UserStruct,
    taskKey: string,
    batch?: WriteBatch | null,
    activityLog?: ActivityLog,
    checklist?: ChecklistItemStruct[] | null
) => {
    return setTaskUpdate(
        firebase,
        currentUser,
        taskKey,
        {
            assignedTo: null,
            status: 'open'
        },
        batch,
        activityLog,
        checklist
    )
}
export const assignTask = (
    firebase: Firebase | FirebaseFirestore,
    currentUser: UserStruct,
    taskKey: string,
    taskUpdate: Pick<TaskUpdate, 'assignedTo'>,
    batch?: WriteBatch | null,
    activityLog?: ActivityLog,
    checklist?: ChecklistItemStruct[] | null
) => {
    if (!taskUpdate.assignedTo || taskUpdate.assignedTo.length === 0)
        return unassignTask(firebase, currentUser, taskKey, batch, activityLog, checklist)
    else {
        return setTaskUpdate(
            firebase,
            currentUser,
            taskKey,
            { assignedTo: taskUpdate.assignedTo, status: 'assigned' },
            batch,
            activityLog,
            checklist
        )
    }
}

export const completeTask = (
    firebase: Firebase,
    currentUser: UserStruct,
    taskKey: string,
    batch?: WriteBatch | null,
    activityLog?: ActivityLog,
    checklist?: ChecklistItemStruct[] | null
) => {
    return setTaskUpdate(firebase, currentUser, taskKey, { status: 'completed' }, batch, activityLog, checklist)
}
export const setTaskPriority = (
    firebase: Firebase | FirebaseFirestore,
    currentUser: UserStruct,
    taskKey: string,
    taskUpdate: Pick<TaskUpdate, 'priority'>,
    batch?: WriteBatch | null,
    activityLog?: ActivityLog,
    checklist?: ChecklistItemStruct[] | null
) => {
    return setTaskUpdate(firebase, currentUser, taskKey, { priority: taskUpdate.priority }, batch, activityLog, checklist)
}

export function generateTaskId(firebase: Firebase | FirebaseFirestore) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return db.collection('tasks').doc().id
}

export const generateIssueId = generateTaskId

export async function setTaskUpdate(
    firebase: Firebase | FirebaseFirestore,
    currentUser: UserStruct,
    taskKey: string,
    update: TaskUpdate,
    batch?: WriteBatch | null,
    activityLog?: ActivityLog,
    checklist?: ChecklistItemStruct[] | null
) {
    const taskRef = getTaskDocRefById(firebase, taskKey)
    try {
        const assignedTo = update.assignedTo
        const { assignedTo: _, ...taskWithoutAssignedTo } = update
        const assignmentUpdate =
            typeof assignedTo === 'undefined'
                ? {}
                : assignedTo === null
                  ? { assignedTo: null, assignedToKeys: null }
                  : {
                        assignedTo: assignedTo?.map(a =>
                            getMiniUserObject(a as Pick<UserStruct, 'key' | 'name' | 'initials'>)
                        ) as TaskStruct['assignedTo'],
                        assignedToKeys: assignedTo?.map(a => a.key) as TaskStruct['assignedToKeys']
                    }

        const completedOverride =
            taskWithoutAssignedTo.status === 'completed' && (assignedTo ?? []).length === 0
                ? {
                      assignedTo: [getMiniUserObject(currentUser)],
                      assignedToKeys: [currentUser.key]
                  }
                : {}

        const taskUpdate: Partial<TaskStruct> = Object.fromEntries(
            Object.entries({
                ...taskWithoutAssignedTo,
                ...assignmentUpdate,
                ...completedOverride,
                startDate:
                    taskWithoutAssignedTo.startDate === null
                        ? null
                        : taskWithoutAssignedTo?.startDate
                          ? taskWithoutAssignedTo.startDate.valueOf()
                          : undefined,
                updated: taskWithoutAssignedTo.updated ?? moment().valueOf(),
                organizationKey: currentUser.organizationKey,
                lastModifiedBy: getMiniUserObject(currentUser),
                changerName: currentUser.name
            }).filter(([_, value]) => value !== undefined)
        )
        if (taskUpdate.area) {
            // @ts-ignore
            taskUpdate.propKey = taskUpdate.area?.propKey ?? null
            taskUpdate.area = dataObjects.getAreaObjectForTask(taskUpdate.area)
        }

        if (checklist) {
            taskUpdate.checklist = checklist
        }

        if (taskUpdate?.status === TASK_COMPLETE) {
            taskUpdate.completedDate = moment().startOf('day').valueOf()
        }
        console.log(
            `Task update: ${JSON.stringify(
                taskUpdate,
                function (k, v) {
                    return v === undefined ? 'jumpy slippery son of a gun, I got you now' : v
                },
                2
            )}`
        )

        if (batch) {
            batch.set(taskRef, taskUpdate, { merge: true })
        } else {
            try {
                await taskRef.set(taskUpdate, { merge: true })
            } catch (error) {
                console.error(error)
                throw error
            }
        }

        if (activityLog) {
            const { areaKey, type, date, change, key, created } = activityLog
            await logActivity(firebase, currentUser as UserStruct, areaKey, type, date, change, batch ?? undefined, key, created)
        }

        return { ...taskUpdate, key: taskKey }
    } catch (e) {
        console.error(`Error getting task ${taskKey}: ${e}`)
        throw e
    }
}

export function getGenericTasksQuery(firebase: Firebase | FirebaseFirestore, organizationKey: string, filters: GetTasksFilters) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    let tasksQuery = db.collection<TaskStruct>('tasks').where('organizationKey', '==', organizationKey).where('visible', '==', true)

    filters.forEach(filter => {
        tasksQuery = tasksQuery.where(...filter)
    })

    return tasksQuery
}

export async function getTasks(query: Query<DocumentData>) {
    try {
        return await tasksQueryDirect(query)()
    } catch (error) {
        console.error('(task-data getTasks)', error)
        throw new Error('Error getting tasks. Please, try again')
    }
}

export function getTaskQueryByKey(firebase: Firebase | FirebaseFirestore, organizationKey: string, taskKey: string) {
    return getGenericTasksQuery(firebase, organizationKey, [['key', '==', taskKey]])
}

export function getTasksByDateQuery(
    firebase: Firebase | FirebaseFirestore,
    organizationKey: string,
    date: number | [number, number],
    taskStatuses?: TaskStatus[]
) {
    if (!taskStatuses) taskStatuses = [TASK_ASSIGNED, TASK_OPEN]
    const filters: GetTasksFilters = []

    taskStatuses ? filters.push(['status', 'in', taskStatuses]) : filters.push(['status', 'in', [TASK_ASSIGNED, TASK_OPEN, TASK_COMPLETE]])

    if (Array.isArray(date)) {
        filters.push(['startDate', '>=', date[0]])
        filters.push(['startDate', '<=', date[1]])
    } else {
        filters.push(['startDate', '==', date])
    }

    return getGenericTasksQuery(firebase, organizationKey, filters)
}

export function getHousekeepingTasksQuery(
    firebase: Firebase | FirebaseFirestore,
    organizationKey: string,
    date: number | [number, number],
    taskStatuses: TaskStatus[] = [TASK_ASSIGNED, TASK_OPEN, TASK_COMPLETE],
    areaKey?: string | null,
    userKey?: string | null
) {
    let query = getTasksByDateQuery(firebase, organizationKey, date, taskStatuses)

    query = query.where('type', '==', TASK_TYPE_HOUSEKEEPING)

    if (areaKey) {
        query = query.where('areaKey', '==', areaKey)
    }

    if (userKey) {
        query = query.where('assignedToKeys', 'array-contains', userKey)
    }

    return query
}

export function getIssueTasksQuery(firebase: Firebase | FirebaseFirestore, organizationKey: string, isCompleted?: boolean) {
    const filters: GetTasksFilters = [
        ['type', '==', TASK_TYPE_ISSUE],
        isCompleted ? ['status', '==', TASK_COMPLETE] : ['status', 'in', [TASK_ASSIGNED, TASK_OPEN]]
    ]

    return getGenericTasksQuery(firebase, organizationKey, filters)
}

function getGenericGeneralTasksQueryWithPMSIntegration(
    firebase: Firebase | FirebaseFirestore,
    { organizationKey, currentTasksTypes, context, integrationParams }: GetTasksQuery
): Query<DocumentData> {
    const { propKey: propertyId, reservationId } = integrationParams || {}

    const filters: GetTasksFilters = []

    if (context === TaskboardContext.RESERVATIONS && propertyId && propertyId.length > 0 && reservationId && reservationId.length > 0) {
        filters.push(['reservationId', '==', reservationId])
        filters.push(['propKey', '==', propertyId])
    }

    context === TaskboardContext.PROPERTY && propertyId && propertyId.length > 0 && filters.push(['propKey', '==', propertyId])

    currentTasksTypes.includes(TASK_TYPE_GENERAL)
        ? filters.push(['type', '==', TASK_TYPE_GENERAL])
        : filters.push(['type', '==', NON_EXIST])

    return getGenericTasksQuery(firebase, organizationKey, filters)
}

export function getGeneralActiveTasksQueryWithPMSIntegration(
    firebase: Firebase | FirebaseFirestore,
    { organizationKey, currentTasksTypes, context, integrationParams, date }: GetTasksByDateQuery
): Query<DocumentData> {
    let query = getGenericGeneralTasksQueryWithPMSIntegration(firebase, {
        organizationKey,
        context,
        integrationParams,
        currentTasksTypes
    })

    if (context !== TaskboardContext.RESERVATIONS) {
        query = query.where('startDate', '<=', date)
    }

    return query.where('status', 'in', [TASK_ASSIGNED, TASK_OPEN])
}

export function getGeneralCompletedTasksQueryWithPMSIntegration(
    firebase: Firebase | FirebaseFirestore,
    { organizationKey, currentTasksTypes, context, integrationParams, date }: GetTasksByDateQuery
): Query<DocumentData> {
    let query = getGenericGeneralTasksQueryWithPMSIntegration(firebase, {
        organizationKey,
        context,
        integrationParams,
        currentTasksTypes
    })

    if (context !== TaskboardContext.RESERVATIONS) {
        query = query.where('completedDate', '==', date)
    } else {
        query = query.where('completedDate', '>=', moment(date).subtract(1, 'week').valueOf())
    }

    return query.where('status', '==', TASK_COMPLETE)
    // return query.where('status', '==', TASK_COMPLETE).where('completedDate', '>=', moment(date).subtract(1, 'week').valueOf())
}

export function getHousekeepingTasksQueryWithPMSIntegration(
    firebase: Firebase | FirebaseFirestore,
    { organizationKey, date, currentTasksTypes, context, integrationParams }: GetTasksByDateQuery
): Query<DocumentData> | null {
    if (context === TaskboardContext.RESERVATIONS) return null

    const { propKey: propertyId } = integrationParams || {}

    const filters: GetTasksFilters = [['startDate', '==', date]]

    context === TaskboardContext.PROPERTY && propertyId && propertyId.length > 0 && filters.push(['propKey', '==', propertyId])

    currentTasksTypes.includes(TASK_TYPE_HOUSEKEEPING)
        ? filters.push(['type', '==', TASK_TYPE_HOUSEKEEPING])
        : filters.push(['type', '==', NON_EXIST])

    return getGenericTasksQuery(firebase, organizationKey, filters)
}

export function getIssueTasksQueryWithPMSIntegration(
    firebase: Firebase | FirebaseFirestore,
    { organizationKey, currentTasksTypes, context, date }: GetTasksByDateQuery
): Query<DocumentData> | null {
    if (context === TaskboardContext.RESERVATIONS) return null

    const filters: GetTasksFilters = [['startDate', '<=', date]]

    currentTasksTypes.includes(TASK_TYPE_ISSUE) ? filters.push(['type', '==', TASK_TYPE_ISSUE]) : filters.push(['type', '==', NON_EXIST])

    return getGenericTasksQuery(firebase, organizationKey, filters)
}

export const deleteTask = async (
    firebase: Firebase,
    taskKey: string,
    currentUser: Pick<UserStruct, 'key' | 'name' | 'initials'>,
    batch?: WriteBatch
) => {
    console.log(`DeleteTask with key ${taskKey}`)
    const taskRef = getTaskDocRefById(firebase, taskKey)

    const deleteObject = {
        visible: false,
        updated: moment().valueOf(),
        lastModifiedBy: getMiniUserObject(currentUser)
    }

    if (batch) {
        batch.set(taskRef, deleteObject, { merge: true })
    } else {
        try {
            await firebase.firestore().collection('tasks').doc(taskKey).set(deleteObject, { merge: true })
        } catch (error) {
            console.error(error)
            throw error
        }
    }
}
