import apaleoLogo from 'app/img/pms/apaleo/apaleo-logo.png'
import { PMS_NAMES } from 'app/utils/constants'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { SIGN_UP } from '../../../../navigation/url-constants'
import ReconnectScreen from '../../ReconnectScreen'
import { startWorkflow } from '../../helpers'
import { APALEO_RECONNECT_WORKFLOW } from '../../signup-worflow-templates'

function ApaleoReconnect() {
    const history = useHistory()
    const description = `You will be redirected to Apaleo to re-approve the connection. Once you approve, you will be redirected back to Sweeply.`

    function startReconnect() {
        startWorkflow(
            {
                bus: PMS_NAMES.APALEO,
                imageUrl: apaleoLogo,
                workflowTemplate: APALEO_RECONNECT_WORKFLOW,
                currentStep: SIGN_UP.APALEO_RECONNECT,
                history
            },
            null,
            true
        )
    }

    return <ReconnectScreen title={'This is a reconnect workflow...'} description={description} onClick={startReconnect} />
}

export default ApaleoReconnect
