import { sortByName } from '@shared/helpers'
import { resolveBookingRule } from '@shared/rule-resolver'
import { ACTIVITY_TYPE_CLEANING_STATUS, CLEANING_STATUS_NO_SERVICE } from '@shared/txt-constants'
import { chunk } from 'lodash'
import moment from 'moment-timezone'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { db } from '../../../../utils/firebase'
import LoadingView from '../../../../utils/loading-view'
import { AuthContext } from '../../../Auth/AuthContext'
import { ReportsContext } from '../../ReportsContext'
import ReportTableHeaders from '../housekeeping-report/components/ReportTableHeaders'
import { ChartSummary } from './ChartSummary'

export function OptionalCleaningTable() {
    const { listLoading, setListLoading, queryParams } = useContext(ReportsContext)
    const { currentOrganization } = useContext(AuthContext)

    const [dates, setDates] = useState([])
    const [rows, setRows] = useState([])
    const [summary, setSummary] = useState()

    const reportsUIProps = useMemo(() => {
        return {
            listLoading: listLoading,
            setListLoading: setListLoading
        }
    }, [listLoading, setListLoading])

    const unitHasOptOutOnDate = (unit, activities, date) => {
        const activity = activities.filter(x => x.date === date).sort((a, b) => a.date - b.date)[0]
        if (activity) {
            return activity && activity.type === ACTIVITY_TYPE_CLEANING_STATUS && activity.change.after === CLEANING_STATUS_NO_SERVICE
        }
        return false
    }

    const getData = async () => {
        const numberOfDaysInRange = moment(queryParams.filter.endDate).diff(moment(queryParams.filter.startDate), 'days') + 1

        const dates = Array.from({ length: numberOfDaysInRange }, (_, index) => {
            return moment(queryParams.filter.startDate).add(index, 'days').valueOf()
        })
        const chunkedDates = chunk(dates, 10)

        reportsUIProps.setListLoading(true)

        let lu_bookings = []

        let activities = []
        for (const chunk of chunkedDates) {
            const bookingsRef = await db
                .collection('bookings')
                .where('organizationKey', '==', currentOrganization.key)
                .where(
                    'bookingDates',
                    'array-contains-any',
                    chunk.map(x => x.toString())
                )
                .get()
            lu_bookings.push(bookingsRef.docs.map(x => x.data()))

            if (currentOrganization.allowOptOut) {
                const activitiesRef = await db
                    .collectionGroup('activities')
                    .where('organizationKey', '==', currentOrganization.key)
                    .where('type', '==', ACTIVITY_TYPE_CLEANING_STATUS)
                    .where('date', 'in', chunk)
                    .get()

                activities = activitiesRef.docs.map(activitiesSnap => {
                    return activitiesSnap.data()
                })
            }
        }

        lu_bookings = lu_bookings.flat(1)
        const bookings = Object.values(lu_bookings.reduce((acc, obj) => ({ ...acc, [obj.key]: obj }), {}))

        const areasRef = await db
            .collection('areas')
            .where('organizationKey', '==', currentOrganization.key)
            .where('synced', '==', true)
            .where('visible', '==', true)
            .get()

        const areas = areasRef.docs.map(x => x.data())

        let optIn = 0
        let optOut = 0
        let checkout = 0
        let vacant = 0
        let checkin = 0
        let rows = areas.map(x => {
            const areaBookings = bookings.filter(y => {
                return y.areaKey === x.key
            })
            const areaActivities = activities.filter(y => {
                return y.areaKey === x.key
            })

            const cols = dates.map(y => {
                const bookingsForDate = areaBookings.filter(x => x.bookingDates.includes(y.toString()))

                bookingsForDate.map(b => {
                    const result = resolveBookingRule(
                        { repeatType: 'optin', productIds: ['d44de9f6-6aee-4302-8477-b14000d8047d'] },
                        [b],
                        y.valueOf(),
                        currentOrganization,
                        x,
                        {}
                    )
                    if (result.optInDates) {
                        b.optInDates = result.optInDates.map(x => x.toString())
                    }
                })

                let state
                if (bookingsForDate.length === 2) {
                    state = 'checkout'
                    checkout++
                }
                if (bookingsForDate.length === 1) {
                    if (bookingsForDate[0].optInDates && bookingsForDate[0].optInDates.includes(y.toString())) {
                        state = 'optIn'
                        optIn++
                    } else if (
                        (bookingsForDate[0].optOutDates && bookingsForDate[0].optOutDates.includes(y.toString())) ||
                        unitHasOptOutOnDate(x, areaActivities, y)
                    ) {
                        state = 'optOut'
                        optOut++
                    } else if (bookingsForDate[0].checkoutDate === y) {
                        state = 'checkout'
                        checkout++
                    } else if (bookingsForDate[0].checkinDate === y) {
                        state = 'checkin'
                        checkin++
                    } else {
                        if (currentOrganization.allowOptIn) {
                            state = 'optOut'
                            optOut++
                        } else {
                            state = 'optIn'
                            optIn++
                        }
                    }
                }
                if (bookingsForDate.length === 0) {
                    state = 'vacant'
                    vacant++
                }
                return {
                    state: state,
                    date: y
                }
            })

            return {
                name: x.name,
                cols
            }
        })

        rows = rows.sort((a, b) => sortByName(a.name, b.name))

        const allCols = rows.map(x => x.cols).flat(1)

        const getChartData = state => {
            return dates.map(y => {
                return {
                    date: y,
                    value: allCols.filter(z => {
                        return z.state === state && y === z.date
                    }).length
                }
            })
        }

        const totalCleaningCount = optIn + optOut + checkout

        const summaryItems = {
            optIn: { average: optIn / (optOut + optIn), count: optIn, cols: getChartData('optIn') },
            optOut: { average: optOut / (optOut + optIn), count: optOut, cols: getChartData('optOut') },
            checkout: { average: checkout / totalCleaningCount, count: checkout, cols: getChartData('checkout') }
        }

        setSummary(summaryItems)

        setRows(rows)

        setDates(dates)
        reportsUIProps.setListLoading(false)
    }

    async function handleGetData() {
        await getData()
    }

    useEffect(() => {
        handleGetData()
    }, [queryParams.filter.startDate, queryParams.filter.endDate])

    const getColorClass = status => {
        switch (status) {
            case 'optOut':
                return 'label-specta-teal'
            case 'optIn':
                return 'label-specta-pink'
            case 'checkout':
                return 'label-sweeply-purple'
            case 'checkin':
                return 'label-warning'
            default:
                return 'label-dark-50'
        }
    }

    const rowsCondition = !reportsUIProps.listLoading && (
        <tbody>
            {rows.map((userRow, index) => {
                return (
                    <tr key={index}>
                        <td style={{ 'white-space': 'nowrap' }}>
                            <strong>{userRow.name}</strong>
                        </td>
                        {userRow.cols.map(col => {
                            return (
                                <td key={col.index}>
                                    <span className={`label ${getColorClass(col.state)} mr-2`}></span>
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
        </tbody>
    )

    const planningInfo = [
        { name: 'Requested', colorClass: 'specta-pink' },
        { name: 'No service', colorClass: 'specta-teal' },
        { name: 'Vacant', colorClass: 'dark-50' },
        { name: 'Check out', colorClass: 'sweeply-purple' },
        { name: 'Check in', colorClass: 'warning' }
    ]

    const PlanningInfo = () => {
        const plan = planningInfo
        return plan.map(x => (
            <div key={x.name} className="d-flex align-items-center pb-9">
                <span
                    className={`rounded-circle text-center bg-${x.colorClass} cursor-pointer`}
                    style={{ height: '1.2rem', width: '1.2rem' }}></span>
                <span className="font-size-lg font-weight-bolder ml-2">{x.name}</span>
            </div>
        ))
    }

    const renderSummary = !reportsUIProps.listLoading && (
        <div className="card card-custom bg-gray-100 mt-10">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label font-size-h3">Summary</h3>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="row">
                    <div className="col-md-9">
                        <ChartSummary dates={dates} summary={summary} />
                    </div>
                    <div className="col-md-3">
                        <div className="card bg-body card-xl-stretch mb-xl-8">
                            <div className="card-body">
                                <i className="fa fa-percentage" />
                                <div className="text-gray-900 font-weight-bold font-size-h5 mb-2 mt-5">No service</div>
                                <div className="font-weight-bolder font-size-h3 text-gray-400">
                                    {(summary['optOut'].average * 100).toFixed(0)} %
                                </div>
                            </div>
                        </div>
                        <div className="card bg-body card-xl-stretch mb-xl-8">
                            <div className="card-body">
                                <i className="fas fa-hourglass-half" />
                                <div className="text-gray-900 font-weight-bold font-size-h5 mb-2 mt-5">Time saved</div>
                                <div className="font-weight-bolder font-size-h3 text-gray-400">
                                    {((summary['optOut'].count * 10) / 60).toFixed(0)} hours
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <>
            {!listLoading && (
                <>
                    {renderSummary}
                    <div className="d-flex justify-content-between flex-wrap mt-20 col-md-6">
                        <PlanningInfo />
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover table-head-custom table-vertical-center">
                            <ReportTableHeaders headers={dates.map(x => moment(parseInt(x)).format('DD/MM/YY'))} />

                            {rowsCondition}
                        </table>
                    </div>
                </>
            )}

            {!reportsUIProps.listLoading && rows.length === 0 && <h3 className="mt-20 text-center">No records found</h3>}
            {reportsUIProps.listLoading && (
                <div className="mt-20">
                    <LoadingView />
                </div>
            )}
        </>
    )
}
