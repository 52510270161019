import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import React, { useState, useEffect } from 'react'
import RectangularButton from '../../../components/buttons/RectangularButton'
import CodeInput from '../../../components/forms/CodeInput/index'
import { VerificationWrap } from './style'

const Verification = ({ onLogin, sendMagicLink }) => {
    const {
        LL: { login }
    } = useI18nContext()
    const [code, setCode] = useState('')
    const disabled = code.length < 6

    useEffect(() => {
        return () => {
            setCode('')
        }
    }, [])

    return (
        <VerificationWrap>
            <div>
                <p id="text">{login.checkCode()}</p>

                <form onSubmit={() => onLogin(code)}>
                    <CodeInput value={code} onChange={setCode} label={login.codeLabel()} />

                    <div id="button-wrap">
                        <RectangularButton disabled={disabled} type="submit" margin={'64px 0 86px 0'} width={'181px'} height={'64px'}>
                            {login.logInBtn()}
                        </RectangularButton>
                    </div>
                </form>

                <p id="link">
                    {login.missingCode()} <a onClick={sendMagicLink}>{login.useMagicLink()}</a>
                </p>
            </div>
        </VerificationWrap>
    )
}

export default Verification
