import type { FieldProps } from 'formik'
import React from 'react'
import Select, { createFilter } from 'react-select'

import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'

interface OptionType {
    label: string
    value: string
    isFixed?: boolean
}

interface IUserRoleSelector {
    options: OptionType[]
    disabled: boolean
    filterConfig: any
}

export const UserRolesSelect = ({ disabled, field, filterConfig, form, options }: IUserRoleSelector & FieldProps) => {
    const error = form.errors[field.name]

    const selectStyles = {
        control: (styles: any) => ({
            ...styles,
            borderWidth: 1,
            borderColor: error ? colors.bs_danger : colors.bs_gray_300,
            borderRadius: 6,
            boxShadow: 'none',
            ':hover': {
                borderWidth: 1,
                borderRadius: 6,
                borderColor: error ? colors.bs_danger : colors.green_spectaflow
            }
        }),
        option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
            ...styles,
            backgroundColor: isSelected || isFocused ? iOSColors.customGray : null,
            color: data.isFixed ? colors.green_spectaflow : isSelected ? colors.textPrimary : colors.textPrimary,
            cursor: isDisabled ? 'default' : 'pointer'
        }),
        placeholder: (styles: any) => {
            return [
                {
                    ...styles,
                    color: '#bab9c7'
                }
            ]
        },
        multiValue: (styles: any) => {
            const backColor = colors.hex2rgb(colors.blue_spectaflow, 0.15)
            return [
                {
                    ...styles,
                    backgroundColor: backColor,
                    paddingLeft: 4,
                    paddingRight: 2,
                    borderRadius: 23,
                    height: 30,
                    alignItems: 'center'
                }
            ]
        },
        multiValueLabel: (styles: any) => {
            return {
                ...styles,
                color: colors.blue_spectaflow,
                fontWeight: 500,
                fontSize: 15
            }
        },
        multiValueRemove: (styles: any) => {
            return [
                {
                    ...styles,
                    color: colors.blue_spectaflow,
                    ':hover': {
                        height: 25,
                        borderRadius: 23,
                        color: colors.blue_spectaflow
                    }
                }
            ]
        },
        indicatorSeparator: (styles: any) => ({
            ...styles,
            backgroundColor: '#f4f5f6',
            width: 0
        }),
        valueContainer: (base: any) => ({
            ...base,
            maxHeight: 200
        }),
        menuList: (base: any) => ({
            ...base,
            padding: 0
        })
    }

    const errorMessage = error && (
        <span style={{ display: 'initial' }} className="invalid-feedback">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {error}
        </span>
    )
    return (
        <>
            <Select
                value={options ? options.find(option => option.value === field.value) : ''}
                isDisabled={disabled}
                placeholder="Select role..."
                name={field.name}
                options={options}
                styles={selectStyles as any}
                onChange={option => form.setFieldValue(field.name, typeof option === 'string' ? option : option!.value)}
                onBlur={field.onBlur}
                filterOption={createFilter(filterConfig)}
            />
            {errorMessage}
        </>
    )
}
