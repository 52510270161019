import { iOSColors } from '@shared/react-native-typography'
import moment from 'moment-timezone'
import React from 'react'
import { Image, Text, TouchableOpacity, View } from 'react-native-web'

import * as brand from '@shared/brand'
import { FOOTER } from './navigation/url-constants'

export const Footer = props => {
    const DEBUG_BORDER = 0

    const LINE_MARGIN = 25
    const THIRD_LINE_FONT_SIZE = 12
    const FONT_COLOR = iOSColors.midGray
    const FONT_WEIGHT = 'light'

    let thirdLineFlexDirection = 'row'
    let thirdLineBottomMargin = 0
    let thirdLineLeftMargin = 6
    if (props.width < 600) {
        thirdLineFlexDirection = 'column'
        thirdLineBottomMargin = 6
        thirdLineLeftMargin = 0
    }

    const brandConfig = brand.getBrand()

    const appStoreScale = 0.7

    if (props.location.pathname !== '/support') {
        return (
            <View
                style={{
                    backgroundColor: 'transparent',
                    flexDirection: 'column',

                    width: '100%',
                    borderColor: 'red',
                    borderWidth: 0,
                    justifyContent: 'flex-start',
                    borderTopColor: '#f4f5f6',
                    borderTopWidth: 0,
                    alignSelf: 'center'
                    // zIndex: -100
                }}>
                <View
                    style={{
                        flexDirection: 'column',
                        borderColor: 'blue',
                        borderWidth: DEBUG_BORDER
                    }}>
                    {/* 1st line */}

                    {/* {props.isAuthenticated && !props.location.pathname.includes('/sharedissue/') && ( */}
                    {/* 3rd line */}

                    <View
                        style={{
                            borderColor: 'green',
                            borderWidth: DEBUG_BORDER,
                            flexDirection: thirdLineFlexDirection,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 16
                        }}>
                        <a
                            href={brandConfig.urls.marketingSite}
                            target="_blank"
                            rel="noopener noreferrer"
                            accessibility-role="link"
                            style={{
                                marginLeft: thirdLineLeftMargin,
                                marginBottom: thirdLineBottomMargin,
                                fontSize: THIRD_LINE_FONT_SIZE,
                                color: FONT_COLOR,
                                fontWeight: 'regular',
                                borderColor: 'blue',
                                borderWidth: DEBUG_BORDER
                            }}>
                            ⓒ2018-{moment().format('YYYY')} All Rights Reserved Sweeply ehf. |
                        </a>

                        <a
                            href={FOOTER.TERMS}
                            target="_blank"
                            rel="noopener noreferrer"
                            accessibility-role="link"
                            style={{
                                marginLeft: thirdLineLeftMargin,
                                marginBottom: thirdLineBottomMargin,
                                fontSize: THIRD_LINE_FONT_SIZE,
                                color: FONT_COLOR,
                                textAlign: 'center',
                                fontWeight: FONT_WEIGHT,
                                flexWrap: 'wrap'
                            }}>
                            Terms and conditions |
                        </a>

                        <a
                            href={FOOTER.PRIVACY_POLICY}
                            target="_blank"
                            rel="noopener noreferrer"
                            accessibility-role="link"
                            style={{
                                marginLeft: thirdLineLeftMargin,
                                marginBottom: thirdLineBottomMargin,
                                fontSize: THIRD_LINE_FONT_SIZE,
                                color: FONT_COLOR,
                                textAlign: 'center',
                                fontWeight: FONT_WEIGHT,
                                flexWrap: 'wrap'
                            }}>
                            Privacy Policy |
                        </a>

                        <a
                            href={FOOTER.RELEASE_NOTES}
                            target="_blank"
                            rel="noopener noreferrer"
                            accessibility-role="link"
                            style={{
                                marginLeft: thirdLineLeftMargin,
                                marginBottom: thirdLineBottomMargin,
                                fontSize: THIRD_LINE_FONT_SIZE,

                                color: FONT_COLOR,
                                textAlign: 'center',
                                fontWeight: FONT_WEIGHT,
                                flexWrap: 'wrap'
                            }}>
                            Version {import.meta.env.VITE_VERSION}
                        </a>
                    </View>
                </View>
            </View>
        )
    }
    return null
}

export { Footer as default }
