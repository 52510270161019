import { CloseModalButton } from 'app/components/buttons/close-modal-button'
import { ModalContainer, ModalOverlay } from 'app/components/styled-components/modal'
import QRCode from 'qrcode.react'
import React from 'react'
import styled from 'styled-components'

export const QRContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const UserQrDialog = ({ appUrl, onClose, isOpen }: { appUrl: string; onClose: () => void; isOpen: boolean }) => {
    if (!isOpen) {
        return null
    }

    const handleClose = () => {
        onClose()
    }

    return (
        <ModalOverlay>
            <ModalContainer width="50%" height="50%">
                <QRContainer>
                    <CloseModalButton close={handleClose}></CloseModalButton>
                    <QRCode value={appUrl} size={300} />
                    <h1 className="mt-15">QR code for logged-in user</h1>
                </QRContainer>
            </ModalContainer>
        </ModalOverlay>
    )
}
