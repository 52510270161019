import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import cloudbedsLogo from '../../../../img/pms/cloudbeds/cloudbeds.svg'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { PMS_NAMES } from '../../../../utils/constants'
import { startWorkflow } from '../../helpers'
import { CLOUDBEDS_WORKFLOW } from '../../signup-worflow-templates'

const CloudbedsStart = () => {
    const history = useHistory()

    useEffect(() => {
        startWorkflow({
            bus: PMS_NAMES.CLOUDBEDS,
            imageUrl: cloudbedsLogo,
            workflowTemplate: CLOUDBEDS_WORKFLOW,
            currentStep: SIGN_UP.CLOUDBEDS_START,
            history
        })
    }, [])

    return <></>
}

export default CloudbedsStart
