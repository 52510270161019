import moment from 'moment-timezone'
import type { Firebase } from './firebase'
import type { AreaStruct, BookingStruct } from './firestore-structs'
import { sortTimeStampAscending } from './helpers'
import * as c from './txt-constants'

export function findLeadBooking<
    T extends Pick<BookingStruct, 'bookingDates' | 'status' | 'checkinDate' | 'checkoutDate'> &
        Partial<Pick<BookingStruct, 'isDayUse' | 'checkinTime' | 'checkoutTime' | 'guestCheckedOut' | 'guestCheckedIn'>>
>(area: Pick<AreaStruct, 'occupancy'>, bookings: T[], date: number): T | null {
    if (!bookings?.length || bookings.every(b => b.status === c.BOOKING_STATUS_BLOCKED)) {
        return null
    }

    const todaysBookings = bookings.filter(
        b => b.bookingDates.some(d => moment(+d).isSame(date, 'day')) && b.status !== c.BOOKING_STATUS_BLOCKED
    )
    const futureBookings = bookings.filter(
        b => !b.bookingDates.some(d => moment(+d).isSame(date, 'day')) && b.status !== c.BOOKING_STATUS_BLOCKED
    )

    const hasDayUse = todaysBookings.some(b => b.isDayUse)

    if (hasDayUse) {
        const sortedBookings = todaysBookings.sort((a, b) =>
            sortTimeStampAscending(a.checkinTime || a.checkinDate, b.checkinTime || b.checkinDate)
        )

        switch (todaysBookings.length) {
            case 3:
                return !sortedBookings[1].guestCheckedIn ? sortedBookings[1] : sortedBookings[2]
            case 2:
                return sortedBookings[1].isDayUse
                    ? sortedBookings[1].guestCheckedIn
                        ? futureBookings[0]
                        : sortedBookings[1]
                    : sortedBookings[0].guestCheckedIn
                      ? sortedBookings[1]
                      : sortedBookings[0]
            case 1:
                return sortedBookings[0].guestCheckedIn ? futureBookings[0] : sortedBookings[0]
            default:
                return sortedBookings[0]
        }
    } else {
        if ([c.OCCUPANCY_CHECKOUT, c.OCCUPANCY_TURNOVER].includes(area.occupancy)) {
            if (area.occupancy === c.OCCUPANCY_TURNOVER) {
                return todaysBookings.length > 1 ? todaysBookings[1] : todaysBookings[0]
            } else {
                return futureBookings[0]
            }
        } else if (todaysBookings.length > 0) {
            return todaysBookings[0]
        } else {
            return futureBookings[0]
        }
    }
}

export async function getNextBooking(
    firebase: Firebase,
    areaKey: string,
    organizationKey: string,
    date: number
): Promise<null | BookingStruct> {
    const tomorrow = moment(date).add(1, 'days')

    const bookingsQuery = firebase
        .firestore()
        .collection<BookingStruct>('bookings')
        .where('organizationKey', '==', organizationKey)
        .where('areaKey', '==', areaKey)
        .where('checkoutDate', '>=', tomorrow.valueOf())

    const bookingsRef = await bookingsQuery.limit(1).get()

    return bookingsRef.docs.length > 0 ? bookingsRef.docs.map(b => b.data())[0] : null
}
