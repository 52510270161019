import type { OrgStruct } from '@shared/firestore-structs'
import { type ApaleoQueryParams, type IntegrationParams, TaskboardContext } from '@shared/traces-types'
import firebase from 'app/utils/firebase'
import {
    type IntegrationsParamsPayload,
    changeOrganizationSetup,
    getApaleoOrganizationSetup,
    getIntegrationParamsSetup
} from '../modules/OrganizationSignUp/cloud-functions'
import { TASKBOARD } from '../navigation/url-constants'
import { LOCAL_STORAGE } from '../utils/constants'

export const EMAIL_PASSWORD_LOGIN_ALLOWED_ACCOUNT_CODES: string[] = [
    'YUMY', // A dev account that we often use for local dev and on dev env
    'QOYB' //STAYERY and related accounts
]

export function convertUrlSearchParamsToObj(locationSearch: string): ApaleoQueryParams {
    const urlParams = new URLSearchParams(locationSearch)

    return {
        reservationId: urlParams.get('reservationId') ?? null,
        propertyId: urlParams.get('propertyId') ?? null,
        accountCode: urlParams.get('accountCode') ?? null,
        subjectId: urlParams.get('subjectId') ?? null
    }
}

export function defineTaskboardContext(pathname: string, locationSearch: string): TaskboardContext {
    const params = convertUrlSearchParamsToObj(locationSearch)

    if (pathname === TASKBOARD.APALEO && params.reservationId) {
        return TaskboardContext.RESERVATIONS
    } else if (pathname === TASKBOARD.APALEO && params.propertyId) {
        return TaskboardContext.PROPERTY
    } else if (pathname === TASKBOARD.APALEO) {
        return TaskboardContext.ACCOUNT
    } else {
        return TaskboardContext.EMBEDDED
    }
}

export async function matchCurrentOrganizationWithApaleo(search: string, currentOrganization: OrgStruct) {
    const getApaleoOrganization = getApaleoOrganizationSetup()
    const changeOrganization = changeOrganizationSetup()

    console.log(`Search is ...`, search)
    const { accountCode, propertyId } = convertUrlSearchParamsToObj(search)

    try {
        const { data: matchedOrganizationKey } = await getApaleoOrganization({
            accountCode: accountCode!,
            propertyId: propertyId ?? undefined
        })

        if (matchedOrganizationKey === currentOrganization.key) return

        await changeOrganization({ organizationKey: matchedOrganizationKey })
        document.location.reload()
    } catch (error) {
        console.error(error)
        throw error
    }
}

export async function getIntegrationParams(
    taskboardContext: TaskboardContext.PROPERTY | TaskboardContext.RESERVATIONS,
    search: string
): Promise<IntegrationParams<TaskboardContext.PROPERTY | TaskboardContext.RESERVATIONS>> {
    const { reservationId, propertyId } = convertUrlSearchParamsToObj(search)
    const getReservation = getIntegrationParamsSetup()

    try {
        const route = taskboardContext === TaskboardContext.RESERVATIONS ? 'getReservation' : 'getProperty'
        const payload: IntegrationsParamsPayload<typeof route> =
            route === 'getReservation' && reservationId
                ? ({ reservationId } as IntegrationsParamsPayload<'getReservation'>)
                : ({ propertyId } as IntegrationsParamsPayload<'getProperty'>)

        const { data } = await getReservation({ route, payload })

        return data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export function handleSessionAutoTermination(locationSearch: string) {
    const { subjectId } = convertUrlSearchParamsToObj(locationSearch)
    const localSubjectId = window.localStorage.getItem(LOCAL_STORAGE.APALEO_SUBJECT_ID)

    const logout = () => firebase.auth().signOut()

    if (!subjectId) {
        window.localStorage.removeItem(LOCAL_STORAGE.APALEO_SUBJECT_ID)
    }

    if (subjectId && localSubjectId !== subjectId) {
        window.localStorage.setItem(LOCAL_STORAGE.APALEO_SUBJECT_ID, subjectId)
        localSubjectId && logout()
    }

    return subjectId
}
