import moment from 'moment-timezone'
import React, { forwardRef, useState } from 'react'
import DatePicker from 'react-datepicker'

import './react-datepicker.css'

function remapDateToNumber(update) {
    return update !== null && typeof update !== 'string' && typeof update !== 'number'
        ? moment(update?.toISOString().split('T')[0]).valueOf()
        : update
}

export default function DateRangePicker(props) {
    const [dateRange, setDateRange] = useState([props.startDate || null, props.endDate || null])
    const [startDate, endDate] = dateRange

    if (startDate !== props.startDate || endDate !== props.endDate) {
        setDateRange([props.startDate, props.endDate])
    }

    const ref = React.createRef()

    const CustomDateInput = forwardRef((props, ref) => (
        <div className="d-flex flex-center" ref={ref}>
            <span
                className={`font-weight-bolder cursor-pointer font-size-h5 ${
                    props.isWhite ? 'text-white text-hover-specta-gray' : 'text-dark text-hover-dark-65'
                }`}
                onClick={() => {
                    props.onClick()
                }}>
                <i
                    className="
far fa-calendar-alt icon-md mr-2 text-dark"
                />
                {startDate &&
                    endDate &&
                    moment(startDate).startOf('day').valueOf() !== moment(endDate).startOf('day').valueOf() &&
                    moment(startDate).format('D MMM, YYYY') + ' - ' + moment(endDate).format('D MMM, YYYY')}
                {startDate &&
                    endDate &&
                    moment(startDate).startOf('day').valueOf() === moment(endDate).startOf('day').valueOf() &&
                    moment(startDate).format('D MMM, YYYY')}
                {!startDate && !endDate && moment().format('D MMM, YYYY')}
                {startDate && !endDate && moment(startDate).format('D MMM, YYYY')}
            </span>
        </div>
    ))

    CustomDateInput.displayName = 'CustomDateInput'
    return (
        <DatePicker
            disabled={props.disabled}
            shouldCloseOnSelect={!(!startDate || Boolean(startDate && endDate))}
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update, e) => {
                e.preventDefault()
                setDateRange(update)
                props.onDateChange([remapDateToNumber(update[0]), remapDateToNumber(update[1])])
            }}
            customInput={<CustomDateInput ref={ref} />}
            withPortal
        />
    )
}
