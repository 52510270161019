import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState, useMemo } from 'react'
import * as Yup from 'yup'
import RectangularButton from '../../../components/buttons/RectangularButton'
import { getAllCountries, getCountryItem, getGeoLocation } from '../../../components/country-selector'
import Input from '../../../components/forms/Input/index'
import Select from '../../../components/forms/Select/index'
import { formatPhoneInputValue, phoneNumberValidationTest } from '../../../utils/formatters'
import { LoginFormWrapper } from './style'

const LoginForm = ({ onSubmit }) => {
    const {
        locale,
        LL: { login, shared, countries }
    } = useI18nContext()
    const [selectedCountry, setSelectedCountry] = useState('')

    const allCountries = useMemo(() => {
        const all = getAllCountries()
        return all.map(country => ({ ...country, label: country.dialCode + ' ' + countries[country.key]() }))
    }, [locale])

    useEffect(() => {
        const US = getCountryItem('US', allCountries)
        setSelectedCountry(US)
        getGeoLocation(allCountries, country => setSelectedCountry(country ? country : US))
    }, [allCountries])

    const loginScheme = Yup.object().shape({
        phoneNumber: Yup.string()
            .test('isValid', login.phoneValidError(), value => phoneNumberValidationTest(value, selectedCountry))
            .required(shared.errorFieldIsRequired({ field: login.phoneNumber() }))
    })
    return (
        <LoginFormWrapper>
            <Formik
                validationSchema={loginScheme}
                enableReinitialize
                key={locale}
                initialValues={{ phoneNumber: '' }}
                onSubmit={values => onSubmit({ values, selectedCountry })}>
                {({ handleSubmit, setFieldValue, values, errors }) => {
                    const { phoneNumber } = values
                    const disabled = Object.keys(errors).length !== 0 || phoneNumber.length === 0

                    return (
                        <Form>
                            <Select
                                value={selectedCountry}
                                label={login.country()}
                                options={allCountries}
                                onChange={country => setSelectedCountry(country)}
                            />

                            <Field
                                autoFocus
                                name="phoneNumber"
                                component={Input}
                                value={formatPhoneInputValue(phoneNumber, selectedCountry)}
                                label={login.phoneNumber()}
                                placeholder={login.phonePlaceholder({ isWeb: true })}
                                onChange={e => setFieldValue('phoneNumber', e.target.value)}
                                withoutValidation
                            />

                            <div className={'btn-wrap'}>
                                <RectangularButton disabled={disabled} type="submit" width={'181px'} height={'64px'} onClick={handleSubmit}>
                                    {login.logInBtn()}
                                </RectangularButton>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </LoginFormWrapper>
    )
}

export default LoginForm
