import { getBrand } from '@shared/brand'
import * as colors from '@shared/colors'
import styled from 'styled-components'

const brandColor = getBrand().navBarColor

interface Active {
    active: boolean
}

export const Wrapper = styled.div<Active>`
    display: flex;
    column-gap: 21px;
    align-items: center;
    cursor: pointer;

    > svg {
        width: 16px;
        height: 16px;

        g,
        path,
        rect {
            ${({ active }) => {
                return active ? 'opacity: 1;' : ''
            }}
        }

        path,
        rect {
            fill: ${props => (props.active ? brandColor : colors.black_sweeply)};
        }
    }

    &:hover {
        span {
            color: ${({ active }) => (active ? brandColor : colors.black_sweeply)};
            opacity: 1;
        }

        svg {
            g,
            path,
            rect {
                opacity: 1;
            }

            path,
            rect {
                fill: ${({ active }) => (active ? brandColor : colors.black_sweeply)};
            }
        }
    }
    @media (max-width: 1150px) {
        > svg {
            width: 20px;
            height: 20px;
        }
    }
`

export const NavItemText = styled.span<Active>`
    font-size: 1.077rem;
    opacity: ${props => (props.active ? 1 : 0.5)};
    color: ${props => (props.active ? brandColor : colors.black_sweeply)};
`
