import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import beds24Logo from '../../../../img/pms/beds24/beds24-logo.svg'
import bookingAutomationLogo from '../../../../img/pms/booking-automation/booking_automation.png'
import godoLogo from '../../../../img/pms/godo/godo-logo.svg'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { BRANDS, INTEGRATIONS, PMS_NAMES } from '../../../../utils/constants'
import { startWorkflow } from '../../helpers'
import { BEDS_24_WORKFLOW } from '../../signup-worflow-templates'

const getLogo = bus => {
    switch (bus) {
        case PMS_NAMES.GODO:
            return godoLogo
        case 'BookingAutomation':
            return bookingAutomationLogo
        default:
            return beds24Logo
    }
}
const Beds24Start = () => {
    const history = useHistory()
    const params = new URLSearchParams(history.location.search)

    const settings = {}
    params.forEach((value, key) => (settings[key] = value))

    useEffect(() => {
        if (settings.bus === 'beds24.com') {
            settings.bus = INTEGRATIONS.BEDS_24
        }
        const imageUrl = getLogo(settings.bus)
        const brand = BRANDS.SWEEPLY
        const bus = settings.bus
        const workflowTemplate = BEDS_24_WORKFLOW
        const currentStep = SIGN_UP.BEDS_24_CALLBACK

        startWorkflow({ bus, imageUrl, workflowTemplate, currentStep, history }, brand)
    }, [])

    return <></>
}

export default Beds24Start
