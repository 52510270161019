import type React from 'react'
import { type ReactElement, useMemo, useRef } from 'react'
import { useIsMobile } from '../../../utils/hooks/useIsMobile'
import { CodeInputContainer, Underscore } from './style'

export type CodeInputProps = {
    value: string
    onChange: (value: string) => void
    onFocus: React.FocusEventHandler<HTMLInputElement>
    label: string
}

const CodeInput = ({ value, onChange, label, ...rest }: CodeInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const isMobile = useIsMobile()
    const MAX_LENGTH = 6

    const renderUnderscores = (length = 0, leftPosition = isMobile ? 31.38 : 40): ReactElement | null => {
        const filled = length < value.length
        const increment = isMobile ? 40.79 : 52

        if (length === MAX_LENGTH) return null
        return (
            <>
                <Underscore leftPosition={leftPosition} filled={filled} />
                {renderUnderscores(length + 1, leftPosition + increment)}
            </>
        )
    }

    const getStrokeLength = () => {
        let length = 0

        for (const element of value) {
            if (element === '1') length++
        }

        return length
    }

    const strokeLength = useMemo(() => getStrokeLength(), [value])

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, '')
        onChange(value)
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.relatedTarget && e.relatedTarget.nodeName === 'IFRAME') {
            inputRef.current?.focus()
        }
    }

    return (
        <CodeInputContainer strokeLength={strokeLength} isMobile={isMobile} value={value} MAX_LENGTH={MAX_LENGTH}>
            <label>{label}</label>
            <input {...rest} ref={inputRef} autoFocus onBlur={onBlur} value={value} onChange={onInputChange} maxLength={MAX_LENGTH} />
            {renderUnderscores()}
        </CodeInputContainer>
    )
}

export default CodeInput
