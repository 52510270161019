import type { FilterType } from './types'

export const prepareFilter = (queryParams: FilterType, values: Partial<FilterType['filter']>) => {
    const searchText = values.name
    const newQueryParams = { ...queryParams }
    const filter: FilterType['filter'] = {
        dateFilter: values.dateFilter || queryParams.filter.dateFilter,
        startDate: values.startDate || queryParams.filter.startDate,
        endDate: values.endDate || queryParams.filter.endDate,
        dateFilterType: values.dateFilterType || queryParams.filter.dateFilterType,
        name: searchText || ''
    }
    if (searchText) {
        filter.name = searchText
    }
    newQueryParams.filter = filter
    return newQueryParams
}
