import * as brand from '@shared/brand'
import * as colors from '@shared/colors'
import React from 'react'
import { View } from 'react-native'
import { ActivityIndicator } from 'react-native-web'

export default function ActivityView(props) {
    return (
        <View
            style={{
                borderWidth: 0,
                width: '100%',
                height: '100%',
                borderColor: 'red'
                // backgroundColor: 'white'
            }}>
            <View
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '49%',
                    borderWidth: 0,
                    borderColor: 'blue'
                }}>
                <ActivityIndicator size="large" color={props.color ? props.color : brand.getBrand().navBarColor} style={{}} />
            </View>
        </View>
    )
}
