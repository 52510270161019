import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { getBrand, getNavBarNOAuthLogo } from '@shared/brand'
import LanguageSwitcher from '_metronic/layout/components/aside/aside-menu/LanguageSwitcher'
import React from 'react'
import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from '../../../utils/constants.js'
import { FooterWrap, LoginLayoutWrapper } from './style'

const LoginLayout = ({ children, customLogo, orgKey }) => {
    const {
        LL: { login }
    } = useI18nContext()
    const { helpEmail } = getBrand().urls
    const brandLogo = getNavBarNOAuthLogo()
    const customLogoUrl = `https://storage.googleapis.com/godopronto.appspot.com/assets/${orgKey}/logo.svg`
    const Logo = styled.img`
        object-fit: contain;
        height: 5vh;
        @media (max-width: ${MOBILE_BREAKPOINT}px) {
            height: 10vh;
        }
    `

    return (
        <LoginLayoutWrapper>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Logo id={'logo'} src={customLogo ? customLogoUrl : brandLogo.img} />
            </div>

            {children}

            <FooterWrap>
                <span>{login.troubleLoggingIn()}</span>
                <div>
                    <a href={'mailto:' + helpEmail}>{login.contactSupport()}</a>
                </div>
                <div className="switcher">
                    <LanguageSwitcher size="medium" />
                </div>
            </FooterWrap>
        </LoginLayoutWrapper>
    )
}

export default LoginLayout
