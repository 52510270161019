import * as colors from '@shared/colors'
import styled from 'styled-components'

export const LoaderWrapper = styled.div<{ screenSized: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({ screenSized }) => (screenSized ? '100vh' : '100%')};
    width: ${({ screenSized }) => (screenSized ? '100vw' : '100%')};
    ${({ screenSized }) => screenSized && `position: fixed; top: 0; left: 0; background-color: ${colors.white}; z-index: 9999;`}
`
