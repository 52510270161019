import { isEqual, isFunction } from 'lodash'
import moment from 'moment-timezone'
import React, { createContext, useState, useCallback, useMemo, useContext } from 'react'
import { AuthContext } from '../Auth/AuthContext'

export const ReportsContext = createContext()

export const ReportsProvider = props => {
    const { currentOrganization } = useContext(AuthContext)
    const [reports, setReports] = useState([])
    const [allReports, setAllReports] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [listLoading, setListLoading] = useState(true)
    const initialFilter = {
        filter: {
            name: '',
            startDate: moment.tz(currentOrganization.timezone).startOf('day').valueOf(),
            endDate: moment.tz(currentOrganization.timezone).startOf('day').valueOf(),
            dateFilter: 'Today',
            dateFilterType: 'date'
        },
        sortOrder: 'asc', // asc||desc
        sortField: 'name'
    }
    const [queryParams, setQueryParamsBase] = useState(initialFilter)
    const [actionsLoading, setActionsLoading] = useState(false)

    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }
            return nextQueryParams
        })
    }, [])

    const value = useMemo(
        () => ({
            reports,
            setReports,
            allReports,
            setAllReports,
            totalCount,
            setTotalCount,
            listLoading,
            setListLoading,
            queryParams,
            setQueryParams,
            setQueryParamsBase,
            actionsLoading,
            setActionsLoading
        }),
        [
            reports,
            setReports,
            allReports,
            setAllReports,
            totalCount,
            setTotalCount,
            listLoading,
            setListLoading,
            queryParams,
            setQueryParams,
            setQueryParamsBase,
            actionsLoading,
            setActionsLoading
        ]
    )

    return <ReportsContext.Provider value={value} {...props} />
}
