import * as colors from '@shared/colors'
import styled from 'styled-components'
import { MEDIUM_DESKTOP_SCREEN_HEIGHT, MOBILE_BREAKPOINT } from '../../../utils/constants'

const white = colors.white
const blue = colors.medium_blue
const black = colors.light_black
const red = colors.red_sweeply

export const InputWrap = styled.div`
    width: 100%;
    height: 115px;
    display: flex;
    flex-direction: column;

    @media (max-height: ${MEDIUM_DESKTOP_SCREEN_HEIGHT}px) {
        height: 110px;
    }
`

export const InputItem = styled.div`
    position: relative;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;

    input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: ${white};
        border: 2px solid ${({ isError }) => (isError ? red : blue)};
        border-radius: 8px;
        padding: 18px 0 14px 24px;
        font-style: normal;
        font-weight: 450;
        font-size: 1.231rem;
        line-height: 24px;
        color: ${({ isError }) => (isError ? red : black)};
        ${({ phone }) => phone && 'border-top-left-radius: 0; border-bottom-left-radius: 0;'}

        &:focus {
            outline: none;
        }

        &::placeholder {
            font-style: normal;
            font-weight: 450;
            font-size: 1.231rem;
            line-height: 24px;
            opacity: 0.5;
            color: ${({ isError }) => (isError ? red : black)};
        }
    }

    img {
        position: absolute;
        right: 21px;
        z-index: 1;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        input {
            &::placeholder {
                font-size: 1.167rem;
            }
        }
    }
`

export const LabelWrap = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    label {
        font-style: normal;
        font-weight: 700;
        font-size: 1.077rem;
        line-height: 18px;
        color: ${black};
    }

    @media (max-weight: ${MOBILE_BREAKPOINT}px) {
        label {
            font-size: 12px;
        }
    }
`

export const Error = styled.span`
    font-style: normal;
    font-weight: 450;
    font-size: 1.077rem;
    line-height: 18px;
    color: ${red};

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        font-size: 1rem;
    }
`
