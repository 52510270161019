import { TaskStruct } from '@shared/firestore-structs'
import { atom } from 'recoil'

export const reportData = atom<any>({
    key: 'reportWorkloadData',
    default: []
})

export const reportHeaders = atom<number[]>({
    key: 'reportHeaders',
    default: []
})

export const reportSummary = atom({
    key: 'reportSummary',
    default: []
})
