/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useContext, useState, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Layout, LayoutSplashScreen } from '../_metronic/layout'

import LoadingView from './utils/loading-view'

import BasePage from './BasePage'

import { ErrorPage } from './error/404'
import SignUpWelcome from './modules/OrganizationSignUp/SignUpWelcome'
import { GettingStarted } from './open/getting-started'
import { Support } from './open/support'

import { AuthContext } from './modules/Auth/AuthContext'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import LoginNew from './modules/Login'
import LoginEmailPassword from './modules/Login/LoginEmailPassword'
import Logout from './modules/Login/logout'

import { useIntercom } from 'react-use-intercom'
import { OrganizationSuspended } from './OrganizationSuspended'
import BookingInfo from './modules/Guests/BookingInfo'
import GuestsPage from './modules/Guests/GuestHome/pages/GuestsPage'
import GuestLogin from './modules/Guests/Login'
import LoginWithToken from './modules/Guests/LoginWithToken'
import GuestMagicLinkInQR from './modules/Guests/QR'
import OrganizationSignUp from './modules/OrganizationSignUp'
import { SubscriptionProvider } from './modules/Subscriptions/SubscriptionContext'
import { GUESTS, HOUSEKEEPING, LOGIN, SIGN_UP } from './navigation/url-constants'
import TracesRouter from './traces/TracesRouter'
import { useTracesInitialUrlCatcher } from './traces/hooks'
import { INSIDE_IFRAME } from './utils/constants'
import firebase from './utils/firebase'
import { useIntercomState } from './utils/hooks/useIntercomState'
import { openPageStyle, subPageStyle } from './utils/styles.js'

function ForceLogout() {
    const { shutdown } = useIntercom()
    const [out, setOut] = useState(false)
    useEffect(() => {
        shutdown()
        firebase
            .auth()
            .signOut()
            .then(_ => setOut(true))
            .catch(e => console.error(e))
    }, [])

    return !out ? <LayoutSplashScreen /> : <Redirect to="/login" />
}

export function Routes() {
    const location = useLocation()
    const { isAuthorized, loadingAuthState, user, currentOrganization } = useContext(AuthContext)

    useTracesInitialUrlCatcher()
    useIntercomState(isAuthorized, user, currentOrganization)

    if (location.pathname.startsWith('/logout')) {
        return <ForceLogout />
    }
    const organizationSignUpCondition = location.pathname.startsWith('/sign-up') || location.pathname.startsWith('/signup')

    if (isAuthorized && location.state) {
        if (!['/login', '/logout', '/login/emailpass'].includes(location.state.pathname)) {
            return <Redirect to={location.state} />
        }
    }
    if (loadingAuthState) return <LoadingView />
    if (!isAuthorized) {
        if (organizationSignUpCondition) {
            return <OrganizationSignUp authorized={isAuthorized} currentOrganization={currentOrganization} />
        }

        return (
            <>
                <ToastContainer />
                <Switch>
                    {/* <Route exact path={GUESTS.LOGIN} component={GuestLogin} /> */}
                    <Route path={GUESTS.LOGIN.INDEX}>
                        {({ history, match }) => (
                            <GuestLogin
                                show={match != null}
                                id={match && match.params.id}
                                onHide={() => {
                                    history.push(GUESTS.LOGIN.INDEX)
                                }}
                            />
                        )}
                    </Route>
                    <Route exact path={GUESTS.GUEST_DATA} component={BookingInfo} />

                    <Route
                        exact
                        path={SIGN_UP.ORG}
                        component={() => <OrganizationSignUp authorized={isAuthorized} currentOrganization={currentOrganization} />}
                    />
                    <Route exact path={GUESTS.QR_MAGIC_LINK} component={GuestMagicLinkInQR} />

                    <Route exact path={GUESTS.LOGIN_WITH_TOKEN} component={LoginWithToken} />

                    <Route exact path={SIGN_UP.WELCOME} component={SignUpWelcome} />

                    <Route exact path={LOGIN.EMAIL_CALLBACK} render={() => <LoginNew emailCallback={true} />} />
                    <Route exact path={LOGIN.INDEX} render={() => <LoginNew />} />
                    <Route exact path={LOGIN.EMAIL_PASS} render={() => <LoginEmailPassword />} />

                    <Route key="signup" exact path="/welcome" component={SignUpWelcome} />
                    <Route
                        exact
                        path="/getting-started"
                        render={props => (
                            <GettingStarted context={'unauthorized'} {...props} openPageStyle={openPageStyle} subPageStyle={subPageStyle} />
                        )}
                    />
                    <Route
                        exact
                        path="/support"
                        render={props => {
                            return <Support {...props} openPageStyle={openPageStyle} subPageStyle={subPageStyle} />
                        }}
                    />
                    {/* <GuestsLayout> */}
                    <Route key="guests" path="/experience/:bookingId" component={GuestsPage} />
                    {/* </GuestsLayout> */}
                    <Route
                        path="/terms-and-conditions"
                        component={() => {
                            window.location.href = 'https://getsweeply.com/terms'
                            return null
                        }}
                    />
                    <Redirect to={{ pathname: '/login', state: { pathname: location.pathname } }} />
                </Switch>
            </>
        )
    } else {
        if (location.pathname.startsWith('/sign-up') || (location.pathname.startsWith('/signup') && location.pathname.endsWith('/start'))) {
            console.log(`Redirecting signup to housekeeping when user is authorized`)
            return <Redirect to={HOUSEKEEPING.INDEX} />
        }
        if (organizationSignUpCondition) {
            return <OrganizationSignUp authorized={isAuthorized} currentOrganization={currentOrganization} />
        }

        return (
            <>
                <ToastContainer />

                <Switch>
                    {/* TODO: remove guest routes on final version */}
                    <Route path={GUESTS.LOGIN.INDEX}>
                        {({ history, match }) => (
                            <GuestLogin
                                show={match != null}
                                id={match && match.params.id}
                                onHide={() => {
                                    history.push(GUESTS.LOGIN.INDEX)
                                }}
                            />
                        )}
                    </Route>
                    <Route exact path={GUESTS.QR_MAGIC_LINK} component={GuestMagicLinkInQR} />
                    <Route exact path={GUESTS.LOGIN_WITH_TOKEN} component={LoginWithToken} />
                    <Route exact path={GUESTS.GUEST_DATA} component={BookingInfo} />
                    <Route key="guests" path="/experience/:bookingId" component={GuestsPage} />
                    <Route exact path="/signup/welcome" component={SignUpWelcome} />
                    <Redirect from="/auth" to="/" />
                    <Route path={LOGIN.LOGOUT} component={Logout} />
                    <Route exact path={LOGIN.EMAIL_CALLBACK} render={_props => <Redirect to="/" />} />
                    <Route exact path="/login/email" render={_props => <Redirect to="/" />} />
                    <Route exact path={LOGIN.INDEX} render={_props => <Redirect to="/" />} />
                    <Route exact path={LOGIN.EMAIL_PASS} render={() => <Redirect to="/" />} />
                    {INSIDE_IFRAME ? (
                        <TracesRouter />
                    ) : (
                        <Layout>
                            <Switch>
                                {currentOrganization?.status === 'suspended' ? (
                                    <Route render={props => <OrganizationSuspended {...props} />} />
                                ) : (
                                    <>
                                        <Route
                                            exact
                                            path="/getting-started"
                                            render={props => (
                                                <GettingStarted {...props} openPageStyle={openPageStyle} subPageStyle={subPageStyle} />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/support"
                                            render={props => {
                                                return <Support {...props} openPageStyle={openPageStyle} subPageStyle={subPageStyle} />
                                            }}
                                        />

                                        <SubscriptionProvider location={location}>
                                            <BasePage />
                                        </SubscriptionProvider>
                                    </>
                                )}
                            </Switch>
                        </Layout>
                    )}
                    <Route component={ErrorPage} />
                </Switch>
            </>
        )
    }
}
