import { getBrand, getNavBarNOAuthLogo } from '@shared/brand'
import React from 'react'
import { ModalContainer, ModalOverlay } from '../styled-components/modal'
import ErrorMessage from './ErrorMessage'
import { ContactSupportWrap, ErrorLayout } from './style'

const ErrorModal = ({ errorMessage, contactSupportMessage, button, closeModal }) => {
    const brandLogo = getNavBarNOAuthLogo()
    const { helpEmail } = getBrand().urls

    return (
        <ModalOverlay>
            <ModalContainer width={'512px'} height={'718px'} padding={'64px 37px 35px 37px'}>
                <ErrorLayout>
                    <div className="logo-wrap">
                        <img src={brandLogo.img} style={brandLogo.style} />
                    </div>

                    <ErrorMessage message={errorMessage} button={button} closeModal={closeModal} />

                    {contactSupportMessage && (
                        <ContactSupportWrap>
                            {typeof contactSupportMessage === 'string' && <span>{contactSupportMessage}</span>}
                            <a href={'mailto:' + helpEmail}>{'Contact support'}</a>
                        </ContactSupportWrap>
                    )}
                </ErrorLayout>
            </ModalContainer>
        </ModalOverlay>
    )
}

export default ErrorModal
