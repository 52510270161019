import { LoginTypesEnum } from '@shared/guest'
import React from 'react'
import { useIntercom } from 'react-use-intercom'
import withErrorCatching from '../../../utils/hocs/withErrorCatching'
import { GuestLoginWIthLastNameAndRoomName } from './LoginWIthLastNameAndRoomName'

const GuestLogin = () => {
    const searchParams = new URLSearchParams(document.location.search)
    const type = searchParams.get('type')

    const { shutdown } = useIntercom()
    shutdown()

    switch (type) {
        case LoginTypesEnum.withLastNameAndRoomName:
            return <GuestLoginWIthLastNameAndRoomName />
        default:
            console.log('Login type was not found, default login type will be used')

            return <GuestLoginWIthLastNameAndRoomName />
    }
}

export default withErrorCatching(GuestLogin)
