import React, { useContext, useEffect, useRef } from 'react'
import { CSVLink } from 'react-csv'
// import { dateFilterType } from './atoms.js'
import { exportToExcel } from 'react-json-to-excel'
import { useRecoilValue } from 'recoil'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls/index.js'
import MainActionButton from '../../../../components/buttons/MainActionButton/index'
import { formatMonthDayYear } from '../../../../utils/formatters'
import { AuthContext } from '../../../Auth/AuthContext'
import { ReportsContext } from '../../ReportsContext.js'
import { ReportsFilter } from '../reports-filter/ReportsFilter'
import { ReportsFilterAndExportWrap } from './ReportsCard.style.js'
import { ReportsTable } from './ReportsTable.js'
import { exportExcelReportData } from './selectors'

const dateFilters = ['Today', 'Yesterday', 'This month', 'Last month', 'This week']
export function ReportsCard() {
    // const exportData = useRecoilValue(exportReportData)
    const exportData = useRecoilValue(exportExcelReportData)
    const { user } = useContext(AuthContext)
    const {
        queryParams: {
            filter: { dateFilterType, startDate, endDate }
        },
        listLoading
    } = useContext(ReportsContext)

    const createFileName = () => {
        const periodOrDate = dateFilterType === 'date' ? 'Date' : 'Period'
        const date = formatMonthDayYear(startDate)
        const periodDate = formatMonthDayYear(startDate) + ' - ' + formatMonthDayYear(endDate)
        const dateCondition = dateFilterType === 'date' ? date : periodDate
        const filename = `${periodOrDate} Report (${dateCondition})`

        return filename
    }

    const filename = createFileName()

    return (
        <>
            <Card className="card-no-shadow">
                <CardHeader title="Housekeeping report">
                    <CardHeaderToolbar></CardHeaderToolbar>
                </CardHeader>

                <CardBody>
                    <ReportsFilter dateFilter={dateFilters}>
                        {/*<CSVLink data={exportData} filename={filename}>*/}
                        <MainActionButton
                            disabled={listLoading}
                            size="large"
                            type="primary"
                            onClick={() => exportToExcel(exportData, filename, true)}>
                            {'Export'}
                        </MainActionButton>
                        {/*</CSVLink>*/}
                    </ReportsFilter>

                    <ReportsTable />
                </CardBody>
            </Card>
        </>
    )
}
