import moment, { type Moment } from 'moment-timezone'

import type {
    ActivityStruct,
    AreaCleaningStatus,
    AreaStruct,
    BookingStruct,
    CleaningStruct,
    CommentivityStruct,
    CurrencyCode,
    DailyCommentStruct,
    IssueArea,
    IssueStruct,
    Occupancy,
    OrgStruct,
    RuleStruct,
    SubscriptionItemStruct,
    SubscriptionPlan,
    SubscriptionStruct,
    TaskStruct,
    TaskStructBase,
    UserRole,
    UserStruct
} from './firestore-structs'
import { TASK_CLEANING_DEFAULT_NAME } from './txt-constants'
import type { Needed } from './type-utils'

export type {
    AreaStruct,
    AreaCleaningStatus,
    IssueStruct,
    UserStruct,
    RuleStruct,
    TaskStruct,
    OrgStruct,
    ActivityStruct,
    CommentivityStruct,
    BookingStruct,
    SubscriptionPlan,
    DailyCommentStruct,
    SubscriptionStruct,
    SubscriptionItemStruct,
    Occupancy
}

export type Admin = {
    name: string
    phoneNumber: string
    organizationKey: string
    areaGroups: string[]
    initials: string
    email: string
    visible: boolean
    brand: 'Godo Pronto' | 'Sweeply'
}

export interface CurrentUser {
    key: string
    initials: string
    name: string
}

export type IssueStatus = 'my' | 'any' | 'open' | 'assigned' | 'completed' | 'deleted'
export type TaskStatus = 'any' | 'open' | 'assigned' | 'completed' | 'deleted'

// export type IssueStructNormalized = IssueStruct & {
//     dueDate?: number
// }

export function getUserObject(
    userKey: string | null,
    userPhonNumber: string,
    userName: string,
    userInitials: string,
    userEmail: string,
    organizationKey: string,
    userAreaGroups: string[],
    userIssueHashtags: string[],
    userAssignIssueHashtags: boolean,
    userShowCleaningTaskNote: boolean,
    userVisible: boolean,
    userRole: UserRole,
    isActive = false
): Partial<UserStruct> {
    return {
        key: userKey ?? undefined,
        phoneNumber: userPhonNumber,
        name: userName,
        initials: userInitials,
        email: userEmail,
        organizationKey: organizationKey,
        areaGroups: Array.isArray(userAreaGroups) ? userAreaGroups : [],
        issueHashtags: Array.isArray(userIssueHashtags) ? userIssueHashtags : [],
        assignIssueHashtags: !!userAssignIssueHashtags,
        showCleaningTaskNote: !!userShowCleaningTaskNote,
        visible: userVisible,
        authRole: userRole,
        isActive
    }
}

export function getMiniUserObject(user: Partial<UserStruct>): Pick<UserStruct, 'key' | 'name' | 'initials'> {
    return {
        key: user.key!,
        initials: user.initials!,
        name: user.name!
    }
}

export function authUserObject(
    userKey: string,
    organizationKey: string,
    organizationName: string,
    authRole: UserRole
): {
    userKey: string
    organizationKey: string
    organizationName: string
    authRole: UserRole
    authSuper?: boolean
} {
    return {
        userKey,
        organizationKey,
        organizationName,
        authRole: authRole
    }
}

export function getSuperAdminObjects(organizationKey: string): Admin[] {
    return [
        {
            name: 'Sweeply Support',
            phoneNumber: '+3549991111',
            organizationKey,
            areaGroups: ['All'],
            initials: 'SPS',
            email: 'support@getsweeply.com',
            visible: false,
            brand: 'Sweeply' as const
        },
        {
            name: 'Erlendur Steinn Guðnason',
            phoneNumber: '+3548609100',
            organizationKey,
            areaGroups: ['All'],
            initials: 'ESG',
            email: 'erlendur@getsweeply.com',
            visible: false,
            brand: 'Sweeply' as const
        },
        {
            name: 'Brynja Þrastardóttir',
            phoneNumber: '+3548400101',
            organizationKey,
            areaGroups: ['All'],
            initials: 'BTH',
            email: 'brynja@getsweeply.com',
            visible: false,
            brand: 'Sweeply' as const
        },
        {
            name: 'Petar Shomov',
            phoneNumber: '+3548242728',
            organizationKey,
            areaGroups: ['All'],
            initials: 'PES',
            email: 'petar@getsweeply.com',
            visible: false,
            brand: 'Sweeply' as const
        },
        {
            name: 'Frans Veigar Garðarsson',
            phoneNumber: '+3548495890',
            organizationKey,
            areaGroups: ['All'],
            initials: 'FVG',
            email: 'frans@getsweeply.com',
            visible: false,
            brand: 'Sweeply' as const
        }
    ]
}

export function getBrandAdminObjects(organizationKey: string): Admin[] {
    return [
        {
            name: 'Pronto Support',
            phoneNumber: '+3545554636',
            organizationKey,
            areaGroups: ['All'],
            initials: 'PS',
            email: 'support@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        },
        {
            name: 'Lára Sverrisdóttir',
            phoneNumber: '+3548476756',
            organizationKey,
            areaGroups: ['All'],
            initials: 'LSV',
            email: 'lara@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        },
        {
            name: 'Kristín Grímsdóttir',
            phoneNumber: '+3548485198',
            organizationKey,
            areaGroups: ['All'],
            initials: 'KGR',
            email: 'kristin@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        },
        {
            name: 'Örvar Steinback',
            phoneNumber: '+3548430608',
            organizationKey,
            areaGroups: ['All'],
            initials: 'ÖST',
            email: 'orvarst@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        },
        {
            name: 'Þórdís Erla Sveinsdóttir',
            phoneNumber: '+3546911852',
            organizationKey,
            areaGroups: ['All'],
            initials: 'ÞES',
            email: 'thordis@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        },
        {
            name: 'Anna Sigurðardóttir',
            phoneNumber: '+3546112443',
            organizationKey,
            areaGroups: ['All'],
            initials: 'ASI',
            email: 'annasig@godo.is',
            visible: false,
            brand: 'Godo Pronto' as const
        }
    ]
}

export function getOrganizationObject(
    orgKey: string | null,
    orgName: string,
    orgStatus: any,
    issueHashtags: string[] = [],
    assignMaintenance = false,
    excludeMaintenanceNotification: string[] = [],
    currencyCode = 'ISK' as CurrencyCode,
    timezone = 'Atlantic/Reykjavik',
    allowOptIn = false,
    allowOptOut = false
): {
    key?: string
    name: string
    timezone: string
    currencyCode: CurrencyCode
    logo: string
    issueHashtags: string[]
    assignMaintenance: boolean
    excludeMaintenanceNotification: string[]
    type: 'customer'
    modules: {
        web: true
        app: true
    }
    status: any
    allowOptIn: boolean
    allowOptOut: boolean
    updated: number
} {
    const result = {
        name: orgName,
        timezone: timezone,
        currencyCode,
        logo: '',
        issueHashtags,
        assignMaintenance,
        excludeMaintenanceNotification,
        type: 'customer' as const,
        modules: {
            web: true as const,
            app: true as const
        },
        status: orgStatus,
        allowOptIn,
        allowOptOut,
        updated: moment().valueOf()
    }
    return { ...result, key: orgKey! }
}

export function getAreaObject<T extends Needed<Partial<AreaStruct>, 'key' | 'name'>>(
    area: T
): Pick<AreaStruct, 'key' | 'name' | 'description' | 'address' | 'group' | 'cleaningFrequency'> {
    return {
        key: area.key,
        name: area.name,
        description: area.description || '',
        address: area.address || '',
        group: area.group,
        cleaningFrequency: area.cleaningFrequency ? area.cleaningFrequency : 'unknown'
    }
}

export function getAreaObjectForTask<T extends Needed<Partial<AreaStruct>, 'key' | 'name'>>(
    area: T
): ReturnType<typeof getAreaObject> &
    Pick<T, 'cleaningStatus' | 'occupancy' | 'synced' | 'lastCleaningTaskKey' | 'guestCheckedIn' | 'guestCheckedOut'> {
    const areaObject = getAreaObject(area)

    let result = {}
    if (area.cleaningStatus) {
        result = { ...result, cleaningStatus: area.cleaningStatus }
    }
    if (area.occupancy) {
        result = { ...result, occupancy: area.occupancy }
    }

    if (area.synced) {
        result = { ...result, synced: area.synced }
    }

    if (area.lastCleaningTaskKey) {
        result = { ...result, lastCleaningTaskKey: area.lastCleaningTaskKey }
    }

    if (area.guestCheckedIn) {
        result = { ...result, guestCheckedIn: area.guestCheckedIn }
    }

    if (area.guestCheckedOut) {
        result = { ...result, guestCheckedOut: area.guestCheckedOut }
    }

    return { ...areaObject, ...result }
}

export function getTaskBaseObject<TaskType extends TaskStruct['type']>(
    area: Needed<Partial<Omit<AreaStruct, 'activeRule'>>, 'name' | 'key'> | null,
    startDate: Moment | undefined | null,
    assignedTo: Pick<UserStruct, 'key' | 'name' | 'initials'>[] | null,
    type: TaskType,
    creator: Pick<UserStruct, 'key' | 'name' | 'initials' | 'organizationKey'>
): Needed<Partial<TaskStruct>, 'type' | 'created' | 'startDate'> & { assignedToKeys?: string[] } {
    const taskObject = {
        created: moment().valueOf(),
        type,
        visible: true,
        creator: getMiniUserObject(creator),
        creatorName: creator.name,
        changerName: creator.name,
        lastModifiedBy: getMiniUserObject(creator),
        organizationKey: creator.organizationKey,
        updated: moment().valueOf(),
        startDate: startDate ? moment(startDate).startOf('day').valueOf() : null
        // eslint-disable-next-line prettier/prettier
    } satisfies Partial<TaskStruct> & { assignedToKeys?: string[] }

    const optionalAssigned = assignedTo
        ? {
              assignedToKeys: assignedTo.map(a => a.key!),
              assignedTo: assignedTo.map(a => {
                  return getMiniUserObject(a)
              })
          }
        : {}

    const optionalArea = area
        ? {
              area: getAreaObjectForTask(area),
              areaKey: area.key,
              ...(area.propKey ? { propKey: area.propKey } : {}),
              ...(area.propName ? { propName: area.propName } : {})
          }
        : {}

    return { ...taskObject, ...optionalAssigned, ...optionalArea }
}

export function touchIssueObject(currentUser: CurrentUser): Partial<IssueStruct> {
    const timeStamp = moment().valueOf()

    const issueObject: Partial<IssueStruct> = {
        updated: timeStamp,
        lastModifiedBy: getMiniUserObject(currentUser),
        updates: {
            [currentUser.key]: timeStamp
        }
    }

    return issueObject
}

export function getCommentivityObject<T extends CommentivityStruct['type']>(
    commentivityKey: string,
    type: T,
    text: string,
    notify: boolean,
    currentUser: CurrentUser
): CommentivityStruct {
    const timeStamp = moment().valueOf()
    return {
        key: commentivityKey,
        type,
        created: timeStamp,
        text,
        visible: true,
        creator: {
            key: currentUser.key,
            initials: currentUser.initials,
            name: currentUser.name,
            notify
        }
    }
}

export function getCleaningTaskObject(
    currentUser: Pick<UserStruct, 'key' | 'name' | 'initials' | 'organizationKey'>,
    area: Needed<Partial<AreaStruct>, 'name' | 'key'> | null = null,
    selectedDateNumber: Moment | null = null,
    assignedTo: Pick<UserStruct, 'key' | 'name' | 'initials'>[] | null = null,
    activeRule: Partial<RuleStruct> | null = null,
    cleaning?: CleaningStruct,
    ruleOverride?: RuleStruct
): Needed<Partial<TaskStruct>, 'name' | 'status' | 'startDate'> {
    const startDate = selectedDateNumber ? selectedDateNumber : moment()
    const status = assignedTo ? 'assigned' : 'open'

    const currentRule = ruleOverride ?? activeRule

    const taskObject = getTaskBaseObject(area, startDate, assignedTo, 'cleaning', currentUser)
    if (currentRule && currentRule.checklistTasks) {
        taskObject.checklist = currentRule.checklistTasks.map((item, index) => {
            return {
                name: item,
                checked: false
            }
        })
    }

    return { ...taskObject, cleaning, name: currentRule?.name ?? TASK_CLEANING_DEFAULT_NAME, status }
}

export function getAreaObjectForIssue(area: Pick<AreaStruct, 'key' | 'name' | 'description' | 'address' | 'group'>): IssueArea {
    return {
        key: area.key,
        name: area.name,
        description: area.description || '',
        address: area.address ?? '',
        group: area.group || ''
    }
}

export function getCleaningObject(cleaning?: CleaningStruct): CleaningStruct {
    const cleaningObject = {
        end: cleaning?.end,
        lastCleanedBy: cleaning?.lastCleanedBy,
        pause: cleaning?.pause ?? [],
        stop: cleaning?.stop ?? [],
        play: cleaning?.play ?? [],
        start: cleaning?.start ?? moment().valueOf()
    }
    if (!cleaningObject.end) delete cleaningObject.end
    return cleaningObject
}
