import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import bluelagoonLogo from '../../../../img/pms/bluelagoon/bluelagoon-logo.png'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { PMS_NAMES } from '../../../../utils/constants'
import { startWorkflow } from '../../helpers'
import { BLUE_LAGOON_WORKFLOW } from '../../signup-worflow-templates'

const BluelagoonStart = () => {
    const history = useHistory()

    useEffect(() => {
        startWorkflow({
            bus: PMS_NAMES.BLUE_LAGOON,
            imageUrl: bluelagoonLogo,
            workflowTemplate: BLUE_LAGOON_WORKFLOW,
            currentStep: SIGN_UP.BLUE_LAGOON_START,
            history
        })
    }, [])

    return <></>
}

export default BluelagoonStart
