import React, { useEffect, useState } from 'react'
import firebase, { asFirebase, asFirestore, db } from '../../utils/firebase'

import { datadogRum } from '@datadog/browser-rum'
import * as analytics from '@shared/analytics'
import type { User as FirebaseUser } from '@shared/firebase'
import type { CurrencyCode, OrgStruct, SubscriptionStruct, UserStruct } from '@shared/firestore-structs'
import { getAllPlans } from '@shared/subscriptions/get-all-plans'
import { Subscription } from '@shared/subscriptions/subscription'
import { getActiveUserByPhoneNumber, getUser } from '@shared/user-data'
import moment from 'moment-timezone'
import { useSetRecoilState } from 'recoil'
import { currentOrganizationAtom, currentUserAtom } from './atoms'
import { getBrowserInfo } from './browser-info'
import { checkAppUpgradeNeeded } from './version-checker'

type AuthContextType = {
    organization: OrgStruct | null
    currentOrganization: OrgStruct | null
    user: CurrentUser | null
    currentUser: CurrentUser | null
    isAuthorized: boolean
    loadingAuthState: boolean
    setStopAuthListener: (a: boolean) => void
    setLoadingAuthState: (a: boolean) => void
    setOrganization: (a: OrgStruct) => void
    setOrgSubscription: (a: Subscription) => void
    orgSubscription: Subscription | null
}
export const AuthContext = React.createContext<AuthContextType>({
    currentOrganization: null,
    currentUser: null,
    isAuthorized: false,
    loadingAuthState: false,
    organization: null,
    setLoadingAuthState: a => {},
    setOrganization: a => {},
    setStopAuthListener: a => {},
    setOrgSubscription: a => {},
    user: null,
    orgSubscription: null
})

let authListener: (() => void) | null = null

export type CurrentUser = UserStruct & { newVersionDisplay: boolean; name: string }
export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [user, setUser] = useState<CurrentUser | null>(null)
    const [organization, setOrganization] = useState<OrgStruct | null>(null)
    const [orgSubscription, setOrgSubscription] = useState<Subscription | null>()
    const [loadingAuthState, setLoadingAuthState] = useState(true)
    const [stopAuthListener, setStopAuthListener] = useState(false)
    const setCurrentOrganization = useSetRecoilState(currentOrganizationAtom)
    const setCurrentUser = useSetRecoilState(currentUserAtom)

    let unsubscribeAuth: (() => void) | null = null
    let unsubscribeOrg: (() => void) | null = null
    let unsubscribeSubscription: (() => void) | null = null
    let userListener: (() => void) | null = null

    async function subscriptionBySubscription(data: SubscriptionStruct, orgCurrency: CurrencyCode) {
        const plans = await getAllPlans(asFirestore(db))
        const plan = plans.find(x => x.id === data.plan)
        return new Subscription({
            id: data.plan,
            key: data.key,
            name: data.plan,
            features: data.features,
            pricing: { [`${orgCurrency}:monthly`]: data.item },
            status: data.status,
            upgrades: plan?.upgrades ?? [],
            trialEnd: data.trialEnd
        })
    }

    async function planBySubscription(subscription?: string) {
        console.log('Getting plan by subscription')
        const plans = await getAllPlans(asFirestore(db))
        return plans.find(pl => pl.id === (subscription ?? 'basic'))!
    }

    const orgListener = (currentUser: Pick<UserStruct, 'organizationKey'>, currentOrg: Pick<OrgStruct, 'updated'>) => {
        if (unsubscribeOrg) {
            unsubscribeOrg()
            unsubscribeOrg = null
        }
        unsubscribeOrg = db
            .collection('organizations')
            .doc(currentUser.organizationKey)
            .onSnapshot(
                orgSnap => {
                    const currentOrganization = orgSnap.data() as OrgStruct
                    if (currentOrganization && currentOrganization.updated !== currentOrg.updated) {
                        if (currentOrganization.timezone) {
                            moment.tz.setDefault(currentOrganization.timezone)
                        }
                        setOrganization(currentOrganization)
                        setCurrentOrganization(currentOrganization)
                    }
                },
                error => {
                    console.error(`Org listener ${currentUser.organizationKey}`, error)
                }
            )
    }

    const subscriptionListener = (orgKey: string) => {
        if (unsubscribeSubscription) {
            unsubscribeSubscription()
            unsubscribeSubscription = null
        }
        unsubscribeSubscription = db
            .collection('organizations')
            .doc(orgKey)
            .collection('subscriptions')
            .where('status', 'in', ['active', 'trial'])
            .orderBy('created')
            .limitToLast(1)
            .onSnapshot(
                subscriptionsSnap => {
                    const run = async () => {
                        if (!organization) {
                            return
                        }
                        const subscription =
                            subscriptionsSnap.docs.length === 0
                                ? await planBySubscription(organization.subscription)
                                : await subscriptionBySubscription(
                                      subscriptionsSnap.docs[0].data() as SubscriptionStruct,
                                      organization!.currencyCode
                                  )
                        setOrgSubscription(subscription)
                    }
                    run().catch(error => console.error(`Error updating user: ${error}`))
                },
                error => {
                    console.error(`Subscription listener for org: ${orgKey} sub:${organization?.subscription}`, error)
                }
            )
    }

    const setUserUnauthorized = () => {
        setLoadingAuthState(false)
        setUser(null)
        setCurrentUser(null)
        setOrganization(null)
        setCurrentOrganization(null)
        if (unsubscribeOrg) {
            unsubscribeOrg()
            unsubscribeOrg = null
        }
        if (unsubscribeAuth) {
            unsubscribeAuth()
            unsubscribeAuth = null
        }
        if (unsubscribeSubscription) {
            unsubscribeSubscription()
            unsubscribeSubscription = null
        }
        if (userListener) {
            userListener()
            userListener = null
        }
        if (firebase.auth().currentUser) {
            firebase.auth().signOut()
        }
    }

    function clearCurrentUser() {
        setUserUnauthorized()
        // @ts-ignore
        window.checksumai?.reset()
        datadogRum.clearUser()
    }

    const stopAuth = (listener: (() => void) | null) => {
        if (listener) {
            listener()
        }
    }

    useEffect(() => {
        let lastUserKey = ''

        if (stopAuthListener) {
            stopAuth(authListener)
        } else {
            const getUserListener = (currentUser: UserStruct, authUser: FirebaseUser, currentUserOrg: OrgStruct) => {
                return db
                    .collection('users')
                    .doc(currentUser.key)
                    .onSnapshot(
                        async userSnap => {
                            console.log(`User updated while watching for ${currentUser.key} changes`)
                            if (userSnap?.data()?.isActive === false) {
                                console.log('User is not active, switching users')
                                const newActiveUser = await getActiveUserByPhoneNumber(asFirestore(db), authUser)
                                onUserChangedOrg(newActiveUser, authUser)
                            } else {
                                const currentUserSnapshot = await getUser(asFirestore(db), currentUser.key)
                                setUser(currentUserSnapshot)
                                setCurrentUser(currentUserSnapshot)
                                analytics.setUser(asFirebase(firebase), currentUser, currentUserOrg)
                            }
                        },
                        error => {
                            console.error(`User listener for ${currentUser.key}`, error)
                        }
                    )
            }

            const onUserChangedOrg = async (currentUser: Awaited<ReturnType<typeof getUser>>, authUser: FirebaseUser) => {
                // @ts-ignore
                window.checksumai?.identify(currentUser.key, {
                    isSuperAdmin: currentUser.authSuper,
                    org: currentUser.organizationKey
                })
                currentUser.showOnboarding = currentUser.lastWebAppLogin ? false : true
                setWebAppLoginInfo(currentUser)
                const orgRef = db.collection('organizations').doc(currentUser.organizationKey)
                const currentUserOrgDoc = await orgRef.get()
                const currentUserOrg = currentUserOrgDoc.data() as OrgStruct
                const orgSubscriptionDoc = await orgRef.collection('subscriptions').orderBy('created').limitToLast(1).get()
                currentUser.newVersionDisplay = await checkAppUpgradeNeeded()
                moment.tz.setDefault(currentUserOrg.timezone)
                setOrganization(currentUserOrg)
                setCurrentOrganization(currentUserOrg)
                const subscription =
                    orgSubscriptionDoc.docs.length === 0
                        ? await planBySubscription(currentUserOrg.subscription)
                        : await subscriptionBySubscription(
                              orgSubscriptionDoc.docs[0].data() as SubscriptionStruct,
                              currentUserOrg.currencyCode
                          )
                setOrgSubscription(subscription)
                setUser(currentUser)
                setCurrentUser(currentUser)
                setLoadingAuthState(false)
                orgListener(currentUser, currentUserOrg)
                subscriptionListener(currentUserOrg.key)
                console.log(`Listening for user changes for user: ${currentUser.key}`)
                if (userListener) {
                    userListener()
                }
                userListener = getUserListener(currentUser, authUser, currentUserOrg)
            }

            authListener = firebase.auth().onAuthStateChanged(async authUser => {
                if (authUser) {
                    const user = await getActiveUserByPhoneNumber(asFirestore(db), authUser)
                    lastUserKey = user.key
                    // ONLY FETCH THIS INFORMATION FOR THE FIRST TIME
                    // if (authUser && !user) {
                    const currentUser = await getUser(asFirestore(db), lastUserKey)
                    if (currentUser && currentUser.phoneNumber !== authUser.phoneNumber) {
                        clearCurrentUser()
                    } else if (currentUser) {
                        onUserChangedOrg(currentUser, authUser)
                    } else {
                        lastUserKey = ''
                        clearCurrentUser()
                        window.location.href =
                            'https://help.getsweeply.com/en/articles/8957768-it-seems-like-you-have-not-signed-up-for-sweepy-yet'
                    }
                    // // }
                } else {
                    lastUserKey = ''
                    clearCurrentUser()
                }
            })
        }

        return () => {
            if (unsubscribeOrg) {
                unsubscribeOrg()
                unsubscribeOrg = null
            }
            if (unsubscribeAuth) {
                unsubscribeAuth()
                unsubscribeAuth = null
            }
            if (unsubscribeSubscription) {
                unsubscribeSubscription()
                unsubscribeSubscription = null
            }
            if (userListener) {
                userListener()
                userListener = null
            }
        }
    }, [stopAuthListener])

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthorized: user !== null,
                loadingAuthState,
                organization,
                currentUser: user,
                currentOrganization: organization,
                orgSubscription: orgSubscription ?? null,
                setOrgSubscription,
                setLoadingAuthState,
                setStopAuthListener,
                setOrganization
            }}>
            {children}
        </AuthContext.Provider>
    )
}

const setWebAppLoginInfo = (currentUser: { key: string }) => {
    // const remoteConfig = firebase.remoteConfig()
    // const webVersion = remoteConfig.getValue('webVersion').asString()

    const userObj = {
        lastWebAppLogin: {
            date: moment().valueOf(),
            version: process.env.VITE_VERSION ?? '1.0.0',
            browser: getBrowserInfo()
        }
    }
    firebase
        .firestore()
        .collection('users')
        .doc(currentUser.key)
        .update(userObj)
        .catch(error => {
            console.error(`Error updating user ${currentUser.key}: ${error}`)
        })
}
