import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import { SETTINGS } from '../../../navigation/url-constants'
import { AreasContext } from '../AreasContext'
import { AreasCard } from './AreasCard'
import { AreaEditDialog } from './area-edit-dialog/AreaEditDialog'
import { AreasDeleteDialog } from './areas-delete-dialog/AreasDeleteDialog'
import { AreasLoadingDialog } from './areas-loading-dialog/AreasLoadingDialog'

export default function AreasPage({ currentOrganization }) {
    const { setAreaForEdit, initArea } = useContext(AreasContext)

    return (
        <div>
            <AreasLoadingDialog />

            <Route path={SETTINGS.UNITS.NEW}>
                {({ history, match }) => (
                    <AreaEditDialog
                        show={match != null}
                        onHide={() => {
                            history.push(SETTINGS.UNITS.INDEX)
                        }}
                    />
                )}
            </Route>

            <Route path={SETTINGS.UNITS.EDIT.INDEX}>
                {({ history, match }) => (
                    <AreaEditDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            setAreaForEdit(initArea)
                            history.push(SETTINGS.UNITS.INDEX)
                        }}
                    />
                )}
            </Route>

            <Route path={SETTINGS.UNITS.DELETE_AREAS}>
                {({ history, match }) => (
                    <AreasDeleteDialog
                        show={match != null}
                        onHide={() => {
                            setAreaForEdit(initArea)
                            history.push(SETTINGS.UNITS.INDEX)
                        }}
                    />
                )}
            </Route>

            <AreasCard currentOrganization={currentOrganization} />
        </div>
    )
}
