import { getBrand } from '@shared/brand'
import { Form, Formik, type FormikHelpers } from 'formik'
import type React from 'react'
import { toast } from 'react-toastify'
import { useRecoilValue } from 'recoil'
import * as Yup from 'yup'
import RectangularButton from '../../../components/buttons/RectangularButton'
import CodeInput from '../../../components/forms/CodeInput'
import ModalLayout from '../../../components/modals/ModalLayout'
import { toastErrorStyle } from '../../../utils/styles'
import { confirmationAtom } from '../state'
import { CodeVerificationWrapper } from '../style'

export type CodeVerificationProps = {
    setCodeApproved: React.Dispatch<React.SetStateAction<boolean>>
}

const ConfirmationCodeSchema = Yup.object().shape({
    confirmationCode: Yup.string()
        .required()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(6, 'Must be exactly 6 digits')
        .max(6, 'Must be exactly 6 digits')
})

type ConfirmationCode = Yup.InferType<typeof ConfirmationCodeSchema>

const CodeVerification = ({ setCodeApproved }: CodeVerificationProps) => {
    const confirmation = useRecoilValue(confirmationAtom)
    const brandColor = getBrand().navBarColor

    const onSubmit = async ({ confirmationCode }: ConfirmationCode, { resetForm }: FormikHelpers<ConfirmationCode>) => {
        try {
            if (!confirmation) console.error('No confirmation recoil value!')
            await confirmation?.confirm(confirmationCode)
            setCodeApproved(true)
        } catch (error) {
            console.error(error)
            toast.error('Please enter a valid code', toastErrorStyle)
            resetForm()
        }
    }

    return (
        <ModalLayout>
            <CodeVerificationWrapper>
                <h1>{'Enter code'}</h1>
                <p>{'Check your mobile phone for a text message and enter verification code'}</p>

                <Formik
                    enableReinitialize
                    validationSchema={ConfirmationCodeSchema}
                    initialValues={{ confirmationCode: '' }}
                    onSubmit={onSubmit}>
                    {({ values, handleSubmit, setFieldValue }) => {
                        const { confirmationCode } = values
                        const disabled = confirmationCode.length < 6 || !confirmation

                        return (
                            <Form>
                                <CodeInput
                                    label={'Enter code'}
                                    value={confirmationCode}
                                    onFocus={e => e.currentTarget.select()}
                                    onChange={(value: string) => setFieldValue('confirmationCode', value)}
                                />

                                <RectangularButton
                                    type="submit"
                                    backgroundColor={brandColor}
                                    // @ts-ignore
                                    onClick={handleSubmit}
                                    width={'184px'}
                                    height={'64px'}
                                    margin={'80px 0 0 0'}
                                    disabled={disabled}>
                                    Verify
                                </RectangularButton>
                            </Form>
                        )
                    }}
                </Formik>
            </CodeVerificationWrapper>
        </ModalLayout>
    )
}

export default CodeVerification
