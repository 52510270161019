import { getBrand } from '@shared/brand'
import styled from 'styled-components'
import { selectInputStyles } from '../../../forms/Select/style'

const brandColor = getBrand().navBarColor
const displayNone = () => ({ display: 'none' })

export const OrgSelectorButton = styled.button`
    width: 100%;
    height: 100%;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    ${({ usingInHeader }) => {
        if (usingInHeader) {
            return `
                display: flex;
                justify-content: flex-end;
            `
        }
        return
    }}
`

export const ControlContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;

    > span {
        color: ${brandColor};
        font-weight: 700;
        font-size: 0.923rem;
        line-height: 16px;
        display: flex;
        justify-content: flex-end;
        ${({ usingInHeader }) => usingInHeader && 'text-align: right !important; width: max-content;'}
    }
`

export const selectStyles = {
    ...selectInputStyles,
    indicatorsContainer: () => {
        return {
            height: 20,
            padding: 0,
            div: {
                padding: 0
            },
            svg: {
                fill: brandColor
            }
        }
    },

    menu: provided => ({
        ...provided,
        ...selectInputStyles.menu(),
        width: 200,
        zIndex: 1000
    }),

    menuList: provided => ({
        ...provided,
        ...selectInputStyles.menuList()
    }),

    placeholder: displayNone,
    indicatorSeparator: displayNone,
    valueContainer: displayNone
}
