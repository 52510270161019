import { getBrand, getNavBarNOAuthLogo } from '@shared/brand'
import { capitalize } from 'lodash'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import RectangularButton from '../../../../components/buttons/RectangularButton'
import { PMS_NAMES, type PmsName } from '../../../../utils/constants'
import { useIsMobile } from '../../../../utils/hooks/useIsMobile'
import { getWorkflowInstance, storeWorkflowInstance } from '../../../../utils/signup-flow-template/signup-workflow-instance'
import { currentIntegrationKey } from '../../signup-worflow-templates'
import { formIsOpenAtom } from '../../state'
import EmphasisIcon from './EmphasisIcon'
import { IntroductionWrap, PmsIntegrationWrap } from './style'

type BusName = PmsName | 'BookingAutomation'

const Introduction = () => {
    const [formIsOpen, setFormIsOpen] = useRecoilState(formIsOpenAtom)
    const history = useHistory()
    const isMobile = useIsMobile()
    const brandLogo = getNavBarNOAuthLogo()
    const brand = getBrand()
    const brandColor = brand.navBarColor
    const brandName = brand.name

    const workflowInstance = useMemo(() => getWorkflowInstance(currentIntegrationKey), [])
    if (workflowInstance == 'not found') throw new Error('No workflow instance found')

    const pmsImage = workflowInstance.getInput('imageUrl') as string | undefined
    const isGodo = pmsImage?.toLowerCase().includes('godo') ?? false
    const bus = workflowInstance.getInput('bus') as BusName | undefined
    const isImageWithMargins = bus === PMS_NAMES.RENTLIO || bus === PMS_NAMES.BOOKING_FACTORY
    let customLogoWidth

    let textBus

    const params = new URLSearchParams(history.location.search)
    params.forEach((_, key) => {
        if (key === 'test') {
            workflowInstance.values.test = true
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)
        }
    })

    switch (bus) {
        case PMS_NAMES.BEDS_24:
            if (isGodo) textBus = 'Godo'
            else textBus = capitalize(bus)
            break

        case PMS_NAMES.BOOKING_FACTORY:
            textBus = 'Booking Factory'
            break

        case 'BookingAutomation':
            textBus = 'Booking Automation'
            customLogoWidth = '180px'
            break

        default:
            textBus = capitalize(bus)
            break
    }

    const text = pmsImage ? (
        <p>
            When you create your free account your <b>{textBus}</b> rooms and bookings are automatically synced with {brandName}.
        </p>
    ) : (
        <p>When you create your free account we will set you up with a few rooms which you can use to test {brandName}.</p>
    )

    const onCreateAccountClick = () => setFormIsOpen(true)

    return (
        <IntroductionWrap isMobile={isMobile} formIsOpen={formIsOpen} brandColor={brandColor}>
            <div className="img-container">
                <img src={brandLogo.img} style={brandLogo.style} />
            </div>

            <div className="introduction">
                <h1>
                    Create your{' '}
                    <span>
                        free
                        <EmphasisIcon />
                    </span>{' '}
                    <br /> account
                </h1>
                {text}
            </div>

            {pmsImage && (
                <PmsIntegrationWrap isImageWithMargins={isImageWithMargins} isMobile={isMobile} formIsOpen={formIsOpen}>
                    <div className="line" />

                    <div className="content">
                        <img style={{ width: customLogoWidth }} src={pmsImage} />
                        <p>{`You need a${textBus[0] === 'A' ? 'n' : ''} ${textBus} account to connect ${brandName} and ${textBus}`}</p>
                    </div>
                </PmsIntegrationWrap>
            )}

            {isMobile && !formIsOpen && (
                <RectangularButton onClick={onCreateAccountClick} backgroundColor={brandColor} width={'230px'} height={'48px'}>
                    Create your account
                </RectangularButton>
            )}
        </IntroductionWrap>
    )
}

export default Introduction
