import { clone } from 'ramda'
import { isNotNull } from './array'
import type { AreaStruct, UserStruct } from './firestore-structs'
import { AREA_GROUPS_ALL } from './txt-constants'

export function filterByAreaGroupAccess(currentUser: UserStruct, areas: (Partial<AreaStruct> | undefined)[]) {
    let userAreaGroupsAreas = clone(areas.filter(isNotNull))
    const userAreaGroups = new Set(currentUser.areaGroups)

    if (userAreaGroups.size > 0) {
        if (!userAreaGroups.has('All')) {
            userAreaGroupsAreas = userAreaGroupsAreas.filter(area => area.group && userAreaGroups.has(area.group))
        }
    } else {
        userAreaGroupsAreas = []
    }

    return userAreaGroupsAreas
}

export function userHasAccessToAreaGroup(currentUserAreaGroups: string[], areaGroup: string | undefined) {
    if (!areaGroup) return false
    return currentUserAreaGroups.includes(areaGroup) || currentUserAreaGroups.includes(AREA_GROUPS_ALL)
}
