import * as brand from '@shared/brand'
import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'
import React from 'react'
import { Image, Text, TouchableOpacity, View } from 'react-native-web'
import { OpenNavbar } from '../components/open-navbar'

export const GettingStarted = props => {
    const appStoreScale = 0.8
    const URLs = brand.getBrand().urls
    return (
        <>
            {props.context && props.context === 'unauthorized' && <OpenNavbar />}
            <View style={props.subPageStyle.contentOuterView}>
                <View style={props.subPageStyle.contentInnerView}>
                    {/* #1 */}

                    <View style={props.subPageStyle.itemView}>
                        <View
                            style={{
                                marginTop: 40,
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 18
                            }}>
                            <TouchableOpacity
                                onPress={() => {
                                    window.location.href = URLs.appStore
                                }}>
                                <Image
                                    source={require('@shared/img/icon-ios.svg')}
                                    style={{
                                        opacity: 1,
                                        width: 160 * appStoreScale,
                                        height: 47 * appStoreScale,
                                        marginRight: 16
                                    }}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => {
                                    window.location.href = URLs.playStore
                                }}>
                                <Image
                                    source={require('@shared/img/icon-android.svg')}
                                    style={{
                                        opacity: 1,
                                        width: 160 * appStoreScale,
                                        height: 47 * appStoreScale
                                    }}
                                />
                            </TouchableOpacity>
                        </View>
                    </View>

                    {/* #2 */}

                    {/* #3 */}

                    <View style={props.subPageStyle.itemView}>
                        <span style={{ color: iOSColors.gray, fontSize: 15, fontWeight: '500' }}>
                            Having problems? Contact support
                            <TouchableOpacity
                                onPress={() => {
                                    window.location.href = 'mailto:' + URLs.helpEmail
                                }}>
                                <span style={{ color: colors.green_spectaflow, fontWeight: '600' }}>{URLs.helpEmail}</span>
                            </TouchableOpacity>
                        </span>
                    </View>
                </View>
            </View>
        </>
    )
}

export default { GettingStarted }
