import { Formik } from 'formik'
import { isEqual } from 'lodash'
import React, { useMemo, useContext } from 'react'
import { SearchInput } from '../../../../components/forms/SearchInput'
import { RulesContext } from '../../RulesContext'

const prepareFilter = (queryParams, values) => {
    const { status, type, searchText } = values
    const newQueryParams = { ...queryParams }
    const filter = {}
    // Filter by status
    // filter.status = status !== '' ? +status : ''
    // Filter by type
    // filter.type = type !== "" ? +type : undefined;
    // Filter by all fields
    filter.name = searchText
    // filter.description = searchText
    // filter.group = searchText
    if (searchText) {
        filter.name = searchText
        // filter.description = searchText
        // filter.group = searchText
    }
    newQueryParams.filter = filter
    return newQueryParams
}

export function RulesFilter({ listLoading }) {
    // Customers UI Context
    const { queryParams, setQueryParams } = useContext(RulesContext)
    // const customersUIContext = useCustomersUIContext();
    const customersUIProps = useMemo(() => {
        return {
            queryParams: queryParams,
            setQueryParams: setQueryParams
        }
    }, [queryParams, setQueryParams])

    // queryParams, setQueryParams,
    const applyFilter = values => {
        const newQueryParams = prepareFilter(customersUIProps.queryParams, values)
        if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
            // update list by queryParams
            customersUIProps.setQueryParams(newQueryParams)
        }
    }

    return (
        <>
            <Formik
                initialValues={{
                    status: '', // values => All=""/Susspended=0/Active=1/Pending=2
                    // type: "", // values => All=""/Business=0/Individual=1
                    searchText: ''
                }}
                onSubmit={values => {
                    applyFilter(values)
                }}>
                {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="form form-label-right">
                        <div className="form-group row">
                            {/* <div className="col-lg-2">
								<select
									className="form-control"
									name="status"
									placeholder="Filter by Status"
									// TODO: Change this code
									onChange={e => {
										setFieldValue("status", e.target.value)
										handleSubmit()
									}}
									onBlur={handleBlur}
									value={values.status}
								>
									<option value="">All</option>
									<option value="0">Clean</option>
									<option value="1">Unclean</option>
									<option value="2">Waiting for checkout</option>
								</select>
								<small className="form-text text-muted">
									<b>Filter</b> by Status
								</small>
							</div> */}
                            {/* <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Type"
                  name="type"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("type", e.target.value);
                    handleSubmit();
                  }}
                  value={values.type}
                >
                  <option value="">All</option>
                  <option value="0">Business</option>
                  <option value="1">Individual</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Type
                </small>
              </div> */}
                            <div className="col-lg-4">
                                <SearchInput
                                    handleBlur={handleBlur}
                                    handleSubmit={handleSubmit}
                                    setFieldValue={setFieldValue}
                                    fieldValueToSet="searchText"
                                    placeholderText="Search..."
                                    helpText="by name"
                                    initValue=""
                                />
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}
