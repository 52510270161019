import AsyncStorage from '@react-native-community/async-storage'
import { isEqual, isFunction } from 'lodash'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { initialFilter } from './AccountsUIHelpers'

export const AccountsContext = createContext()

const initAccount = {
    id: '',
    name: '',
    subscription: '',
    status: 'active',
    synced: '',
    issueHashtags: []
}

export const AccountsProvider = props => {
    const [accounts, setAccounts] = useState([])
    const [allAccounts, setAllAccounts] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [listLoading, setListLoading] = useState(true)
    const [filterLoading, setFilterLoading] = useState(true)
    const [queryParams, setQueryParamsBase] = useState(initialFilter)
    const [ids, setIds] = useState([])
    const [accountForEdit, setAccountForEdit] = useState(initAccount)
    const [actionsLoading, setActionsLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    const accountsUIEvents = props.accountsUIEvents

    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }
            return nextQueryParams
        })
    }, [])

    const value = {
        accounts,
        setAccounts,
        allAccounts,
        setAllAccounts,
        totalCount,
        setTotalCount,
        listLoading,
        setListLoading,
        ids,
        setIds,
        queryParams,
        setQueryParams,
        setQueryParamsBase,
        initAccount,
        accountForEdit,
        setAccountForEdit,
        actionsLoading,
        setActionsLoading,
        showAlert,
        setShowAlert,
        filterLoading,
        setFilterLoading,
        newAccountButtonClick: accountsUIEvents.newAccountButtonClick,
        openEditAccountDialog: accountsUIEvents.openEditAccountDialog,
        openDeleteAccountDialog: accountsUIEvents.openDeleteAccountDialog,
        openDeleteAccountsDialog: accountsUIEvents.openDeleteAccountsDialog
    }

    return <AccountsContext.Provider value={value} {...props} />
}
