import React, { useMemo, useContext, useState, useEffect } from 'react'

import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import { UpgradeMessageContent } from '../../../components/upgrade-signals'
import { RulesContext } from '../RulesContext'
import { RulesFilter } from './rules-filter/RulesFilter'
import { RulesGrouping } from './rules-grouping/RulesGrouping'
import { RulesTable } from './rules-table/RulesTable'

import Modal from 'react-modal'
import MainActionButton from '../../../components/buttons/MainActionButton'
import * as styles from '../../../housekeeping/dashboard/styles'
import { SubscriptionContext } from '../../Subscriptions/SubscriptionContext'

export function RulesCard(props) {
    const { ids, newRuleButtonClick } = useContext(RulesContext)
    const { checkSubscription } = useContext(SubscriptionContext)
    const rulesUIProps = useMemo(() => {
        return {
            ids: ids,
            newRuleButtonClick: newRuleButtonClick
        }
    }, [ids, newRuleButtonClick])

    const [hasSubscriptionAccess, setHasSubscriptionAccess] = useState(false)
    const [notAccessibleFeature, setNotAccessibleFeature] = useState(null)
    const [availableUpgrades, setAvailableUpgrades] = useState(null)

    const [showUpgradeMessage, setShowUpgradeMessage] = useState(false)

    useEffect(() => {
        const onAvailable = () => {
            setHasSubscriptionAccess(true)
        }
        const onNotAvailable = (notAccessible, availableUpgrad) => {
            setHasSubscriptionAccess(false)
            setNotAccessibleFeature(notAccessible)
            setAvailableUpgrades(availableUpgrad)
        }

        checkSubscription('complex-rules', onAvailable, onNotAvailable)
    }, [])

    return (
        <>
            <Modal
                isOpen={showUpgradeMessage}
                style={styles.upgradeMessageStyle}
                contentLabel="UpgradeMessages"
                onRequestClose={() => setShowUpgradeMessage(false)}>
                <UpgradeMessageContent
                    onClose={() => setShowUpgradeMessage(false)}
                    notAccessibleFeature={notAccessibleFeature}
                    availableUpgrades={availableUpgrades}
                    showCloseButton={true}
                />
            </Modal>
            <Card className="card-no-shadow">
                <CardHeader title="Rules" style={{ fontSize: 40 }}>
                    <CardHeaderToolbar>
                        <MainActionButton
                            type="primary"
                            size="large"
                            onClick={() => {
                                if (!hasSubscriptionAccess) {
                                    setShowUpgradeMessage(true)
                                    return
                                }
                                rulesUIProps.newRuleButtonClick()
                            }}>
                            New Rule
                        </MainActionButton>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <RulesFilter />
                    {rulesUIProps.ids.length > 0 && <RulesGrouping />}
                    <RulesTable currentOrganization={props.currentOrganization} />
                </CardBody>
            </Card>
        </>
    )
}
