import { isToday } from '@shared/cleaning-helpers'
import React from 'react'
import { HeaderColumn, HeaderRow } from '../../workload-report/style'

const ReportTableHeaders = ({ headers }) => {
    const headersMap = headers.map((header, index) => {
        return (
            <HeaderColumn isToday={isToday(header)} key={header + index}>
                {header}
            </HeaderColumn>
        )
    })

    return (
        <thead>
            <HeaderRow className="text-left text-nowrap">
                <tr></tr>
                {headersMap}
            </HeaderRow>
        </thead>
    )
}

export default ReportTableHeaders
