import React, { useState, useEffect, useContext } from 'react'

import { useFormik } from 'formik'
import { ActivityIndicator } from 'react-native-web'
import { notify } from 'react-notify-toast'
import * as Yup from 'yup'

import * as brand from '@shared/brand'
import firebase from '../../../../utils/firebase'
import { Toast } from '../../../../utils/toast'
import { AuthContext } from '../../../Auth/AuthContext'

import { getOrganizationObject } from '@shared/dataObjects'
import { httpsCallable } from '../../../../api'
import MainActionButton from '../../../../components/buttons/MainActionButton'
import { AccountsContext } from '../../AccountsContext'

export function AccountInformation(props) {
    const [loading, setloading] = useState(false)
    const [logo, setLogo] = useState('')
    const { currentOrganization, setOrganization } = useContext(AuthContext)
    const { setShowAlert } = useContext(AccountsContext)

    const initialValues = {
        // logo: currentOrganization.logo,
        name: currentOrganization.name,
        email: currentOrganization.email || '',
        phoneNumber: currentOrganization.phoneNumber || '',
        address: currentOrganization.address || ''
    }

    const Schema = Yup.object().shape({
        // logo: Yup.string(),
        name: Yup.string().required('Name is required'),
        email: Yup.string().email(),
        phoneNumber: Yup.string(),
        address: Yup.string()
    })

    const saveAccount = async (values, setStatus, setSubmitting) => {
        setloading(true)
        let organizationObject = getOrganizationObject(
            currentOrganization.key,
            values.name,
            currentOrganization.status,
            currentOrganization.issueHashtags,
            currentOrganization.assignMaintenance,
            currentOrganization.excludeMaintenanceNotification,
            currentOrganization.currencyCode || 'ISK',
            currentOrganization.timezone,
            currentOrganization.allowOptIn,
            currentOrganization.allowOptOut
        )

        organizationObject.brand = currentOrganization.brand

        const manageOrganization = httpsCallable('manageOrganization')
        await manageOrganization(organizationObject).catch(error => {
            setSubmitting(false)
            setloading(false)
            console.log(error)
            notify.show(<Toast width={250} message={error.message} />, 'custom', 3000, {})
            throw error
        })
        setOrganization(organizationObject)
        setSubmitting(false)
        setShowAlert(true)
        setloading(false)
    }

    const getInputClasses = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid'
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return 'is-valid'
        }

        return ''
    }
    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveAccount(values, setStatus, setSubmitting)
        },
        onReset: (values, { resetForm }) => {
            resetForm()
        }
    })

    const getUserLogo = () => {
        if (!logo) {
            return 'none'
        }

        return `url(${logo})`
    }

    const removeLogo = () => {
        setLogo('')
    }

    // return <h1>Fuu</h1>

    return (
        <form onSubmit={formik.handleSubmit}>
            {/* {loading && <ModalProgressBar />} */}
            <div
                style={{
                    position: 'absolute',
                    right: '50%',
                    top: '40%',
                    height: 50,
                    width: 50,
                    zIndex: 10000
                }}>
                {loading && <ActivityIndicator size="large" color={brand.getBrand().navBarColor} style={{}} />}
            </div>

            {/* begin::Header */}
            <div className="px-9 with-border py-3">
                <div className="align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">Account name</h3>
                    <span className="font-weight-bold text-dark-50">Select the name for you account</span>
                </div>
                <div className="card-toolbar"></div>
            </div>
            {/* end::Header */}
            {/* begin::Form */}
            <div className="form">
                {/* begin::Body */}
                <div className="card-body">
                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Name</label>
                        <div className="col-lg-9 col-xl-6 px-0">
                            <input
                                type="text"
                                placeholder="Name"
                                disabled={loading}
                                className={`form-control form-control-lg form-control-solid ${getInputClasses('name')}`}
                                name="name"
                                {...formik.getFieldProps('name')}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="invalid-feedback">{formik.errors.name}</div>
                            ) : null}
                        </div>
                    </div>
                    {/* <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Email</label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="text"
                                placeholder="Email"
                                disabled={loading}
                                className={`form-control form-control-lg form-control-solid ${getInputClasses('email')}`}
                                name="email"
                                {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="invalid-feedback">{formik.errors.email}</div>
                            ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Phone number</label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="text"
                                placeholder="Phone number"
                                disabled={loading}
                                className={`form-control form-control-lg form-control-solid ${getInputClasses('phoneNumber')}`}
                                name="phoneNumber"
                                {...formik.getFieldProps('phoneNumber')}
                            />
                            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                <div className="invalid-feedback">{formik.errors.phoneNumber}</div>
                            ) : null}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">Address</label>
                        <div className="col-lg-9 col-xl-6">
                            <input
                                type="text"
                                placeholder="Address"
                                disabled={loading}
                                className={`form-control form-control-lg form-control-solid ${getInputClasses('address')}`}
                                name="address"
                                {...formik.getFieldProps('address')}
                            />
                            {formik.touched.address && formik.errors.address ? (
                                <div className="invalid-feedback">{formik.errors.address}</div>
                            ) : null}
                        </div>
                    </div> */}
                </div>
                {/* end::Body */}
            </div>
            {/* end::Form */}
            <div className=" d-flex justify-content-end col-xl-9 mr-2">
                <MainActionButton
                    type="primary"
                    size={'large'}
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}>
                    Save
                    {formik.isSubmitting}
                </MainActionButton>
            </div>
        </form>
    )
}

// export default connect(null)(PersonaInformation)
