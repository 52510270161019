import type { ChangeEvent } from 'react'
import type { FirebaseAuthError } from '../../../../utils/firebase'

export type SignupError = FirebaseAuthError | Error

export function isSignupError(err: unknown): err is SignupError {
    if (typeof err === 'object' && err != null) {
        return 'message' in err || ('message' in err && 'code' in err)
    } else {
        return false
    }
}

export const isFirebaseAuthError = (err: SignupError): err is FirebaseAuthError => 'message' in err && 'code' in err

export type SignUpChangeEvent = ChangeEvent<any>

export function extractErrorMessage(error: unknown) {
    if (isSignupError(error)) {
        return isFirebaseAuthError(error) && error.code === 'auth/invalid-phone-number'
            ? 'Invalid phone number, please use another one'
            : error.message
    } else {
        return error?.toString() ?? 'Unknown error'
    }
}
