import moment from 'moment-timezone'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import Alert from '../../../../components/alerts/Alert'
import { db } from '../../../../utils/firebase'
import { toastErrorStyle, toastSuccessStyle } from '../../../../utils/styles'
import { AuthContext } from '../../../Auth/AuthContext'
import { RulesContext } from '../../RulesContext'

export function RuleDeleteDialog({ deleteRuleId, onHide }) {
    const { setIds } = useContext(RulesContext)
    const { user } = useContext(AuthContext)

    async function onConfirm() {
        try {
            await db
                .collection('rules')
                .doc(deleteRuleId)
                .set({ visible: false, updated: moment().valueOf(), lastChangedByUser: user.key }, { merge: true })

            setIds([])
            onHide()
            toast.success('Rule deleted successfully', toastSuccessStyle)
        } catch (error) {
            console.error(error)
            toast.error(`Error deleting rule (${error.message})`, toastErrorStyle)
        }
    }

    return (
        <>
            <Alert title={'Rule deleting'} text={'Are you sure to permanently delete this rule?'} onCancel={onHide} onConfirm={onConfirm} />
        </>
    )
}
