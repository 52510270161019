import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import bookingFactoryLogo from '../../../../img/pms/bookingfactory/bookingfactory-logo.png'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { PMS_NAMES } from '../../../../utils/constants'
import { startWorkflow } from '../../helpers'
import { BOOKING_FACTORY_WORKFLOW } from '../../signup-worflow-templates'

const BookingFactoryStart = () => {
    const history = useHistory()

    useEffect(() => {
        startWorkflow({
            bus: PMS_NAMES.BOOKING_FACTORY,
            imageUrl: bookingFactoryLogo,
            workflowTemplate: BOOKING_FACTORY_WORKFLOW,
            currentStep: SIGN_UP.BOOKING_FACTORY_START,
            history
        })
    }, [])

    return <></>
}

export default BookingFactoryStart
