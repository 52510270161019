import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { PMS_NAMES } from '../../../../utils/constants'
import { startWorkflow } from '../../helpers'
import { NON_PMS_WORKFLOW } from '../../signup-worflow-templates'

const NoPmsStart = () => {
    const history = useHistory()

    useEffect(() => {
        startWorkflow({
            bus: PMS_NAMES.NO_PMS,
            imageUrl: '',
            workflowTemplate: NON_PMS_WORKFLOW,
            currentStep: SIGN_UP.NO_PMS_START,
            history
        })
    }, [])

    return <></>
}

export default NoPmsStart
