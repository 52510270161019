import * as brand from '@shared/brand'
import imgName from '@shared/img/qr-code-icon.svg'
// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from 'react'
import { ActivityIndicator } from 'react-native-web'
import { useFeatureToggle } from '../../../../../features'

export function ActionsColumnFormatter(
    cellContent,
    row,
    rowIndex,
    { openEditUserDialog, setDeleteUserId, openUserQrDialog, currentUser, stateLoading, stateSelectedUser, isFeatureOn }
) {
    return (
        <>
            {/*{isFeatureOn('snap') && currentUser.key !== row.id && (*/}
            {/*    <a*/}
            {/*        title="Login user"*/}
            {/*        className={`btn btn-icon btn-circle btn-bg-specta-gray btn-hover-bg-specta-gray-light btn-hover-icon-primary btn-sm mx-3`}*/}
            {/*        onClick={() => openUserQrDialog(row.id)}>*/}
            {/*        {stateLoading && row.id === stateSelectedUser ? (*/}
            {/*            <ActivityIndicator color={brand.getBrand().navBarColor} />*/}
            {/*        ) : (*/}
            {/*            <img src={imgName} alt="QR code icon" />*/}
            {/*        )}*/}
            {/*    </a>*/}
            {/*)}*/}

            <a
                title="Edit user"
                className="btn btn-icon btn-circle btn-bg-specta-gray btn-hover-bg-specta-gray-light btn-hover-icon-primary btn-sm mx-3"
                onClick={() => openEditUserDialog(row.id)}>
                <i className="specta-pencil2" />
            </a>

            <a
                title="Delete user"
                className={`btn btn-icon btn-circle btn-bg-specta-gray btn-hover-bg-specta-gray-light btn-hover-icon-danger btn-sm ${
                    currentUser.key === row.id ? 'disabled' : ''
                }`}
                onClick={() => setDeleteUserId(row.id)}>
                <i className="specta-trash" />
            </a>
        </>
    )
}
