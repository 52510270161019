import moment from 'moment-timezone'
import React, { useEffect, useState, useRef, useContext } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls'
import { ModalProgressBar } from '../../../../../_metronic/_partials/controls'
import { useSubheader } from '../../../../../_metronic/layout'
import { db } from '../../../../utils/firebase'
import { AreasContext } from '../../../Areas/AreasContext'
import { initialFilter } from '../../../Areas/AreasUIHelpers'
import { AuthContext } from '../../../Auth/AuthContext'
import { RulesContext } from '../../RulesContext'
import { RuleEditForm } from './RuleEditForm'
import { RuleEditFormWizard } from './RuleEditFormWizard'

export function RuleEdit({
    history,
    match: {
        params: { id }
    }
}) {
    // Subheader
    const suhbeader = useSubheader()

    // Tabs
    const [tab, setTab] = useState('basic')
    const [title, setTitle] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { user } = useContext(AuthContext)
    const { rules, ruleForEdit, setRuleForEdit, actionsLoading, setActionsLoading, initRule } = useContext(RulesContext)
    const { setIds, setQueryParams } = useContext(AreasContext)

    useEffect(() => {
        setActionsLoading(true)
        const getRule = async () => {
            if (id) {
                const ruleRef = await db.collection('rules').doc(id).get()
                const rule = ruleRef.data()

                setIds(rule.areas)
                setRuleForEdit(rule)
            }
            setActionsLoading(false)
        }
        setRuleForEdit(initRule)
        getRule(id)
    }, [id, setActionsLoading])

    useEffect(() => {
        let _title = id ? '' : 'New rule'
        if (ruleForEdit && id) {
            _title = `Edit rule`
        }

        setTitle(_title)
        // suhbeader.setTitle(_title)
    }, [ruleForEdit, id])

    const saveRule = async rule => {
        setIsLoading(true)
        const batch = db.batch()
        let ruleKey = rule.key
        if (!rule.key) {
            ruleKey = db.collection('rules').doc().id
            rule.key = ruleKey
            rule.organizationKey = user.organizationKey
            rule.visible = true
        }
        delete rule.id

        if (rule.inspection) {
            rule.priority = 0
        }

        rule.start = moment(rule.start).startOf('day').valueOf()
        rule.end = moment(rule.end).startOf('day').valueOf()

        if (['checkin', 'checkout', 'daily'].includes(rule.repeatType)) {
            rule.repeatOffsetStart = 0
            rule.repeatOffsetEnd = 0
        }

        batch.set(db.collection('rules').doc(ruleKey), rule, { merge: true })

        await batch.commit()

        rule.id = rule.key
        setIsLoading(false)

        setIds([])

        setRuleForEdit(initRule)
        history.push('/settings/rules')
    }

    useEffect(() => {
        return () => {
            setIds([])
            setRuleForEdit(initRule)
            setQueryParams(initialFilter)
        }
    }, [])

    const btnRef = useRef()

    return (
        <Card className="card-no-shadow">
            {actionsLoading && <ModalProgressBar />}

            <CardHeader title={title}>
                <CardHeaderToolbar>
                    {/* <button type="button" onClick={backToRuleList} className="btn btn-light">
                        <i className="fa fa-arrow-left"></i>
                        Back
                    </button> */}
                    {/* {`  `}
					<button className="btn btn-light ml-2">
						<i className="fa fa-redo"></i>
						Reset
					</button> */}
                    {/* {`  `}
                    <button type="submit" className="btn btn-primary ml-2" onClick={saveRuleClick}>
                        Save
                    </button> */}
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <div className="">
                    {!actionsLoading && (
                        <RuleEditFormWizard
                            actionsLoading={actionsLoading}
                            rule={ruleForEdit || initRule}
                            btnRef={btnRef}
                            saveRule={saveRule}
                        />
                    )}
                </div>
            </CardBody>
        </Card>
    )
}
