import type { OrgStruct } from '@shared/firestore-structs'
import Alert from 'app/components/alerts/Alert'
import { currentOrganizationAtom } from 'app/modules/Auth/atoms'
import { PMS_RECONNECT_LINK } from 'app/navigation/url-constants'
import type React from 'react'
import { useContext, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { Card, CardHeader } from '../../../../../_metronic/_partials/controls'
import { useFeatureToggle } from '../../../../features'
import { AccountsContext } from '../../AccountsContext'
import { Tab, TabsContainer } from './subscription-plans/style'

export const AccountTabs = () => {
    const { showAlert, setShowAlert } = useContext(AccountsContext)
    const { isFeatureOn } = useFeatureToggle()
    const currentOrganization = useRecoilValue(currentOrganizationAtom) as OrgStruct
    const history = useHistory()

    const [reconnectAlertIsOpen, setReconnectAlertIsOpen] = useState(false)
    const alertTitle = `${currentOrganization.pms.charAt(0).toUpperCase() + currentOrganization.pms.slice(1)} reconnection`

    const handleReconnectPMSClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        setReconnectAlertIsOpen(true)
    }

    const onReconnectPMSConfirm = () => history.push(PMS_RECONNECT_LINK(currentOrganization.pms))

    return (
        // add typescript to Card
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Card className="card-no-shadow">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/* @ts-ignore */}
            <CardHeader title="Account" style={{ fontSize: 40 }} />
            {showAlert && (
                // This library not support typescript
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <SweetAlert
                    success
                    title="Account information saved"
                    onConfirm={() => {
                        setShowAlert(false)
                    }}
                    onCancel={() => {
                        setShowAlert(false)
                    }}
                    cancelBtnBsStyle="success"
                    confirmBtnBsStyle="success"
                    confirmBtnStyle={{ border: 0 }}>
                    You&apos;ve successfully updated your account information!
                </SweetAlert>
            )}

            {reconnectAlertIsOpen && (
                <Alert
                    title={alertTitle}
                    text={'Are you sure you want to reconnect your PMS organization?'}
                    onConfirm={onReconnectPMSConfirm}
                    onCancel={() => setReconnectAlertIsOpen(false)}
                />
            )}

            <TabsContainer>
                <Tab to="/settings/account/information" activeClassName="active">
                    General
                </Tab>

                <Tab to="/settings/account/subscription" activeClassName="active">
                    Subscription
                </Tab>

                <Tab to="/settings/account/issues" activeClassName="active">
                    Issue categories
                </Tab>

                {isFeatureOn('pms-reconnect') && currentOrganization.pms && currentOrganization.pms.length > 0 && (
                    <Tab onClick={handleReconnectPMSClick} to={PMS_RECONNECT_LINK(currentOrganization.pms)} activeClassName="active">
                        Reconnect PMS
                    </Tab>
                )}
            </TabsContainer>
        </Card>
    )
}
