import * as colors from '@shared/colors'
import styled from 'styled-components'
import { MOBILE_BREAKPOINT } from '../../../utils/constants'

const blue = colors.medium_blue
const red = colors.main_red
const yellow = colors.main_yellow
const black = colors.black_sweeply
const white = colors.white

export interface CountBoxProps {
    done: boolean
}

export const SignUpWelcomeWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;

    .content-container {
        width: 91%;
        height: 100%;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        display: block;

        .content-container {
            display: block;
            position: relative;
            width: 100%;
            height: max-content;
            padding: 54px 40px 0 40px;
        }
    }
`

export const ContentWrap = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-between;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: block;
    }
`

export const LogoWrap = styled.div`
    width: 25%;
    padding-top: 56px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        height: 100%;
        padding: 0;

        > img {
            width: 128px !important;
            height: 23.46px !important;
        }
    }
`

export const Rectangles = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -1;

    div {
        position: absolute;
    }

    .rectangle-6 {
        width: 14px;
        height: 61px;
        background-color: ${blue};
        border-radius: 4px;
        right: 23%;
        top: 28.5%;
    }

    .rectangle-5 {
        width: 20.42px;
        height: 20.42px;
        background-color: ${red};
        border-radius: 4px;
        transform: rotate(45deg);
        top: 46%;
        left: 18%;
    }

    .rectangle-4 {
        width: 60.22px;
        height: 60.22px;
        background: ${yellow};
        border-radius: 8px;
        bottom: 42.5%;
        left: 36%;
    }

    .rectangle-3 {
        width: 34.89px;
        height: 34.89px;
        background-color: ${blue};
        border-radius: 100px;
        bottom: 36.2%;
        left: 2%;
    }

    .rectangle-2 {
        width: 33px;
        height: 35px;
        background-color: ${yellow};
        border-radius: 4px;
        transform: rotate(30deg);
        bottom: 33%;
        right: 21%;
    }

    .rectangle-1 {
        width: 60.22px;
        height: 60.22px;
        border-radius: 8px;
        background-color: ${blue};
        bottom: 20%;
        left: 17%;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        padding: 0;
        height: 125px;

        .rectangle-1 {
            width: 9.02px;
            height: 39.29px;
            transform: rotate(90deg);
            left: unset;
            right: 12%;
            bottom: 10%;
        }

        .rectangle-2 {
            border-radius: 4px;
            transform: rotate(120deg);
            width: 21.26px;
            height: 21.9px;
            right: 17%;
            top: 7%;
        }

        .rectangle-3 {
            width: 22.48px;
            height: 22.48px;
            left: -2%;
            top: 36%;
        }

        .rectangle-4 {
            left: 12.5%;
            bottom: 10%;
            width: 24px;
            height: 24px;
        }

        .rectangle-5 {
            border: 1px solid ${red};
            top: unset;
            left: unset;
            bottom: 16%;
            right: 29.5%;
            width: 13.15px;
            height: 13.15px;
        }
    }
`

export const CountWrap = styled.div`
    width: 57%;
    padding-top: 160px;
    font-family: 'Circular Std';
    font-style: normal;
    color: ${black};

    h1 {
        font-weight: 700;
        font-size: 3.692rem;
        line-height: 64px;
        margin-bottom: 24px;
    }

    > span {
        font-weight: 450;
        font-size: 1.846rem;
        line-height: 40px;
        margin-bottom: 64px;
        display: block;
    }

    > div {
        display: flex;
        column-gap: 40px;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        padding: 0;
        margin-top: 82.54px;
        margin-bottom: 94px;

        h1 {
            font-size: 2.667rem;
            line-height: 48px;
            width: 90%;
            margin-bottom: 16px;
        }

        > span {
            font-size: 1.333rem;
            line-height: 32px;
            margin-bottom: 44px;
        }

        > div {
            column-gap: 32px;
        }

        button {
            width: 219px !important;
            height: 48px !important;
            font-size: 1.333rem !important;
            line-height: 24px !important;
        }
    }
`

export const CountBox = styled.div<CountBoxProps>`
    width: 168px;
    height: 168px;
    background: ${white};
    border: 2px solid ${yellow};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Circular Std';
    font-weight: 450;
    padding-bottom: 15px;

    label {
        font-size: 1.077rem;
        line-height: 24px;
        opacity: 0.5;
    }

    span {
        color: ${black};
        font-size: 3.692rem;
        line-height: 64px;
        ${({ done }) =>
            done &&
            'background: linear-gradient(104.65deg, #EB4A4A 46.8%, #F08F6C 71.8%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; background-clip: text; text-fill-color: transparent;'}
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 132px;
        height: 132px;

        label {
            font-size: 1rem;
            line-height: 24px;
            margin: 0;
        }

        span {
            font-size: 3.333rem;
        }
    }
`

export const ServerSideImportErrorWrap = styled.div`
    width: 57%;
    margin-top: 20px;
    font-family: 'Circular Std';
    font-style: normal;
    color: ${black};

    h3 {
        font-weight: 700;
        font-size: 2.042rem;
        line-height: 64px;
        margin-bottom: 24px;
    }

    > span {
        font-weight: 450;
        font-size: 1.846rem;
        line-height: 40px;
        margin-bottom: 64px;
        display: block;
    }

    > div {
        display: flex;
        width: 343px !important;
        background-color: #fff0c4;
        flex-direction: column;
        column-gap: 40px;
        padding: 30px;
        font-size: 1.246rem;
        line-height: 30px;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        width: 100%;
        padding: 0;
        margin-top: 22.54px;
        margin-bottom: 94px;

        > div {
            width: 219px !important;
            padding: 10px;
        }
    }
`

export const ServerSideImportErrorMessageWrap = styled.div`
    background-color: #fff9db;
    padding: 8px;
`
