import moment from 'moment-timezone'
import { Subject, combineLatest, filter, map, scan, startWith } from 'rxjs'
import { P, match } from 'ts-pattern'
import { findLeadBooking } from '../booking-service'
import { isFeatureOn } from '../feature-toggles'
import type { Firebase, FirebaseFirestore } from '../firebase'
import type {
    ActivityStruct,
    AreaCleaningStatus,
    AreaStruct,
    BookingStruct,
    Occupancy,
    OrgStruct,
    RuleOverride,
    RuleStruct
} from '../firestore-structs'
import {
    RULE_TYPE_CUSTOM,
    RULE_TYPE_OPTIN,
    type RuleResolveResult,
    type RuleResolverOptions,
    resolveBookingRule,
    resolveRepeatDates,
    resolveRule
} from '../rule-resolver'
import {
    BOOKING_STATUS_BLOCKED,
    BOOKING_STATUS_BOOKED,
    CLEANING_STATUS_DIRTY,
    CLEANING_STATUS_INSPECTION,
    CLEANING_STATUS_NO_SERVICE,
    CLEANING_STATUS_OUT_OF_SERVICE,
    OCCUPANCY_CHECKIN,
    OCCUPANCY_STAYOVER,
    OCCUPANCY_STAYOVER_80,
    OCCUPANCY_VACANT
} from '../txt-constants'

type ExtRuleResolveResult = Partial<RuleResolveResult> &
    Partial<RuleStruct> & {
        name?: string
        description?: string
        priority?: number
        checklistTasks?: string[]
        customChar?: string
    }

export type ExtAreaStruct = AreaStruct & { rules?: RuleStruct[]; ruleName?: string }

function sortTimeStampAscending(a: number | string, b: number | string) {
    // if (a == null || b == null) return
    let result = 0

    let aTimeStamp = a
    let bTimeStamp = b

    if (aTimeStamp === undefined) aTimeStamp = 0
    if (bTimeStamp === undefined) bTimeStamp = 0

    result = Number(aTimeStamp) - Number(bTimeStamp)
    return result
}

function getOccupancyFromBookings(bookings: BookingStruct[], d: number): string {
    const todaysBookings = bookings.filter(b => b.bookingDates.includes(d.toString()))
    const dayUseBookings = todaysBookings.filter(b => b.isDayUse)

    if (dayUseBookings.length > 0) {
        const bookingsSorted = todaysBookings.sort((a, b) =>
            sortTimeStampAscending(a.checkinTime || a.checkinDate, b.checkinTime || b.checkinDate)
        )

        switch (bookingsSorted.length) {
            case 1:
                return bookingsSorted[0].guestCheckedIn ? 'checkout' : 'checkin'
            case 2:
                if (bookingsSorted[0].isDayUse) {
                    return bookingsSorted[0].guestCheckedIn ? 'turnover' : 'checkin'
                } else if (bookingsSorted[1].isDayUse) {
                    return bookingsSorted[1].guestCheckedIn ? 'checkout' : 'turnover'
                }
                break
            case 3:
                return 'turnover'
        }
    } else {
        const checkinBookings = bookings.some(b => b.checkinDate === d)
        const checkoutBookings = bookings.some(b => b.checkoutDate === d)
        const stayoverBookings = bookings.some(b => b.checkinDate !== d && b.checkoutDate !== d && b.bookingDates.includes(d.toString()))

        if (stayoverBookings) return 'stayover'
        if (checkinBookings && checkoutBookings) return 'turnover'
        if (checkinBookings) return 'checkin'
        if (checkoutBookings) return 'checkout'
        return 'vacant'
    }

    return 'vacant'
}

export async function fetchBookingsForDate(firebase: Firebase | FirebaseFirestore, date: number, organizationKey: string) {
    const bookings: BookingStruct[] = []
    const db = 'firestore' in firebase ? firebase.firestore() : firebase

    const bookingSnap = await db
        .collection('bookings')
        .where('organizationKey', '==', organizationKey)
        .where('bookingDates', 'array-contains', date.toString())
        .get()

    bookingSnap.docs.forEach(doc => {
        const b = doc.data() as BookingStruct
        if (b.status && b.status.toLowerCase() !== 'cancelled') {
            bookings.push(b)
        }
    })

    return bookings
}

export function getActiveRule(
    area: Pick<ExtAreaStruct, 'rules' | 'inspection' | 'cleaningStatus'>,
    bookings: BookingStruct[],
    date: number,
    org: Pick<OrgStruct, 'timezone' | 'featuresEnabled' | 'featuresDisabled'>
) {
    const results: ExtRuleResolveResult[] = []
    const options = {
        cleanUntilCheckin: cleanUntilCheckinCondition(org, date)
    }
    area.rules!.forEach(r => {
        const result: ExtRuleResolveResult = resolveRule(r, bookings, date, org, area, options)
        if (result && Object.keys(result).length > 0) {
            result.name = r.name
            result.description = r.description
            result.priority = r.priority
            results.push(result)
        }
        if (result.inspection) {
            area.inspection = result.inspection
        }
    })
    const sortedResults = results.sort((a, b) => b.priority! - a.priority!)
    const activeResult = sortedResults.length > 0 ? sortedResults[0] : {}

    return activeResult
}

function calculateRules<
    Area extends Pick<ExtAreaStruct, 'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus'>
>(
    area: Area,
    bookings: Pick<
        BookingStruct,
        | 'areaKey'
        | 'checkinDate'
        | 'checkoutDate'
        | 'bookingDates'
        | 'optInDates'
        | 'optOutDates'
        | 'status'
        | 'guestCheckedIn'
        | 'guestCheckedOut'
        | '_external'
    >[],
    date: number,
    org: Pick<OrgStruct, 'timezone'>,
    options: { cleanUntilCheckin: boolean }
) {
    const results = area.rules!.flatMap(r => {
        const result: ExtRuleResolveResult = resolveRule(r, bookings, date, org, area, options)
        if (result.inspection) {
            area.inspection = result.inspection
            const checkoutBookings = bookings.filter(b => b.checkoutDate === date)
            if (checkoutBookings && checkoutBookings.length > 0 && area.rules!.filter(r => r.repeatType === 'checkout').length > 0) {
                result.cleaningStatus = CLEANING_STATUS_INSPECTION
            }
        }
        if (result && Object.keys(result).length > 0) {
            result.name = r.name
            result.description = r.description
            result.priority = r.priority
            result.checklistTasks = r.checklistTasks
            result.customChar = r.customChar
            result.mandatory = r.mandatory || false
            return [result]
        }
        return []
    })

    // console.debug(`Results for area: ${area.name} and date: ${date} are: ${JSON.stringify(results)}`)
    const sortedResults = results.sort((a, b) => b.priority! - a.priority!)
    const activeResult = sortedResults.length > 0 ? sortedResults[0] : {}

    const leadBooking = findLeadBooking(area, bookings, date)

    // console.debug(`Active result for area: ${area.name} and date: ${date} is: ${JSON.stringify(activeResult)}`)
    if (activeResult) {
        if (activeResult.occupancy) {
            area.occupancy = activeResult.occupancy
            area.ruleName = activeResult.name
            area.activeRule = activeResult as RuleStruct
        }
        if (activeResult.cleaningStatus) {
            area.cleaningStatus = activeResult.cleaningStatus
            area.ruleName = activeResult.name
            area.activeRule = activeResult as RuleStruct
        }
        if (activeResult.inspection) {
            if (area.cleaningStatus === 'inspection') {
                area.ruleName = activeResult.name
                area.activeRule = activeResult as RuleStruct
            }
        }
        if (activeResult.optInDates) {
            leadBooking!.optInDates = activeResult.optInDates.map(x => x.toString())
        }
    }

    return area
}

const cleanUntilCheckinCondition = (
    org: Pick<OrgStruct, 'timezone' | 'featuresEnabled' | 'featuresDisabled'>,
    selectedDate: number,
    testParams?: { date: number }
) => {
    return (
        isFeatureOn(org, 'clean-until-checkin') &&
        moment.tz(testParams?.date || moment.tz(), org.timezone).isSameOrAfter(moment.tz(selectedDate, org.timezone).startOf('day'))
    )
}

const wasLastExtraCleaningSkipped = (
    areaRules: RuleStruct[],
    booking: BookingStruct,
    date: number,
    org: Pick<OrgStruct, 'timezone' | 'featuresDisabled' | 'featuresEnabled'>,
    area: Pick<AreaStruct, 'occupancy' | 'cleaningStatus' | 'daysSinceLastCleaning' | 'daysSinceLastCheckout'>,
    options: RuleResolverOptions
) => {
    const extraCleaningRule = areaRules.find(x => x.repeatType === 'custom' && x.repeatInterval > 1)
    // No extra cleaning rule, so no extra cleaning was skipped
    if (!extraCleaningRule) {
        return false
    }
    // extra cleaning rule already applied so no extra cleaning was skipped
    if (resolveBookingRule(extraCleaningRule, [booking], date, org, area, options).occupancy === 'stayover-80') {
        return false
    }

    // we only only need to check dates pror to the date being checked against
    const extraCleamningDays = resolveRepeatDates(extraCleaningRule, booking.checkinDate, booking.checkoutDate, org)
    const allExtraCleaningDaysPrior = extraCleamningDays.filter(x => moment(x).isSameOrBefore(date))

    // if the day is extra cleaning we don't need to check if the last extra cleaning was skipped
    if (allExtraCleaningDaysPrior.includes(date)) {
        return false
    }
    // No extra cleaning prior so no extra cleaning was skipped
    if (allExtraCleaningDaysPrior.length === 0) {
        return false
    }
    const extraCleaningDaysOptedInto = booking
        .optInDates!.filter(x => allExtraCleaningDaysPrior.includes(Number.parseInt(x)))
        .sort(sortTimeStampAscending)

    // Some skipped extra cleaning days
    if (allExtraCleaningDaysPrior.length !== extraCleaningDaysOptedInto.length) {
        // No extra cleaning days opted into so last extra cleaning was skipped
        if (extraCleaningDaysOptedInto.length === 0) {
            return true
        }
        const lastExtraCleaningDayOptedInto = extraCleaningDaysOptedInto[extraCleaningDaysOptedInto.length - 1]
        const daysSinceLastExtraCleaning = moment(date).diff(moment(Number.parseInt(lastExtraCleaningDayOptedInto)), 'days')
        // if the last extra cleaning was skipped, return true
        if (lastExtraCleaningDayOptedInto && daysSinceLastExtraCleaning >= extraCleaningRule.repeatInterval) {
            return true
        }
    }

    return false
}

export function getDaysToClean(
    booking: Pick<BookingStruct, 'areaKey' | 'checkinDate' | 'checkoutDate' | 'optInDates' | 'optOutDates'>,
    areaRules: RuleStruct[],
    org: Pick<OrgStruct, 'timezone'>
) {
    let daysToClean = areaRules
        .filter(x => ![RULE_TYPE_OPTIN].includes(x.repeatType))
        .flatMap(r => resolveRepeatDates(r, booking.checkinDate, booking.checkoutDate, org))

    // resolve optInDates for optInRules and add them to the daysToClean
    const optInRuleDates = areaRules
        .filter(x => x.repeatType === RULE_TYPE_OPTIN)
        .flatMap(r => resolveRepeatDates(r, booking.checkinDate, booking.checkoutDate, org))
    const optInDates = optInRuleDates.filter(x => booking.optInDates?.includes(x.toString()))
    daysToClean.push(...optInDates)

    // remove optOut dates
    daysToClean = daysToClean.filter(x => !booking.optOutDates?.includes(x.toString()))

    // reapply mandatory dates just to be sure
    daysToClean.push(
        ...areaRules.filter(x => x.mandatory).flatMap(r => resolveRepeatDates(r, booking.checkinDate, booking.checkoutDate, org))
    )

    // Only return the unique days
    return [...new Set(daysToClean)]
}

export function newOptionalCalculations<
    Area extends Pick<ExtAreaStruct, 'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus' | 'key'>
>(
    date: number,
    area: Area,
    bookings: Pick<
        BookingStruct,
        | 'areaKey'
        | 'checkinDate'
        | 'checkoutDate'
        | 'bookingDates'
        | 'optInDates'
        | 'optOutDates'
        | 'ruleOverride'
        | 'status'
        | 'guestCheckedIn'
        | 'guestCheckedOut'
        | '_external'
    >[],
    org: Pick<OrgStruct, 'timezone' | 'featuresEnabled' | 'featuresDisabled'>,
    options: { cleanUntilCheckin: boolean },
    rules?: RuleStruct[]
) {
    const leadBooking = findLeadBooking(area, bookings, date)

    if (!leadBooking) {
        return calculateRules(area, bookings, date.valueOf(), org, options)
    }

    const daysToClean: number[] = bookings.flatMap(x => getDaysToClean(x, area.rules!, org))

    if (daysToClean.includes(date)) {
        area = calculateOverrides(area, bookings, date, rules!, org, options, leadBooking.ruleOverride || [])
    }

    return Object.assign({}, area)
}

function calculateOverrides<
    Area extends Pick<ExtAreaStruct, 'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus'>
>(
    area: Area,
    bookings: Pick<
        BookingStruct,
        | 'areaKey'
        | 'checkinDate'
        | 'checkoutDate'
        | 'bookingDates'
        | 'optInDates'
        | 'optOutDates'
        | 'ruleOverride'
        | 'status'
        | 'guestCheckedIn'
        | 'guestCheckedOut'
        | '_external'
    >[],
    date: number,
    allRules: RuleStruct[],
    org: Pick<OrgStruct, 'timezone' | 'featuresEnabled' | 'featuresDisabled'>,
    options: { cleanUntilCheckin: boolean },
    ruleOverride: RuleOverride[]
) {
    const leadBooking = findLeadBooking(area, bookings, date)

    if (!leadBooking && (!ruleOverride || ruleOverride.length === 0)) {
        return calculateRules(area, bookings, date.valueOf(), org, options)
    }

    const ruleOverrideDates = ruleOverride.map(x => Number.parseInt(Object.keys(x)[0]))

    if (isFeatureOn(org, 'editable-optin') && ruleOverrideDates.includes(date)) {
        const override = ruleOverride.find(x => Number.parseInt(Object.keys(x)[0]) === date)
        const activeRule = allRules?.find(x => override && x.key === Object.values(override)[0].ruleKey)

        if (activeRule) {
            const ruleSet: Partial<RuleStruct> = {
                name: activeRule.name,
                description: activeRule.description,
                priority: activeRule.priority,
                checklistTasks: activeRule.checklistTasks,
                customChar: activeRule.customChar,
                key: activeRule.key
            }
            area.activeRule = ruleSet as RuleStruct
            area.ruleName = activeRule.name
            area.cleaningStatus = CLEANING_STATUS_DIRTY
            if (activeRule.inspection) {
                area.cleaningStatus = CLEANING_STATUS_INSPECTION
            }
            return Object.assign({}, area)
        }
    }
    area = calculateRules(area, bookings, date.valueOf(), org, options)
    return Object.assign({}, area)
}

function calculateOptIns<
    Area extends Pick<ExtAreaStruct, 'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus'>
>(
    area: Area,
    bookings: BookingStruct[],
    date: number,
    allRules: RuleStruct[],
    org: Pick<OrgStruct, 'timezone' | 'featuresEnabled' | 'featuresDisabled'>,
    options: { cleanUntilCheckin: boolean }
) {
    const leadBooking = findLeadBooking(area, bookings, date)

    if (leadBooking) {
        if (leadBooking.optInDates && leadBooking.optInDates.includes(date.toString())) {
            if (
                area.rules &&
                wasLastExtraCleaningSkipped(area.rules, leadBooking, date, org, area, options) &&
                !isFeatureOn(org, 'editable-optin')
            ) {
                area.cleaningStatus = CLEANING_STATUS_DIRTY
                area.occupancy = 'stayover-80'
            } else {
                area = calculateOverrides(area, bookings, date, allRules, org, options, leadBooking.ruleOverride || [])
            }
        } else {
            const optInRule = allRules.find(r => r.repeatType === RULE_TYPE_OPTIN)
            if (optInRule) {
                // If there is a rule with higher priority it should take precedence
                const otherHousekeepingRule = area.rules?.find(r => r.repeatType === RULE_TYPE_CUSTOM && r.repeatInterval > 1)
                if (otherHousekeepingRule && otherHousekeepingRule.priority > optInRule.priority) {
                    const otherHousekeepingRuleResults = resolveBookingRule(otherHousekeepingRule, [leadBooking], date, org, area, options)
                    if (otherHousekeepingRuleResults && otherHousekeepingRuleResults.cleaningStatus) {
                        area = calculateOverrides(area, bookings, date, allRules, org, options, leadBooking.ruleOverride || [])
                    } else {
                        area.cleaningStatus = CLEANING_STATUS_NO_SERVICE
                    }
                } else {
                    area.cleaningStatus = CLEANING_STATUS_NO_SERVICE
                }
            } else {
                area.cleaningStatus = CLEANING_STATUS_NO_SERVICE
            }
        }
    } else {
        area = calculateRules(area, bookings, date.valueOf(), org, options)
    }

    return area
}

function calculateOptOuts<
    Area extends Pick<ExtAreaStruct, 'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus'>
>(
    area: Area,
    bookings: BookingStruct[],
    date: number,
    org: Pick<OrgStruct, 'timezone'>,
    options: {
        cleanUntilCheckin: boolean
    }
) {
    const leadBooking = findLeadBooking(area, bookings, date)
    if (leadBooking && leadBooking.optOutDates && leadBooking.optOutDates.length > 0) {
        if (leadBooking.optOutDates.includes(date.toString())) {
            area.cleaningStatus = CLEANING_STATUS_NO_SERVICE
        } else {
            area = calculateRules(area, bookings, date.valueOf(), org, options)
        }
    } else {
        area = calculateRules(area, bookings, date.valueOf(), org, options)
    }

    return area
}

const sortByTime = <
    T extends {
        created: number
    }
>() => map<T[], T[]>(events => events.sort((a, b) => a.created - b.created))
type CleaningCalculation = { cleaningStatus: AreaCleaningStatus }

function calculateActivitiesNewNew<Area extends Pick<ExtAreaStruct, 'cleaningStatus' | 'occupancy'>>(
    area: Area,
    activities: ActivityStruct[],
    numberOfBookingsForTheDay: number,
    org: Pick<OrgStruct, 'key' | 'timezone' | 'featuresEnabled' | 'featuresDisabled' | 'allowOptIn' | 'allowOptOut'>
) {
    const src = new Subject<ActivityStruct>()
    let resultOccupancy = area.occupancy

    src.pipe(
        filter(a => a.change.field === 'occupancy'),
        map(a => a.change.after as Occupancy),
        startWith(area.occupancy)
    ).subscribe(occ => {
        resultOccupancy = occ
    })
    const guestCheckinActivities = src.pipe(
        filter(a => a.change.field === 'guestCheckedIn'),
        map(a => ({ type: 'checkin', created: a.created }) as const),
        startWith({ type: 'no-checkin', created: 0 } as const) // this is so each observable emits an event even if there are no events of this type
    )
    const guestCheckOutActivities = src.pipe(
        filter(a => a.change.field === 'guestCheckedOut'),
        map(a => ({ type: 'checkout', created: a.created }) as const),
        startWith({ type: 'no-checkout', created: 0 } as const)
    )

    const reservationsLeft = guestCheckOutActivities.pipe(scan((acc, _) => acc - 1, numberOfBookingsForTheDay + 1)) // +1 to account for the lead dummy event in the source observable

    const cleaningActivities = src.pipe(
        filter(a => a.change.field === 'cleaningStatus'),
        map(
            a =>
                ({
                    type: 'cleaningStatus',
                    cleaningStatus: a.change.after as AreaCleaningStatus,
                    created: a.created
                }) as const
        ),
        startWith({ type: 'no-cleaning', created: 0, cleaningStatus: 'clean' } as const)
    )

    let latestCleaningStatus: CleaningCalculation = { cleaningStatus: 'clean' }

    combineLatest([
        combineLatest([cleaningActivities, guestCheckOutActivities, guestCheckinActivities]).pipe(sortByTime()),
        reservationsLeft
    ])
        .pipe(
            map(seq => {
                const outstandingCheckinsForToday = seq[1]
                const activities = seq[0]

                const mapped: Partial<CleaningCalculation> = match(activities)
                    .with([{ type: P._ }, { type: P._ }, { type: 'checkout' }], () => ({
                        cleaningStatus: 'dirty'
                    }))

                    .with([{ type: P._ }, { type: P._ }, { type: 'cleaningStatus', cleaningStatus: P.select() }], c => ({
                        cleaningStatus: c
                    }))
                    .with(
                        [
                            { type: P._ },
                            {
                                type: 'cleaningStatus',
                                cleaningStatus: P.select()
                            },
                            { type: 'checkout' }
                        ],
                        c => ({
                            cleaningStatus: c
                        })
                    )
                    .with([{ type: 'cleaningStatus' }, { type: 'checkin' }, { type: 'checkout' }], () => ({
                        cleaningStatus: 'dirty' as const
                    }))
                    .with([{ type: P._ }, { type: 'cleaningStatus', cleaningStatus: P.select() }, { type: 'checkin' }], c => {
                        if (isFeatureOn(org, 'ncs-after-checkin') && c === 'clean') {
                            return { cleaningStatus: 'no-cleaning-service' as const }
                        }
                        return {
                            cleaningStatus: outstandingCheckinsForToday > 1 ? 'waiting-for-checkout' : c
                        }
                    })
                    .otherwise(() => ({}))
                return mapped
            }),
            scan((acc, update) => ({ ...acc, ...update }), { cleaningStatus: area.cleaningStatus })
        )
        .subscribe(update => {
            latestCleaningStatus = update
        })
    activities.reverse() // activities are in reverse chronological order, so we need to reverse them to get the correct order
    activities.forEach(a => src.next(a))
    activities.reverse() // reverse them back to the original order. We should drop this as soon as we switch to this way of processing activities
    src.complete()
    return { cleaningStatus: latestCleaningStatus.cleaningStatus, occupancy: resultOccupancy }
}

function calculateActivitiesNew<Area extends Pick<ExtAreaStruct, 'cleaningStatus' | 'occupancy'>>(
    area: Area,
    activities: ActivityStruct[],
    org: Pick<OrgStruct, 'key' | 'timezone' | 'featuresEnabled' | 'featuresDisabled' | 'allowOptIn' | 'allowOptOut'>
) {
    const cleaningStatusActivities = activities.filter(a => a.change.field && a.change.field === 'cleaningStatus')
    const occupancyActivities = activities.filter(a => a.change.field && a.change.field === 'occupancy')

    const checkoutActivities = activities.filter(a => a.change.field && a.change.field === 'guestCheckedOut')
    const checkinActivities = activities.filter(a => a.change.field && a.change.field === 'guestCheckedIn')

    const latestGuestCheckedOutActivity = checkoutActivities.length > 0 ? checkoutActivities[0] : null
    const latestGuestCheckedInActivity = checkinActivities.length > 0 ? checkinActivities[0] : null
    const latestCleaningStatusActivity = cleaningStatusActivities.length > 0 ? cleaningStatusActivities[0] : null
    const isCleaningActivityBeforeGuestCheckedOut =
        !!latestGuestCheckedOutActivity &&
        !!latestCleaningStatusActivity &&
        latestCleaningStatusActivity.created < latestGuestCheckedOutActivity.created

    const cleanAndCheckinAndCheckoutPattern =
        !!latestCleaningStatusActivity &&
        !!latestGuestCheckedInActivity &&
        !!latestGuestCheckedOutActivity &&
        latestCleaningStatusActivity.created < latestGuestCheckedInActivity.created &&
        latestGuestCheckedInActivity.created < latestGuestCheckedOutActivity.created

    const roomCleanedAfterCheckoutAndNextGuestCheckedInPattern =
        !!latestCleaningStatusActivity &&
        !!latestGuestCheckedOutActivity &&
        !!latestGuestCheckedInActivity &&
        latestCleaningStatusActivity.created > latestGuestCheckedOutActivity.created &&
        latestCleaningStatusActivity.created < latestGuestCheckedInActivity.created

    const guestCheckoutAndNoCleaningPattern = !!latestGuestCheckedOutActivity && !latestCleaningStatusActivity
    const guestCheckoutAfterCleaningPattern = !!latestGuestCheckedOutActivity && isCleaningActivityBeforeGuestCheckedOut
    if (guestCheckoutAndNoCleaningPattern || cleanAndCheckinAndCheckoutPattern) {
        area.cleaningStatus = CLEANING_STATUS_DIRTY
    } else if (roomCleanedAfterCheckoutAndNextGuestCheckedInPattern) {
        if (isFeatureOn(org, 'ncs-after-checkin')) {
            area.cleaningStatus = CLEANING_STATUS_NO_SERVICE
        } else {
            area.cleaningStatus = latestCleaningStatusActivity?.change.after as AreaCleaningStatus
        }
    } else if (guestCheckoutAfterCleaningPattern) {
        area.cleaningStatus = latestCleaningStatusActivity.change.after as AreaCleaningStatus
    } else if (latestCleaningStatusActivity) {
        area.cleaningStatus = latestCleaningStatusActivity.change.after as AreaCleaningStatus
    }

    if (occupancyActivities.length > 0) {
        const latestActivity = occupancyActivities[0]
        area.occupancy = latestActivity.change.after as Occupancy
    }

    return area
}

function calculateGuestStatus(area: Pick<AreaStruct, 'occupancy'>, bookings: BookingStruct[], d: number) {
    const checkinBookings = bookings.filter(b => b.checkinDate === d)
    const checkoutBookings = bookings.filter(b => b.checkoutDate === d)

    let guestCheckedIn = false
    let guestCheckedOut = false

    switch (area.occupancy) {
        case 'checkout':
            if (checkoutBookings[0] && checkoutBookings.length > 0 && checkoutBookings[0].guestCheckedOut) {
                guestCheckedOut = true
            }
            break
        case 'turnover':
            if (checkoutBookings[0] && checkoutBookings.length > 0 && checkoutBookings[0].guestCheckedOut) {
                guestCheckedOut = true
            }
            if (checkinBookings[0] && checkinBookings.length > 0 && checkinBookings[0].guestCheckedIn) {
                guestCheckedIn = true
            }
            break
        case 'checkin':
            if (checkinBookings[0] && checkinBookings.length > 0 && checkinBookings[0].guestCheckedIn) {
                guestCheckedIn = true
            }
            break
        case 'stayover':
            guestCheckedIn = true
            break
        case 'stayover-80':
            guestCheckedIn = true
            break
        default:
            break
    }

    return {
        guestCheckedIn,
        guestCheckedOut
    }
}

export async function calculateCleaningStatus<
    A extends Pick<
        ExtAreaStruct,
        'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus' | 'key' | 'guestCheckedOut' | 'guestCheckedIn'
    >
>(
    firebase: Firebase | FirebaseFirestore,
    areas: A[],
    selectedDate: number,
    org: Pick<OrgStruct, 'key' | 'timezone' | 'featuresEnabled' | 'featuresDisabled' | 'allowOptIn' | 'allowOptOut'>,
    bookings: BookingStruct[] | null = null,
    activities: ActivityStruct[] | null = null,
    allRules: RuleStruct[] = [],
    usingCleaningSchedule = false,
    testParams?: { date: number }
) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    const date = moment.tz(selectedDate, org.timezone).startOf('day')

    if (!bookings) {
        bookings = await fetchBookingsForDate(db, date.valueOf(), org.key)
    }

    if (activities === null) {
        // refactor to area-data
        const activitiesRef = await db
            .collectionGroup('activities')
            .where('organizationKey', '==', org.key)
            .where('date', '==', date.valueOf())
            .get()

        activities = activitiesRef.docs.map(activitiesSnap => {
            return activitiesSnap.data()
        })
    }

    const calcAreas = calculateCleaningStatusSync(
        areas,
        selectedDate,
        org,
        bookings,
        activities,
        allRules,
        usingCleaningSchedule,
        testParams
    )
    return calcAreas
}

export function calculateCleaningStatusSync<
    Area extends Pick<
        ExtAreaStruct,
        'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus' | 'key' | 'guestCheckedOut' | 'guestCheckedIn'
    >
>(
    areas: Area[],
    selectedDate: number,
    org: Pick<OrgStruct, 'key' | 'timezone' | 'featuresEnabled' | 'featuresDisabled' | 'allowOptIn' | 'allowOptOut'>,
    bookings: BookingStruct[] = [],
    activities: ActivityStruct[] = [],
    allRules: RuleStruct[] = [],
    usingCleaningSchedule?: boolean,
    testParams?: { date: number },
    taskRuleOverride?: RuleOverride
) {
    const date = moment.tz(selectedDate, org.timezone).startOf('day')
    const options = {
        cleanUntilCheckin: cleanUntilCheckinCondition(org, selectedDate, testParams)
    }

    const calcAreas = areas.map(area => {
        // console.debug(`Calculating cleaning status for area: ${area.name}, key: ${area.key}`)

        let areaBookings = bookings!.filter(b => {
            return b.areaKey === area.key
        })

        // Enrich the bookings with opt ins from extras
        // First check if there is an optin rule present
        const optInRule = allRules.find(r => r.repeatType === RULE_TYPE_OPTIN)
        if (optInRule) {
            areaBookings.map(b => {
                const result = resolveBookingRule(optInRule, [b], date.valueOf(), org, area, options)
                if (result.optInDates) {
                    b.optInDates = result.optInDates.map(x => x.toString())
                }
            })
        }

        const areaActivities = activities!.filter(a => {
            return a.areaKey === area.key
        })

        const areaRules = allRules.filter(r => {
            return r.areas.includes(area.key)
        })

        const rulesSorted = areaRules.sort((a, b) => a.priority - b.priority)

        area.rules! = rulesSorted
        areaBookings = areaBookings
            .filter(b => [BOOKING_STATUS_BOOKED, BOOKING_STATUS_BLOCKED].includes(b.status))
            .sort((a, b) => sortTimeStampAscending(a.checkinDate, b.checkinDate))
        const occupancyFromBookings = getOccupancyFromBookings(areaBookings, date.valueOf())
        // console.debug(`Occupancy from bookings: ${occupancyFromBookings}`)
        area.occupancy = occupancyFromBookings as Occupancy

        if (
            isFeatureOn(org, 'new-optional-calculations') &&
            (occupancyFromBookings === OCCUPANCY_STAYOVER || occupancyFromBookings === OCCUPANCY_STAYOVER_80)
        ) {
            area.cleaningStatus = 'no-cleaning-service'
        } else if (!date.isSame(moment.tz(org.timezone).startOf('day')) || usingCleaningSchedule) {
            area.cleaningStatus = 'clean'
        }

        const newOOOBehaviour = isFeatureOn(org, 'new-ooo-behaviour')
        if (areaBookings.length > 0 && areaBookings[0].status && areaBookings[0].status === BOOKING_STATUS_BLOCKED) {
            if (newOOOBehaviour && date.valueOf() === areaBookings[0].checkoutDate) {
                area.cleaningStatus = CLEANING_STATUS_INSPECTION
            } else {
                area.cleaningStatus = CLEANING_STATUS_OUT_OF_SERVICE
            }
            area.occupancy =
                areaBookings[1] &&
                areaBookings[1].status === BOOKING_STATUS_BOOKED &&
                areaBookings[1].checkinDate === areaBookings[0].checkoutDate &&
                date.valueOf() === areaBookings[0].checkoutDate
                    ? OCCUPANCY_CHECKIN // setting OCCUPANCY_CHECKIN if there is the last day of blocked booking and today is the new guests arriving
                    : OCCUPANCY_VACANT
            // console.debug(`Area is blocked, so setting cleaning status to ${area.cleaningStatus} and occupancy to ${area.occupancy}`)
        }

        if (area.rules && area.rules.length > 0) {
            if (taskRuleOverride) {
                area = calculateOverrides(area, areaBookings, date.valueOf(), allRules, org, options, [taskRuleOverride])
            } else if (
                isFeatureOn(org, 'new-optional-calculations') &&
                ([OCCUPANCY_STAYOVER, OCCUPANCY_STAYOVER_80].includes(area.occupancy) || usingCleaningSchedule)
            ) {
                area = newOptionalCalculations(date.valueOf(), area, areaBookings, org, options, allRules)
            } else if (area.occupancy === 'stayover' && org && org.allowOptIn) {
                // area.cleaningStatus = CLEANING_STATUS_CLEAN
                const optIns = calculateOptIns(area, areaBookings, date.valueOf(), allRules, org, options)
                // console.debug(
                //     `Area is stayover and org allows opt in, so calculating optin cleaning status: ${optIns.cleaningStatus} and occupancy: ${optIns.occupancy}`
                // )
                area = optIns
            } else if (area.occupancy === 'stayover' && org && org.allowOptOut) {
                // area = calculateRules(area, areaBookings, date.valueOf())
                const optOuts = calculateOptOuts(area, areaBookings, date.valueOf(), org, options)
                // console.debug(
                //     `Area is stayover and org allows opt out, so calculating optout cleaning status: ${optOuts.cleaningStatus} and occupancy: ${optOuts.occupancy}`
                // )
                area = optOuts
            } else {
                if (areaBookings[0]?.status !== BOOKING_STATUS_BLOCKED) {
                    const rules = calculateRules(area, areaBookings, date.valueOf(), org, options)
                    // console.debug(
                    //     `Area is not blocked, so calculating cleaning status from rules: cleaning status: ${rules.cleaningStatus} and occupancy: ${rules.occupancy}`
                    // )
                    area = rules
                }
            }
        }

        if (areaActivities.length > 0) {
            areaActivities.sort((a, b) => b.created - a.created)

            const activities = calculateActivitiesNewNew(area, areaActivities, areaBookings.length, org)
            area = calculateActivitiesNew(area, areaActivities, org)
            // console.debug(
            //     `Area has activities, so calculating cleaning status from activities: ${activities.cleaningStatus} and occupancy: ${activities.occupancy}`
            // )
            if (activities.cleaningStatus !== area.cleaningStatus || activities.occupancy !== area.occupancy) {
                console.warn(`
                    Area: ${area.key} for ${date.format('YYYY-MM-DD')} has different cleaning status or occupancy from activities using RxJS and existing activities algorithm.\nInput: activities: ${JSON.stringify(areaActivities)}, bookings count: ${areaBookings.length}, org: ${JSON.stringify(
                        {
                            key: org.key,
                            timezone: org.timezone,
                            featuresEnabled: org.featuresEnabled,
                            featuresDisabled: org.featuresDisabled,
                            allowOptIn: org.allowOptIn,
                            allowOptOut: org.allowOptOut
                        }
                    )}
                    Output: RXJS (cleaning status-${activities.cleaningStatus}, occupancy-${activities.occupancy}), existing algorithm (cleaning status-${area.cleaningStatus}, occupancy-${area.occupancy})`)
            }
            if (areaBookings.some(b => b.isDayUse)) {
                area.cleaningStatus = activities.cleaningStatus
                area.occupancy = activities.occupancy
            }
        }

        const guestStatus = calculateGuestStatus(area, areaBookings, date.valueOf())
        // console.debug(
        //     `Calculating guest status for area: ${area.name} with guest checked in: ${guestStatus.guestCheckedIn} and guest checked out: ${guestStatus.guestCheckedOut}`
        // )
        area.guestCheckedIn = guestStatus.guestCheckedIn
        area.guestCheckedOut = guestStatus.guestCheckedOut

        return area
    })

    return calcAreas
}
