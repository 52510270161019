import { getBrand } from '@shared/brand'
import Select from 'app/components/forms/Select'
import { Field, Form, Formik } from 'formik'
import React, { type ChangeEvent } from 'react'
import styled from 'styled-components'
import RectangularButton from '../../../components/buttons/RectangularButton'
import Input from '../../../components/forms/Input'
import { ContentWrap, ModalContainer, ModalOverlay } from '../../../components/styled-components/modal'
import SignUpLayout from '../SignUpLayout'
import { CodeVerificationWrapper } from '../style'

const CustomerCredentialsFormContainer = styled(Form)`
    @media (min-width: 992px) {
        padding-bottom: 50px;
    }
`
interface FormValues {
    siteId: string
    operatorCode: string
    password: string
    inspectionCode: number
    dirtyCode: number
}

interface Props {
    bus: string
    onSubmit: (values: FormValues) => void
}

const CustomerCredentialsForm = ({ bus, onSubmit }: Props) => {
    const brandColor = getBrand().navBarColor
    const initialValues: FormValues = {
        siteId: '',
        operatorCode: '',
        password: '',
        inspectionCode: 1100,
        dirtyCode: 1300
    }
    return (
        <SignUpLayout withModal>
            <ModalOverlay>
                <ModalContainer>
                    <ContentWrap>
                        <CodeVerificationWrapper>
                            <h1>{`Enter ${bus} customer credentials`}</h1>
                            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                                {({ handleSubmit, setFieldValue, values }) => {
                                    const disabled = !values.siteId || !values.operatorCode || !values.password

                                    return (
                                        <CustomerCredentialsFormContainer>
                                            <Field
                                                component={Input}
                                                label={`${bus} Site Id`}
                                                placeholder={'Enter Site ID here'}
                                                value={values.siteId}
                                                name={'siteId'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('siteId', e.target.value)}
                                                withoutValidation
                                            />
                                            <Field
                                                component={Input}
                                                label={`${bus} Operator Code`}
                                                placeholder={'Enter Operator Code here'}
                                                value={values.operatorCode}
                                                name={'operatorCode'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    setFieldValue('operatorCode', e.target.value)
                                                }
                                                withoutValidation
                                            />
                                            <Field
                                                component={Input}
                                                label={`${bus} Password`}
                                                placeholder={'Enter password here'}
                                                value={values.password}
                                                name={'password'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('password', e.target.value)}
                                                withoutValidation
                                            />

                                            <Select
                                                value={values.inspectionCode}
                                                label={`${bus} Inspection Code`}
                                                placeholder={`${bus} Inspection Code`}
                                                options={Array.from({ length: 7 }, (_, i) => ({
                                                    value: 1100 + i * 100,
                                                    label: 1100 + i * 100
                                                }))}
                                                onChange={(value: number) => setFieldValue('inspectionCode', value)}
                                            />
                                            <Select
                                                value={values.dirtyCode}
                                                label={`${bus} Dirty Code`}
                                                placeholder={`${bus} Dirty Code`}
                                                options={Array.from({ length: 7 }, (_, i) => ({
                                                    value: 1100 + i * 100,
                                                    label: 1100 + i * 100
                                                }))}
                                                onChange={(value: number) => setFieldValue('dirtyCode', value)}
                                            />

                                            <RectangularButton
                                                type="submit"
                                                disabled={disabled}
                                                width={'100%'}
                                                height={'64px'}
                                                backgroundColor={brandColor}
                                                margin={'10px 0 0 0'}
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                onClick={handleSubmit}
                                            >
                                                Confirm
                                            </RectangularButton>
                                        </CustomerCredentialsFormContainer>
                                    )
                                }}
                            </Formik>
                        </CodeVerificationWrapper>
                    </ContentWrap>
                </ModalContainer>
            </ModalOverlay>
        </SignUpLayout>
    )
}

export default CustomerCredentialsForm
