import React, { type PropsWithChildren, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Introduction from './Introduction'
import SignUpForm from './SignUpForm'
import { SignUpLayoutWrapper } from './style'

type SignUpLayoutProps = PropsWithChildren<{ withModal?: boolean }>

const SignUpLayout = ({ children, withModal = false }: SignUpLayoutProps) => {
    const [codeVerificationModalIsOpen, setCodeVerificationModalIsOpen] = useState(false)

    return (
        <SignUpLayoutWrapper>
            <Introduction />
            <SignUpForm setCodeVerificationModalIsOpen={setCodeVerificationModalIsOpen} />
            {(codeVerificationModalIsOpen || withModal) && children}
            <ToastContainer />
        </SignUpLayoutWrapper>
    )
}

export default SignUpLayout
