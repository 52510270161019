import type { AreaStruct } from './firestore-structs'

export class Area {
    key: string
    name: string
    description: string
    address: string
    group: string
    displayCleaningStatus: boolean
    organizationKey: string
    visible: boolean
    priority: number
    rules: string[]
    updated: number
    cleaningStatus: string
    occupancy: string

    constructor(values: Partial<AreaStruct> & { priority?: number; rules?: string[] } = {}) {
        this.key = values.key || ''
        this.name = values.name || ''
        this.description = values.description ?? ''
        this.address = values.address || ''
        this.group = values.group ?? ''
        this.displayCleaningStatus = values.displayCleaningStatus || false
        this.organizationKey = values.organizationKey || ''
        this.visible = values.visible ?? true
        this.priority = values.priority ?? 1
        this.rules = values.rules ?? []
        this.updated = values.updated || 0
        this.cleaningStatus = values.cleaningStatus || 'clean'
        this.occupancy = values.occupancy || 'vacant'
    }

    getJSObject(): AreaStruct {
        return JSON.parse(JSON.stringify(this))
    }
}
