import { type Features, featureAtScope, getClientFeatureScope, getFeaturesAtScope } from '@shared/feature-toggles'
import { useContext } from 'react'
import { AuthContext } from './modules/Auth/AuthContext'
import { currentIntegrationKey } from './modules/OrganizationSignUp/signup-worflow-templates'
import { getWorkflowInstance } from './utils/signup-flow-template/signup-workflow-instance'

export function getClientFeatureValues(): { [featureName: string]: boolean } {
    const workflowInstance = getWorkflowInstance(currentIntegrationKey)
    const values = typeof workflowInstance !== 'string' ? workflowInstance.serialize().values : {}
    return Object.fromEntries(
        Object.entries(values)
            .filter(([key, value]) => key.startsWith('feature'))
            .map(([key, value]) => [key.replace('feature', ''), 'true' === value])
    )
}

export function useFeatureToggle() {
    const authContext = useContext(AuthContext)
    const clientFeatureScope = getClientFeatureScope(getClientFeatureValues())
    if (!authContext.currentOrganization || !authContext.user) {
        return {
            isFeatureOn: (featureName: Features) => {
                return featureAtScope(clientFeatureScope, featureName) ?? false
            }
        }
    }
    const { isAuthorized, user, currentOrganization } = authContext

    return {
        isFeatureOn: function (featureName: Features) {
            const clientFeatureScope = getClientFeatureScope(getClientFeatureValues())
            if (!isAuthorized) {
                return featureAtScope(clientFeatureScope, featureName) ?? false
            } else {
                const orgFeature = getFeaturesAtScope(currentOrganization)
                const userFeatures = getFeaturesAtScope(user)

                return (
                    featureAtScope(clientFeatureScope, featureName) ??
                    featureAtScope(userFeatures, featureName) ??
                    featureAtScope(orgFeature, featureName) ??
                    false
                )
            }
        }
    }
}
