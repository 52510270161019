import { isEqual, isFunction } from 'lodash'
import React, { createContext, useState, useContext, useCallback } from 'react'
import { initialFilter } from './UsersUIHelpers'

export const UsersContext = createContext()

export class User {
    constructor(values = {}) {
        this.key = values.key || null
        this.name = values.name || ''
        this.email = values.email || ''
        this.areaGroups = values.areaGroups || ['All']
        this.phoneNumber = values.phoneNumber || ''
        this.isAdmin = values.isAdmin || false
        this.initials = values.initials || ''
        this.issueHashtags = values.issueHashtags || []
        this.assignIssueHashtags = values.assignIssueHashtags || false
        this.showCleaningTaskNote = values.showCleaningTaskNote || false
        this.authRole = values.authRole || 'user'
    }

    getJSObject() {
        return JSON.parse(JSON.stringify(this))
    }
}

export const UsersProvider = props => {
    const [users, setUsers] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [listLoading, setListLoading] = useState(true)
    const [queryParams, setQueryParamsBase] = useState(initialFilter)
    const [ids, setIds] = useState([])
    const [userForEdit, setUserForEdit] = useState(new User().getJSObject())
    const [actionsLoading, setActionsLoading] = useState(false)
    const [areaGroups, setAreaGroups] = useState(['All'])

    const usersUIEvents = props.usersUIEvents

    const setQueryParams = useCallback(nextQueryParams => {
        setQueryParamsBase(prevQueryParams => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }
            return nextQueryParams
        })
    }, [])

    const value = {
        users,
        setUsers,
        allUsers,
        setAllUsers,
        totalCount,
        setTotalCount,
        areaGroups,
        setAreaGroups,
        listLoading,
        setListLoading,
        ids,
        setIds,
        queryParams,
        setQueryParams,
        setQueryParamsBase,
        initUser: new User(),
        userForEdit,
        setUserForEdit,
        actionsLoading,
        setActionsLoading,
        openUserDialog: usersUIEvents.openUserDialog,
        newUserButtonClick: usersUIEvents.newUserButtonClick,
        openEditUserDialog: usersUIEvents.openEditUserDialog,
        openDeleteUserDialog: usersUIEvents.openDeleteUserDialog,
        openDeleteUsersDialog: usersUIEvents.openDeleteUsersDialog
    }

    return <UsersContext.Provider value={value} {...props} />
}

// export const useUsersContext = () => {
// 	const context = useContext(UsersContext)

// 	if (!context) {
// 		throw new Error("useUsersContext must be used inside a UsersProviced")
// 	}

// 	const { state, dispatch } = context
// 	console.log(context)
// 	const { users } = state

// 	return { users }
// }
