import * as colors from '@shared/colors'
import styled from 'styled-components'

const borderGrey = colors.bs_gray_300
const textGrey = colors.bs_gray_500
const hoverColor = colors.bs_success

export const CleaningDropdownWrapper = styled.div`
    .dropdown {
        button {
            background-color: white;
            color: ${textGrey};
            border-color: ${borderGrey};
            min-width: 177.53px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover {
                border-color: ${hoverColor};
            }
        }

        .dropdown-item {
            display: flex;
            align-items: center;
        }
    }
`

export const StatusCircle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin-right: 8px;
`

export const ResetButton = styled.div`
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
        font-size: 14px;
        color: rgb(255, 59, 48);
    }
`
