import { capitalize } from 'lodash'
import React, { useContext, useEffect } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { NavLink } from 'react-router-dom'
import { AuthContext } from '../../../Auth/AuthContext'
import { GuestsContext } from '../../GuestsContext'

import { Card, CardBody } from '../../../../../_metronic/_partials/controls'

export function GuestsCard() {
    // const user = useSelector(({ auth }) => auth.user, shallowEqual)
    const { user, organization } = useContext(AuthContext)
    const { showAlert, setShowAlert } = useContext(GuestsContext)

    useEffect(() => {
        return () => {}
    }, [organization])

    return (
        <>
            {showAlert && (
                <SweetAlert
                    success
                    title="Guest information saved"
                    // style={{ position: 'absolute', top: 50, right: 50 }}
                    onConfirm={() => {
                        setShowAlert(false)
                    }}
                    onCancel={() => {
                        setShowAlert(false)
                    }}
                    cancelBtnBsStyle="success"
                    confirmBtnBsStyle="success"
                    confirmBtnStyle={{ border: 0 }}>
                    You&apos;ve successfully updated your guest information!
                </SweetAlert>
            )}
            {/* {organization && ( */}
            <div className="col-12 col-xl-3" id="kt_profile_aside">
                <Card>
                    {/* begin::Body */}
                    <CardBody className="pt-4">
                        {/* begin::Toolbar */}
                        <div className="d-flex justify-content-end"></div>
                        {/* end::Toolbar */}
                        {/* begin::User */}
                        <div className="d-flex align-items-center mt-5">
                            {/* <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                <div className="symbol-label" style={{ backgroundImage: `url(${organization.logo})` }}></div>
                                <i className="symbol-badge bg-success"></i>
                            </div> */}
                            <div>
                                <a href="#" className="font-weight-bolder font-size-h3 text-dark-75 text-hover-primary">
                                    {organization.name}
                                </a>
                                <div className="text-muted">{capitalize(organization.status)}</div>
                            </div>
                        </div>
                        {/* end::User */}
                        {/* begin::Contact */}
                        <div className="py-3">
                            {/* <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">Subscription:</span>
                                <span className="text-muted text-hover-primary">{capitalize(organization.subscription)}</span>
                            </div> */}
                        </div>
                        {/* end::Contact */}
                        {/* begin::Nav */}
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                                <NavLink to="/settings/guests/housekeeping" className="navi-link py-4" activeClassName="active">
                                    <span className="navi-icon mr-2">
                                        <i className="specta-broom" style={{ fontSize: '1.5rem' }}></i>
                                    </span>
                                    <span className="navi-text font-size-lg">Optional Housekeeping</span>
                                </NavLink>
                            </div>
                            {/* <div className="navi-item mb-2">
                                <NavLink to="/settings/guest/subscription" className="navi-link py-4" activeClassName="active">
                                    <span className="navi-icon mr-2">
                                        <i className="fas fa-money-check" style={{ fontSize: '1.1rem' }} />
                                    </span>
                                    <span className="navi-text font-size-lg">Subscription Plans</span>
                                </NavLink>
                            </div>
                            <div className="navi-item mb-2">
                                <NavLink to="/settings/guest/billing" className="navi-link py-4" activeClassName="active">
                                    <span className="navi-icon mr-2">
                                        <i className="specta-money-bag" style={{ fontSize: '1.5rem' }} />
                                    </span>
                                    <span className="navi-text font-size-lg">Billing</span>
                                </NavLink>
                            </div>
                            <div className="navi-item mb-2">
                                <NavLink to="/settings/guest/issues" className="navi-link py-4" activeClassName="active">
                                    <span className="navi-icon mr-2">
                                        <i className="specta-issues" style={{ fontSize: '1.1rem' }}></i>
                                    </span>
                                    <span className="navi-text font-size-lg">Issues</span>
                                </NavLink>
                            </div> */}
                        </div>
                        {/* end::Nav */}
                    </CardBody>
                    {/* end::Body */}
                </Card>
            </div>
            {/* )} */}
        </>
    )
}
