import { type EmbedDashboardParams, type EmbeddedDashboard, embedDashboard } from '@superset-ui/embedded-sdk'
import React, { useRef, useEffect, useState } from 'react'
import firebase, { asFirebase } from '../../utils/firebase'
import LoadingView from '../../utils/loading-view'
import { getApi } from '../OrganizationSignUp/frontend-api'
import { type SupersetFilter, convertToNativeFilter, nativeFiltersToRison } from './superset-filters'

export type SupersetReportName = 'Housekeeping' | 'Workload'

function fetchGuestTokenFromBackend(dashboardId: string) {
    return async () => {
        const api = await getApi(asFirebase(firebase))
        const serverResp = await api['fetch-reporting-guest-token'].$post({
            json: { dashboardId }
        })

        const response = await serverResp.json()
        if ('jwt' in response) {
            return response.jwt
        } else {
            throw new Error(response.error)
        }
    }
}

async function fetchConfig(reportName: 'Housekeeping' | 'Workload') {
    // TODO need to replace with exponential backoff thing.
    console.log('Attempting to fetch config for', reportName)
    for (let attempts = 0; attempts < 2; attempts++) {
        const api = await getApi(asFirebase(firebase))
        const resp = await api['fetch-reporting-config'].$post({
            json: { reportName }
        })

        const config = await resp.json()
        if (!('error' in config)) {
            return config
        } else {
            console.error(config.error)
        }
    }

    throw new Error(`Unable to fetch config`)
}

// Superset Domain is fetched from the server, but can be
// explicitly overridden here.
type SupersetDashboardProps = {
    dashboardId?: string
    supersetDomain?: string
    reportName: SupersetReportName
    filter: SupersetFilter // TODO support multiple filters.
}

function styleDashboardIframe(mountpoint: HTMLDivElement, loadingView: HTMLElement) {
    const iframe = mountpoint.querySelector('iframe')
    if (iframe) {
        iframe.style.width = '100%'
        iframe.style.height = '100%'
        iframe.style.border = '0px'

        // Highly advanced "loading logic."
        setTimeout(() => {
            mountpoint.style.display = 'block'
            loadingView.style.display = 'none'
        }, 500)
    } else {
        console.warn('Could not find iframe of the embedded dashboard')
    }
}

const defaultDashboardConfig = {
    hideTitle: true,
    hideTab: true,
    hideChartControls: true,
    filters: {
        expanded: false,
        visible: false
    }
}

const initialStyle = {
    width: '100%',
    height: '100%',
    position: 'relative' as const,
    border: '0',
    display: 'none' as const
}

export function SupersetDashboard(props: SupersetDashboardProps) {
    const loadingViewRef = useRef<HTMLDivElement | null>(null)
    const mountpointRef = useRef<HTMLDivElement | null>(null)

    const mountpoint = <div ref={mountpointRef} style={initialStyle} />
    const loadingView = (
        <div ref={loadingViewRef}>
            <LoadingView />
        </div>
    )

    let dashboard: EmbeddedDashboard | null = null

    const reportName = props.reportName
    const filterName = props.filter.filterName
    const filter = props.filter
    const propsSupersetDomain = props.supersetDomain

    useEffect(() => {
        async function mountDashboard() {
            const config = await fetchConfig(reportName)
            const nativeFilterId = config.filterConfig.find(f => f.name === filterName)?.id
            if (!nativeFilterId) throw new Error(`Could not find native filter ID for ${filterName}`)

            const dashboardId = props.dashboardId ?? config.dashboardId
            const nativeFilter = convertToNativeFilter({ filterId: nativeFilterId, ...filter })
            const risonFilters = nativeFiltersToRison(nativeFilter)
            const supersetDomain = propsSupersetDomain ?? config.supersetConfig.supersetDomain

            const dashboardConfig = {
                ...defaultDashboardConfig,
                urlParams: {
                    native_filters: risonFilters
                }
            }

            if (mountpointRef.current && loadingViewRef.current) {
                // Required for highly advanced "loading logic."
                mountpointRef.current.style.display = 'none'
                loadingViewRef.current.style.display = 'block'

                dashboard = await embedDashboard({
                    id: dashboardId,
                    supersetDomain: supersetDomain,
                    mountPoint: mountpointRef.current,
                    fetchGuestToken: fetchGuestTokenFromBackend(dashboardId),
                    dashboardUiConfig: dashboardConfig
                })

                styleDashboardIframe(mountpointRef.current, loadingViewRef.current)
            } else {
                console.warn('Mountpoint ref not available!')
            }
        }

        mountDashboard()

        return () => {
            if (dashboard) {
                dashboard.unmount()
            }
        }
    }, [dashboard, reportName, filterName, filter, props.dashboardId, propsSupersetDomain])

    return (
        <>
            {loadingView}
            {mountpoint}
        </>
    )
}
