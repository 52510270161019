import React, { useContext } from 'react'
import { AuthContext } from '../../../modules/Auth/AuthContext'
import OrganizationDropdown from './OrganizationDropdown'
import { InfoWrap, PhotoWrap, UserWrap } from './style'

interface Props {
    asideIsOpen?: boolean
    usingInHeader?: boolean
}

const User = ({ asideIsOpen = true, usingInHeader = false }: Props) => {
    const { user } = useContext(AuthContext)
    if (!user) return null

    return (
        <UserWrap asideIsOpen={asideIsOpen} usingInHeader={usingInHeader}>
            <PhotoWrap>{user.initials}</PhotoWrap>

            {asideIsOpen && (
                <InfoWrap usingInHeader={usingInHeader}>
                    <div>
                        <span>{user.name}</span>
                        <OrganizationDropdown usingInHeader={usingInHeader} />
                    </div>
                </InfoWrap>
            )}
        </UserWrap>
    )
}

export default User
