import React, { useMemo, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { CleaningStatusClasses } from '../../../AreasUIHelpers'
import { CleaningDropdownWrapper, ResetButton, StatusCircle } from './style'

const CleaningDropdown = ({ onDropdownSelect, ...props }) => {
    const placeholder = 'Select cleaning status'

    const statuses = useMemo(() => {
        return Object.entries(CleaningStatusClasses).map(([value, { name, color }]) => ({
            value,
            label: name,
            color
        }))
    }, [])

    const [title, setTitle] = useState(placeholder)

    const onSelect = (eventKey, event) => {
        setTitle(event.target.outerText)
        onDropdownSelect(eventKey)
    }

    const renderOptions =
        statuses.length > 0 &&
        statuses.map(({ value, label, color }, index) => {
            if (value === 'reset') {
                const onResetClick = () => {
                    setTitle(placeholder)
                    onDropdownSelect(undefined)
                }

                return (
                    <ResetButton key={index + value} onClick={onResetClick}>
                        <span>Reset</span>
                    </ResetButton>
                )
            }

            return (
                <Dropdown.Item key={index + value} name={value} eventKey={value}>
                    <StatusCircle className={`bg-${color}`} />
                    <span>{label}</span>
                </Dropdown.Item>
            )
        })

    return (
        <CleaningDropdownWrapper>
            <DropdownButton {...props} variant="dropdown-variant-secondary" title={title} onSelect={onSelect}>
                {renderOptions}
            </DropdownButton>
        </CleaningDropdownWrapper>
    )
}

export default CleaningDropdown
