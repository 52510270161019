import React, { useState, useEffect, useContext } from 'react'

import { Field, Form, Formik, useFormik } from 'formik'
import { ActivityIndicator } from 'react-native-web'
import { notify } from 'react-notify-toast'
import * as Yup from 'yup'

import * as brand from '@shared/brand'
import firebase from '../../../../utils/firebase'
import { Toast } from '../../../../utils/toast'
import { AuthContext } from '../../../Auth/AuthContext'

import { getOrganizationObject } from '@shared/dataObjects'
import { httpsCallable } from '../../../../api'
import { GuestsContext } from '../../GuestsContext'

export function OptHousekeepingSetup(props) {
    const [loading, setloading] = useState(false)
    const [allowOptOut, setAllowOptOut] = useState(false)
    const { currentOrganization } = useContext(AuthContext)
    const { setShowAlert } = useContext(GuestsContext)

    const initialValues = {
        allowOptIn: currentOrganization.allowOptIn || false,
        allowOptOut: currentOrganization.allowOptOut || false
    }

    const Schema = Yup.object().shape({
        // logo: Yup.string(),
        name: Yup.string().required('Name is required'),
        email: Yup.string().email(),
        phoneNumber: Yup.string(),
        address: Yup.string()
    })

    const saveGuest = values => {
        console.log(values)
        setloading(true)
        let organizationObject = getOrganizationObject(
            currentOrganization.key,
            currentOrganization.name,
            currentOrganization.status,
            currentOrganization.issueHashtags,
            currentOrganization.assignMaintenance,
            currentOrganization.excludeMaintenanceNotification,
            currentOrganization.currencyCode || 'ISK',
            currentOrganization.timezone,
            values.allowOptIn,
            values.allowOptOut
        )
        organizationObject.brand = currentOrganization.brand || 'sweeply'
        const manageOrganization = httpsCallable('manageOrganization')
        manageOrganization(organizationObject)
            .then(() => {
                // setSubmitting(false)
                setShowAlert(true)
                setloading(false)
            })
            .catch(error => {
                // setSubmitting(false)
                setloading(false)
                console.log(error)
                notify.show(<Toast width={250} message={error.message} />, 'custom', 3000, {})
            })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={values => {
                saveGuest(values)
            }}>
            {({ handleSubmit, values, setFieldValue }) => (
                <div className="card card-custom ">
                    {/* {loading && <ModalProgressBar />} */}
                    <div
                        style={{
                            position: 'absolute',
                            right: '50%',
                            top: '40%',
                            height: 50,
                            width: 50,
                            zIndex: 10000
                        }}>
                        {loading && <ActivityIndicator size="large" color={brand.getBrand().navBarColor} style={{}} />}
                    </div>

                    {/* begin::Header */}
                    <div className="card-header with-border py-3">
                        <div className="card-title align-items-start flex-column">
                            <h3 className="card-label font-weight-bolder text-dark">Optional housekeeping</h3>
                            <span className="text-muted font-weight-bold font-size-sm mt-1">
                                Allow guests to opt-in or opt-out from housekeeping
                            </span>
                        </div>
                        <div className="card-toolbar"></div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Form */}
                    <Form className="form">
                        {/* begin::Body */}
                        <div className="card-body">
                            <div className="card card-custom card-border gutter-b">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label font-size-h2">
                                            Allow opt-in
                                            {/* <small>sub title</small> */}
                                        </h3>
                                    </div>
                                    <div className="card-toolbar">
                                        <Field
                                            component={({ onChange }) => {
                                                return (
                                                    <span className="switch switch-outline switch-success">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={values.allowOptIn}
                                                                onChange={onChange}
                                                                name="select"
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                )
                                            }}
                                            label=""
                                            isSelected={values.allowOptIn}
                                            disabled={loading}
                                            onChange={e => {
                                                setFieldValue('allowOptIn', e.target.checked)
                                                if (e.target.checked) {
                                                    setFieldValue('allowOptOut', !e.target.checked)
                                                }
                                            }}></Field>
                                    </div>
                                </div>
                                <div className="card-body">
                                    Opt-in housekeeping gives your guests more flexibility and control over when and if they want any
                                    housekeeping in their rooms. <br />
                                    <br />
                                    As a hotel this means a change in the way you operate as you will be defaulting to no housekeeping
                                    during stayover unless specified otherwise by the guest.
                                </div>
                            </div>

                            <div className="card card-custom card-border gutter-b">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label font-size-h2">
                                            Allow opt-out
                                            {/* <small>sub title</small> */}
                                        </h3>
                                    </div>
                                    <div className="card-toolbar">
                                        <Field
                                            component={({ onChange }) => {
                                                return (
                                                    <span className="switch switch-outline switch-success">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={values.allowOptOut}
                                                                onChange={onChange}
                                                                name="select"
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                )
                                            }}
                                            label=""
                                            isSelected={values.allowOptOut}
                                            disabled={loading}
                                            onChange={e => {
                                                setFieldValue('allowOptOut', e.target.checked)
                                                if (e.target.checked) {
                                                    setFieldValue('allowOptIn', !e.target.checked)
                                                }
                                            }}></Field>
                                    </div>
                                </div>
                                <div className="card-body">
                                    Opt-out housekeeping allows your guests to more easily manage if he wants housekeeping during his stay.
                                    <br />
                                    <br />
                                    As a hotel, this means less housekeeping on stayover and money and time saved on housekeeping.
                                </div>
                            </div>

                            {(values.allowOptIn || values.allowOptOut) && (
                                <div className="card card-custom gutter-b bg-light-primary">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3 className="card-label font-size-h2">
                                                Present to your guests
                                                {/* <small>sub title</small> */}
                                            </h3>
                                        </div>
                                        <div className="card-toolbar"></div>
                                    </div>
                                    <div className="card-body">
                                        To present to your guests you can include the link below in your automatic emails.
                                        <br />
                                        <br />
                                        <span className="text-decoration-underline" style={{ textDecoration: 'underline' }}>
                                            {`${brand.getBrand().urls.webappSite}/experience/[BOOKID]/housekeeping`}
                                        </span>
                                        <i
                                            className="far fa-copy text-muted ml-3 cursor-pointer"
                                            onClick={e => {
                                                navigator.clipboard.writeText(
                                                    `${brand.getBrand().urls.webappSite}/experience/[BOOKID]/housekeeping`
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* end::Body */}
                    </Form>
                    {/* end::Form */}
                    <div className="card-footer d-flex justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-primary mr-2"
                            onClick={() => {
                                saveGuest(values)
                            }}>
                            Save Changes
                            {/* {formik.isSubmitting} */}
                        </button>
                    </div>
                </div>
            )}
        </Formik>
    )
}

// export default connect(null)(PersonaInformation)
