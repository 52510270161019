import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'
import React from 'react'

let w = window,
    d = document,
    documentElement = d.documentElement,
    body = d.getElementsByTagName('body')[0]
export const width = w.innerWidth || documentElement.clientWidth || body.clientWidth
export const height = w.innerHeight || documentElement.clientHeight || body.clientHeight

export const sharedIssueStyle = { flexDirection: 'row', mobile: false }
export const openPageStyle = { marginTop: 160, marginLeft: 100, marginRight: 100, fontSize: 42, maxWidth: 655 }

export const subPageStyle = {}
export const authPageStyle = 60
subPageStyle.screenWidth = width

const DEBUG_BORDER = 0
subPageStyle.contentOuterView = {
    flexDirection: 'row',
    paddingBottom: 40,
    // marginTop: 80,
    borderWidth: DEBUG_BORDER,
    borderColor: 'blue',
    justifyContent: 'center'
}
subPageStyle.contentInnerView = {
    width: openPageStyle.maxWidth + 200,
    flexDirection: 'column',
    paddingTop: 35,
    justifyContent: 'flex-start',
    borderColor: 'red',
    borderWidth: DEBUG_BORDER
}
subPageStyle.pageTitleView = { marginBottom: 32 }
subPageStyle.chapterTitleView = { marginBottom: 24 }

let itemHeader1fontSize = 26

if (width < 920) {
    openPageStyle.marginTop = 140
    openPageStyle.marginLeft = 40
    openPageStyle.marginRight = 40
    subPageStyle.contentInnerView.marginLeft = openPageStyle.marginLeft
    subPageStyle.contentInnerView.marginRight = openPageStyle.marginLeft

    subPageStyle.contentOuterView.paddingBottom = 20
    subPageStyle.contentInnerView.width = openPageStyle.maxWidth - 100
}
if (width < 450) {
    sharedIssueStyle.flexDirection = 'column'
    sharedIssueStyle.alignItems = 'flex-start'
    sharedIssueStyle.mobile = true

    openPageStyle.marginTop = 100

    openPageStyle.marginLeft = 20
    openPageStyle.marginRight = 20
    subPageStyle.contentInnerView.marginLeft = openPageStyle.marginLeft
    subPageStyle.contentInnerView.marginRight = openPageStyle.marginLeft

    openPageStyle.fontSize = 36
    openPageStyle.maxWidth = 330

    subPageStyle.contentOuterView.paddingBottom = 10
    subPageStyle.contentInnerView.width = openPageStyle.maxWidth
    openPageStyle.fontSize = 38

    subPageStyle.contentInnerView.paddingTop = 10
    subPageStyle.pageTitleView.marginBottom = 20
    itemHeader1fontSize = 20
}

subPageStyle.pageTitleText = {
    fontSize: openPageStyle.fontSize,
    color: colors.textPrimary
}

subPageStyle.chapterTitleText = {
    fontSize: 30,
    color: colors.textPrimary
}

subPageStyle.itemView = {
    borderColor: 'blue',
    marginBottom: 32,
    borderWidth: DEBUG_BORDER
}
subPageStyle.itemHeader1 = {
    fontSize: itemHeader1fontSize,

    color: iOSColors.gray,
    marginBottom: 20
}

subPageStyle.itemHeader1b = {
    fontSize: itemHeader1fontSize,

    color: iOSColors.black,
    marginBottom: 20
}

subPageStyle.itemHeader2 = {
    fontSize: 24,

    // color: iOSColors.gray,
    marginBottom: 20,
    marginTop: 10
}

subPageStyle.bodyText = {
    fontSize: 16,
    color: colors.textPrimary,
    marginBottom: 16,
    borderColor: 'black',
    borderWidth: DEBUG_BORDER
}

subPageStyle.dateHeader = {
    fontSize: 16,
    color: iOSColors.gray,
    marginBottom: 16,
    borderColor: 'black',
    borderWidth: DEBUG_BORDER
}

const styles = {}
styles.openPageStyle = openPageStyle
styles.subPageStyle = subPageStyle
styles.sharedIssueStyle = sharedIssueStyle

export const toastSuccessStyle = { autoClose: 3000, hideProgressBar: true, className: 'bg-success' }
export const toastErrorStyle = { autoClose: 3000, hideProgressBar: true, className: 'bg-danger' }

export const renderRectangles = count => {
    if (count === 0) return null
    return (
        <>
            <div className={`rectangle-${count}`} />
            {renderRectangles(count - 1)}
        </>
    )
}
