import type { FirebaseFirestore } from '../firebase'
import type { SubscriptionPlan } from '../firestore-structs'
import type { FeatureId } from '../subscriptions.types'
import { Subscription } from './subscription'

let plans: Subscription[] | null = null
export async function getAllPlans(db: FirebaseFirestore) {
    if (!plans) {
        const plansRef = await db.collection('plans').get()
        plans = plansRef.docs.map(plan => {
            const planEntity = plan.data() as SubscriptionPlan
            return new Subscription({
                name: planEntity.name,
                id: plan.id.toLowerCase(),
                key: planEntity.key,
                features: planEntity.features ?? [],
                pricing: planEntity.pricing,
                upgrades: planEntity.upgrades || []
            })
        })
    }
    return plans
}

function getPlanById(id: string) {
    const find = plans?.find(plan => plan.id === id)
    if (!find) {
        throw new Error(`Plan with id ${id} not found`)
    }
    return find
}

export const findAvailableUpgrades = (featureId: FeatureId, subscription: Subscription) => {
    const subscriptionPlan = getPlanById(subscription.id)

    return subscriptionPlan.upgrades.map(up => getPlanById(up)).filter(up => up.isPartOf(featureId))
}
