import { getBrand } from '@shared/brand'
import * as colors from '@shared/colors'
import styled from 'styled-components'

const black = colors.black_sweeply
const brandColor = getBrand().navBarColor

export const UserWrap = styled.div<{ asideIsOpen: boolean; usingInHeader: boolean }>`
    width: 100%;
    padding: ${({ usingInHeader, asideIsOpen }) => {
        if (usingInHeader) {
            return '0 24px 0 0'
        }
        return asideIsOpen ? '0 24px' : '0 12px'
    }};
    margin: ${({ usingInHeader }) => (usingInHeader ? '0' : '20px 0 21px 0')};
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    flex-direction: ${({ usingInHeader }) => (usingInHeader ? 'row-reverse' : 'row')};
`

export const InfoWrap = styled.div<{ usingInHeader: boolean }>`
    width: 80%;
    ${({ usingInHeader }) => {
        if (usingInHeader) {
            return `
                display: flex;
                align-items: center;
                justify-content: flex-end;
            `
        }
        return
    }};

    > div {
        display: flex;
        flex-direction: column;
        ${({ usingInHeader }) => {
            if (usingInHeader) {
                return `
                    align-items: flex-end;
                `
            }
            return
        }}
        > span {
            font-weight: 700;
            font-size: 1rem;
            line-height: 16px;
            color: ${black};
            ${({ usingInHeader }) => {
                if (usingInHeader)
                    return `
                    display: inline-block;
                    width: 140px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: end;
                    margin-right: 4px;
                `
                return
            }}
        }
    }
`

export const PhotoWrap = styled.div<{
    size?: string
    backgroundColor?: string
    fontSize?: string
    cursor?: 'default' | 'pointer'
}>`
    width: ${({ size }) => (size ? size : '34px')} !important;
    height: ${({ size }) => (size ? size : '34px')} !important;
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : brandColor)};
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
    cursor: ${({ cursor }) => (cursor ? cursor : 'default')};
`
