import type { FeatureId } from '@shared/subscriptions.types'
import { ALL_FEATURES } from '@shared/subscriptions/feature-items'
import { getAllPlans } from '@shared/subscriptions/get-all-plans'
import type { Subscription } from '@shared/subscriptions/subscription'
import type React from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { asFirestore, db } from '../../utils/firebase'
import { AuthContext } from '../Auth/AuthContext'

const useSettings = () => {
    const { orgSubscription } = useContext(AuthContext)
    const [subscriptionPlans, setSubscriptionPlans] = useState<Subscription[]>([])
    const [currentSubscription, setCurrentSubscription] = useState<Subscription | null>(null)

    useEffect(() => {
        getPlans()
    }, [orgSubscription?.name])

    async function getPlans() {
        const dataPlans = await getAllPlans(asFirestore(db))

        setCurrentSubscription(orgSubscription)
        setSubscriptionPlans(dataPlans)
    }

    return { subscriptionPlans, currentSubscription }
}

type Context = {
    hasAccess: boolean
    notAccessibleFeature: FeatureId | null
    availableUpgrades: Subscription[] | null
    showUpgradeModal: boolean
    setShowUpgradeModal: (a: boolean) => void
    subscriptionsLoaded: boolean
    checkSubscription: (
        featureId: FeatureId,
        onAvailable: () => void,
        onNotAvailable: (notAccessibleFeature: FeatureId | null, availableUpgrades: Subscription[]) => void
    ) => void
    currentSubscription: Subscription | null
    isFeatureInSubscription: (featureId: FeatureId) => boolean
}
export const SubscriptionContext = createContext<Context>({
    hasAccess: false,
    notAccessibleFeature: null,
    availableUpgrades: null,
    showUpgradeModal: false,
    setShowUpgradeModal: () => {},
    subscriptionsLoaded: false,
    checkSubscription: () => {},
    currentSubscription: null,
    isFeatureInSubscription: () => false
})

export const SubscriptionProvider: React.FC<React.PropsWithChildren> = props => {
    const [hasAccess, setHasAccess] = useState(false)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)
    const [notAccessibleFeature, setNotAccessibleFeature] = useState<FeatureId | null>(null)
    const [availableUpgrades, setAvailableUpgrades] = useState<Subscription[] | null>(null)
    const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false)

    const { currentSubscription } = useSettings()
    const checkSubscription: Context['checkSubscription'] = (featureId, onAvailable, onNotAvailable) => {
        if (currentSubscription) {
            const checkStatus = currentSubscription.check(featureId)
            switch (checkStatus.status) {
                case 'available': {
                    onAvailable()
                    return
                }
                case 'not-available': {
                    onNotAvailable(checkStatus.notAccessibleFeature, checkStatus.availableUpgrades)
                    return
                }
                case 'upgrade-not-available': {
                    return
                }
            }
        }
    }

    const isFeatureInSubscription = (featureId: FeatureId) => {
        return currentSubscription ? currentSubscription.features.includes(featureId) : false
    }

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const path: string = props.location.pathname
        const featureByPath = Object.entries(ALL_FEATURES).find(([featureId, f]) => f.path === path)
        if (featureByPath) {
            const [featureId, feature] = featureByPath
            checkSubscription(
                featureId as FeatureId,
                () => {
                    setHasAccess(true)
                    setNotAccessibleFeature(null)
                    setAvailableUpgrades(null)
                },
                (notAccessibleFeature, availableUpgrades) => {
                    setHasAccess(false)
                    setNotAccessibleFeature(notAccessibleFeature)
                    setAvailableUpgrades(availableUpgrades)
                }
            )
        } else {
            setHasAccess(true)
            setNotAccessibleFeature(null)
            setAvailableUpgrades(null)
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
    }, [props.location.pathname])

    useEffect(() => {
        if (currentSubscription) {
            setSubscriptionsLoaded(true)
        }
        return () => {}
    }, [currentSubscription?.id])

    return (
        <SubscriptionContext.Provider
            value={useMemo(
                () => ({
                    hasAccess,
                    notAccessibleFeature,
                    availableUpgrades,
                    showUpgradeModal,
                    setShowUpgradeModal,
                    subscriptionsLoaded,
                    checkSubscription,
                    currentSubscription,
                    isFeatureInSubscription
                }),
                [
                    hasAccess,
                    notAccessibleFeature,
                    availableUpgrades,
                    showUpgradeModal,
                    setShowUpgradeModal,
                    checkSubscription,
                    currentSubscription,
                    isFeatureInSubscription
                ]
            )}
            {...props}
        />
    )
}
