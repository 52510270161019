import { capitalize } from 'lodash'
import React, { useEffect, useContext } from 'react'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import { NavLink } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers'
import { GuestsContext } from '../GuestsContext'

import { getPlural } from '@shared/helpers'
import moment from 'moment-timezone'
import { Card, CardBody, CardHeader, CardHeaderCounter, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls'

function BookingInfo() {
    const { showAlert, setShowAlert, guestData } = useContext(GuestsContext)

    const { booking } = guestData

    const guestCount = parseInt(booking.adults) + parseInt(booking.children)

    return (
        <div className="py-0">
            <div className="d-flex justify-content-between align-items-center font-weight-bolder">
                <h3 className="font-weight-bolder">{booking.guestName}</h3>
                <div className="text-muted" style={{ minWidth: 60 }}>
                    {guestCount} {getPlural('guest', guestCount)}{' '}
                </div>
            </div>
            <div className="text-muted font-weight-bolder">Booking Id: {booking.bookingId}</div>
            <div className="py-5">
                <div className="row justify-content-center font-size-sm">
                    <div style={{ position: 'relative', bottom: -6 }}>{booking.bookingDates.length - 1} nights</div>
                </div>
                <div className="row font-size-sm font-weight-bolder">
                    <div className="col-6 text-center partial-border-right">
                        <div>Check in</div>
                        <h1 className="display3 my-0">{moment(booking.checkinDate).format('DD')}</h1>
                        <div>{moment(booking.checkinDate).format('MMM YYYY')}</div>
                    </div>
                    <div className="col-6 text-center">
                        <div>Check out</div>
                        <h1 className="display3 my-0">{moment(booking.checkoutDate).format('DD')}</h1>
                        <div>{moment(booking.checkoutDate).format('MMM YYYY')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function GuestsCard() {
    // const user = useSelector(({ auth }) => auth.user, shallowEqual)
    const { showAlert, setShowAlert, guestData } = useContext(GuestsContext)

    const { booking, area } = guestData

    return (
        <>
            {showAlert && (
                <SweetAlert
                    success
                    title="Guest information saved"
                    // style={{ position: 'absolute', top: 50, right: 50 }}
                    onConfirm={() => {
                        setShowAlert(false)
                    }}
                    onCancel={() => {
                        setShowAlert(false)
                    }}
                    cancelBtnBsStyle="success"
                    confirmBtnBsStyle="success"
                    confirmBtnStyle={{ border: 0 }}>
                    You&apos;ve successfully updated your guest information!
                </SweetAlert>
            )}
            {/* {organization && ( */}
            <div className="col-12 col-lg-4" id="kt_profile_aside">
                <Card>
                    {/* begin::Body */}
                    <CardBody className="pt-4">
                        {/* begin::Toolbar */}
                        <div className="d-flex justify-content-end"></div>

                        <BookingInfo />

                        {/* <div className="d-none d-lg-block">
                            <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                                <div className="navi-item mb-2">
                                    <NavLink
                                        to={`/experience/${guestData.booking.bookingId}/housekeeping`}
                                        className="navi-link py-4"
                                        activeClassName="active">
                                        <span className="navi-icon mr-2">
                                            <i className="specta-organization" style={{ fontSize: '1.5rem' }}></i>
                                        </span>
                                        <span className="navi-text font-size-lg">Cleaning Schedule</span>
                                    </NavLink>
                                </div>
                                <div className="navi-item mb-2">
                                    <NavLink to="/settings/guest/subscription" className="navi-link py-4" activeClassName="active">
                                        <span className="navi-icon mr-2">
                                            <i className="fas fa-money-check" style={{ fontSize: '1.1rem' }} />
                                        </span>
                                        <span className="navi-text font-size-lg">Chat</span>
                                    </NavLink>
                                </div>
                                <div className="navi-item mb-2">
                                    <NavLink to="/settings/guest/billing" className="navi-link py-4" activeClassName="active">
                                        <span className="navi-icon mr-2">
                                            <i className="specta-money-bag" style={{ fontSize: '1.5rem' }} />
                                        </span>
                                        <span className="navi-text font-size-lg">Requests</span>
                                    </NavLink>
                                </div>
                                <div className="navi-item mb-2">
                                    <NavLink to="/settings/guest/issues" className="navi-link py-4" activeClassName="active">
                                        <span className="navi-icon mr-2">
                                            <i className="specta-issues" style={{ fontSize: '1.1rem' }}></i>
                                        </span>
                                        <span className="navi-text font-size-lg">Food</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div> */}
                        {/* end::Nav */}
                    </CardBody>
                    {/* end::Body */}
                </Card>
            </div>
            {/* )} */}
        </>
    )
}
