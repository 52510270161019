/* eslint-disable func-names */
import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import './components/tippy.css'
import { AuthProvider } from './modules/Auth/AuthContext'

import { useAsyncStorage } from '@react-native-community/async-storage'
import TypesafeI18n from '@shared-snap/i18n/i18n-react'
import { loadLocaleAsync } from '@shared-snap/i18n/i18n-util.async'
import { getBrand } from '@shared/brand'
import moment from 'moment'
import { IntercomProvider } from 'react-use-intercom'
import { RecoilRoot } from 'recoil'
import { MaterialThemeProvider, MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from '../_metronic/layout'
import ContentBlocker from './components/ContentBlocker'
import { Routes } from './routes'
import { useIsMobile } from './utils/hooks/useIsMobile'
import 'moment/dist/locale/uk'
import 'moment/dist/locale/es'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/is'
import 'moment/dist/locale/it'
import 'moment/dist/locale/pl'
import 'moment/dist/locale/uk'
import 'moment/dist/locale/de'
import { detectLocale } from '@shared-snap/i18n/i18n-util'
import { localStorageDetector } from 'typesafe-i18n/detectors'

const detectedLocale = detectLocale(localStorageDetector)

export function App() {
    const isMobile = useIsMobile()
    const { getItem, setItem } = useAsyncStorage('forceDesktop')
    const [forceDesktop, setForceDesktop] = useState(false)
    const [wasLoaded, setWasLoaded] = useState(false)

    useEffect(() => {
        getItem().then(value => {
            setForceDesktop(value === 'true')
        })
        loadLocaleAsync(detectedLocale)
            .then(() => {
                moment.locale(detectedLocale)
                setWasLoaded(true)
            })
            .catch(error => {
                console.error('Failed to load locale:', error)
            })
    }, [])

    if (!wasLoaded) return null
    location.search.match(/forceDesktop=true/) && setItem('true')
    const isGuestsPage = location.pathname.includes('/guest')
    if (isMobile && !forceDesktop && !isGuestsPage) return <ContentBlocker />
    return (
        <>
            <TypesafeI18n locale={detectedLocale}>
                <RecoilRoot>
                    <AuthProvider>
                        <MetronicLayoutProvider>
                            <MetronicSubheaderProvider>
                                <MetronicSplashScreenProvider>
                                    <IntercomProvider appId={getBrand().intercomAppId}>
                                        <BrowserRouter>
                                            <MaterialThemeProvider>
                                                {/* <ErrorBoundary> */}
                                                <Routes />
                                                {/* </ErrorBoundary> */}
                                            </MaterialThemeProvider>
                                        </BrowserRouter>
                                    </IntercomProvider>
                                </MetronicSplashScreenProvider>
                            </MetronicSubheaderProvider>
                        </MetronicLayoutProvider>
                    </AuthProvider>
                </RecoilRoot>
            </TypesafeI18n>
        </>
    )
}

export default App
