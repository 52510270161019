import moment from 'moment-timezone'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import Alert from '../../../../components/alerts/Alert'
import { asFirestore, db, updateOrgAreasStatistics } from '../../../../utils/firebase'
import { toastErrorStyle, toastSuccessStyle } from '../../../../utils/styles'
import { AuthContext } from '../../../Auth/AuthContext'
import { AreasContext } from '../../AreasContext'

export function AreasDeleteDialog({ show, onHide }) {
    const { ids, setIds } = useContext(AreasContext)
    const { user, currentOrganization } = useContext(AuthContext)
    const text = `Are you sure to permanently delete ${ids.length} selected unit${ids.length === 1 ? '' : 's'}?`

    async function onConfirm() {
        try {
            const batch = db.batch()
            ids.map(id => {
                return batch.update(db.collection('areas').doc(id), {
                    visible: false,
                    updated: moment().valueOf(),
                    lastChangedByUser: user.key
                })
            })

            await batch.commit()
            setIds([])
            await updateOrgAreasStatistics(asFirestore(db), currentOrganization.key)
            onHide()
            toast.success('Units deleted successfully', toastSuccessStyle)
        } catch (error) {
            console.error(error)
            toast.error(`Error deleting units (${error.message})`, toastErrorStyle)
        }
    }

    return <>{show && <Alert title={'Delete units'} text={text} onCancel={onHide} onConfirm={onConfirm} />}</>
}
