import { CLEANING_STATUS_CLEAN } from '@shared/txt-constants'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Spinner from '../../../../components/Spinner'
import { db } from '../../../../utils/firebase'
import { toastSuccessStyle } from '../../../../utils/styles'
import { AuthContext } from '../../../Auth/AuthContext'
import { AreasContext } from '../../AreasContext'
import { AreaEditDialogHeader } from './AreaEditDialogHeader'
import { AreaEditForm } from './AreaEditForm'

export function AreaEditDialog({ id, show, onHide }) {
    const { areas, allAreas, setIds, areaForEdit, setAreaForEdit, actionsLoading, setActionsLoading, initArea } = useContext(AreasContext)
    const { user } = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const [groups, setGroups] = useState([])

    const areasUIProps = useMemo(() => {
        return {
            initArea: initArea
        }
    }, [initArea])

    useEffect(() => {
        const allGroups = allAreas.map(area => area.group)
        const groupsOptions = [...new Set(allGroups)].map(group => ({
            value: group,
            label: group
        }))

        setGroups(groupsOptions)
    }, [])

    useEffect(() => {
        setIsLoading(true)
        const getArea = async () => {
            if (id) {
                const areaRef = await db.collection('areas').doc(id).get()
                const area = areaRef.data()
                setAreaForEdit(area)
            }
            setIsLoading(false)
        }
        getArea(id)
    }, [id, setIsLoading, setAreaForEdit])

    const updateArea = async (db, area) => {
        await db.collection('areas').doc(area.key).set(area)
        return true
    }

    const createArea = async (db, user, areas, area) => {
        const areaKey = db.collection('areas').doc().id
        const defaultRulesQuery = db
            .collection('rules')
            .where('organizationKey', '==', user.organizationKey)
            .where('isDefaultRule', '==', true)
        const defaultRulesSnapshot = await defaultRulesQuery.get()

        const newArea = {
            ...area,
            key: areaKey,
            organizationKey: user.organizationKey,
            visible: true,
            cleaningStatus: CLEANING_STATUS_CLEAN,
            id: areaKey
        }

        await db.collection('areas').doc(areaKey).set(newArea)

        defaultRulesSnapshot.forEach(doc => {
            const defaultRule = doc.data()
            const ruleAreas = defaultRule.areas || []
            ruleAreas.push(areaKey)
            db.collection('rules').doc(doc.id).update({ areas: ruleAreas })
        })

        areas.push(newArea)

        return false
    }

    const saveArea = async area => {
        setActionsLoading(true)

        const isAreaUpdated = area.key ? await updateArea(db, area) : await createArea(db, user, areas, area)

        setActionsLoading(false)
        setIds([])
        setAreaForEdit(initArea)
        onHide()

        const message = isAreaUpdated ? `${area.name} updated successfully` : `${area.name} created successfully`
        toast.success(message, toastSuccessStyle)
    }

    return (
        <Modal show={show} size="lg" onHide={onHide} centered={true} aria-labelledby="example-modal-sizes-title-lg">
            {isLoading && (
                <div className="w-100 h-100">
                    <Spinner style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 1500 }} />
                </div>
            )}
            <AreaEditDialogHeader id={id} onHide={onHide} />
            <AreaEditForm
                saveArea={saveArea}
                actionsLoading={actionsLoading}
                dataLoading={isLoading}
                area={areaForEdit || areasUIProps.initArea}
                groups={groups}
                setGroups={setGroups}
                onHide={onHide}
            />
        </Modal>
    )
}
