import { getNavBarAuthLogo, getNavBarNOAuthLogo } from '@shared/brand'
import type React from 'react'
import { useEffect } from 'react'
import { useIsMobile } from '../../utils/hooks/useIsMobile'
import { ContentWrap, ModalContainer, ModalOverlay } from '../styled-components/modal'

interface Props {
    children: React.ReactNode
}

const ModalLayout = ({ children }: Props) => {
    const isMobile = useIsMobile()
    const { img } = isMobile ? getNavBarNOAuthLogo() : getNavBarAuthLogo()

    useEffect(() => {
        toggleLockBodyScroll(true)

        return () => {
            toggleLockBodyScroll(false)
        }
    }, [])

    return (
        <ModalOverlay>
            <ModalContainer>
                <img src={img} />

                <ContentWrap>{children}</ContentWrap>
            </ModalContainer>
        </ModalOverlay>
    )
}

export function toggleLockBodyScroll(showModal: boolean) {
    showModal ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto')
}

export default ModalLayout
