// import Moment from 'moment-timezone'

// import Moment from 'moment-timezone'
// import { extendMoment } from 'moment-range'
//
// const moment = extendMoment(Moment)

// const { sortTimeStampAscending } = require('../helpers')

import moment from 'moment-timezone'
import type { Moment } from 'moment-timezone'
import { memoizeWith } from 'ramda'
import { isFeatureOn } from './feature-toggles'
import type { AreaCleaningStatus, AreaStruct, BookingStruct, Occupancy, OrgStruct, RuleStruct } from './firestore-structs'
import { sortTimeStampAscending } from './helpers'
import { measurePerformanceSync } from './performance-utils'
import {
    BOOKING_STATUS_BLOCKED,
    CLEANING_STATUS_CLEAN,
    CLEANING_STATUS_DIRTY,
    CLEANING_STATUS_INSPECTION,
    CLEANING_STATUS_OUT_OF_SERVICE
} from './txt-constants'

export const RULE_TYPE_DAILY = 'daily'
export const RULE_TYPE_WEEKLY = 'weekly'
export const RULE_TYPE_MONHTLY = 'monthly'
export const RULE_TYPE_CHECKIN = 'checkin'
export const RULE_TYPE_CHECKOUT = 'checkout'
export const RULE_TYPE_CHECKUP = 'checkup'
export const RULE_TYPE_CUSTOM = 'custom'
export const RULE_TYPE_OPTIN = 'optin'
export const RULE_TYPE_RATES = 'rates'

export const RULE_TRIGGER_BOOKING = 'booking'
export const RULE_TRIGGER_DATE = 'date'

const haveCommonItems = (arr1: string[], arr2: string[]) => {
    const set1 = new Set(arr1)
    const commonItems = arr2.filter(item => set1.has(item))
    return commonItems.length > 0
}

export interface RuleResolveResult {
    optInDates?: number[]
    cleaningStatus?: AreaCleaningStatus
    inspection?: boolean
    occupancy?: Occupancy
}

export type RuleResolverOptions = {
    cleanUntilCheckin: boolean
}
const getDateRangeMomoized = memoizeWith(
    (start, end, timezone) => `${start.valueOf()}-${end.valueOf()}-${timezone}`,
    (start: Moment, end: Moment, timezone: string) => {
        const diff = moment.tz(end, timezone).diff(moment.tz(start, timezone), 'days')
        const dates = []
        for (let i = 0; i <= diff; i++) {
            const date = moment.tz(start, timezone).add(i, 'days').startOf('day')
            dates.push(date)
        }
        return dates.map(d => d.valueOf())
    }
)

export const getDateRange = (start: Moment, end: Moment, org: Pick<OrgStruct, 'timezone'>) => {
    return getDateRangeMomoized(start, end, org.timezone)
}

const createTaskTemplates = (rule: RuleStruct, dates: number[]) => {
    return dates.map(d => ({
        dueDate: d,
        //name: rule.taskName,
        //color: rule.taskColor,
        //estimatedTime: rule.estimatedTime,
        checklist: rule.checklistTasks
    }))
}

export const resolveRepeatDates = (
    rule: Pick<RuleStruct, 'repeatInterval' | 'repeatOffsetStart' | 'repeatOffsetEnd' | 'repeatType' | 'repeatDays'>,
    startDate: number,
    endDate: number,
    org: Pick<OrgStruct, 'timezone'>
) => {
    if ([RULE_TYPE_CHECKOUT].includes(rule.repeatType)) {
        return [endDate]
    }

    const start = moment.tz(startDate, org.timezone).add(rule.repeatOffsetStart, 'days').startOf('day')
    const end = moment.tz(endDate, org.timezone).add(-rule.repeatOffsetEnd, 'days').startOf('day')

    const range = getDateRange(start, end, org)
    const onlyAtInterval = range.filter((e, i) => i % rule.repeatInterval === 0)

    // Handle cases where repeatDays is undefined or empty
    if (!rule.repeatDays || rule.repeatDays.length === 0) {
        return onlyAtInterval
    }

    const repeatDaysSet = new Set(rule.repeatDays.map(day => day.toLowerCase()))

    // Adjust dates based on repeatDays
    const adjustedDates = onlyAtInterval.map(date => {
        const currentDate = moment.tz(date, org.timezone)

        // Check if the current date matches a valid repeat day
        let currentDay = currentDate.format('dddd').toLowerCase()
        if (repeatDaysSet.has(currentDay)) {
            return currentDate.valueOf() // Return the timestamp if valid
        }

        // Adjust the date to match the repeatDays condition
        if (currentDay === 'saturday') {
            // Move back to the previous Friday
            return currentDate.subtract(1, 'days').valueOf()
        }

        // Otherwise, move forward to the next valid weekday
        while (!repeatDaysSet.has(currentDay)) {
            currentDate.add(1, 'days')
            currentDay = currentDate.format('dddd').toLowerCase()
        }
        return currentDate.valueOf()
    })

    return adjustedDates
}

const isStayWithinRule = (lengthOfStay: number, rule: RuleStruct): boolean => {
    const isUndefinedOrEmpty = (prop: any) => {
        return prop === undefined || prop === ''
    }
    const isMinStayValid = !isUndefinedOrEmpty(rule.minStay) ? lengthOfStay >= rule.minStay! : true
    const isMaxStayValid = !isUndefinedOrEmpty(rule.maxStay) ? lengthOfStay <= rule.maxStay! : true

    return isMinStayValid && isMaxStayValid
}

const resolveCustomRule = (
    rule: RuleStruct,
    date: number,
    bookings: Pick<
        BookingStruct,
        | 'areaKey'
        | 'checkinDate'
        | 'checkoutDate'
        | 'bookingDates'
        | 'optInDates'
        | 'optOutDates'
        | 'status'
        | 'guestCheckedIn'
        | 'guestCheckedOut'
        | 'extras'
        | '_external'
    >[],
    org: Pick<OrgStruct, 'timezone'>,
    options: RuleResolverOptions
) => {
    const relevantBookings = bookings.filter(b => isStayWithinRule(b.bookingDates.length, rule))
    const dates = relevantBookings
        .filter(b => !options.cleanUntilCheckin || (b.guestCheckedIn && !b.guestCheckedOut))
        .flatMap(b => resolveRepeatDates(rule, b.checkinDate, b.checkoutDate, org))
    const result: RuleResolveResult = {}
    if (dates.includes(date)) {
        // If regular daily cleaning the number of days to clean is 2 less days than the number of days in the booking
        result.cleaningStatus = 'dirty'
        if (rule.repeatInterval === 1) {
            result.occupancy = 'stayover'
        } else {
            result.occupancy = 'stayover-80'
        }
    }

    return result
}

export const resolveBookingRule = (
    rule: RuleStruct,
    bookings: Pick<
        BookingStruct,
        | 'areaKey'
        | 'checkinDate'
        | 'checkoutDate'
        | 'bookingDates'
        | 'optInDates'
        | 'optOutDates'
        | 'status'
        | 'guestCheckedIn'
        | 'guestCheckedOut'
        | 'extras'
        | '_external'
        | 'isDayUse'
        | 'checkinTime'
        | 'checkoutTime'
    >[],
    date: number,
    org: Pick<OrgStruct, 'timezone' | 'featuresDisabled' | 'featuresEnabled'>,
    area: Pick<AreaStruct, 'daysSinceLastCleaning' | 'daysSinceLastCheckout' | 'cleaningStatus'>,
    options: RuleResolverOptions
) => {
    let dates: number[] = []
    let result: RuleResolveResult = {}
    // TODO - MAKE BETTER LOGIC LATER
    if (rule.inspection) {
        result.inspection = rule.inspection
        return result
    }
    switch (rule.repeatType) {
        case RULE_TYPE_CUSTOM:
            result = resolveCustomRule(rule, date, bookings, org, options)
            break
        case RULE_TYPE_CHECKIN: {
            dates = bookings.map(b => b.checkinDate)
            const checkinDate = bookings.filter(b => b.checkinDate === date)
            if (dates.includes(date)) {
                if (checkinDate && checkinDate.length > 0) {
                    result.cleaningStatus = 'dirty'
                }
            }
            break
        }
        case RULE_TYPE_CHECKOUT: {
            dates = bookings.map(b => b.checkoutDate)
            const checkoutBookings = bookings.filter(b => b.checkoutDate === date)
            const checkinBookings = bookings.filter(b => b.checkinDate === date)
            const hasDayUse = bookings.some(b => b.isDayUse)

            if (hasDayUse) {
                const sortedBookings = bookings.sort((a, b) =>
                    sortTimeStampAscending(a.checkinTime || a.checkinDate, b.checkinTime || b.checkinDate)
                )
                // Case 1: 3 bookings
                if (bookings.length === 3) {
                    // Case 1.1 : No booking is checked out
                    if (!sortedBookings[0].guestCheckedOut && !sortedBookings[1].guestCheckedOut && !sortedBookings[2].guestCheckedOut) {
                        result.cleaningStatus = 'waiting-for-checkout'
                    }
                    // Case 1.2 : First booking is checked out
                    if (sortedBookings[0].guestCheckedOut && !sortedBookings[1].guestCheckedIn && !sortedBookings[2].guestCheckedIn) {
                        result.cleaningStatus = 'dirty'
                    }

                    // Case 1.3 : First booking is checked out and second is checked in
                    if (sortedBookings[0].guestCheckedOut && sortedBookings[1].guestCheckedIn && !sortedBookings[2].guestCheckedIn) {
                        result.cleaningStatus = 'waiting-for-checkout'
                    }

                    // Case 1.4 : First booking is checked out and second is checked in and checked out
                    if (sortedBookings[0].guestCheckedOut && sortedBookings[1].guestCheckedOut && !sortedBookings[2].guestCheckedIn) {
                        result.cleaningStatus = 'dirty'
                    }

                    // Case 1.5 : First booking is checked out and second is checked in and checked out and third is checked in
                    // Maybe don't do anything due to activity
                    if (sortedBookings[0].guestCheckedOut && sortedBookings[1].guestCheckedOut && sortedBookings[2].guestCheckedIn) {
                        result.cleaningStatus = 'no-cleaning-service'
                    }
                }
                if (bookings.length === 2) {
                    // Case 2: 2 bookings

                    // Case 2.1 : Normal booking following a day use booking
                    // Case 2.1.1 : No booking is checked out
                    if (!sortedBookings[0].guestCheckedOut && !sortedBookings[1].guestCheckedOut) {
                        result.cleaningStatus = 'waiting-for-checkout'
                    }
                    // Case 2.1.2 : First booking is checked out
                    if (sortedBookings[0].guestCheckedOut && !sortedBookings[1].guestCheckedIn) {
                        result.cleaningStatus = 'dirty'
                    }
                    // Case 2.1.3 : First booking is checked out and second is checked in
                    if (sortedBookings[0].guestCheckedOut && sortedBookings[1].guestCheckedIn) {
                        result.cleaningStatus = 'waiting-for-checkout'
                    }
                    // Case 2.1.4 : First booking is checked out and second is checked in and checked out
                    if (sortedBookings[0].guestCheckedOut && sortedBookings[1].guestCheckedOut) {
                        result.cleaningStatus = 'dirty'
                    }

                    // Case 2.2 : Day use booking following a normal booking
                    // Case 2.2.1 : No booking is checked in
                    if (!sortedBookings[0].guestCheckedIn && !sortedBookings[1].guestCheckedIn) {
                        return result
                    }
                    // Case 2.2.2 : First booking is checked in
                    if (sortedBookings[0].guestCheckedIn && !sortedBookings[1].guestCheckedIn) {
                        result.cleaningStatus = 'waiting-for-checkout'
                    }
                    // Case 2.2.3 : First booking is checked in and checked out
                    if (sortedBookings[0].guestCheckedOut && !sortedBookings[1].guestCheckedIn) {
                        result.cleaningStatus = 'dirty'
                    }
                    // Case 2.2.4 : First booking is checked in and checked out and second is checked in
                    if (sortedBookings[0].guestCheckedOut && sortedBookings[1].guestCheckedIn && !sortedBookings[1].guestCheckedOut) {
                        result.cleaningStatus = 'no-cleaning-service'
                    }
                }
                if (bookings.length === 1) {
                    // Case 3: 1 booking
                    // Case 3.1 : No booking is checked out
                    if (!sortedBookings[0].guestCheckedOut) {
                        result.cleaningStatus = 'waiting-for-checkout'
                    }
                    // Case 3.2 : First booking is checked out
                    if (sortedBookings[0].guestCheckedOut) {
                        result.cleaningStatus = 'dirty'
                    }
                }
            } else {
                const newOOOBehaviour = isFeatureOn(org, 'new-ooo-behaviour')
                if (dates.includes(date)) {
                    if (checkoutBookings && checkoutBookings.length > 0) {
                        const bookingNights = checkoutBookings[0].bookingDates.length - 1
                        if (!isStayWithinRule(bookingNights, rule)) {
                            return result
                        }
                        if (options.cleanUntilCheckin && !checkoutBookings[0].guestCheckedIn && !checkoutBookings[0].guestCheckedOut) {
                            result.cleaningStatus = 'clean'
                        } else if (
                            newOOOBehaviour &&
                            checkinBookings.length > 0 &&
                            checkinBookings[0].status &&
                            checkinBookings[0].status === BOOKING_STATUS_BLOCKED
                        ) {
                            result.cleaningStatus = CLEANING_STATUS_OUT_OF_SERVICE
                        } else if (checkoutBookings[0].guestCheckedOut) {
                            result.cleaningStatus = 'dirty'
                        } else {
                            result.cleaningStatus = 'waiting-for-checkout'
                        }
                    }
                }
            }
            break
        }
        case RULE_TYPE_CHECKUP: {
            const checkinBookings = bookings.filter(b => b.checkinDate === date)

            if ((bookings.length === 0 || checkinBookings.length > 0) && area.daysSinceLastCheckout! >= rule.repeatInterval) {
                if (area.daysSinceLastCleaning! >= rule.repeatInterval && area.cleaningStatus !== CLEANING_STATUS_DIRTY) {
                    result.cleaningStatus = CLEANING_STATUS_INSPECTION
                }
            }
            break
        }
        case RULE_TYPE_OPTIN: {
            if (rule.productIds && rule.productIds.length > 0) {
                const bookingsWithMatchedExtras = bookings.filter(b =>
                    haveCommonItems(b.extras?.map(x => x.productId) ?? [], rule.productIds ?? [])
                )
                const matchedExtras = bookingsWithMatchedExtras.flatMap(b => b.extras!)
                const bookingsWithMatchedExtrasDates = bookingsWithMatchedExtras.filter(b => b.extras?.map(x => x.date).includes(date))
                if (bookingsWithMatchedExtrasDates.length > 0) {
                    result.occupancy = 'stayover'
                    result.cleaningStatus = 'dirty'
                    result.optInDates = matchedExtras.map(x => x?.date)
                }
            } else {
                const relevantBookings = bookings.filter(b => isStayWithinRule(b.bookingDates.length, rule))
                const dates = relevantBookings.flatMap(x => x.optInDates)
                if (dates.includes(date.toString())) {
                    result = resolveCustomRule(rule, date, bookings, org, options)
                }
            }
            break
        }
        case RULE_TYPE_RATES: {
            const bookingsWithMatchedRates = bookings.filter(b => {
                if (!b._external?.rateCategory && !b._external?.rateCode) {
                    return false
                }
                const rateId = b._external.rateCategory?.id || b._external.rateCode?.id
                return rateId ? rule.rateCodes?.includes(rateId) : false
            })

            if (bookingsWithMatchedRates.length === 0) {
                break
            }

            dates = bookingsWithMatchedRates
                .filter(b => !options.cleanUntilCheckin || (b.guestCheckedIn && !b.guestCheckedOut))
                .flatMap(b => resolveRepeatDates(rule, b.checkinDate, b.checkoutDate, org))

            if (dates.includes(date)) {
                // If regular daily cleaning the number of days to clean is 2 less days than the number of days in the booking
                result.cleaningStatus = 'dirty'
                if (rule.repeatInterval === 1) {
                    result.occupancy = 'stayover'
                } else {
                    result.occupancy = 'stayover-80'
                }
            }
            break
        }
        default:
            dates = [rule.start]
            break
    }
    return result
}

export const resolveDateRule = (rule: RuleStruct, date: number, org: Pick<OrgStruct, 'timezone'>) => {
    let dates = []
    const result: RuleResolveResult = {}
    switch (rule.repeatType) {
        case RULE_TYPE_DAILY:
            dates = resolveRepeatDates(rule, rule.start, moment.tz(org.timezone).add(1, 'year').valueOf(), org)
            if (dates.includes(date)) {
                result.cleaningStatus = 'dirty'
            }
            break
        default:
            dates = []
            break
    }

    return result
}

export const resolveRule = (
    rule: RuleStruct,
    bookings: Pick<
        BookingStruct,
        | 'areaKey'
        | 'checkinDate'
        | 'checkoutDate'
        | 'bookingDates'
        | 'optInDates'
        | 'optOutDates'
        | 'status'
        | 'guestCheckedOut'
        | 'guestCheckedIn'
        | '_external'
    >[],
    date: number,
    org: Pick<OrgStruct, 'timezone'>,
    area: Pick<AreaStruct, 'daysSinceLastCleaning' | 'daysSinceLastCheckout' | 'cleaningStatus'>,
    options: RuleResolverOptions
) => {
    switch (rule.trigger) {
        case RULE_TRIGGER_BOOKING:
            return resolveBookingRule(rule, bookings, date, org, area, options)
        default:
            return resolveDateRule(rule, date, org)
    }
}
