import checkmarkIcon from '@shared/img/checkmark-blue.svg'
import errorIcon from '@shared/img/error/error-face.svg'
import React from 'react'
import { Error, InputItem, InputWrap, LabelWrap } from './style'

const Input = ({ value, label, placeholder, form: { setFieldTouched, errors, touched }, field: { name, onChange }, ...rest }) => {
    const errorMessage = errors[name]
    const isTouched = touched[name]
    const isError = errorMessage && isTouched

    const onBlur = () => {
        setFieldTouched(name)
    }

    return (
        <InputWrap>
            <LabelWrap>
                {label && <label>{label}</label>}
                {isError && <Error>{errorMessage}</Error>}
            </LabelWrap>

            <InputItem isError={isError}>
                <input
                    {...rest}
                    name={name}
                    value={value}
                    type={rest?.type ?? 'text'}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                {isTouched && <img src={isError ? errorIcon : checkmarkIcon} />}
            </InputItem>
        </InputWrap>
    )
}

export default Input
