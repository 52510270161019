import type { Features } from '@shared/feature-toggles'
import type { Inputs } from './inputs.builder'
import { SignupWorkflowInstance } from './signup-workflow-instance'

export interface WorkflowStep {
    url: string
    inputs?: Inputs
    external?: boolean
    state?: 'not started' | 'complete'
}

export class SignupWorkflow {
    steps: Array<WorkflowStep> = []
    persistentFeatureFlags: Partial<Record<Features, boolean>> = {}

    constructor() {}

    /**
     * Enable (or disable) a feature flag for this workflow. It will always
     * be set during the entire workflow, at client scope. Resolution of
     * the feature flag value at runtime is handled by the normal existing
     * logic.
     *
     * param feature - The feature flag to set.
     * param enabled - Whether to explicitly enable or disable the flag.
     * @see {@link useFeatureToggle}
     */
    persistFeature(feature: Features, enabled: boolean) {
        this.persistentFeatureFlags[feature] = enabled
        return this
    }

    /**
     * Creating a step definition. For examples of usage, please take a look at the test cases.
     * @param url - any string can be used here but might be a good idea to use URLs to lead the workflow
     * @param external - not used for now
     * @param inputs - an objects with input definitions for the step. Use InputsBuilder for creating input definitions
     * @returns {SignupWorkflow}
     */
    step({ url, external = false, inputs = {} }: WorkflowStep): SignupWorkflow {
        if (this.steps.map(s => s.url).includes(url)) throw new Error(`Url "${url}" already configured`)
        if (Object.keys(inputs).some(inputName => this.steps.some(s => Object.keys(s.inputs || {}).includes(inputName))))
            throw new Error(`Duplicate input key. Please use unique input keys`)
        this.steps.push({ url, inputs, external, state: 'not started' })
        return this
    }

    /**
     * Starts a new instance of this workflow.
     * @returns {SignupWorkflowInstance}
     */
    start(): SignupWorkflowInstance {
        return new SignupWorkflowInstance(this.steps, this.persistentFeatureFlags)
    }
}
