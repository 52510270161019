import React from 'react'
import { TouchableOpacity } from 'react-native-web'

import * as brand from '@shared/brand'
import * as colors from '@shared/colors'

import { Tooltip } from 'react-tippy'
import * as thelpers from './tooltips/tooltip-helpers'

export const Initials = props => {
    const item = props.item
    let backgroundColor =
        props.item && props.currentUser.initials === props.item.initials ? brand.getBrand().navBarColor : colors.green_spectaflow

    let initials
    let tooltipTextArr
    let name
    let isPlusItem = false
    if (props.item) {
        if (item.isPlusItem) {
            isPlusItem = true
            initials = '+' + item.assignees.length
            tooltipTextArr = item.assignees.map(a => {
                return a.name
            })
        } else {
            initials = props.item.initials
            name = props.item.name
            tooltipTextArr = [name]
        }
    } else {
        initials = props.initials
        name = ''
        tooltipTextArr = [name]
    }

    initials = initials.trim()

    const font_weight = props.fontWeight ? props.fontWeight : '700'
    let font_size = props.fontSize ? props.fontSize : 13
    const font_color = props.fontColor ? props.fontColor : colors.white

    font_size = isPlusItem ? font_size + 2 : font_size

    const tool_tip_disabled = props.toolTipDisabled ? props.toolTipDisabled : false

    const margin_between = props.margin ? props.margin : 8

    return (
        <Tooltip
            distance={0}
            disabled={tool_tip_disabled}
            arrow
            html={thelpers.getDefaultTooltip(tooltipTextArr)}
            theme="transparent"
            position="top"
            trigger="mouseenter"
            interactiveBorder={0}>
            <TouchableOpacity
                {...props}
                disabled={props.disabled}
                delay={thelpers.TOOLTIP_DELAY}
                style={{
                    marginRight: props.noMargin ? 0 : margin_between,
                    backgroundColor: backgroundColor,
                    width: props.dimension || 40,
                    height: props.dimension || 40,
                    borderRadius: '50%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                onPress={() => {
                    if (props.removeUser) {
                        props.removeUser(initials)
                    }
                    if (props.onPress) {
                        props.onPress(name)
                    }
                }}>
                <span style={{ fontSize: font_size, fontWeight: font_weight, color: font_color }}>{initials}</span>
            </TouchableOpacity>
        </Tooltip>
    )
}
