import * as colors from '@shared/colors'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const SpinnerWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
`

export const Error = styled.span`
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
`

export const ActionsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export const TrialDaysContainer = styled.div({
    margin: 'auto'
})

export const TrialDaysText = styled('p')({
    fontSize: 15,
    fontWeight: 600,
    color: colors.red_sweeply,
    margin: 0
})

export const PlanContainer = styled.div(({ color }) => ({
    width: '275px',
    background: 'white',
    height: 'fit-content',
    padding: '32px 24px 64px 24px',
    borderTop: '8px solid',
    boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
    borderRadius: '0 0 8px 8px',
    borderColor: color,
    color: colors.black_sweeply,
    margin: '0 10px',
    [PriceContainer]: {
        borderColor: color
    },
    [PriceWrapper]: {
        '&::after': {
            color: color
        }
    },
    [BenefitsItem]: {
        '&::after': {
            background: color
        }
    },
    [PricingDetails]: {
        '&::after': {
            color: color
        }
    },
    '@media(max-width: 780px)': {
        minWidth: '100%'
    }
}))
export const PriceContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 15,
    borderBottom: '2px solid',
    marginTop: 10
})
export const PriceWrapper = styled.h1(({ hasExtraDetails }) => ({
    fontSize: 40,
    paddingRight: hasExtraDetails ? 30 : 18,
    position: 'relative',
    '&::after': {
        position: 'absolute',
        content: hasExtraDetails ? "'*'" : '',
        display: 'block',
        top: 10,
        transform: 'translateY(-50%)',
        right: 12,
        fontWeight: 600
    }
}))
export const PriceSubtitle = styled.p({
    color: '#211E1C',
    opacity: '50%',
    width: 75,
    fontWeight: 450,
    lineHeight: 1.2
})

export const BenefitsContainer = styled.div({
    marginTop: 10,
    '&:first-child': {
        marginTop: 30
    }
})

export const BenefitsItem = styled.p(({ bold }) => ({
    color: colors.black_sweeply,
    fontSize: 15,
    fontWeight: bold ? 700 : 450,
    marginTop: '16px',
    paddingLeft: '30px',
    position: 'relative',
    textAlign: 'left',
    '&:first-child': {
        marginTop: 0
    },
    '&::after': {
        content: "''",
        position: 'absolute',
        width: '12px',
        height: '2px',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)'
    }
}))

export const ActionButtonContainer = styled.button({
    textAlign: 'center',
    cursor: 'pointer',
    background: '#fff',
    transition: 'all .3s ease-in-out',
    borderRadius: 4,
    border: `1px solid ${colors.yello_spectaflow}`,
    display: 'inline-block',
    padding: '16px 32px'
})

export const ButtonText = styled('span')(({ color }) => ({
    lineHeight: 1.2,
    color: color,
    fontWeight: 600,
    cursor: 'pointer'
}))

export const ContactSalesButton = styled('a')({
    background: colors.red_sweeply,
    transition: 'all .3s ease-in-out',
    border: 0,
    borderRadius: 4,
    display: 'inline-block',
    padding: '16px 32px',
    textAlign: 'center'
})
export const UpgradePlanButton = styled('div')({
    zIndex: 101,
    cursor: 'pointer',
    width: '75%',
    margin: '25px 0',
    background: colors.red_sweeply,
    transition: 'all .3s ease-in-out',
    border: 0,
    borderRadius: 4,
    display: 'inline-block',
    padding: '10px 32px',
    textAlign: 'center'
})
export const ActiveContainer = styled.div({
    color: '#211E1C',
    opacity: 0.5,
    position: 'absolute',
    top: -40,
    left: 5
})

export const SubscriptionPlansContainer = styled.div`
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 780px) {
        flex-direction: column;
    }
`

export const PageTitle = styled.div`
    margin: 0.5rem;
    padding-left: 20px;
    font-size: 2.5rem;
    font-weight: bolder;
    color: ${colors.black_sweeply};
`
export const TabsContainer = styled.div`
    display: flex;
    border-bottom: 1px solid #ebedf3;
    margin: 0rem 2.25rem;
`

export const Tab = styled(NavLink)`
    margin-right: 35px;
    font-size: 1rem;
    font-weight: 600;
    color: ${colors.textSecondary};
    text-emphasis: none;
    text-decoration: none;
    padding-bottom: 15px;

    &:hover {
        text-decoration: none !important;
        color: ${colors.textSecondary};
    }
    &.active {
        color: ${colors.black_sweeply};

        border-bottom: 2px solid black;
    }
`
export const PricingDeatilsText = styled.span`
    line-height: 1.7;
    opacity: 50%;
    color: ${colors.black_sweeply};
    font-weight: 450;
`

export const PricingDeatilsAction = styled.span`
    display: inline-block;
    height: 23.5px;
    color: ${colors.red_sweeply};
    cursor: pointer;
    font-size: 1.1rem;
    border-bottom: 2px solid ${colors.yello_spectaflow};
    font-weight: 600;
`
export const PricingDetails = styled.div({
    fontSize: 15,
    paddingTop: 30,
    paddingLeft: 30,
    position: 'relative',
    '&::after': {
        position: 'absolute',
        content: "'*'",
        display: 'block',
        top: 17,
        left: 0,
        fontWeight: 600,
        fontSize: 30
    }
})
