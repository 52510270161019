import type { OrgStruct, UserStruct } from '@shared/firestore-structs'
import { atom } from 'recoil'

export const currentOrganizationAtom = atom<OrgStruct | null>({
    key: 'global-currentOrganization',
    default: null
})

export const currentUserAtom = atom<UserStruct | null>({
    key: 'global-currentUser',
    default: null
})
