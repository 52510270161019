import { Field, Form, Formik, useFormikContext } from 'formik'
import React, { useState, useMemo, useContext, useEffect } from 'react'
import { CirclePicker } from 'react-color'
import { Input, Select } from '../../../../../../_metronic/_partials/controls'
import { AreasContext } from '../../../../Areas/AreasContext'
import { AreasFilter } from '../../../../Areas/pages/areas-filter/AreasFilter'
import { AreasGrouping } from '../../../../Areas/pages/areas-grouping/AreasGrouping'
import { AreasTable } from '../../../../Areas/pages/areas-table/AreasTable'
import { AVAILABLE_COLORS } from '../../../RulesUIHelpers'

export default function LinkAreasForm(props) {
    const { ids, setIds, setQueryParams, queryParams, newAreaButtonClick } = useContext(AreasContext)

    const { values } = useFormikContext()
    // const areasUIProps = useMemo(() => {
    // 	return {
    // 		ids: values.areas,
    // 		newAreaButtonClick: newAreaButtonClick
    // 	}
    // }, [newAreaButtonClick, values.areas])

    values.areas = ids
    setIds(values.areas)

    useEffect(() => {
        let newQueryParams = { ...queryParams }
        newQueryParams.pageSize = 250
        setQueryParams(newQueryParams)
    }, [])

    return (
        <div className="">
            <AreasFilter fields={['group', 'synced']} />
            {/* <AreasGrouping /> */}
            <AreasTable fields={['name', 'description', 'group', 'synced']} />
        </div>
    )
}
