import React from 'react'
import { CSVLink } from 'react-csv'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls/index.js'
import MainActionButton from '../../../../components/buttons/MainActionButton/index.js'
import { ReportsFilterAndExportWrap } from '../housekeeping-report/ReportsCard.style.js'
import { ReportsFilter } from '../reports-filter/ReportsFilter'
import { OptionalCleaningTable } from './OptionalCleaningTable.js'

export function OptionalCard() {
    return (
        <>
            <Card className="card-no-shadow">
                <CardHeader title="Optional housekeeping">
                    <CardHeaderToolbar></CardHeaderToolbar>
                </CardHeader>

                <CardBody>
                    <ReportsFilter defaultDateFilter={'This month'} />

                    <OptionalCleaningTable />
                </CardBody>
            </Card>
        </>
    )
}
