import { Field, Formik } from 'formik'
import React, { useMemo, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { Input } from '../../../../../_metronic/_partials/controls'
import Alert from '../../../../components/alerts/Alert'
import MainActionButton from '../../../../components/buttons/MainActionButton'
import { db } from '../../../../utils/firebase'
import { toastErrorStyle, toastSuccessStyle } from '../../../../utils/styles'
import { AreasContext } from '../../AreasContext'
import CleaningDropdown from './CleaningDropdown'

export function AreasGrouping() {
    const { ids, setIds, openDeleteAreasDialog, actionsLoading, setActionsLoading } = useContext(AreasContext)
    const [showEditAlert, setShowEditAlert] = useState(false)
    const [updateData, setUpdateData] = useState({})

    const areasUIProps = useMemo(() => {
        return {
            ids: ids,
            setIds: setIds,
            openDeleteAreasDialog: openDeleteAreasDialog
        }
    }, [ids, openDeleteAreasDialog, setIds])

    return (
        <>
            <div className="row row-paddingless align-items-center form-group-actions mt-10 mb-10">
                <div className="col-xl-10">
                    <div className="form-group form-group-inline mb-0">
                        <div className="row row-paddingless align-items-center">
                            <Formik
                                enableReinitialize
                                initialValues={{ group: undefined, description: undefined, cleaningStatus: undefined }}
                                onSubmit={values => {
                                    setShowEditAlert(true)
                                    setUpdateData(values)
                                }}>
                                {({ handleSubmit, values, setFieldValue }) => {
                                    const alertTitle = 'Changing unit info'
                                    const alertText = `Are you sure you want to change the following info for ${ids.length} unit${
                                        ids.length === 1 ? '' : 's'
                                    }?`

                                    const onConfirm = async () => {
                                        try {
                                            setShowEditAlert(false)
                                            setActionsLoading(true)

                                            const batch = db.batch()

                                            areasUIProps.ids.forEach(id => {
                                                const areaRef = db.collection('areas').doc(id)

                                                Object.entries(updateData).forEach(item => {
                                                    if (item[1]) {
                                                        batch.update(areaRef, Object.fromEntries([item]))
                                                    }
                                                })
                                            })

                                            await batch.commit()

                                            setIds([])
                                            setActionsLoading(false)

                                            toast.success('Updated ' + ids.length + ' units', toastSuccessStyle)
                                        } catch (error) {
                                            console.error(error)
                                            toast.error(`Error updating units (${error.message})`, toastErrorStyle)
                                        }
                                    }

                                    const onCancel = () => setShowEditAlert(false)

                                    const onDropdownSelect = eventKey => {
                                        setFieldValue('cleaningStatus', eventKey)
                                    }

                                    const isSaveButtonDisabled =
                                        (!values.group && !values.description && !values.cleaningStatus) || actionsLoading
                                    console.log('isSaveButtonDisabled', isSaveButtonDisabled)

                                    const saveButtonTextCondition = !actionsLoading ? (
                                        <span>Save</span>
                                    ) : (
                                        <span>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            <span className="sr-only">Loading...</span>
                                        </span>
                                    )

                                    return (
                                        <>
                                            {showEditAlert && (
                                                <Alert title={alertTitle} text={alertText} onCancel={onCancel} onConfirm={onConfirm} />
                                            )}

                                            <div className="col mr-5">
                                                <Field
                                                    name="description"
                                                    component={Input}
                                                    placeholder="Enter description"
                                                    disabled={actionsLoading}
                                                />
                                            </div>

                                            <div className="col mr-5">
                                                <Field name="group" component={Input} placeholder="Enter group" disabled={actionsLoading} />
                                            </div>

                                            <div className="col mr-5">
                                                <CleaningDropdown
                                                    name="cleaningStatus"
                                                    disabled={actionsLoading}
                                                    onDropdownSelect={onDropdownSelect}
                                                />
                                            </div>

                                            <div className="col">
                                                <MainActionButton onClick={handleSubmit} disabled={isSaveButtonDisabled}>
                                                    {saveButtonTextCondition}
                                                </MainActionButton>
                                            </div>
                                        </>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>

                <div className="col-xl-2">
                    <div className="form-group form-group-inline mb-0 float-right">
                        <MainActionButton type={'danger'} onClick={areasUIProps.openDeleteAreasDialog}>
                            Delete all
                        </MainActionButton>
                    </div>
                </div>
            </div>
        </>
    )
}
