import { getBrand } from '@shared/brand'
import * as colors from '@shared/colors'
import styled from 'styled-components'

const white = colors.white
const brandColor = getBrand().navBarColor

export const AlertWrap = styled.div`
    cursor: auto;

    > div {
        > div {
            background-color: ${colors.hex2rgb(white, 0.8)} !important;
        }
    }

    .alert-wrap {
        width: 45em !important;
        height: 32em !important;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
        padding: 80px 104px !important;
        justify-content: start !important;
        align-items: start !important;

        > h2 {
            font-weight: 700 !important;
            font-size: 2.462rem !important;
            line-height: 48px !important;
            text-align: left !important;
            margin-bottom: 16px;
        }

        > div {
            text-align: left !important;
            font-weight: 450 !important;
            font-size: 1.077rem !important;
            line-height: 24px !important;
        }

        > p {
            margin: 0 !important;
        }
    }

    .buttons-wrap {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        margin-top: 64px;

        .cancel-button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            font-weight: 700;
            font-size: 1.538rem;
            line-height: 24px;
            text-align: center;
            color: ${brandColor};
        }
    }
`
