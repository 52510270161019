import React, { type PropsWithChildren } from 'react'
import '../../_metronic/_assets/sass/pages/error/error-5.scss'
import * as brand from '@shared/brand'
import bgImage from '@shared/img/error/bg5.jpg'
import { ContactSupportWrap } from 'app/components/ErrorMessage/style'

type ErrorMessageProps = {
    title?: string
    message?: string
    showBacklink?: boolean
    notLoggedIn: boolean
}

export function ErrorMessage({
    notLoggedIn,
    title = 'Something went wrong here.',
    message = 'Try reloading the page',
    showBacklink = true
}: PropsWithChildren<ErrorMessageProps>) {
    const brandColor = brand.getBrand().navBarColor
    const { helpEmail } = brand.getBrand().urls

    const backLink = notLoggedIn ? (
        <span>
            Try <a href="/">login</a>
        </span>
    ) : (
        <span>
            Try going back to to the <a href="/">dashboard</a>
        </span>
    )

    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                style={{
                    backgroundImage: `url(${bgImage})`
                }}>
                <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
                    <h1 className="error-title font-weight-boldest mt-10 mt-md-0 mb-12" style={{ color: brandColor }}>
                        Oops!
                    </h1>
                    <p className="font-weight-boldest display-4">{title}</p>
                    <p className="font-size-h3">
                        {message ? message : ''}
                        <ContactSupportWrap>
                            <span>
                                Please <a href={`mailto:` + helpEmail}>{'contact support'}</a>
                            </span>
                        </ContactSupportWrap>
                        <br />
                        {showBacklink && backLink}
                    </p>
                </div>
            </div>
        </div>
    )
}
