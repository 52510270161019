import { Field } from 'formik'
import React from 'react'
import { Switch } from 'react-native-web'
import OrganizationIDsSelect from '../../../admin/organizations/organization-ids-select'

export function Beds24Form({ setFieldValue, values, actionsLoading, dataLoading }) {
    return (
        <div className="my-3">
            <h3 className="text-dark font-weight-bolder mb-10">Godo Property</h3>

            <div className="form-group row">
                <div className="col-lg-12 d-flex align-items-center">
                    <Field
                        name="pms"
                        disabled={dataLoading || actionsLoading}
                        component={() => {
                            return (
                                <Switch
                                    disabled={dataLoading || actionsLoading}
                                    style={{ boxShadow: 'none', display: 'inline-block' }}
                                    value={values.pms}
                                    onValueChange={syncPMS => {
                                        setFieldValue('pms', syncPMS)
                                    }}
                                />
                            )
                        }}
                    />
                    <label className="m-0 ml-2" htmlFor="pms">
                        Syncronize owner account IDs.
                    </label>
                </div>
            </div>
            {values.pms && (
                <>
                    <div className="form-group row">
                        {/* Name */}
                        <div className="col-lg-12">
                            <label className="font-weight-bold" htmlFor="pmsAccountIDs">
                                Owner ids:
                            </label>
                            <Field
                                name="pmsAccountIDs"
                                disabled={dataLoading || actionsLoading}
                                component={() => {
                                    return (
                                        <OrganizationIDsSelect
                                            isDisabled={dataLoading || actionsLoading}
                                            width={'100%'}
                                            organizationIDs={values._pmsAccountIDs}
                                            onChange={pmsAccountIDs => {
                                                setFieldValue('_pmsAccountIDs', pmsAccountIDs)
                                            }}
                                        />
                                    )
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-12 col-md-6">
                            <label className="font-weight-bold" htmlFor="_include">
                                Include Property IDs:
                            </label>
                            <Field
                                name="_include"
                                disabled={dataLoading || actionsLoading}
                                component={() => {
                                    return (
                                        <OrganizationIDsSelect
                                            isDisabled={dataLoading || actionsLoading}
                                            width={'100%'}
                                            organizationIDs={values._include}
                                            onChange={pmsAccountIDs => {
                                                setFieldValue('_include', pmsAccountIDs)
                                            }}
                                        />
                                    )
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="font-weight-bold" htmlFor="_exclude">
                                Exclude Property IDs:
                            </label>
                            <Field
                                name="_exclude"
                                disabled={dataLoading || actionsLoading}
                                component={() => {
                                    return (
                                        <OrganizationIDsSelect
                                            isDisabled={dataLoading || actionsLoading}
                                            width={'100%'}
                                            organizationIDs={values._exclude}
                                            onChange={pmsAccountIDs => {
                                                setFieldValue('_exclude', pmsAccountIDs)
                                            }}
                                        />
                                    )
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
