import * as brand from '@shared/brand'
import React from 'react'
import { Image, View } from 'react-native-web'

export const OpenNavbar = () => {
    const brandLogo = brand.getNavBarNOAuthLogo()

    return (
        <View style={{ width: '100%', height: 70, borderBottomWidth: 1, borderBottomColor: '#F4F5F6', justifyContent: 'center' }}>
            <div style={{ paddingLeft: 40 }}>
                <img src={brandLogo.img} style={brandLogo.style} />
            </div>
        </View>
    )
}
