import * as colors from '@shared/colors'
import { isPhoneNumberValid } from '@shared/helpers'
import { iOSColors } from '@shared/react-native-typography'
import { getRolesForOrg } from '@shared/roles/roles'
import * as constants from '@shared/txt-constants'
import RectangularButton from 'app/components/buttons/RectangularButton'
import firebase from 'app/utils/firebase'
import { toastErrorStyle, toastSuccessStyle } from 'app/utils/styles'
import { Field, Form, Formik } from 'formik'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import React, { useEffect, useState, useContext } from 'react'
import { ModalBody, ModalFooter } from 'react-bootstrap'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Input } from '../../../../../_metronic/_partials/controls'
import { PhoneInput } from '../../../../../_metronic/_partials/controls/forms/PhoneInput'
import { getAllCountries, getCountryItem } from '../../../../components/country-selector'
import { Checkbox } from '../../../../components/forms/Checkbox'
import IssueHashtagsSelect from '../../../../components/hashtags-select'
import { Initials } from '../../../../components/initials'
import { ModalFormFooter } from '../../../../components/modals/ModalFormFooter'
import { FeatureLockedSignal, SubscriptionCheck, UpgradeMessageContent } from '../../../../components/upgrade-signals'
import { useFeatureToggle } from '../../../../features'
import { upgradeMessageStyle } from '../../../../housekeeping/dashboard/styles'
import { SubscriptionContext } from '../../../Subscriptions/SubscriptionContext'
import AreasGroupsSelector from './AreaGroupsSelector'
import { UserRolesSelect } from './UserRolesSelect'

export function UserEditForm({ saveUser, currentUser, areaGroups, user, actionsLoading, dataLoading, onHide, organization }) {
    const [selectedCountry, setSelectedCountry] = useState()
    const [formUser, setFormUser] = useState()
    const [hasGroupAccess, setHasGroupAccess] = useState(false)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)
    const [availableFeature, setAvailableFeature] = useState()
    const [notAvailableFeature, setNotAvailableFeature] = useState()
    const { checkSubscription, currentSubscription } = useContext(SubscriptionContext)
    const { isFeatureOn } = useFeatureToggle()
    const isEdit = window.location.href.includes('edit')

    let defaultHashtags =
        organization.issueHashtags && organization.issueHashtags.length > 0 ? organization.issueHashtags : constants.HASHTAGS_DEFAULT

    const UserEditSchema = Yup.object().shape({
        name: Yup.string().min(1, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Name is required'),
        email: Yup.string().email(),
        phoneNumber: Yup.string()
            .test('phoneNumber', 'Phone number must be valid', function (value) {
                if (!selectedCountry || !value) return false
                return isPhoneNumberValid(value.toString(), selectedCountry.key)
            })
            .required('Phone number is required'),
        areaGroups: Yup.array().min(1, 'User must be in at least one group').required('Area group is required')
    })

    useEffect(() => {
        checkSubscription(
            'user-access-control',
            () => {
                setHasGroupAccess(true)
            },
            () => {
                setHasGroupAccess(false)
            }
        )
    }, [currentSubscription?.id])

    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: 'start'
    }

    const areaGroupsSelect = areaGroups.map(ag => {
        return { value: ag, label: ag }
    })
    const roles = getRolesForOrg(organization)
    const userRolesOptions = Object.keys(roles).map(ag => {
        return { value: roles[ag].name, label: roles[ag].displayName }
    })

    useEffect(() => {
        const pn = parsePhoneNumberFromString(user.phoneNumber)

        const allCountries = getAllCountries()

        if (pn) {
            const country = getCountryItem(
                pn.country,
                allCountries.map(country => ({ ...country, label: country.dialCode }))
            )

            setSelectedCountry(country)
            user.phoneNumber = pn.nationalNumber
        }
    }, [user.phoneNumber])

    const onSubmit = async values => {
        const phoneNumber = selectedCountry.dialCode + values.phoneNumber

        setFormUser(values)
        await saveUser(values, phoneNumber)
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={formUser ? formUser : user}
                validationSchema={UserEditSchema}
                onSubmit={onSubmit}
                validateOnBlur={true}>
                {({ handleSubmit, values, setFieldValue }) => {
                    const {
                        areaGroups,
                        assignIssueHashtags,
                        initials,
                        isAdmin,
                        issueHashtags,
                        phoneNumber,
                        authRole,
                        showCleaningTaskNote
                    } = values

                    let userRolesDisabled = true

                    if (isFeatureOn('roles-and-permissions')) {
                        userRolesDisabled = false
                        console.log(`User role is ${authRole}`)
                    }
                    let areaGroupsToOptions = areaGroups.map(ag => ({ value: ag, label: ag }))
                    const sortedIssueHashtags = issueHashtags.sort()

                    const disabled = dataLoading || actionsLoading
                    const isCurrentUser = user && user.key === currentUser.key

                    const onSelectedCountryChange = country => setSelectedCountry(country)
                    const onIssueHashtagsChange = hashtags => setFieldValue('issueHashtags', hashtags)

                    const onShowCleaningTaskNoteChange = e => {
                        setFieldValue('showCleaningTaskNote', e.target.checked)
                    }

                    const onAdminChange = e => {
                        setFieldValue('isAdmin', e.target.checked)
                        setFieldValue('authRole', e.target.checked ? 'admin' : 'user')
                    }

                    const onAssignIssueHashtagsChange = e => {
                        setFieldValue('assignIssueHashtags', e.target.checked)
                    }

                    const setModalClose = () => {
                        setShowUpgradeModal(false)
                    }

                    const phoneNumberValue = () => {
                        const dialCode = selectedCountry?.dialCode
                        const pn = dialCode ? dialCode + phoneNumber : phoneNumber
                        const parsedNumber = parsePhoneNumberFromString(selectedCountry ? pn : '')
                        const value = parsedNumber ? parsePhoneNumberFromString(pn).formatNational() : phoneNumber

                        return value.replace(/\D/g, '')
                    }

                    const onPhoneNumberChange = e => setFieldValue('phoneNumber', e.target.value)

                    const onAreaGroupsChange = (value, { action, option, removedValue }) => {
                        if (action === 'remove-value') {
                            const userAreaGroups = areaGroups
                                .filter(ag => ag !== removedValue.value)
                                .map(ag => ({
                                    value: ag,
                                    label: ag
                                }))

                            setFieldValue(
                                'areaGroups',
                                userAreaGroups.map(ag => ag.label)
                            )
                            return
                        }

                        if (action === 'clear') {
                            setFieldValue('areaGroups', [])
                            return
                        }

                        if (option.value === 'All') {
                            value = [option]
                        }

                        if (value.length > 1 && option.value !== 'All') {
                            value = value.filter(ag => ag.value !== 'All')
                        }

                        setFieldValue(
                            'areaGroups',
                            value.map(ag => ag.label)
                        )
                    }

                    const handlePasswordReset = async () => {
                        try {
                            const res = await firebase.auth().sendPasswordResetEmail(user.email)
                            console.log(res)
                            toast.info('Email for resetting the password is sent successfully', toastSuccessStyle)
                        } catch (error) {
                            toast.error(error.message, toastErrorStyle)
                        }
                    }

                    return (
                        <>
                            <Modal
                                isOpen={showUpgradeModal}
                                style={upgradeMessageStyle}
                                contentLabel="UpgradeMessages"
                                onRequestClose={setModalClose}>
                                <UpgradeMessageContent
                                    onClose={setModalClose}
                                    notAccessibleFeature={notAvailableFeature}
                                    availableUpgrades={availableFeature}
                                    showCloseButton={true}
                                />
                            </Modal>
                            <ModalBody className="overlay overlay-block cursor-default pt-3">
                                <div
                                    className="form-group row row-paddingless justify-content-between align-items-center mb-3"
                                    style={{ minHeight: 40 }}>
                                    {(isEdit || initials || isAdmin) && (
                                        <>
                                            <div>
                                                {initials && (
                                                    <Initials
                                                        disabled={true}
                                                        item={{ initials }}
                                                        currentUser={currentUser}
                                                        dimension={40}
                                                        fontSize={14}
                                                        noMargin={true}
                                                        toolTipDisabled={true}
                                                    />
                                                )}
                                            </div>
                                            <div>{isAdmin && <i className="specta-admin icon-lg" />}</div>
                                        </>
                                    )}
                                </div>

                                <Form className="form form-label-right">
                                    <div className="form-group row">
                                        {/* Name */}
                                        <div className="col-lg-12">
                                            <Field
                                                name="name"
                                                component={Input}
                                                placeholder="Enter name of user"
                                                label="Name"
                                                required
                                                disabled={disabled}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        {/* Group */}
                                        <div className="col-lg-12">
                                            <Field
                                                name="phoneNumber"
                                                component={PhoneInput}
                                                placeholder="Enter phone number"
                                                selectedCountry={selectedCountry}
                                                onCountryChange={onSelectedCountryChange}
                                                onChange={onPhoneNumberChange}
                                                value={phoneNumberValue()}
                                                required
                                                label="Phone number"
                                                disabled={disabled || isCurrentUser}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        {/* Description */}
                                        <div className="col-lg-12">
                                            <Field
                                                name="email"
                                                type="email"
                                                component={Input}
                                                placeholder="Enter email"
                                                label="Email"
                                                disabled={disabled}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <RectangularButton
                                                type="button"
                                                disabled={!user.email}
                                                onClick={handlePasswordReset}
                                                height={'38px'}
                                                rounded={true}
                                                backgroundColor={colors.blue_sweeply}>
                                                Reset Password
                                            </RectangularButton>
                                        </div>
                                    </div>

                                    {isFeatureOn('roles-and-permissions') ? (
                                        <div className="form-group row">
                                            <div className="col-lg-12">
                                                <label className="font-weight-bold text-dark-65">User roles:</label>
                                                <Field
                                                    name="authRole"
                                                    component={UserRolesSelect}
                                                    options={userRolesOptions}
                                                    width="100%"
                                                    disabled={disabled || userRolesDisabled}
                                                    filterConfig={filterConfig}
                                                />
                                                <div className="form-text text-muted">
                                                    Admin can manage the account, users, units, and rules
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="form-group row">
                                            <div className="col-lg-12">
                                                <Field
                                                    component={Checkbox}
                                                    label="Admin"
                                                    isSelected={isAdmin}
                                                    disabled={disabled || isCurrentUser}
                                                    onChange={onAdminChange}
                                                />
                                                <div className="form-text text-muted">
                                                    Admin can manage the account, users, units, and rules
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="form-group row">
                                        <div className="col-lg-12">
                                            <Field
                                                component={Checkbox}
                                                label="Show Booking notes"
                                                isSelected={showCleaningTaskNote}
                                                disabled={disabled}
                                                onChange={onShowCleaningTaskNoteChange}
                                            />
                                            <div className="form-text text-muted">
                                                Booking notes will be displayed in the Housekeeping detail view
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        {/* Group */}
                                        <div className="col-lg-12">
                                            <div className="d-flex justify-content-between">
                                                <label className="font-weight-bold text-dark-65">
                                                    Unit groups:{<span className="text-danger">*</span>}
                                                </label>
                                                {!hasGroupAccess && (
                                                    <SubscriptionCheck
                                                        featureId="user-access-control"
                                                        onAvailable={() => null}
                                                        onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                                                            return (
                                                                <FeatureLockedSignal
                                                                    showLock
                                                                    isActive
                                                                    onClick={() => {
                                                                        setNotAvailableFeature(notAccessibleFeature)
                                                                        setAvailableFeature(availableUpgrades)
                                                                        setShowUpgradeModal(true)
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </div>

                                            <Field
                                                name="areaGroups"
                                                component={AreasGroupsSelector}
                                                placeholder=""
                                                label="Area access"
                                                disabled={disabled || !hasGroupAccess}
                                                areaGroupsToOptions={areaGroupsToOptions}
                                                onAreaGroupsChange={onAreaGroupsChange}
                                                filterConfig={filterConfig}
                                                currentUser={currentUser}
                                                areaGroupsSelect={areaGroupsSelect}
                                            />
                                            <div className="form-text text-muted">This user has access to the above unit groups</div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="font-weight-bold text-dark-65">Subscribe to hashtags:</label>
                                        <Field
                                            name="issueHashtags"
                                            component={() => {
                                                return (
                                                    <IssueHashtagsSelect
                                                        hashtagColor={iOSColors.gray}
                                                        borderHoverColor={colors.green_spectaflow}
                                                        isDisabled={disabled}
                                                        defaultHashtags={defaultHashtags}
                                                        width={'100%'}
                                                        hashtags={sortedIssueHashtags}
                                                        onChange={onIssueHashtagsChange}
                                                    />
                                                )
                                            }}
                                            label="Issue hashtags"
                                            disabled={disabled}
                                        />
                                        <span className="form-text text-muted">
                                            This user gets a push notification when an issue with the above hashtag(s) is created
                                        </span>
                                    </div>
                                    {issueHashtags && issueHashtags.length > 0 && (
                                        <div className="form-group row">
                                            <div className="col-lg-12">
                                                <Field
                                                    component={Checkbox}
                                                    label="Assign subscribed Issues"
                                                    isSelected={assignIssueHashtags}
                                                    disabled={disabled}
                                                    onChange={onAssignIssueHashtagsChange}
                                                />
                                                <div className="form-text text-muted">
                                                    This user will be assigned issues he has subscribed to
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Form>
                            </ModalBody>
                            <ModalFooter className="border-top-0">
                                <ModalFormFooter
                                    onHide={onHide}
                                    onSubmit={handleSubmit}
                                    dataLoading={dataLoading}
                                    actionsLoading={actionsLoading}
                                    type="save"
                                />
                            </ModalFooter>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}
