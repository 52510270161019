import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

if (import.meta.env.PROD) {
    const clientToken = 'puba57a05e97667714cfc44156607119a3e'
    const site = 'us5.datadoghq.com'
    const env = import.meta.env.VITE_ENV === 'prod' ? 'prod' : 'dev'
    const version = import.meta.env.VITE_BUILD ?? 'unknown'
    const sessionSampleRate = 100
    const serviceName = 'web'
    datadogRum.init({
        applicationId: '86c721a1-8c9d-46c1-8698-6bf2f45cefd3',
        clientToken: clientToken,
        site: site,
        env: env,
        version: version,
        sessionSampleRate: sessionSampleRate,
        sessionReplaySampleRate: 20,
        traceSampleRate: 100,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'mask-user-input',
        service: serviceName,
        allowedTracingUrls: [
            `${window.location.protocol}//${window.location.host}/api`,
            url => url.startsWith(`https://firebase.googleapis.com`) && !url.endsWith('/webConfig'),
            /https?:\/\/(?!(identitytoolkit|www|securetoken|firebaseinstallations|firebaseremoteconfig|firebase|firebasestorage)\.)[a-zA-Z0-9-]+\.googleapis\.com/,
            /https:\/\/.*\.cloudfunctions\.net/
        ]
    })
    datadogLogs.init({
        clientToken: clientToken,
        site: site,
        env: env,
        version: version,
        service: serviceName,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['log', 'info', 'warn', 'error'], // no debug
        forwardReports: 'all',
        sessionSampleRate: sessionSampleRate,
        beforeSend: log => {
            const knownErrorUrls = [
                'https://app.posthog.com',
                'https://firestore.googleapis.com/google.firestore.v1.Firestore/Listen/channel'
            ]
            if (knownErrorUrls.some(knownUrl => log.http?.url?.startsWith(knownUrl))) {
                return false
            }
        }
    })
    datadogRum.startSessionReplayRecording()
} else {
    console.log('Datadog RUM disabled')
}
