import React, { useState, useEffect, useContext } from 'react'
import { ModalHeader } from '../../../../components/modals/ModalHeader'
// import { shallowEqual, useSelector } from "react-redux";
import { UsersContext } from '../../UsersContext'

export function UserEditDialogHeader({ id, onHide }) {
    const { userForEdit, actionsLoading } = useContext(UsersContext)

    const [title, setTitle] = useState('')
    useEffect(() => {
        let _title = id ? '' : 'New user'
        if (userForEdit && id) {
            _title = `Edit user`
        }

        setTitle(_title)
    }, [userForEdit])

    return (
        <>
            <ModalHeader title={title} onHide={onHide} />
        </>
    )
}
