import { useFeatureToggle } from 'app/features'
import React, { useEffect } from 'react'
import { compose } from 'redux'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { INTEGRATIONS } from '../../../../utils/constants'
import firebase, { asFirebase, asFirestore, db } from '../../../../utils/firebase'
import withErrorCatching from '../../../../utils/hocs/withErrorCatching'
import withIntegrationWorkflow from '../../../../utils/hocs/withIntegrationWorkflow'
import LoadingScreen from '../../LoadingScreen/index'
import SignUpLayout from '../../SignUpLayout/index'
import { mewsSetup } from '../../cloud-functions'
import { getApi } from '../../frontend-api'
import { errorModalConfigs } from '../../helpers'

const MewsSetup = ({
    newOrgKey,
    catchError,
    signupComplete,
    workflowInstance,
    storeWorkflowInstance,
    setComplete,
    currentIntegrationKey,
    newUserKey,
    settings,
    message,
    setStopAuthListener
}) => {
    const { isFeatureOn } = useFeatureToggle()
    const useNewInitialImport = isFeatureOn('new-initial-import-procedure')

    const setupMewsOld = async () => {
        console.info('Initial Mews PMS Sync: Using old method')
        try {
            const enterpriseId = workflowInstance.getInput('enterpriseId')
            const isDemo = !!workflowInstance.getInput('demo')

            const setupMewsIntegration = mewsSetup()

            const { data } = await setupMewsIntegration({
                orgId: newOrgKey,
                integration: INTEGRATIONS.MEWS,
                signupPayload: { organizationID: newOrgKey, enterpriseID: enterpriseId, isDemo: isDemo }
            })

            if (data.reason !== 'all good') {
                catchError(data.message, errorModalConfigs)
                return
            }

            if ('test' in workflowInstance.values) {
                data.reason = 'test'
            }

            await signupComplete({ orgId: newOrgKey, userId: newUserKey, status: data })

            workflowInstance.completeStep(SIGN_UP.MEWS_SETUP, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)

            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupMewsNew = async () => {
        console.info('Initial Mews PMS Sync: Using new server-side method')
        try {
            const api = await getApi(asFirebase(firebase))

            const enterpriseId = workflowInstance.getInput('enterpriseId')
            const isDemo = !!workflowInstance.getInput('demo')

            await api['setup-org'].$post({
                json: {
                    newAdminUserKey: newUserKey,
                    orgId: newOrgKey,
                    version: 'stable',
                    integration: INTEGRATIONS.MEWS,
                    signupPayload: {
                        organizationID: newOrgKey,
                        enterpriseID: enterpriseId,
                        isDemo: isDemo
                    }
                }
            })

            workflowInstance.completeStep(SIGN_UP.MEWS_SETUP, settings)
            storeWorkflowInstance(currentIntegrationKey, workflowInstance)
            setComplete(true)
        } catch (error) {
            catchError(error, errorModalConfigs)
        }
    }

    const setupMews = async () => (useNewInitialImport ? await setupMewsNew() : await setupMewsOld())

    async function setup() {
        setStopAuthListener(true)
        setupMews()
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <SignUpLayout withModal>
            <LoadingScreen message={message} />
        </SignUpLayout>
    )
}

export default compose(withIntegrationWorkflow, withErrorCatching)(MewsSetup)
