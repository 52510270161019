import clsx from 'clsx'
import React, { type FC, forwardRef, useEffect, useState } from 'react'
import { isFragment } from 'react-is'
import type { CardBaseProps, CardBodyProps, CardHeaderProps, CardProps } from './types'

export const CardHeader: FC<CardHeaderProps> = ({
    className,
    children,
    icon,
    count,
    title,
    toolbar,
    counter,
    subscriptionLock,
    sticky = false,
    labelRef
}) => {
    const [top, setTop] = useState(0)
    const [windowHeight, setWindowHeight] = useState(0)

    useEffect(() => {
        handleResize()

        function handleResize() {
            setWindowHeight(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })

    useEffect(() => {
        // Skip if sticky is disabled or on initial render when we don't know about window height.
        if (!sticky || windowHeight === 0) {
            return
        }

        const headerElement: HTMLElement | null = document.querySelector('.header')
        const subheaderElement: HTMLElement | null = document.querySelector('.subheader')
        const headerMobileElement: HTMLElement | null = document.querySelector('.header-mobile')

        let nextMarginTop = 0

        // mobile header
        if (headerElement && headerMobileElement && window.getComputedStyle(headerElement).height === '0px') {
            nextMarginTop = headerMobileElement.offsetHeight
        } else {
            // desktop header
            if (document.body.classList.contains('header-minimize-topbar')) {
                // hardcoded minimized header height
                nextMarginTop = 60
            } else {
                // normal fixed header
                if (headerElement && document.body.classList.contains('header-fixed')) {
                    nextMarginTop += headerElement.offsetHeight
                }

                if (subheaderElement && document.body.classList.contains('subheader-fixed')) {
                    nextMarginTop += subheaderElement.offsetHeight
                }
            }
        }

        setTop(nextMarginTop)
    }, [sticky, windowHeight])

    return (
        <>
            <div className="card-header" style={{ minHeight: 'auto' }}>
                {subscriptionLock}
            </div>
            <div className="card-header" style={!sticky ? undefined : { top, position: 'sticky', backgroundColor: '#fff' }}>
                {title != null && (
                    <>
                        <div ref={labelRef} className={clsx('card-title', className)}>
                            {icon}

                            {
                                /* Wrap string and fragments in CardHeaderTitle */
                                typeof title === 'string' || isFragment(title) ? <CardHeaderTitle>{title}</CardHeaderTitle> : title
                            }
                        </div>
                        <div className="card-counter">
                            {count != null && count > 0 && <h3 className={clsx('title-number', className)}>({count})</h3>}
                        </div>
                    </>
                )}

                {toolbar}
                {children}
            </div>
        </>
    )
}

export const CardHeaderTitle: FC<CardBaseProps> = ({ className, children }) => {
    return <h3 className={clsx('card-label text-dark', className)}>{children}</h3>
}

export const CardHeaderToolbar: FC<CardBaseProps> = ({ className, children }) => {
    return <div className={clsx('card-toolbar', className)}>{children}</div>
}

export const CardHeaderCounter: FC<CardBaseProps> = ({ className, children }) => {
    return <div className={clsx('card-counter ml-5 ml-md-10', className)}>{children}</div>
}

export const CardHeaderIcon: FC<CardBaseProps> = ({ className, children }) => {
    return <div className={clsx('card-head-icon', className)}>{children}</div>
}

export const CardBody: FC<CardBodyProps> = ({ className, children, fit, fluid }) => {
    return (
        <div
            className={clsx(
                'card-body',
                {
                    'card-body-fit': fit,
                    'card-body-fluid': fluid
                },
                className
            )}>
            {children}
        </div>
    )
}

export const CardFooter: FC<CardBaseProps> = ({ className, children }) => {
    return <div className={clsx('card-footer', className)}>{children}</div>
}

export const Card: FC<CardProps> = ({ fluidHeight, className, children }) => {
    return <div className={clsx('card card-custom gutter-b', { 'card-height-fluid': fluidHeight }, className)}>{children}</div>
}
