import { getBrand } from '@shared/brand'
import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { ActivityIndicator } from 'react-native-web'
import { LoadingWrap } from './style'

const Prompt = ({
    title,
    text,
    onConfirm,
    onCancel,
    placeholder,
    value,
    onChange,
    disabled,
    confirmBtnText = 'Confirm',
    confirmBtnBsStyle = 'primary',
    isLoading = false
}) => {
    return (
        <SweetAlert
            dependencies={[value]}
            title={title}
            customButtons={
                <>
                    <button disabled={isLoading} onClick={onCancel} className="btn btn-lg btn-link">
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        disabled={disabled || isLoading}
                        className={`btn btn-lg btn-${confirmBtnBsStyle} border-0`}
                        style={{ boxShadow: 0 }}>
                        {confirmBtnText}
                    </button>
                </>
            }>
            <p style={{ marginBottom: 10 }}>{text}</p>
            <input className="form-control" placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)} />
            {isLoading && (
                <LoadingWrap>
                    <ActivityIndicator size="small" color={getBrand().navBarColor} />
                </LoadingWrap>
            )}
        </SweetAlert>
    )
}

export default Prompt
