import { CardCVV, CardComponent, CardExpiry, CardNumber } from '@chargebee/chargebee-js-react-wrapper'
import React, { useContext, useState, useEffect } from 'react'

const CardForm = props => {
    const [formState, setFormState] = useState({})
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState()

    const { cardRef } = props

    const handleChange = e => {
        props.setName(e.target.value)
    }

    const style = {
        // Styles for default field state
        base: {
            color: '#3f4254',
            fontWeight: '400',
            fontFamily: 'Inter, Roboto, sans-serif',
            fontSize: '14px',
            fontSmoothing: 'antialiased',

            ':focus': {
                color: '#3f4254'
            },

            '::placeholder': {
                color: 'transparent'
            },

            ':focus::placeholder': {
                color: '#7b808c'
            }
        },

        // Styles for invalid field state
        invalid: {
            color: '#e41029',

            ':focus': {
                color: '#e44d5f'
            },
            '::placeholder': {
                color: '#FFCCA5'
            }
        }
    }

    const classes = {
        focus: 'focus',
        invalid: 'invalid',
        empty: 'empty',
        complete: 'complete'
    }

    const placeholder = {
        number: '4111 1111 1111 1111',
        expiry: 'MM / YY',
        cvv: 'CVV'
    }

    const onCardFieldChange = status => {
        props.setCardErrorState(status)
    }

    const fonts = ['https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800']

    return (
        <>
            <div className="form">
                <div className="form-group row-paddingless">
                    <label className="font-weight-bold text-dark-65">Name on Card</label>
                    <input
                        name="name"
                        style={{ fontSize: '14px' }}
                        // className={formState.firstName ? 'form-control form-control-iconless val' : 'form-control form-control-iconless'}
                        className="form-control"
                        type="text"
                        placeholder="John Doe"
                        value={formState.firstName}
                        onChange={handleChange}
                    />
                </div>

                {/* Pass all options as props to card component  */}
                {/* Assign ref to call internal methods */}
                <CardComponent
                    ref={cardRef}
                    className="fieldset field"
                    styles={style}
                    classes={classes}
                    // locale={locale}
                    onChange={onCardFieldChange}
                    placeholder={placeholder}
                    fonts={fonts}>
                    <div className="form-group row-paddingless">
                        <label className="font-weight-bold text-dark-65">Card Number</label>
                        <CardNumber className="form-control" />
                    </div>

                    <div className="ex1-fields">
                        <div className="form-group row-paddingless">
                            <label className="font-weight-bold text-dark-65">Expiry</label>
                            <CardExpiry className="form-control form-control-iconless" />
                        </div>

                        <div className="form-group row-paddingless">
                            <label className="font-weight-bold text-dark-65">CVC</label>
                            <CardCVV className="form-control form-control-iconless" />
                        </div>
                    </div>
                </CardComponent>
            </div>
            {formState.error && (
                <div className="error" role="alert">
                    {formState.error}
                </div>
            )}
        </>
    )
}

export default CardForm
