import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
// import 'firebase/compat/remote-config'

export type FirebaseAuthError = firebase.auth.AuthError
export type ConfirmationResult = firebase.auth.ConfirmationResult

export type DocumentData = firebase.firestore.DocumentData
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot
export type DocumentReference<T> = firebase.firestore.DocumentReference<T>
export type FirestoreError = firebase.firestore.FirestoreError

import { getBrand } from '@shared/brand'
import axios from 'axios'
import 'firebase/compat/storage'
import type { QuerySnapshot } from '@firebase/firestore-types'
export type { QuerySnapshot }

import { IssueStruct, UserStruct } from '@shared/firestore-structs'
import * as issueData from '@shared/issue-data'
import { TaskClientRepo } from '@shared/task-data'

export * from '@shared/area-data'
export * from '@shared/issue-data'
import type { Firebase, FirebaseFirestore, WriteBatch } from '@shared/firebase'

const config = getBrand().firebase!

firebase.initializeApp(config)

type QuerySnapshotData<T> = T extends QuerySnapshot<infer U> ? U : never

export function getDataFromSnapshot<T extends QuerySnapshot[]>(snapshot: QuerySnapshot): QuerySnapshotData<T>[] {
    return snapshot.docs.map(doc => doc.data()) as QuerySnapshotData<T>[]
}

export function logout() {
    firebase.auth().signOut()
}

// const firebaseWrapped = spy(firebase)
const firebaseWrapped = firebase
export const db = firebaseWrapped.firestore()
export const storage = firebase.storage()
export const asFirebase = (fb: typeof firebase): Firebase => fb as unknown as Firebase
export const asFirestore = (fb: typeof db): FirebaseFirestore => fb as unknown as FirebaseFirestore
export const asBatch = (batch: ReturnType<typeof db.batch>): WriteBatch => batch as unknown as WriteBatch

export const tasksRepo = new TaskClientRepo(asFirebase(firebase))

export default firebaseWrapped

if (location.hostname === 'localhost' && process.env.VITE_LOCAL_STACK) {
    db.useEmulator('localhost', 8080)
    firebase.functions().useEmulator('localhost', 5001)
}
