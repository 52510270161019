import type { FeatureId } from '../subscriptions.types'

const BASIC_PLAN: FeatureId[] = [
    'unlimited-users',
    'tasks-2',
    'simple-cleaning-rules',
    'start-stop-housekeeping',
    'start-stop-housekeeping-2',
    'ios-android-apps',
    'booking-data-updated-from-godo-property'
]
const PRO_PLAN: FeatureId[] = [
    'everything-basic',
    'unlimited-areas',
    'complex-rules',
    'assign-housekeeping',
    'assign-tasks',
    'issues',
    'issue-reports',
    'user-access-control'
]

export const MARKETING_PLANS: { [name in 'basic' | 'pro' | 'pronto-basic' | 'pronto-pro']: FeatureId[] } = {
    basic: BASIC_PLAN,
    pro: PRO_PLAN,
    'pronto-basic': BASIC_PLAN,
    'pronto-pro': PRO_PLAN
}
