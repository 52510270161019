import { getBrand } from '@shared/brand'
import React, { useContext, useState, useEffect } from 'react'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import Spinner from '../../../../../components/Spinner'
import Alert from '../../../../../components/alerts/Alert'
import { AuthContext } from '../../../../Auth/AuthContext'
import { getEstimates } from './api'
import EnterprisePlan from './components/EnterprisePlan'
import PaymentModal from './components/PaymentModal'
import Plan from './components/Plan'
import { UpgradeSuccessMessage } from './components/SuccessMessage'
import { subscriptionPlans, waitingForUpgradeAtom } from './state'
import { Error, SpinnerWrap, SubscriptionPlansContainer } from './style'

const SubscriptionPlans = () => {
    const { contents, state } = useRecoilValueLoadable(subscriptionPlans)
    const { currentOrganization, orgSubscription } = useContext(AuthContext)
    const [selectedCurrency, setSelectedCurrency] = useState(currentOrganization.currencyCode)
    const [dataLoading, setDataLoading] = useState(true)
    const [estimates, setEstimates] = useState([])
    const [success, setSuccess] = useState(false)
    const [subscriptionChangeAction, setSubscriptionChangeAction] = useState()
    const [currentSubscription, setCurrentSubscription] = useState(orgSubscription.name)
    const [subscriptionChanged, setSubscriptionChanged] = useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState()
    const [waitingForUpgrade, setWaitingForUpgrade] = useRecoilState(waitingForUpgradeAtom)

    let { plans } = contents
    useEffect(() => {
        const getData = async () => {
            const ids = plans.map(plan => {
                const planItemIds = Object.keys(plan.pricing).map(x => {
                    if (plan.pricing[x].currencyCode === selectedCurrency && plan.pricing[x].startingPrice > 0) {
                        return plan.pricing[x].id
                    }
                })

                return planItemIds
            })
            const planItems = ids.flat().filter(x => x !== undefined)

            const quantity = currentOrganization.billingUnitsOverride ?? currentOrganization.areasSyncedTotal ?? 1
            const e = await getEstimates(planItems, selectedCurrency, orgSubscription.key, quantity, false)
            setEstimates(e || [])
        }

        if (plans && plans.length > 0) {
            const planBrand = currentOrganization.brand === 'sweeply' ? 'sweeply' : 'pronto'
            plans = plans.filter(p => p.group === planBrand)

            setDataLoading(false)
            getData()
        }
        window.Chargebee.init({
            site: getBrand().firebase.accounting.site,
            publishableKey: getBrand().firebase.accounting.publishable_key
        })

        return () => {}
    }, [plans, selectedCurrency])

    useEffect(() => {
        if (orgSubscription.name !== currentSubscription) {
            setCurrentSubscription(orgSubscription.name)
            setSubscriptionChanged(true)
            setShowPaymentModal(false)
            setWaitingForUpgrade(false)
        }
        return () => {}
    }, [orgSubscription, currentSubscription])

    useEffect(() => {
        if (subscriptionChangeAction) {
            if (subscriptionChangeAction === 'cancel') {
                setSubscriptionChanged(true)
            }
            setSuccess(true)
        }

        return () => {}
    }, [subscriptionChangeAction])

    const isSelectedCurrency = currency => {
        return selectedCurrency.toLowerCase() === currency.toLowerCase() ? 'primary' : 'secondary'
    }

    if (dataLoading) {
        return (
            <SpinnerWrap>
                <Spinner />
            </SpinnerWrap>
        )
    }

    function onSuccessMessageClose() {
        setSuccess(false)
        setSubscriptionChangeAction(null)
        setSubscriptionChanged(false)
        setWaitingForUpgrade(false)
        window.location.reload()
    }

    const successCondition = success && (
        <>
            {subscriptionChanged && subscriptionChangeAction === 'update' && (
                <UpgradeSuccessMessage
                    onConfirm={() => {
                        setSuccess(false)
                        setSubscriptionChangeAction(null)
                        setSubscriptionChanged(false)
                        setWaitingForUpgrade(false)
                    }}
                />
            )}
            {subscriptionChanged && !waitingForUpgrade && subscriptionChangeAction === 'cancel' && (
                <Alert
                    title="You have successfully unsubscribed from Pro"
                    text="Subscription will be changed at the end of your term"
                    confirmBtnText="Okay"
                    onConfirm={onSuccessMessageClose}
                    onCancel={onSuccessMessageClose}
                    type="success"
                />
            )}
        </>
    )

    switch (state) {
        case 'loading':
            return (
                <SpinnerWrap>
                    <Spinner />
                </SpinnerWrap>
            )

        case 'hasError':
            return <Error>{'Something went wrong. Please try again later'}</Error>

        case 'hasValue': {
            const planBrand = currentOrganization.brand === 'sweeply' ? 'sweeply' : 'pronto'

            const p = plans
                .filter(p => p.group === planBrand)
                .map((plan, index) => (
                    <Plan
                        key={plan.name + index}
                        planKey={plan.key}
                        plan={plan}
                        estimates={estimates}
                        setShowPaymentModal={setShowPaymentModal}
                        setSelectedPlan={setSelectedPlan}
                        index={index}
                        plans={plans}
                        currencyCode={selectedCurrency}
                        setSubscriptionChangeAction={setSubscriptionChangeAction}
                    />
                ))

            return (
                <>
                    <div className=" my-md-35 row justify-content-center ">
                        <SubscriptionPlansContainer>
                            {p}
                            <EnterprisePlan />
                        </SubscriptionPlansContainer>
                    </div>
                    {successCondition}
                    {showPaymentModal && (
                        <PaymentModal
                            show={showPaymentModal}
                            onHide={() => {
                                setWaitingForUpgrade(false)
                                setShowPaymentModal(false)
                            }}
                            currencyCode={selectedCurrency}
                            orgKey={currentOrganization.key}
                            subscriptionId={orgSubscription.key}
                            plan={selectedPlan.plan}
                            planItemId={selectedPlan.priceObj.id}
                            periodUnit={selectedPlan.priceObj.periodUnit}
                            priceObj={selectedPlan.priceObj}
                            planName={selectedPlan.plan.name}
                            setSubscriptionChangeAction={setSubscriptionChangeAction}
                            setSubscriptionChanged={setSubscriptionChanged}
                            setSuccess={setSuccess}
                        />
                    )}
                </>
            )
        }
    }
}

export default SubscriptionPlans
