import { getBrand } from '@shared/brand'
import { Field, Form, Formik } from 'formik'
import React, { type ChangeEvent } from 'react'
import RectangularButton from '../../../../components/buttons/RectangularButton'
import Input from '../../../../components/forms/Input'
import { ContentWrap, ModalContainer, ModalOverlay } from '../../../../components/styled-components/modal'
import SignUpLayout from '../../SignUpLayout'
import { CodeVerificationWrapper } from '../../style'

interface FormValues {
    hostName: string
    clientId: string
    clientSecret: string
    username: string
    password: string
    hotelId: string
    appKey: string
    externalSystemCode: string
}

interface Props {
    bus: string
    onSubmit: (values: FormValues) => void
}

const OracleCustomerCredentialsForm = ({ bus, onSubmit }: Props) => {
    const brandColor = getBrand().navBarColor
    const initialValues: FormValues = {
        hostName: '',
        clientId: '',
        clientSecret: '',
        password: '',
        username: '',
        hotelId: '',
        appKey: '',
        externalSystemCode: ''
    }
    return (
        <SignUpLayout withModal>
            <ModalOverlay>
                <ModalContainer>
                    <ContentWrap>
                        <CodeVerificationWrapper>
                            <h1>{`Enter ${bus} customer credentials`}</h1>
                            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                                {({ handleSubmit, setFieldValue, values }) => {
                                    const disabled =
                                        !values.hostName ||
                                        !values.clientId ||
                                        !values.clientSecret ||
                                        !values.username ||
                                        !values.password ||
                                        !values.hotelId ||
                                        !values.appKey ||
                                        !values.externalSystemCode

                                    return (
                                        <Form>
                                            <Field
                                                component={Input}
                                                label={`${bus} Host name`}
                                                placeholder={'Enter Host name here'}
                                                value={values.hostName}
                                                name={'hostName'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('hostName', e.target.value)}
                                                withoutValidation
                                            />
                                            <Field
                                                component={Input}
                                                label={`${bus} Client Id`}
                                                placeholder={'Enter Client ID here'}
                                                value={values.clientId}
                                                name={'clientId'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('clientId', e.target.value)}
                                                withoutValidation
                                            />
                                            <Field
                                                component={Input}
                                                label={`${bus} Client Secret`}
                                                placeholder={'Enter Client Secret Code here'}
                                                value={values.clientSecret}
                                                name={'clientSecret'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    setFieldValue('clientSecret', e.target.value)
                                                }
                                                withoutValidation
                                            />
                                            <Field
                                                component={Input}
                                                label={`${bus} Username`}
                                                placeholder={'Enter Username here'}
                                                value={values.username}
                                                name={'username'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('username', e.target.value)}
                                                withoutValidation
                                            />
                                            <Field
                                                component={Input}
                                                label={`${bus} Password`}
                                                placeholder={'Enter Password here'}
                                                value={values.password}
                                                name={'password'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('password', e.target.value)}
                                                withoutValidation
                                            />
                                            <Field
                                                component={Input}
                                                label={`${bus} Hotel ID`}
                                                placeholder={'Enter Hotel ID here'}
                                                value={values.hotelId}
                                                name={'hotelId'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('hotelId', e.target.value)}
                                                withoutValidation
                                            />
                                            <Field
                                                component={Input}
                                                label={`${bus} Application Key`}
                                                placeholder={'Enter Application Key here'}
                                                value={values.appKey}
                                                name={'appKey'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('appKey', e.target.value)}
                                                withoutValidation
                                            />
                                            <Field
                                                component={Input}
                                                label={`${bus} External System Code`}
                                                placeholder={'Enter External System Code here'}
                                                value={values.externalSystemCode}
                                                name={'externalSystemCode'}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    setFieldValue('externalSystemCode', e.target.value)
                                                }
                                                withoutValidation
                                            />

                                            <RectangularButton
                                                type="submit"
                                                disabled={disabled}
                                                width={'100%'}
                                                height={'64px'}
                                                backgroundColor={brandColor}
                                                margin={'10px 0 0 0'}
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                onClick={handleSubmit}
                                            >
                                                Confirm
                                            </RectangularButton>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </CodeVerificationWrapper>
                    </ContentWrap>
                </ModalContainer>
            </ModalOverlay>
        </SignUpLayout>
    )
}

export default OracleCustomerCredentialsForm
