import React from 'react'
import { RulesCard } from './RulesCard'
import { RulesLoadingDialog } from './rules-loading-dialog/RulesLoadingDialog'

export default function RulesPage(props) {
    return (
        <>
            <RulesLoadingDialog />
            <RulesCard currentOrganization={props.currentOrganization} />
        </>
    )
}
