import { AreaStruct, type UserStruct } from '@shared/firestore-structs'
import { sortByName } from '@shared/helpers'
import type { UserOption } from '@shared/user-data'
import fuzzy from 'fuzzy'
import { ascend, prop, sortWith } from 'ramda'
import type { Locales } from '../../i18n/i18n-types'

export function findSelectedItemsByKeys<T extends { key: string }>(items: T[], keys: string[]): T[] {
    return items.filter(item => keys.includes(item.key))
}

export const normalizeString = (str: string) =>
    str
        .trim()
        .toLowerCase()
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')

export function search<T>(value: string, searchArray: T[], extract: (item: T) => string): T[] {
    const normalizedValue = normalizeString(value)
    const rawValueLowercased = value.toLowerCase()

    return fuzzy
        .filter(normalizedValue, searchArray, {
            extract: item => normalizeString(extract(item))
        })
        .map(result => {
            const originalText = extract(result.original)
            const normalizedOriginal = normalizeString(originalText).toLowerCase()
            const rawOriginalLowercased = originalText.toLowerCase()

            return {
                item: result.original,
                score: result.score, // Lower fuzzy score = better match
                preNormalizedContains: rawOriginalLowercased.includes(rawValueLowercased) ? 1 : 0, // Exact substring match in raw text
                normalizedMatch: normalizedOriginal.includes(normalizedValue) ? 1 : 0 // Normalized substring match
            }
        })
        .filter(result => result.preNormalizedContains || result.normalizedMatch)
        .sort((a, b) => {
            // Prioritize raw substring matches over normalized matches
            if (a.preNormalizedContains !== b.preNormalizedContains) {
                return b.preNormalizedContains - a.preNormalizedContains
            }
            // Then prioritize normalized matches
            if (a.normalizedMatch !== b.normalizedMatch) {
                return b.normalizedMatch - a.normalizedMatch
            }
            // Finally, sort by fuzzy score (lower score = better match)
            return a.score - b.score
        })
        .map(result => result.item)
}

export function constructBasicOptions<T extends { key: string; name: string; propKey?: string }>(
    items: T[]
): {
    value: string
    propKey?: string
    label: string
}[] {
    return items.map(item => ({ value: item.key, label: item.name, propKey: item.propKey }))
}

export function constructUsersOptions(users: UserStruct[]): UserOption[] {
    return constructBasicOptions(users)
        .map((option, i) => {
            const user = users[i]
            return { ...option, initials: user.initials, data: user }
        })
        .sort((a, b) => sortByName(a.label, b.label))
}

export function constructOptions<T extends { key: string; name: string; propKey?: string }>(
    items: T[]
): (T & {
    value: string
    label: string
    propKey?: string
})[] {
    const options = constructBasicOptions(items).map((option, i) => ({ ...items[i], ...option }))
    return sortWith([ascend(prop('label'))], options)
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const languages: { code: Locales; name: string; flag: string }[] = [
    { code: 'en', name: 'English', flag: '🇺🇸' },
    { code: 'es', name: 'Español', flag: '🇪🇸' },
    { code: 'is', name: 'Íslenska', flag: '🇮🇸' },
    { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
    { code: 'fr', name: 'Français', flag: '🇫🇷' },
    { code: 'it', name: 'Italiano', flag: '🇮🇹' },
    { code: 'uk', name: 'Українська', flag: '🇺🇦' },
    { code: 'pl', name: 'Polski', flag: '🇵🇱' },
    { code: 'pt', name: 'Português', flag: '🇵🇹' },
    { code: 'ro', name: 'Română', flag: '🇷🇴' },
    { code: 'pt-br', name: 'Português (Brazil)', flag: '🇧🇷' }
]
