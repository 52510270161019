import type { TaskStruct } from '@shared/firestore-structs'
import { TASK_TYPE_GENERAL } from '@shared/txt-constants'
import { isObject } from 'lodash'
import moment from 'moment-timezone'
import { selector } from 'recoil'
import { reportData, reportHeaders, reportSummary } from './atoms'

export const exportReportData = selector({
    key: 'exportWorkloadData',
    get: ({ get }) => {
        const taskData = get(reportData)
        const dateHeaders = get(reportHeaders)

        if (taskData.length === 0) {
            return []
        }

        const tableData: { [key: string]: { [key: string]: number } } = {}
        taskData.forEach((task: TaskStruct) => {
            const key = task.type === TASK_TYPE_GENERAL ? task.type : task.name || task.type
            if (!tableData[key]) {
                tableData[key] = {}
            }

            if (moment(task.completedDate).isBefore(moment().startOf('day'))) {
                if (task.key) {
                    tableData[key][task.completedDate] = (tableData[key][task.completedDate] || 0) + 1
                }
            } else {
                tableData[key][task.completedDate] = (tableData[key][task.completedDate] || 0) + 1
            }
        })

        const sortedEntries = Object.entries(tableData).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        const sortedTableData = Object.fromEntries(sortedEntries)

        const summaryMap: { [key: string]: number } = {}
        dateHeaders.forEach(date => {
            summaryMap[date] = Object.entries(sortedTableData).reduce((total, [type, typeData]) => {
                return total + (typeData[date] || 0)
            }, 0)
        })

        const summaryData: (number | string)[] = Object.entries(summaryMap).map(([key, value]) => value)
        summaryData.unshift('Total')

        const formattedEntries = sortedEntries.map(([key, value]) => {
            const type = key
            const dates = dateHeaders.map(date => {
                const val = Object.entries(value).find(([key, v]) => parseInt(key) === date)
                if (val) {
                    return val[1]
                } else {
                    return 0
                }
            })
            return [type, ...dates]
        })

        const headers: string[] = dateHeaders.map(x => moment(x).format('DD/MM/YY'))
        headers.unshift('')

        const headersAndRows = [headers, ...formattedEntries]
        return [...headersAndRows, summaryData]
    }
})
