import { filterByAreaGroupAccess } from '@shared/area-groups-helpers'
import { getBookings2 } from '@shared/booking-data'
import { getCleaningTaskObject } from '@shared/dataObjects'
import { isFeatureOn } from '@shared/feature-toggles'
import type { AreaStruct, BookingStruct, OrgStruct, RuleStruct, TaskStruct, UserStruct } from '@shared/firestore-structs'
import { sortTimeStampAscending } from '@shared/helpers'
import { resolveRule } from '@shared/rule-resolver'
import { getHousekeepingTasksQuery, getTasks, getTasksByDateQuery } from '@shared/task-data'
import * as c from '@shared/txt-constants'
import { chunk } from 'lodash'
import moment from 'moment-timezone'
import { getRules } from '../../../../housekeeping/dashboard/data'
import firebase, { asFirebase, getHousekeepingDashboardAreas } from '../../../../utils/firebase'

const getFutureDates = (dates: number[]) => {
    const today = moment().startOf('day').valueOf()
    return dates.filter(date => date >= today)
}

type ResultType = {
    name: string
    description: string
    priority: number
    cleaningStatus: string
    occupancy?: string
    inspection?: boolean
}
export const calculateTasks = (
    areas: AreaStruct[],
    bookings: BookingStruct[],
    date: number,
    rules: RuleStruct[],
    currentUser: UserStruct,
    currentOrg: OrgStruct
) => {
    return areas
        .map(area => {
            const areaBookings = bookings.filter(b => {
                return b.areaKey === area.key && b.status === 'Booked'
            })
            const areaRules = rules.filter(r => {
                return r.areas.includes(area.key)
            })
            const results = areaRules
                .map(r => {
                    const result: ResultType = resolveRule(r, areaBookings, date, currentOrg, area, {
                        cleanUntilCheckin: isFeatureOn(currentOrg, 'clean-until-checkin')
                    }) as ResultType
                    if (result && Object.keys(result).length > 0) {
                        result.name = r.name
                        result.priority = r.priority
                    }
                    return result
                })
                .filter(x => !x.inspection)
                .filter(x => Object.keys(x).length > 0)

            if (results.length > 0) {
                const result = results.reduce((prev, current) => {
                    return prev.priority > current.priority ? prev : current
                })
                const taskObject: Partial<TaskStruct> = getCleaningTaskObject(currentUser, area, moment(date), [])
                taskObject.status = 'calculated'
                taskObject.name = result.name
                taskObject.completedDate = date
                return taskObject as TaskStruct
            }
        })
        .filter(x => x !== undefined) as TaskStruct[]
}
export const getWorkloadData = async (start: number, end: number, orgKey: string, currentUser: UserStruct) => {
    const numberOfDaysInRange = moment(end).diff(moment(start), 'days') + 1

    const dates = Array.from({ length: numberOfDaysInRange }, (_, index) => {
        return moment(start).add(index, 'days').valueOf()
    })
    const futureDates = getFutureDates(dates)
    const chunkedDates = chunk(futureDates, 10)

    const bookings = []

    let tasks = await getTasks(
        getHousekeepingTasksQuery(
            asFirebase(firebase),
            orgKey,
            [moment(start).valueOf(), moment(end || start).valueOf()],
            [c.TASK_COMPLETE]
        )
    )

    tasks = tasks.filter(task => {
        const filteredArea = filterByAreaGroupAccess(currentUser, [task.area])
        return filteredArea.length > 0
    })

    // Filter out todays tasks
    // tasks = tasks.filter(task => {
    //     return !moment(task.startDate).isSame(moment().startOf('day'), 'day')
    // })

    for (const chunk of chunkedDates) {
        const b = await getBookings2(asFirebase(firebase), orgKey, {
            bookingDates: { operator: 'array-contains-any', value: chunk.map(x => x.toString()) }
        })
        bookings.push(...b)
    }

    const areas = await getHousekeepingDashboardAreas(asFirebase(firebase), orgKey)
    const rules = await getRules(asFirebase(firebase), orgKey)

    let uniqueDates = [...new Set(dates)]
    uniqueDates = uniqueDates.sort(sortTimeStampAscending)

    return {
        dates: uniqueDates,
        tasks,
        bookings,
        rules,
        areas
    }
}

export function mergeTasks(data: TaskStruct[]): TaskStruct[] {
    const mergedData: Record<string, TaskStruct> = {}

    for (const obj of data) {
        const key = `${obj.areaKey}-${obj.name}-${obj.startDate}`

        if (!mergedData[key]) {
            mergedData[key] = obj
        }
    }

    // Convert the merged data back to an array
    const mergedArray = Object.values(mergedData)

    return mergedArray
}
