import QRCode from 'qrcode.react'
import type React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import imgName from '@shared/img/kea-hotels-logo.png'

export const Image = styled.img({
    height: 80,
    width: 110,
    objectFit: 'contain'
})
const QRCodeGenerator: React.FC<{ text: string; token: string }> = ({ text, token }) => {
    const history = useHistory()

    return (
        <div className="d-flex flex-column align-items-start">
            {/*<Image src={imgName} />*/}
            <QRCode
                className="mt-10"
                onClick={() => {
                    history.push(`/guest/login-with-token?token=${token}`)
                }}
                size={300}
                value={text}
            />
        </div>
    )
}

export default QRCodeGenerator
