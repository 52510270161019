import { RULE_TYPE_CHECKOUT, RULE_TYPE_CUSTOM, RULE_TYPE_DAILY } from '@shared/rule-resolver'
import { capitalize } from 'lodash'
import moment from 'moment-timezone'
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useContext, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    getHandlerTableChange,
    getSelectRow,
    headerSortingClasses,
    sortCaret
} from '../../../../../_metronic/_helpers'
import { Pagination } from '../../../../../_metronic/_partials/controls'
import { db } from '../../../../utils/firebase'
import LoadingView from '../../../../utils/loading-view'
import { AuthContext } from '../../../Auth/AuthContext'
import { RulesContext } from '../../RulesContext'
import * as uiHelpers from '../../RulesUIHelpers'
import { RuleDeleteDialog } from '../rule-delete-dialog/RuleDeleteDialog'
import * as columnFormatters from './column-formatters'

export function RulesTable() {
    const {
        rules,
        setRules,
        totalCount,
        setTotalCount,
        listLoading,
        setListLoading,
        ids,
        setIds,
        queryParams,
        setQueryParams,
        openEditRuleDialog,
        openDeleteRuleDialog,
        openRuleAreasDialog
    } = useContext(RulesContext)

    const { user } = useContext(AuthContext)
    const [deleteRuleId, setDeleteRuleId] = useState(null)

    const rulesUIProps = useMemo(() => {
        return {
            ids: ids,
            setIds: setIds,
            queryParams: queryParams,
            setQueryParams: setQueryParams,
            openEditRuleDialog: openEditRuleDialog,
            openDeleteRuleDialog: openDeleteRuleDialog,
            openRuleAreasDialog: openRuleAreasDialog
        }
    }, [ids, setIds, queryParams, setQueryParams, openEditRuleDialog, openDeleteRuleDialog])

    useEffect(() => {
        const sortRules = rules => {
            const sortProperty = queryParams.sortField
            let sortedAreas = rules.sort((a, b) => (a[sortProperty] > b[sortProperty] ? 1 : -1))

            if (queryParams.sortOrder === 'desc') {
                sortedAreas = sortedAreas.reverse()
            }

            sortedAreas = sortedAreas.slice(
                (queryParams.pageNumber - 1) * queryParams.pageSize,
                queryParams.pageNumber * queryParams.pageSize
            )
            return sortedAreas
        }

        const getRules = async () => {
            let rulesRef = await db
                .collection('rules')
                .where('organizationKey', '==', user.organizationKey)
                .where('visible', '==', true)
                .get()

            let fetchedRules = rulesRef.docs.map(a => ({
                id: a.data().key,
                name: a.data().name,
                description: a.data().description,
                areasCount: a.data().areas.length,
                repeatType: capitalize(a.data().repeatType),
                start: moment(a.data().start).format('LL'),
                end: moment(a.data().end).format('LL')
            }))

            let filteredRules = fetchedRules.filter(
                a => queryParams.filter.name === '' || a.name.toLowerCase().includes(queryParams.filter.name.toLowerCase())
            )

            let sortedRules = sortRules(filteredRules)

            setRules(sortedRules)
            setListLoading(false)
            setTotalCount(fetchedRules.length)
        }

        getRules()
    }, [
        queryParams.pageNumber,
        queryParams.pageSize,
        queryParams.sortField,
        queryParams.sortOrder,
        setIds,
        ids,
        setListLoading,
        setTotalCount,
        user.organizationKey,
        queryParams.filter.status,
        queryParams,
        setRules
    ])

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            classes: 'font-weight-bolder',
            style: { cursor: 'pointer' },
            events: {
                onClick: (e, column, columnIndex, row) => {
                    const disabled = ![RULE_TYPE_CHECKOUT, RULE_TYPE_CUSTOM, RULE_TYPE_DAILY].includes(row.repeatType.toLowerCase())
                    if (!disabled) {
                        rulesUIProps.openEditRuleDialog(row.id)
                    }
                }
            },
            formatter: function formatter(cellContent, row) {
                const disabled = ![RULE_TYPE_CHECKOUT, RULE_TYPE_CUSTOM, RULE_TYPE_DAILY].includes(row.repeatType.toLowerCase())
                return (
                    <span className={`font-weight-bolder`} style={{ cursor: disabled ? 'auto' : 'pointer', opacity: disabled ? 0.5 : 1 }}>
                        {cellContent}
                    </span>
                )
            }
        },

        {
            dataField: 'description',
            text: 'Description',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
        },

        {
            dataField: 'areasCount',
            text: 'Linked Units',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            style: { cursor: 'pointer' },
            events: {
                onClick: (e, column, columnIndex, row) => {
                    rulesUIProps.openRuleAreasDialog(row.id)
                }
            },
            // eslint-disable-next-line react/display-name
            formatter: cellContent => {
                return <span className="label label-rounded label-success">{cellContent}</span>
            }
        },

        {
            dataField: 'repeatType',
            text: 'Type',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses
        },

        {
            dataField: 'start',
            text: 'Start Date',
            sort: false,
            sortCaret: sortCaret
        },

        {
            dataField: 'action',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openEditRuleDialog: rulesUIProps.openEditRuleDialog,
                setDeleteRuleId
            },
            classes: 'text-right pr-0 px-5',
            headerClasses: 'text-right pr-3 px-5',
            style: {
                minWidth: '100px'
            }
        }
    ]

    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber
    }

    return (
        <div>
            {deleteRuleId && <RuleDeleteDialog deleteRuleId={deleteRuleId} onHide={() => setDeleteRuleId(null)} />}
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination isLoading={listLoading} paginationProps={paginationProps}>
                            <BootstrapTable
                                hover={true}
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                remote
                                keyField="id"
                                data={rules === null ? [] : rules}
                                columns={columns}
                                defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(rulesUIProps.setQueryParams)}
                                selectRow={getSelectRow({
                                    entities: rules,
                                    ids: rulesUIProps.ids,
                                    setIds: rulesUIProps.setIds
                                })}
                                {...paginationTableProps}>
                                <PleaseWaitMessage entities={rules} />
                                <NoRecordsFoundMessage entities={rules} />
                            </BootstrapTable>
                        </Pagination>
                    )
                }}
            </PaginationProvider>
            {listLoading && <LoadingView />}
        </div>
    )
}
