import { getBrand } from '@shared/brand'
import { ALL_FEATURES } from '@shared/subscriptions/feature-items'
import { MARKETING_PLANS } from '@shared/subscriptions/marketing-plans'
import { atom, selector, selectorFamily } from 'recoil'
import { getPlans } from './api'

export const waitingForUpgradeAtom = atom({ key: 'subscription-plans-waitingForUpgradeAtom', default: false })

export const subscriptionPlans = selector({
    key: 'subscription-plans-subscriptionPlans',
    get: async ({ get }) => {
        const brand = getBrand().firebase.accounting.productFamily

        try {
            const response = await getPlans(brand)
            const currencies = response
                .map(x => {
                    return Object.keys(x.pricing)
                })
                .flat()
            const uniqueCurrencies = [...new Set(currencies)].map(x => x.split(':')[0])

            return { plans: response || [], currencies: uniqueCurrencies }
            // return response || []
        } catch (err) {
            console.error(`allSubscriptionPlans -> getPlans() ERROR : \n${err}`)
            return []
        }
    }
})

export const planState = selectorFamily({
    key: 'subscription-plans-planState',
    get:
        planKey =>
        ({ get }) => {
            const { plans } = get(subscriptionPlans)
            const planObject = plans.find(plan => plan.key === planKey)

            const features = MARKETING_PLANS[planObject.key].map(feature => {
                const { title, group, display, marketingTitle } = ALL_FEATURES[feature]
                return { title, group, display, marketingTitle }
            })

            return { ...planObject, features }
        }
})
