import type { OrgStruct } from '@shared/firestore-structs'
import type { UiConfigType } from '@superset-ui/embedded-sdk'
import MainActionButton from 'app/components/buttons/MainActionButton'
import { type Moment, default as moment } from 'moment-timezone'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import LoadingView from '../../utils/loading-view'
import { AuthContext } from '../Auth/AuthContext'
import { ReportsContext, ReportsProvider } from '../Reports/ReportsContext'
import { ReportsFilter } from '../Reports/pages/reports-filter/ReportsFilter'
import type { FilterType } from '../Reports/pages/reports-filter/types'
import { SupersetDashboard } from './SupersetDashboard'
import type { SupersetDateFilter } from './superset-filters'

export function SupersetHousekeepingReport() {
    return (
        <ReportsProvider>
            <ContextifiedSupersetHousekeepingReport />
        </ReportsProvider>
    )
}

const dateFilters: FilterType['filter']['dateFilter'][] = ['Today', 'Yesterday', 'This month', 'Last month', 'This week']

// These values are hardcoded because we use them to tell the server what
// Superset native filter we want. Filters have both an ID and a name, and
// the name will be the same between environments, but IDs not necessarily.
const createSupersetFilter = (currentOrg: OrgStruct, dateFrom: number, dateTo: number): SupersetDateFilter => ({
    filterName: 'Dates', // Comes from the dashboard config.
    column: 'task_start_date', // Must be actual dataset column name, NOT label or filter name!
    dateFrom: moment.tz(dateFrom, currentOrg.timezone).startOf('day'),
    dateTo: moment.tz(dateTo, currentOrg.timezone).add(1, 'day').startOf('day')
})

export function ContextifiedSupersetHousekeepingReport() {
    const { listLoading, setListLoading, queryParams } = useContext(ReportsContext)
    const { currentOrganization } = useContext(AuthContext)
    if (!currentOrganization) throw new Error('Not logged in')

    const [supersetFilter, setSupersetFilter] = useState<SupersetDateFilter | null>(null)

    const reportsUIProps = useMemo(() => {
        return {
            listLoading: listLoading,
            setListLoading: setListLoading
        }
    }, [listLoading, setListLoading])

    useEffect(() => {
        const getData = async () => {
            reportsUIProps.setListLoading(true)
            const updatedFilter = createSupersetFilter(currentOrganization!, queryParams.filter.startDate, queryParams.filter.endDate)
            setSupersetFilter(updatedFilter)
            setTimeout(() => {
                reportsUIProps.setListLoading(false)
            }, 500)
        }

        getData()
    }, [queryParams.filter.startDate, queryParams.filter.endDate, currentOrganization, reportsUIProps.setListLoading])

    return (
        <>
            <ReportsFilter dateFilter={dateFilters} />
            {supersetFilter && <SupersetDashboard reportName="Housekeeping" filter={supersetFilter} />}
        </>
    )
}
