import moment from 'moment-timezone'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import Alert from '../../../../components/alerts/Alert'
import { asFirestore, db, updateOrgAreasStatistics } from '../../../../utils/firebase'
import { toastErrorStyle, toastSuccessStyle } from '../../../../utils/styles'
import { AuthContext } from '../../../Auth/AuthContext'
import { AreasContext } from '../../AreasContext'

export function AreaDeleteDialog({ deleteAreaId, onHide }) {
    const { setIds } = useContext(AreasContext)
    const { user, currentOrganization } = useContext(AuthContext)

    async function onConfirm() {
        try {
            await db
                .collection('areas')
                .doc(deleteAreaId)
                .set({ visible: false, updated: moment().valueOf(), lastChangedByUser: user.key }, { merge: true })

            setIds([])
            await updateOrgAreasStatistics(asFirestore(db), currentOrganization.key)
            onHide()
            toast.success('Unit deleted successfully', toastSuccessStyle)
        } catch (error) {
            console.error(error)
            toast.error(`Error deleting unit (${error.message})`, toastErrorStyle)
        }
    }

    return (
        <>
            {deleteAreaId && (
                <Alert
                    title={'Unit deleting'}
                    text={'Are you sure to permanently delete this unit?'}
                    onConfirm={onConfirm}
                    onCancel={onHide}
                />
            )}
        </>
    )
}
