import moment from 'moment-timezone'
import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { ModalProgressBar } from '../../../../../_metronic/_partials/controls'
import { ModalFormFooter } from '../../../../components/modals/ModalFormFooter'
import { ModalHeader } from '../../../../components/modals/ModalHeader'
import { db } from '../../../../utils/firebase'
import { AuthContext } from '../../../Auth/AuthContext'
import { AccountsContext } from '../../AccountsContext'

export function AccountDeleteDialog({ id, show, onHide }) {
    const { setIds } = useContext(AccountsContext)
    const { user } = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)

    const deleteArea = async () => {
        setIsLoading(true)
        await db
            .collection('organizations')
            .doc(id)
            .set({ visible: false, updated: moment().valueOf(), lastChangedByUser: user.key }, { merge: true })

        setIsLoading(false)
        setIds([])
        onHide()
    }

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
            <ModalHeader title="Account delete" onHide={onHide} />
            <Modal.Body>
                {!isLoading && <span>Are you sure to permanently delete this account?</span>}
                {isLoading && <span>Account is deleting...</span>}
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <ModalFormFooter onHide={onHide} onSubmit={deleteArea} dataLoading={isLoading} actionsLoading={isLoading} type="delete" />
            </Modal.Footer>
        </Modal>
    )
}
