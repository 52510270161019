import * as colors from '@shared/colors'
import * as helpers from '@shared/helpers'
import { iOSColors } from '@shared/react-native-typography'
import { getAllPlans } from '@shared/subscriptions/get-all-plans'
import React from 'react'
import Select, { createFilter } from 'react-select'
import { db } from '../../utils/firebase'

export default class OrganizationSubscriptionPlanSelect extends React.Component {
    constructor(props) {
        super(props)

        this.plans = []
        this.state = {
            subscription: undefined,
            isDisabled: props.isDisabled
        }

        this.filterConfig = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'start'
        }
    }

    getStyles() {
        return {
            control: styles => ({
                ...styles,
                backgroundColor: colors.white,
                height: 40,
                width: 260,
                borderWidth: 1,
                borderColor: iOSColors.lightGray,
                borderRadius: 6,
                boxShadow: 'none',
                fontSize: 15,
                fontWeight: 500,
                ':hover': {
                    borderWidth: 1,
                    borderColor: colors.gentle_gray_spectaflow,
                    borderRadius: 6
                }
            }),
            option: (styles, { data, isDisabled, isFocused }) => {
                const style = {
                    ...styles,
                    borderBottom: '1px solid',
                    backgroundColor: isFocused ? colors.gentle_gray_spectaflow : colors.white,
                    color: colors.textPrimary,
                    fontSize: 14,
                    borderColor: colors.white,
                    cursor: isDisabled ? 'default' : 'default'
                }
                return style
            },
            placeholder: styles => {
                const style = {
                    ...styles,
                    color: iOSColors.lightGray,
                    fontSize: 15,
                    backgroundColor: colors.white
                }
                return style
            },
            singleValue: (styles, { data }) => {
                const style = [
                    {
                        ...styles,
                        color: data.value ? colors.textPrimary : iOSColors.lightGray
                    }
                ]
                return style
            },
            indicatorSeparator: (styles, { data }) => ({
                ...styles,
                backgroundColor: colors.gentle_gray_spectaflow,
                width: 0
            })
        }
    }

    async componentDidMount() {
        this.selectStyles = this.getStyles()
        this.plans = await getAllPlans(db)
        this.allSubscriptionOptions = this.subscriptionsToOption(this.plans)
    }

    subscriptionsToOption(subscriptions) {
        const options = []
        subscriptions.map(s => options.push({ value: s.name, label: helpers.capitalizeFirstLetter(s.name) }))
        return options
    }

    static getDerivedStateFromProps(props, state) {
        if (props.subscription !== undefined && state.subscription === undefined) {
            return {
                subscription: [
                    {
                        value: props.subscription,
                        label: helpers.capitalizeFirstLetter(props.subscription)
                    }
                ]
            }
        }

        if (props.isDisabled !== state.isDisabled) {
            return { isDisabled: props.isDisabled }
        }

        return null
    }

    render() {
        return (
            <Select
                isDisabled={this.state.isDisabled}
                value={this.state.subscription}
                maxMenuHeight={100}
                placeholder="Select subscription plan"
                isMulti={false}
                name="organizationSubscription"
                options={this.allSubscriptionOptions || this.subscriptionsToOption(this.plans)}
                // styles={this.selectStyles}
                onChange={subscription => {
                    this.setState({ subscription: [subscription] })
                    this.props.onChange(subscription.value)
                }}
                filterOption={createFilter(this.filterConfig)}
            />
        )
    }
}
