import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import guestlineLogo from '../../../../img/pms/guestline/guestline-logo.png'
import { SIGN_UP } from '../../../../navigation/url-constants'
import { PMS_NAMES } from '../../../../utils/constants'
import { startWorkflow } from '../../helpers'
import { GUESTLINE_WORKFLOW } from '../../signup-worflow-templates'

const GuestlineStart = () => {
    const history = useHistory()

    useEffect(() => {
        startWorkflow({
            bus: PMS_NAMES.GUESTLINE,
            imageUrl: guestlineLogo,
            workflowTemplate: GUESTLINE_WORKFLOW,
            currentStep: SIGN_UP.GUESTLINE_START,
            history
        })
    }, [])

    return <></>
}

export default GuestlineStart
