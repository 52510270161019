import React, { useState, useEffect, useContext } from 'react'
import { ModalHeader } from '../../../../components/modals/ModalHeader'
import { AreasContext } from '../../AreasContext'

export function AreaEditDialogHeader({ id, onHide }) {
    const { areaForEdit } = useContext(AreasContext)

    const [title, setTitle] = useState('')

    useEffect(() => {
        let _title = id ? '' : 'New unit'
        if (areaForEdit && id) {
            _title = `Edit unit`
        }

        setTitle(_title)
    }, [areaForEdit])

    return (
        <>
            <ModalHeader title={title} onHide={onHide} />
        </>
    )
}
