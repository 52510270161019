import { getBrand } from '@shared/brand'
import { black_sweeply, white } from '@shared/colors'
import styled from 'styled-components'

const brandColor = getBrand().navBarColor

const RectangularButton = styled.button<{ width?: string; height?: string; backgroundColor?: string; margin?: string; rounded?: boolean }>`
    padding: 0 25px;
    width: ${({ width }) => (width ? width : 'auto')};
    height: ${({ height }) => (height ? height : '54px')};
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : brandColor)};
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 1.538rem;
    line-height: 24px;
    text-align: center;
    color: ${({ color }) => (color ? color : white)};
    margin: ${({ margin }) => (margin ? margin : '0')};
    outline: none;
    border: none;
    border-radius: ${({ rounded }) => (rounded ? '4px' : '0')};

    &:focus {
        outline: none;
        border: none;
    }

    &:active {
        outline: none;
        border: none;
    }

    &:hover,
    &:focus {
        box-shadow: ${({ disabled }) => (disabled ? '' : 'inset 0 0 9px 9px rgba(0, 0, 0, 0.05);')};
    }

    &:disabled {
        background-color: ${black_sweeply};
        opacity: 0.15;
    }
`

export default RectangularButton
