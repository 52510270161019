import { type Moment, default as moment } from 'moment-timezone'
import rison from 'rison'

// Utility types for the native temporal filter.
type DateRange = `${string} : ${string}` // Not perfect, but slightly better?

// More can be added. See Superset code.
export type FilterOperation = '==' | '!=' | 'IN' | 'TEMPORAL_RANGE'

// Native Filters are the Superset term for its own filters present on
// dashboards that get rendered by the UI as date pickers, dropdowns, etc.
// They are stored in the dashboard configuration itself.

// An "everything else" filter that does not require special treatment.
export interface NativeBasicFilter {
    filterId: string
    column: string
    operation: Exclude<FilterOperation, 'TEMPORAL_RANGE'>
    value: unknown
}

// We can explicitly narrow the type for a date filter.
export interface NativeTemporalFilter {
    filterId: string
    column: string
    operation: Extract<FilterOperation, 'TEMPORAL_RANGE'>
    value: DateRange
}

// Add more filter types later (dropdowns, user selectors, etc).
export type NativeFilter = NativeTemporalFilter | NativeBasicFilter

export type SupersetDateFilter = {
    filterName: string
    column: string
    dateFrom: Moment
    dateTo: Moment
}

export type SupersetBasicFilter = {
    filterName: string
    column: string
    operation: FilterOperation
    value: unknown
}

// Add more filter types later.
export type SupersetFilter = SupersetDateFilter | SupersetBasicFilter

const isDateFilter = (filter: SupersetFilter): filter is SupersetDateFilter => 'dateFrom' in filter && 'dateTo' in filter
const formatMoment = (date: Moment) => date.format('YYYY-MM-DD')

export function convertToNativeFilter(uiFilter: SupersetDateFilter & { filterId: string }): NativeTemporalFilter
export function convertToNativeFilter(uiFilter: SupersetBasicFilter & { filterId: string }): NativeBasicFilter
export function convertToNativeFilter(uiFilter: SupersetFilter & { filterId: string }): NativeFilter

export function convertToNativeFilter(uiFilter: SupersetFilter & { filterId: string }) {
    //Date filters
    if (isDateFilter(uiFilter)) {
        const momentFrom = uiFilter.dateFrom.clone().startOf('day')
        const momentTo = uiFilter.dateTo.clone().startOf('day')

        return {
            filterId: uiFilter.filterId,
            column: uiFilter.column,
            operation: 'TEMPORAL_RANGE',
            value: `${formatMoment(momentFrom)} : ${formatMoment(momentTo)}`
        } as NativeTemporalFilter
    }

    // Everything else
    return {
        filterId: uiFilter.filterId,
        column: uiFilter.column,
        operation: uiFilter.operation,
        value: uiFilter.value
    } as NativeBasicFilter
}

export function nativeFiltersToRison(filter: NativeFilter): string {
    return rison.encode({
        [filter.filterId]: {
            __cache: { label: filter.value, validateStatus: false, value: filter.value },
            extraFormData: {
                filters: [{ col: filter.column, op: filter.operation, val: filter.value }]
            },
            filterState: { label: filter.value, validateStatus: false, value: filter.value },
            id: filter.filterId,
            ownState: {}
        }
    })
}
