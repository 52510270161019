import type { SubscriptionPlan } from '../firestore-structs'
import type { RolePermissions } from '../roles/roles-types'
import type { FeatureId } from '../subscriptions.types'
import { ALL_FEATURES } from './feature-items'
import { findAvailableUpgrades } from './get-all-plans'

export class Subscription {
    features: FeatureId[]
    id: string
    key: string
    name: string
    pricing: SubscriptionPlan['pricing']
    status?: string
    upgrades: string[]
    trialEnd?: number
    permissions: RolePermissions[]
    constructor({
        name,
        key,
        id,
        features,
        upgrades,
        pricing,
        status,
        trialEnd
    }: {
        name: string
        key: string
        id: string
        features: FeatureId[]
        upgrades: string[]
        pricing: SubscriptionPlan['pricing']
        status?: string
        trialEnd?: number
    }) {
        this.name = name
        this.id = id
        this.key = key
        this.features = features
        this.permissions = features.map(f => (ALL_FEATURES[f]?.permissions ?? []).map(p => `${f}-${p.id}` as RolePermissions)).flat()
        this.upgrades = upgrades
        this.pricing = pricing
        this.status = status
        this.trialEnd = trialEnd
    }
    isPartOf(featureId: FeatureId) {
        const feature = this.features.filter(f => f === featureId)
        return feature.length > 0
    }
    check(
        featureId: FeatureId
    ):
        | { status: 'available' }
        | { status: 'not-available'; notAccessibleFeature: FeatureId | null; availableUpgrades: Subscription[] }
        | { status: 'upgrade-not-available' } {
        if (this.isPartOf(featureId)) {
            return { status: 'available' }
        } else {
            const upgrades = findAvailableUpgrades(featureId, this) ?? []
            if (upgrades.length > 0) {
                return { notAccessibleFeature: featureId, availableUpgrades: upgrades, status: 'not-available' }
            } else {
                return { status: 'upgrade-not-available' }
            }
        }
    }
}
